import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import LabelWithContent from "../../../components/common/LabelWithContent";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { useEffect, useState } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadSimplifiedTenantsAsync } from "store/actions/TenantActions";
import { loadUsersByTenantAsync } from "store/actions/UserActions";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import TemplateSimplifiedResponse from "interfaces/response/TemplateSimplifiedResponse";
import IEATemplateSelectorDropdown from "../IEATemplateSelectorDropdown";
import PromineoToggleButtonLarge from "components/common/controls/buttons/PromineoToggleButtonLarge";

export interface IEACoreInfoProps {
  ownerConfiguration: ConfigForExchangeAgreementResponse[];
  direction: ConfigDirection;
  templateId: number;
  onTitleChange: (value: string) => void;
  onDirectionChange: (value: ConfigDirection) => void;
  onTemplateIdChange: (value: number) => void;
  onPartnerTenantIdChange: (value: number) => void;
  onPartnerRepresentativeIdChange: (value: number) => void;
}

export default function IEACoreInfo(props: IEACoreInfoProps) {
  const { ownerConfiguration, direction, templateId } = props;

  const dispatch = useDispatch<AppDispatch>();
  const [templateDropdownDataSource, setTemplateDropdownDataSource] = useState<
    TemplateSimplifiedResponse[]
  >([]);
  const [selectedTenantId, setSelectedTenantId] = useState<number>(0);
  const [usersByTenant, setUsersByTenant] = useState<UserSimplifiedResponse[]>(
    []
  );

  const tenants = useSelector(
    (state: RootState) => state.tenantData.simplifiedTenants
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadSimplifiedTenantsAsync(false)).finally(hideLoadingPanel);
  }, [dispatch]);

  useEffect(() => {
    const temporaryDataSource: TemplateSimplifiedResponse[] = [];

    for (let index = 0; index < ownerConfiguration.length; index++) {
      if (ownerConfiguration[index].direction === direction) {
        temporaryDataSource.push(ownerConfiguration[index].template);
      }
    }

    // Filtering for Unique values.
    let filteredDataSource = temporaryDataSource.filter(
      (data, ind) =>
        ind === temporaryDataSource.findIndex((val) => val.id === data.id)
    );

    setTemplateDropdownDataSource(filteredDataSource);
  }, [direction, ownerConfiguration]);

  useEffect(() => {
    if (selectedTenantId > 0) {
      displayLoadingPanel();
      dispatch(loadUsersByTenantAsync({ tenantId: selectedTenantId }))
        .unwrap()
        .then((userList: UserSimplifiedResponse[]) => {
          setUsersByTenant(userList);
        })
        .finally(hideLoadingPanel);
    }
  }, [dispatch, selectedTenantId]);

  return (
    <div className="grid grid-col gap-y-4">
      <LabelWithContent
        label="IEA title"
        addAsterisksymbol={true}
        content={
          <PromineoTextBox
            width={328}
            name="title"
            placeholder="Type..."
            onChange={({ event }: any) => {
              props.onTitleChange(event?.currentTarget?.value);
            }}
          />
        }
      />
      <LabelWithContent
        label="Exchange role"
        addAsterisksymbol={true}
        content={
          // Left value is "Sender" and right value is "Receiver".
          // Default value for this is "Receiving".
          // The value for this control and the value of "direction" should be same always.
          <PromineoToggleButtonLarge
            width={328}
            value={"right"}
            toggleLeftText="Sender"
            toggleRightText="Receiver"
            onToggleLeft={() => {
              props.onDirectionChange(ConfigDirection.Sending);
              props.onTemplateIdChange(0);
            }}
            onToggleRight={() => {
              props.onDirectionChange(ConfigDirection.Receiving);
              props.onTemplateIdChange(0);
            }}
          />
        }
      />

      <LabelWithContent
        label="Template"
        addAsterisksymbol={true}
        content={
          <IEATemplateSelectorDropdown
            templates={templateDropdownDataSource}
            selectedTemplateId={templateId}
            onSelectionChange={(value: number) => {
              props.onTemplateIdChange(value);
            }}
          />
        }
      />

      <LabelWithContent
        label="Partner tenant"
        addAsterisksymbol={true}
        content={
          <PromineoSelectBox
            placeholder="Select"
            items={tenants}
            valueExpr={"id"}
            displayExpr={"name"}
            width={328}
            onValueChange={(value: number) => {
              setSelectedTenantId(value);
              props.onPartnerTenantIdChange(value);
              props.onPartnerRepresentativeIdChange(0);
            }}
          />
        }
      />
      <LabelWithContent
        label="Partner representative ID"
        addAsterisksymbol={true}
        content={
          <PromineoSelectBox
            key={selectedTenantId}
            placeholder="Select"
            width={328}
            items={usersByTenant}
            valueExpr={"id"}
            displayExpr={"fullName"}
            onValueChange={(value: number) => {
              props.onPartnerRepresentativeIdChange(value);
            }}
          />
        }
      />
    </div>
  );
}
