import { createSlice } from "@reduxjs/toolkit";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import SystemAdminFieldResponse from "interfaces/response/SystemAdminFieldResponse";
import SystemUserResponse from "interfaces/response/SystemUserResponse";
import {
  createFieldsForSystemAdmin,
  getFieldForSystemAdminAsync,
  getIlapTermFieldsForTenantAdminAsync,
  getSystemUsersForTenantAdminAsync,
  updateFieldsContentControlForSystemAdminAsync,
  updateIlapTermBySystemAdminAsync,
  updateIlapTermWithValuesAsync,
} from "store/actions/SystemAdminActions";

export interface SystemAdminState {
  users: any[];
  fields: SystemAdminFieldResponse[];
  field: FieldDetailsResponse | null;
  craetedFieldId: number;
  systemUsers: SystemUserResponse[];
}

const initialState: SystemAdminState = {
  users: [],
  fields: [],
  field: null,
  craetedFieldId: 0,
  systemUsers: [],
};

export const systemAdminSlice = createSlice({
  name: "systemAdmin",
  initialState,
  reducers: {
    resetSelectedField: (state) => {
      state.field = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIlapTermFieldsForTenantAdminAsync.pending, (state) => {
      state.fields = [];
    });
    builder.addCase(
      getIlapTermFieldsForTenantAdminAsync.fulfilled,
      (state, action) => {
        state.fields = action.payload;
      }
    );
    builder.addCase(getFieldForSystemAdminAsync.fulfilled, (state, action) => {
      state.field = action.payload;
    });
    builder.addCase(
      updateFieldsContentControlForSystemAdminAsync.fulfilled,
      (state, action) => {
        state.fields.forEach((f) => {
          if (f.id === action.meta.arg.fieldId) {
            f.controlledOn = action.payload.contentControl;
            f.contentControlText = action.payload.contentControlLevelText;
          }
        });

        if (state.field && state.field.id === action.meta.arg.fieldId) {
          state.field.controlledOn = action.payload.contentControl;
          state.field.contentControlLevelText = action.payload.contentControlLevelText;
          state.field.contentControlFieldId = action.payload.systemFieldId;
        }
      }
    );
    builder.addCase(createFieldsForSystemAdmin.pending, (state) => {
      state.craetedFieldId = 0;
    });
    builder.addCase(createFieldsForSystemAdmin.fulfilled, (state, action) => {
      state.craetedFieldId = action.payload;
    });

    builder.addCase(
      updateIlapTermWithValuesAsync.fulfilled,
      (state, action) => {
        state.field = action.payload;
      }
    );

    builder.addCase(
      updateIlapTermBySystemAdminAsync.fulfilled,
      (state, action) => {
        state.field = action.payload;
      }
    );

    builder.addCase(
      getSystemUsersForTenantAdminAsync.fulfilled,
      (state, action) => {
        state.systemUsers = action.payload;
      }
    );
  },
});

export const { resetSelectedField } = systemAdminSlice.actions;

export default systemAdminSlice.reducer;
