import { toast, ToastOptions } from "react-toastify";

const toastOption: ToastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  style: {
    whiteSpace: "pre-line",
  },
};

const showSuccessToastStateKey = "flowShowSuccessToastState";

export const getShowSuccessToastStateFromLocalStorage = () => {
  var storedSuccessToastState = localStorage.getItem(showSuccessToastStateKey);
  const showSuccessToastStateBooleanState =
    !storedSuccessToastState || storedSuccessToastState === "true"
      ? true
      : false;

  return showSuccessToastStateBooleanState;
};

export const setShowSuccessToastStateInLocalStorage = (
  showSuccessToast: boolean
) => {
  const showSuccessToastState = showSuccessToast ? "true" : "false";
  localStorage.setItem(showSuccessToastStateKey, showSuccessToastState);
};

export const toastSuccess = (message: string) => {
  if (getShowSuccessToastStateFromLocalStorage()) {
    toast.success(message, toastOption);
  }
};

export const toastError = (message: string) => {
  toast.error(message, toastOption);
};

export const toastWarning = (message: string) => {
  toast.warn(message, toastOption);
};
