import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import AnalyticsBaseUrlField from "./AnalyticsConnectorBaseUrlField";
import AnalyticsAuthentication from "./AnalyticsAuthentication";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";

interface Props {
  configuration: AnalyticsConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
  executionComponent: ExecutionComponent;
}

export default function AnalyticsConnectorInformation(props: Props) {
  return (
    <>
      <div>
        <AnalyticsBaseUrlField
          configuration={props.configuration}
          mode={props.mode}
          onValueChange={props.handleConfigurationValueChanged}
          validateOnFocusOut={props.validateOnFocusOut}
        />
      </div>

      <AnalyticsAuthentication
        executionComponent={props.executionComponent}
        configuration={props.configuration}
        mode={props.mode}
        handleAuthenticationModeValueChanged={
          props.handleAuthenticationModeValueChanged
        }
        handleConfigurationValueChanged={props.handleConfigurationValueChanged}
      />
    </>
  );
}
