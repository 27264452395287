import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";

export default function UploadActivityCountComponent(e: {
  data: { data: ActiveExchangeAgreementListingResponse };
}) {
  return e.data.data.lastSuccessfulUploadActivityCount !== undefined &&
    e.data.data.lastSuccessfulUploadActivityCount !== null ? (
    <>{e.data.data.lastSuccessfulUploadActivityCount}</>
  ) : (
    <>{"-"}</>
  );
}
