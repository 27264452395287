import { createSlice } from "@reduxjs/toolkit";
import { 
  loadUploadDataTransferLogAsync,
  loadDownloadDataTransferLogAsync
} from "store/actions/DataTransferActions";
import DataTransferEventLogResponse from "interfaces/response/DataTransferEventLogResponse";

export interface DataTransferState {
  dataTransferLogs: DataTransferEventLogResponse[];
}

const initialState: DataTransferState = {
  dataTransferLogs: []
};

export const dataTransferSlice = createSlice({
  name: "DataTransferLogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadUploadDataTransferLogAsync.pending, (state) => {
      state.dataTransferLogs = [];
    });
    builder.addCase(loadUploadDataTransferLogAsync.fulfilled, (state, action) => {
      state.dataTransferLogs = action.payload.dataTransferEventLogs;
    });
    builder.addCase(loadDownloadDataTransferLogAsync.pending, (state) => {
      state.dataTransferLogs = [];
    });
    builder.addCase(loadDownloadDataTransferLogAsync.fulfilled, (state, action) => {
      state.dataTransferLogs = action.payload.dataTransferEventLogs;
    });
  },
});

export default dataTransferSlice.reducer;