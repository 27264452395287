import SummaryComponent from "components/common/SummaryComponent";
import PendingColoredIcon from "components/icons/PendingColoredIcon";
import WrenchIcon from "components/icons/WrenchIcon";
import { InactiveIEASummaryFilterOption } from "shared/enums/feature/InactiveIEASummaryFilterOption";

interface Props {
  underConstructionIEAs: number;
  pendingStartIEAs: number;
  totalIEAs: number;
  selectedFilter: InactiveIEASummaryFilterOption;
  onUnderConstructionIEASummaryButtonClick: () => void;
  onPendingStartIEASummaryButtonClick: () => void;
  onTotalIEASummaryButtonClick: () => void;
}

export default function InactiveIEAGridSummary(props: Props) {
  const {
    underConstructionIEAs,
    pendingStartIEAs,
    totalIEAs,
    selectedFilter,
    onUnderConstructionIEASummaryButtonClick,
    onPendingStartIEASummaryButtonClick,
    onTotalIEASummaryButtonClick,
  } = props;

  return (
    <div className="flex space-x-2">
      <SummaryComponent
        value={totalIEAs}
        label="All new IEAs"
        isSelected={selectedFilter === InactiveIEASummaryFilterOption.Total}
        onSummaryButtonClick={onTotalIEASummaryButtonClick}
      />
      <SummaryComponent
        value={underConstructionIEAs}
        label="Being created"
        isSelected={
          selectedFilter === InactiveIEASummaryFilterOption.UnderConstruction
        }
        iconComponent={<WrenchIcon />}
        onSummaryButtonClick={onUnderConstructionIEASummaryButtonClick}
      />
      <SummaryComponent
        value={pendingStartIEAs}
        label="Pending IEA start"
        isSelected={
          selectedFilter ===
          InactiveIEASummaryFilterOption.PendingExchangeAgreementStart
        }
        iconComponent={<PendingColoredIcon />}
        onSummaryButtonClick={onPendingStartIEASummaryButtonClick}
      />
    </div>
  );
}
