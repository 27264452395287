import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import DataGrid, {
  Column,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { useAppSelector } from "store/hooks";
import ConnectorScheduleResponse from "interfaces/response/ConnectorScheduleResponse";
import ConfigCodeSetTemplate from "../../config-common/ConfigCodeSetTemplate";
import ScheduleGridFilter from "./ScheduleGridFilter";
import "components/common/grid/styles/PromineoUIGrid.css";
import { stringAndNumberCombinationSortComparator } from "shared/utilities/CommonUtility";

interface Props {
  height: number;
  connectorSchedules: ConnectorScheduleResponse[];
  selectedScheduleIds: number[];
  onSelectionChanged: (selectedSchedulesCount: number) => void;
  onCodeSetSelectionChange: (codeSet: number | null) => void;
  gridInfoText?: string;
}

const ConfigScheduleEditor = forwardRef((props: Props, ref) => {
  const selectAllCheckBox = useRef<any>();
  const checkBoxUpdating = useRef<boolean>(false);
  const selectedCodeSet = useRef<Number | null>(null);
  const initializedAlreadySelectedItems = useRef<null | boolean>(null);

  const gridRef = useRef<DataGrid>(null);
  const [schedulesFilters, setSchedulesFilters] = useState<{
    isCodeSetFilterApplied: boolean;
    isFinishDateFilterApplied: boolean;
    isDisabled: boolean;
  }>({ isCodeSetFilterApplied: false, isFinishDateFilterApplied: false, isDisabled: true });

  const selectedConnector = useAppSelector(
    (store) => store.connectorData.selectedConnector
  );

  useImperativeHandle(ref, () => ({
    getSelectedRows() {
      return gridRef.current?.instance.getSelectedRowKeys();
    },
    resetGridSelection() {
      gridRef.current?.instance.clearSelection();
    },
  }));

  const setSelectedCodeSet = (codeSet: null | number) => {
    if (selectedCodeSet.current !== codeSet) {
      props.onCodeSetSelectionChange(codeSet);
    }
    selectedCodeSet.current = codeSet;
  };

  useEffect(() => {
    setSchedulesFilters((prev) => {
      return { ...prev, isDisabled: !(props.connectorSchedules.length > 0) };
    });

    if (props.connectorSchedules.length && props.selectedScheduleIds.length) {
      let codeSetOfAlreadySelectedItems =
        props.connectorSchedules.find(
          (sc) => sc.id === props.selectedScheduleIds[0]
        )?.userFieldSetId ?? null;
      setSelectedCodeSet(codeSetOfAlreadySelectedItems);
    }
  }, [props.connectorSchedules, props.selectedScheduleIds]);

  const CodeSetTemplate = useCallback(
    (data: { data: { data: ConnectorScheduleResponse } }) => {
      return (
        <ConfigCodeSetTemplate
          userFieldSetId={data.data.data.userFieldSetId}
          connectorId={selectedConnector?.id}
        />
      );
    },
    [selectedConnector?.hostFields]
  );

  const isSelectable = (item: ConnectorScheduleResponse) => {
    if (selectedCodeSet.current) {
      return selectedCodeSet.current === item.userFieldSetId;
    }
    return true;
  };

  const isSelectAll = useCallback(
    (dataGrid: any) => {
      const dataSource = dataGrid.getDataSource();
      if (dataSource) {
        const items = props.connectorSchedules || [];
        const selectableItems = items.filter(isSelectable);
        const selectedRowKeys = dataGrid.getSelectedRowKeys();

        let selectedVisibleRowCount = 0;
        selectedRowKeys.forEach((item: number | string) => {
          if (
            items.find(
              (it: ConnectorScheduleResponse) =>
                it.id === item || it._key_ === item
            )
          ) {
            selectedVisibleRowCount++;
          }
        });

        if (!selectedVisibleRowCount) {
          return false;
        }

        return selectedVisibleRowCount >= selectableItems.length
          ? true
          : undefined;
      }

      return false;
    },
    [props.connectorSchedules]
  );

  const onEditorPreparing = useCallback(
    (e: any) => {
      let dataGrid = e.component;
      if (e.command === "select") {
        if (e.parentType === "dataRow" && e.row) {
          if (!isSelectable(e.row.data)) {
            e.editorOptions.disabled = true;
          }
        } else if (e.parentType === "headerRow") {
          e.editorOptions.onInitialized = (e: any) => {
            selectAllCheckBox.current = e.component;
          };
          e.editorOptions.value = isSelectAll(dataGrid);
          e.editorOptions.onValueChanged = (e: any) => {
            if (!e.event) {
              // This block can be executed only while re-rendering after a save click and while selection change.
              // if it is executing for selection change, then checkBoxUpdating.current is true and we are not doing anything for that here.
              // But if it is called for the re-rendering caused by the save change, we are changing checkBoxUpdating.current = true.
              // After that we are changing the checkbox state based on the selection state. This will trigger the onValueChanged again.
              // But this time checkBoxUpdating.current = true, so the else block will be executed and there we are just setting checkBoxUpdating.current = false.
              // So basically, checkBoxUpdating.current is used here to prevent infinite triggering of onValueChanged.
              if (!checkBoxUpdating.current) {
                checkBoxUpdating.current = true;
                selectAllCheckBox.current.option(
                  "value",
                  isSelectAll(dataGrid)
                );
              } else {
                checkBoxUpdating.current = false;
              }

              return;
            }

            if (isSelectAll(dataGrid) === e.value) {
              return;
            }

            e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
            e.event.preventDefault();
          };
        }
      }
    },
    [isSelectAll]
  );

  const notifySelectionChangedToParent = (selectedSchedulesCount: number) => {
    // by calling parent's onSelectionChanged we are notifying parents to change state to modified as selection has been changed.
    // This method can be called by the grid at the initialization of the grid for previously selected items.
    // We want to call parents onSelectionChanged only for manual selection of the grid and not for the grid intialization.
    // If there are no items to select initially, initializedAlreadySelectedItems.current will be null.
    if (
      initializedAlreadySelectedItems.current === null ||
      initializedAlreadySelectedItems.current
    ) {
      props.onSelectionChanged(selectedSchedulesCount);
    } else {
      initializedAlreadySelectedItems.current = true;
    }
  };

  const onSelectionChanged = useCallback(
    (e: any) => {
      const deselectRowKeys: any[] = [];
      let shouldRepaintGrid = !selectedCodeSet.current;
      e.selectedRowsData.forEach((item: ConnectorScheduleResponse) => {
        if (!isSelectable(item)) {
          deselectRowKeys.push(e.component.keyOf(item));
        } else {
          setSelectedCodeSet(item.userFieldSetId ?? null);
        }
      });

      if (deselectRowKeys.length) {
        e.component.deselectRows(deselectRowKeys);
      }

      if (e.selectedRowsData.length === 0) {
        setSelectedCodeSet(null);
        shouldRepaintGrid = true;
      }
      checkBoxUpdating.current = true;
      selectAllCheckBox.current.option("value", isSelectAll(e.component));
      checkBoxUpdating.current = false;

      notifySelectionChangedToParent(e.selectedRowsData.length);

      if (shouldRepaintGrid) {
        e.component.repaint();
      }
    },
    [isSelectAll]
  );

  useEffect(() => {
    const applyFilters = () => {
      var filters = [];
      const codesetFilter = ["userFieldSetId", "=", selectedCodeSet.current];

      const finishDateFilter = [
        "finishDate",
        ">=",
        new Date().setHours(0, 0, 0, 0),
      ];

      if (schedulesFilters.isCodeSetFilterApplied) {
        filters.push(codesetFilter);
      }

      if (schedulesFilters.isFinishDateFilterApplied) {
        filters.push(finishDateFilter);
      }

      if (gridRef && gridRef.current) {
        if (filters.length) {
          gridRef.current.instance.filter(filters);
        } else {
          gridRef.current.instance.clearFilter();
        }
      }
    };

    applyFilters();
  }, [schedulesFilters]);

  const handleFilterSchedulesBasedOnSelectedCodeSet = () => {
    setSchedulesFilters((prev) => {
      return { ...prev, isCodeSetFilterApplied: !prev.isCodeSetFilterApplied };
    });
  };

  const handleFilterSchedulesBasedFinishDate = () => {
    setSchedulesFilters((prev) => {
      return {
        ...prev,
        isFinishDateFilterApplied: !prev.isFinishDateFilterApplied,
      };
    });
  };

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  const ToolbarWidgets = useCallback(() => {
    return (
      <div className="flex space-x-4 items-center">
        <ScheduleGridFilter
          isDisabled={schedulesFilters.isDisabled}
          isCodeSetFilterOn={schedulesFilters.isCodeSetFilterApplied}
          isFinishDateFilterOn={schedulesFilters.isFinishDateFilterApplied}
          onCodeSetFilterChange={handleFilterSchedulesBasedOnSelectedCodeSet}
          onFinishDateFilterChange={handleFilterSchedulesBasedFinishDate}
        />
      </div>
    );
  }, [schedulesFilters]);

  const handleOnContentReady = useCallback(
    (evt: any) => {
      const preSelectGridRows = () => {
        const datasource = evt.component?.getDataSource().items();
        if (
          initializedAlreadySelectedItems.current == null &&
          datasource &&
          datasource.length &&
          props.selectedScheduleIds &&
          props.selectedScheduleIds.length
        ) {
          initializedAlreadySelectedItems.current = false;
          gridRef.current?.instance.selectRows(
            props.selectedScheduleIds,
            false
          );
        }
      };

      preSelectGridRows();
    },
    [props.selectedScheduleIds]
  );

  return (
    <div>
      <PromineoViewGrid
        dataSource={props.connectorSchedules}
        keyExpr={"id"}
        onEditorPreparing={onEditorPreparing}
        onSelectionChanged={onSelectionChanged}
        toolbarConfig={toolbarConfig.current}
        additionalWidget={<ToolbarWidgets />}
        ref={gridRef}
        onContentReady={handleOnContentReady}
        height={props.height}
        noDataText={props.gridInfoText ?? "No data"}
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Selection
          mode="multiple"
          selectAllMode={"allPages"}
          showCheckBoxesMode={"always"}
        />
        <Column
          caption={"ID"}
          dataField="scheduleId"
          alignment="left"
          width={150}
          sortingMethod={stringAndNumberCombinationSortComparator}
          sortOrder={"asc"}
        />
        <Column caption={"Title"} dataField="title" alignment="left" />
        <Column
          caption={"Start Date"}
          dataField="startDate"
          dataType={"date"}
          width={130}
        />
        <Column
          caption={"Finish Date"}
          dataField="finishDate"
          dataType={"date"}
          width={130}
        />
        <Column
          caption={"Last Updated"}
          dataField="lastUpdatedDate"
          alignment="left"
          dataType={"date"}
          width={130}
        />
        <Column
          caption={"Code set ID"}
          dataField="userFieldSetId"
          alignment="left"
          width={130}
          cssClass={"custom-control-padding"}
          cellComponent={CodeSetTemplate}
        />
      </PromineoViewGrid>
    </div>
  );
});

export default ConfigScheduleEditor;
