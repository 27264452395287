import ResponseBase from "interfaces/response/ResponseBase";
import { v4 as uuidv4 } from "uuid";

const ilapidPrefix = "R-";

export const GetNewId = () => {
  return uuidv4();
};

export const GetNewIlapId = () => {
  return `${ilapidPrefix}${GetNewId()}`;
};

export const checkIfResponseIsEqual = (
  obj1: ResponseBase,
  obj2: ResponseBase
) => {
  return (
    (obj1.id && obj2.id && obj1.id === obj2.id) ||
    (obj1._key_ && obj2._key_ && obj1._key_ === obj2._key_)
  );
};

export const deepCopyObject = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const openInNewTab = (url: string) => {
  let hostWithProtocol = `${window.location.protocol}//${window.location.host}`;
  let redirectUrl = `${hostWithProtocol}${url}`;
  window.open(redirectUrl, "_blank");
};

export const copyToClipboard = async (value: string) => {
  await navigator.clipboard.writeText(value);
};

export const getClipboardData = async () => {
  return await navigator.clipboard.readText();
};

export const getHiddenPasswordPlaceHolder = (
  password?: string,
  maxLength?: number
) => {
  let length = Math.min(maxLength || Infinity, password?.length || 0);
  return password ? "•".repeat(length) : "";
};

// both values are number - compare as number
// at least one value is number - number has lower weight then string
// both values are sting - compare as string (lexicographical)
export const stringAndNumberCombinationSortComparator = (
  value1: string,
  value2: string
) => {
  // handling null values
  if (!value1 && !value2) return 0;
  if (!value1) return -1;
  if (!value2) return 1;
  if (value1 === value2) return 0;
  // -1 = value1 is smaller , 0  = both values are equal , 1  = value1 is greater
  let result = 0;

  // handling non-null values
  const value1Numeric = Number(value1);
  const value2Numeric = Number(value2);

  if (!Number.isNaN(value1Numeric) && !Number.isNaN(value2Numeric)) {
    result = value1Numeric < value2Numeric ? -1 : 1;
  } else if (!Number.isNaN(value1Numeric)) {
    result = -1;
  } else if (!Number.isNaN(value2Numeric)) {
    result = 1;
  } else {
    result = value1 < value2 ? -1 : 1;
  }

  return result;
};

export const checkIfHasDuplicates = (
  objects: any[],
  keyFn: (object: any) => string
) => {
  const uniqueCombinations: any = {};
  for (const obj of objects) {
    // Create a unique key by combining multiple property.
    // For example: `${obj.prop1}-${obj.prop2}`
    const key = keyFn(obj);
    if (uniqueCombinations[key]) {
      return true; // Found a duplicate
    }
    uniqueCombinations[key] = true;
  }

  // No duplicates found
  return false;
};

export const booleanSortComparator = (value1: boolean, value2: boolean) => {
  // handling null values
  if (!value1 && !value2) return 0;
  if (!value1) return -1;
  if (!value2) return 1;
  if (value1 === value2) return 0;
  if (value1) return 1;
  else return -1;
};

export const checkIfHasFlag = (value: number, flag: number) => {
  return (value & flag) === flag;
};
