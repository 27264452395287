interface Props {
  text: string;
}

export default function NewIEAWizardInformation(props: Props) {
  return (
    <div className="border-b border-t border-lightGray text-sm py-2">
      {props.text}
    </div>
  );
}
