export default function FlowIcon() {
  return (
    <>
      <svg
        width="16"
        height="30"
        viewBox="0 0 16 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6556 5.39059L6.35791 0.25L6.38697 10.5312L15.6556 5.39059Z"
          fill="#53AE96"
        />
        <path
          d="M11.0942 12.6056V10.2397L0.34375 16.1982L6.3582 20.0244L11.0942 23.0328V12.6056Z"
          fill="#53AE96"
        />
        <path
          d="M11.7041 12.2546V24.1131L6.35791 20.7542V29.7502L15.6556 24.6388V10.0933L11.7041 12.2546Z"
          fill="#53AE96"
        />
      </svg>
    </>
  );
}
