interface Props {
  text: string;
}

export default function TextViewerComponent(props: Props) {
  return (
    <div className="ml-3 font-medium text-textGray text-xs">
      {props.text}
    </div>
  );
}