import TextBox, { ITextBoxOptions } from "devextreme-react/text-box";
import "./styles/PromineoTextBox.css";
import { useEffect, useState } from "react";
import Validator, { RequiredRule } from "devextreme-react/validator";
import { GetNewId } from "shared/utilities/CommonUtility";

interface Props {
  fieldName?: string;
  size?: "normal" | "large";
  required?: boolean;
}

export default function PromineoTextBox(props: Props & ITextBoxOptions) {
  const { fieldName, required, size, className } = props;
  const [validatorRules, setValidatorRules] = useState<any[]>([]);

  useEffect(() => {
    const setValidationRules = () => {
      const rules = [];
      if (required) {
        const rule = (
          <RequiredRule
            key={`${GetNewId()}-${fieldName}`}
            message={`${fieldName} is required.`}
          />
        );
        rules.push(rule);
      }

      setValidatorRules(rules);
    };

    setValidationRules();
  }, [fieldName, required]);

  return (
    <TextBox
      {...props}
      className={`promineo-textbox size-${size ?? "normal"} ${className}`}
    >
      <Validator>{validatorRules}</Validator>
    </TextBox>
  );
}
