export enum PlanningObjectTypes {
  None = 0,
  Schedule = 1 << 0,
  Activity = 1 << 1,
  Successor = 1 << 2,
  ResourceUsage = 1 << 3,
  Resource = 1 << 4,
  Profile = 1 << 5,
  Calendar = 1 << 6,
  Structure = 1 << 7,
  All = 1 << 8,
}
