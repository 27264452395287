import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTenantUsersForTenantAdminAsync } from "store/actions/TenantAdminActions";
import { AppDispatch, RootState } from "store/store";
import TenantAdminUserGrid from "./TenantAdminUserGrid";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function TenantAdminUsers() {
  const dispatch = useDispatch<AppDispatch>();

  const tenantUsers = useSelector(
    (state: RootState) => state.tenantAdminData.tenantUsers
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(getTenantUsersForTenantAdminAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  const pageTitleDivId = "tenant-user-page-header";
  const excludedContainerIds: string[] = [pageTitleDivId];
  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  return (
    <>
      <div className="mb-4 title-section" id={pageTitleDivId}>
        <PageHeaderTitle title="Users" />
      </div>
      <div>
        <TenantAdminUserGrid height={gridHeight} tenantUsers={tenantUsers} />
      </div>
    </>
  );
}
