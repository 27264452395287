import { ReactNode } from "react";

interface Props {
  label: ReactNode | string;
  addAsterisksymbol?: boolean;
}

export default function LabelWithAsteriskSymbol(props: Props) {
  const { label, addAsterisksymbol } = props;

  return (
    <div className="flex font-poppins font-semibold text-xs leading-[18px] space-x-0.5">
      <div className="text-textGray">
        {label}
      </div>
      {addAsterisksymbol && (
        <div className="text-red">
          *
        </div>
      )}
    </div>
  )
}