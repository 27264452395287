import ConnectorHostFieldResponse from "interfaces/response/ConnectorHostFieldResponse";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import "components/common/grid/styles/PromineoUIGrid.css";

interface Props {
  hostFieldList: ConnectorHostFieldResponse[];
  height: number;
}

export default function HostFieldGrid(props: Props) {
  return (
    <>
      <PromineoViewGrid
        dataSource={props.hostFieldList}
        className="connectors-hostField-grid disable-hover promineo-ui-grid"
        toolbarConfig={{
          dislplaySearchPanel: true,
        }}
        height={props.height}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column
          caption={"Code set"}
          dataField="userFieldSetId"
          alignment="left"
          width={200}
        />
        <Column
          caption={"Planning Object Type"}
          dataField="planningObjectTypeText"
          alignment="left"
        />
        <Column caption={"Host field Name"} dataField="name" alignment="left" />
        <Column caption={"Alias"} dataField="alias" alignment="left" />
        <Column
          caption={"Data type"}
          dataField="dataTypeText"
          alignment="left"
        />
      </PromineoViewGrid>
    </>
  );
}
