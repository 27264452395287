import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";
import { EditorMode } from "shared/enums/EditorMode";
import TextViewerComponent from "./TextViewerComponent";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";

interface Props {
  mode: EditorMode;
  wbsCandidates: HostFieldDropdownValueResponse[];
  wbsValue: string;
  fieldName: string;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function WbsComponent(props: Props) {
  const {
    mode,
    wbsCandidates,
    wbsValue,
    fieldName,
    handleConfigurationValueChanged,
  } = props;

  const wbsDisplayText =
    wbsCandidates.find((s) => s.value === wbsValue)?.displayName ?? "-";

  return (
    <>
      {mode === EditorMode.Edit && (
        <PromineoSelectBox
          width={320}
          placeholder="Select"
          items={wbsCandidates}
          value={wbsValue}
          valueExpr={"value"}
          displayExpr={"displayName"}
          searchMode="contains"
          searchExpr="displayName"
          searchEnabled={true}
          onValueChange={(value: number) => {
            if (handleConfigurationValueChanged) {
              handleConfigurationValueChanged(fieldName, value);
            }
          }}
        />
      )}

      {mode === EditorMode.View && (
        <TextViewerComponent text={wbsDisplayText} />
      )}
    </>
  );
}
