import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import SAPConnectorUsernameField from "./SAPConnectorUsernameField";
import SAPConnectorPasswordField from "./SAPConnectorPasswordField";
interface Props {
  mode: PromineoModalMode;
  configuration: SAPConnectorConfiguration;
  validateOnFocusOut?: (evt: any) => void;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function SAPBasicAuthentication(props: Props) {
  const classNames =
    props.mode === PromineoModalMode.Create ? "" : "grid grid-cols-2 gap-x-60";
  return (
    <div className={classNames}>
      <SAPConnectorUsernameField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorPasswordField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
    </div>
  );
}
