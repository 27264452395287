import React from "react";

export default function EyeIcon() {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99984 8.66675C8.83317 8.66675 9.54162 8.37519 10.1252 7.79208C10.7083 7.20853 10.9998 6.50008 10.9998 5.66675C10.9998 4.83342 10.7083 4.12497 10.1252 3.54141C9.54162 2.9583 8.83317 2.66675 7.99984 2.66675C7.1665 2.66675 6.45806 2.9583 5.8745 3.54141C5.29139 4.12497 4.99984 4.83342 4.99984 5.66675C4.99984 6.50008 5.29139 7.20853 5.8745 7.79208C6.45806 8.37519 7.1665 8.66675 7.99984 8.66675ZM7.99984 7.46675C7.49984 7.46675 7.07495 7.29164 6.72517 6.94141C6.37495 6.59164 6.19984 6.16675 6.19984 5.66675C6.19984 5.16675 6.37495 4.74164 6.72517 4.39141C7.07495 4.04164 7.49984 3.86675 7.99984 3.86675C8.49984 3.86675 8.92495 4.04164 9.27517 4.39141C9.62495 4.74164 9.79984 5.16675 9.79984 5.66675C9.79984 6.16675 9.62495 6.59164 9.27517 6.94141C8.92495 7.29164 8.49984 7.46675 7.99984 7.46675ZM7.99984 10.6667C6.37761 10.6667 4.89984 10.2139 3.5665 9.30808C2.23317 8.40275 1.2665 7.18897 0.666504 5.66675C1.2665 4.14453 2.23317 2.93053 3.5665 2.02475C4.89984 1.11941 6.37761 0.666748 7.99984 0.666748C9.62206 0.666748 11.0998 1.11941 12.4332 2.02475C13.7665 2.93053 14.7332 4.14453 15.3332 5.66675C14.7332 7.18897 13.7665 8.40275 12.4332 9.30808C11.0998 10.2139 9.62206 10.6667 7.99984 10.6667ZM7.99984 9.33342C9.25539 9.33342 10.4083 9.00275 11.4585 8.34141C12.5083 7.68053 13.3109 6.78897 13.8665 5.66675C13.3109 4.54453 12.5083 3.65275 11.4585 2.99141C10.4083 2.33053 9.25539 2.00008 7.99984 2.00008C6.74428 2.00008 5.59139 2.33053 4.54117 2.99141C3.49139 3.65275 2.68873 4.54453 2.13317 5.66675C2.68873 6.78897 3.49139 7.68053 4.54117 8.34141C5.59139 9.00275 6.74428 9.33342 7.99984 9.33342Z"
        fill="#434343"
      />
    </svg>
  );
}
