import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import ConnectorsGrid from "./ConnectorsGrid";
import { loadConnectorsForCurrentUserAsync } from "store/actions/ConnectorActions";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function Connectors() {
  const dispatch = useDispatch<AppDispatch>();

  const connectors = useSelector(
    (state: RootState) => state.connectorData.connectors
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadConnectorsForCurrentUserAsync()).finally(hideLoadingPanel);
  }, []);

  const headerDivId: string = "connector-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  return (
    <>
      <div className="mb-4" id={headerDivId}>
        <PageHeaderTitle title="Connectors" />
      </div>
      <div>
        <ConnectorsGrid connectors={connectors} height={gridHeight} />
      </div>
    </>
  );
}
