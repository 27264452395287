import { getDataAsync } from "./API";
import DataTransferLogResponse from "interfaces/response/DataTransferLogResponse";

const dataTransferRouteUrl = "DataTransfers";

export const getUploadDataTransferLogAsync = async(dataTransferEventId: number) => {
  const url = `${dataTransferRouteUrl}/${dataTransferEventId}/Logs/Upload`;

  return await getDataAsync<DataTransferLogResponse>(url);
}

export const getDownloadDataTransferLogAsync = async(dataTransferEventId: number) => {
  const url = `${dataTransferRouteUrl}/${dataTransferEventId}/Logs/Download`;

  return await getDataAsync<DataTransferLogResponse>(url);
}
