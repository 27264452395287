import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import GeneralIEAInfo from "../GeneralIEAInfo";
import OwnerConfiguration from "../OwnerConfiguration";
import PartnerConfiguration from "../PartnerConfiguration";
import { useEffect, useState } from "react";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isOwner: boolean;
  isPartner: boolean;
}

export default function IEAInfo(props: Props) {
  const { exchangeAgreement, isOwner, isPartner } = props;

  const [isCurrentUserSender, setIsCurrentUserSender] = useState(false);

  useEffect(() => {
    if (isOwner && exchangeAgreement.ownerRole === ExchangeRoleEnum.Sender) {
      setIsCurrentUserSender(true);
    } else if (
      isPartner &&
      exchangeAgreement.partnerRole === ExchangeRoleEnum.Sender
    ) {
      setIsCurrentUserSender(true);
    }
  }, [
    isOwner,
    isPartner,
    exchangeAgreement.ownerRole,
    exchangeAgreement.partnerRole,
  ]);

  return (
    <div className="space-y-4">
      <GeneralIEAInfo
        selectedExchangeAgreementId={exchangeAgreement.id}
        startDate={exchangeAgreement.startDate}
        isExchangeAgreementActive={exchangeAgreement.isActive}
        isPaused={exchangeAgreement.isPaused}
        expirationDate={exchangeAgreement.expirationDate}
        comments={exchangeAgreement.comments}
        executionCronExpressionText={exchangeAgreement.executionCronDisplay}
        executionCronExpression={exchangeAgreement.executionCronExpression}
        isCurrentUserSender={isCurrentUserSender}
        frequency={exchangeAgreement.frequencyType}
        lagInMinutes={exchangeAgreement.lagInMinutes}
        predecessorAgreementId={
          exchangeAgreement.predecessorAgreement
            ? exchangeAgreement.predecessorAgreement.id
            : 0
        }
      />

      <OwnerConfiguration
        exchangeAgreement={exchangeAgreement}
        isOwner={isOwner}
        handleOwnerRepresentativeChange={() => {}}
        onSaveChangesClick={() => {}}
        allowEdit={false}
      />

      <PartnerConfiguration
        exchangeAgreement={exchangeAgreement}
        isPartner={isPartner}
        handlePartnerRepresentativeChange={() => {}}
        handleSavePartnerRepresentativeClick={() => {}}
        selectedPartnerScheduleId={exchangeAgreement.partnerSchedule?.id}
        selectedPartnerConnectorId={exchangeAgreement.partnerConnector?.id}
        allowEdit={false}
      />
    </div>
  );
}
