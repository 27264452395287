export default function ExpandNavIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_17429_49296"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="16"
      >
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_17429_49296)">
        <path
          d="M8.5 10.2536L4.5 6.25365L5.43333 5.32031L8.5 8.38698L11.5667 5.32031L12.5 6.25365L8.5 10.2536Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
