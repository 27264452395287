import { useState } from "react";
import SettingPopOver from "./SettingPopOver";
import SettingIcon from "components/icons/SettingIcon";

export default function Setting()
{
  const [isSettingPopoverVisible, setIsSettingPopoverVisible] = useState(false);

  const handleSettingPopoverHiding = () => {
    setIsSettingPopoverVisible(false);
  };

  const handleSettingMenuClick = () => {
    setIsSettingPopoverVisible((prev)=> !prev);
  };

  return (
    <>
      <div className="flex justify-center">
        <div>
          <div
            id="setting-menu"
            className={`cursor-pointer flex justify-center items-center`}
            onClick={handleSettingMenuClick}
          >
            <SettingIcon color="white" />
          </div>
        </div>
        <SettingPopOver
          visible={isSettingPopoverVisible}
          onHiding={handleSettingPopoverHiding}
          target="#setting-menu"
        />
      </div>
    </>
  );
}