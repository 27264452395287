export enum PromineoColorVariant {
  IlapGreen = "ilapGreen",
  LightGreen = "lightGreen",
  Red = "red",
  Orange = "orange",
  IlapBlue = "ilapBlue",
  Purple = "purple",
  LightRed = "lightRed",
  LightOrange = "lightOrange",
  LightGray = "lightGray",
  White = "white",
  IlapLightBlue = "ilapLightBlue"
}