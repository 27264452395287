import LabelWithAsteriskSymbol from "components/common/LabelWithAsteriskSymbol";
import LabelWithContent from "components/common/LabelWithContent";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import IlapTermHeaderData from "interfaces/component-data/IlapTermHeaderData";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import { useEffect } from "react";
import { loadDataTypeValuesAsync } from "store/actions/DropdownValueActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface Props {
  isEdit?: boolean;
  isNew?: boolean;
  headerData: IlapTermHeaderData;
  onChange: (value: DropdownValuesResponse) => void;
}

export default function DataTypeLabel(props: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadDataTypeValuesAsync()).finally(hideLoadingPanel);
  }, []);

  const dataTypes = useAppSelector((state) => state.dropdownData.dataTypes);

  const { isEdit, isNew, headerData, onChange } = props;
  return isEdit ? (
    <LabelWithContent
      label="Data type: "
      isInline={true}
      content={
        <PromineoSelectBox
          width={110}
          defaultValue={headerData?.dataType}
          items={dataTypes}
          displayExpr={"name"}
          valueExpr={"value"}
          onValueChange={onChange}
        />
      }
    />
  ) : (
    <LabelWithAsteriskSymbol label={`Data type: ${headerData?.dataTypeText}`} />
  );
}
