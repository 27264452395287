import React from "react";
import TenantInfoSectionTitle from "./TenantInfoSectionTitle";
import LabelWithContent from "components/common/LabelWithContent";
import TenantFieldSection from "./TenantFieldSection";
import TenantInfoLabelTextContent from "./TenantInfoLabelTextContent";
import OwnTenantResponse from "interfaces/response/OwnTenantResponse";

interface Props {
  tenantDetail: OwnTenantResponse;
}

// We have commented out this part because currently we do not have any backend for Identity Provider information.
export default function TenantIdentityProviderViewer(props: Props) {
  return (
    <div className="flex space-x-2">
      {/* <TenantInfoSectionTitle title="Identity Provider" />
      <div className="flex justify-between">
        <TenantFieldSection>
          <LabelWithContent
            label="Identity provider type"
            content={
              <TenantInfoLabelTextContent
                text={props.tenantDetail.identityProvider.type}
              />
            }
          />
        </TenantFieldSection>
        <TenantFieldSection>
          <LabelWithContent
            label="Identity provider"
            content={
              <TenantInfoLabelTextContent
                text={props.tenantDetail.identityProvider.url}
              />
            }
          />
        </TenantFieldSection>
        <TenantFieldSection>
          <LabelWithContent
            label="Identity provider ID"
            content={
              <TenantInfoLabelTextContent
                text={props.tenantDetail.identityProvider.id?.toString()}
              />
            }
          />
        </TenantFieldSection>
      </div> */}
    </div>
  );
}
