import { ActivityFilter } from "interfaces/common/ActivityFilter";

export const analyticsActivityFilterList: ActivityFilter[] = [
  {
    title: `Filter on a specific metadata field`,
    example: `(Activity["OG_Act_SomeField"]="ABC")`,
  },
  {
    title: `Filter on a specific metadata field starting with "A"`,
    example: `(Activity["OG_Act_SomeField"].StartsWith("A"))`,
  },
  {
    title: `Filter on a specific metadata field ending with "A"`,
    example: `(Activity["OG_Act_SomeField"].EndsWith("A"))`,
  },
  {
    title: `Filter on a specific metadata field containing "A"`,
    example: `(Activity["OG_Act_SomeField"].Contains("A"))`,
  },
];
