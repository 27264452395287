export default function WarningIcon() {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5013 4.74219L19.3451 18.2943H3.65755L11.5013 4.74219ZM11.5013 0.585938L0.0429688 20.3776H22.9596L11.5013 0.585938ZM12.543 15.1693H10.4596V17.2526H12.543V15.1693ZM12.543 8.91927H10.4596V13.0859H12.543V8.91927Z"
        fill="#FF3A3A"
      />
    </svg>
  );
}
