import PromineoSelectBox from "components/common/controls/PromineoSelectBox";

interface Props {
  onChange: (ownerStatus: boolean) => void;
  selectedOwnerStatus: boolean;
}

export default function TenantOwnerStatusDropdown(props: Props) {
  const handleSelectionChanged = (evt: any) => {
    props.onChange(evt.value);
  };

  return (
    <PromineoSelectBox
      placeholder="Select"
      dataSource={[
        { id: true, text: "Yes" },
        {
          id: false,
          text: "No",
        },
      ]}
      valueExpr="id"
      displayExpr={"text"}
      onValueChanged={handleSelectionChanged}
      value={props.selectedOwnerStatus}
    />
  );
}
