import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useNavigate } from "react-router-dom";
import { ACTIVE_IEA } from "shared/constants/RoutePathConstants";
import InactiveIEAEditor from "../inactive-ieas/InactiveIEAEditor";

export default function ArchiveIEAEditor() {
  const navigate = useNavigate();

  const handleIeaSaved = (iea: ExchangeAgreementDetailedResponse) => {
    if (iea.isActive) {
      navigate(`${ACTIVE_IEA}/${iea.id}`);
    }
  };

  return (
    <div>
      <InactiveIEAEditor
        isActiveOrArchiveIeaEdit={true}
        onSaved={handleIeaSaved}
      />
    </div>
  );
}
