import { useEffect, useState } from "react";
import Account from "components/header/account/Account";
import MobileNavigation from "./MobileNavigation";
import FlowIcon from "components/icons/FlowIcon";
import NavigationItems from "./NavigationItems";
import NavItemOption from "interfaces/NavItemOption";
import * as routePath from "shared/constants/RoutePathConstants";
import { useAppSelector } from "store/hooks";
import { ApplicationRole } from "shared/enums/feature/ApplicationRole";
import { NavBarId } from "shared/constants/DivIdConstants";
import Setting from "../setting/Setting";

export default function NavigationBar() {
  const currentUser = useAppSelector((store) => store.userData.mySelfResponse);
  const [visibleNavItems, setVisibleNavItems] = useState<NavItemOption[]>([]);

  const hasApplicationRole = (
    userApplicationRole: number,
    requiredRole: ApplicationRole
  ) => {
    return (userApplicationRole & requiredRole) === requiredRole;
  };

  const navItems: NavItemOption[] = [
    {
      text: "Active IEAs",
      route: routePath.ACTIVE_IEA,
      itemId: "active-ieas",
    },
    {
      text: "New IEAs",
      route: routePath.NEW_IEAS,
      itemId: "new-ieas",
    },
    {
      text: "Archive IEAs",
      route: routePath.ARCHIVE_IEAS,
      itemId: "archive-ieas",
    },
    {
      text: "Setup",
      itemId: "setup",
      shouldVisible: () => {
        return currentUser
          ? hasApplicationRole(
              currentUser.applicationRole,
              ApplicationRole.TenantSetup
            )
          : false;
      },
      children: [
        {
          text: "Connectors",
          route: routePath.CONNECTORS,
          itemId: "connectors",
        },
        {
          text: "Templates",
          route: routePath.TEMPLATES,
          itemId: "templates",
        },
        {
          text: "Configs",
          route: routePath.CONFIGS,
          itemId: "configs",
        },
      ],
    },
    {
      text: "Tenant Admin",
      itemId: "tenant-admin",
      shouldVisible: () => {
        return currentUser
          ? hasApplicationRole(
              currentUser.applicationRole,
              ApplicationRole.TenantAdmin
            )
          : false;
      },
      children: [
        {
          text: "Tenant",
          route: routePath.TENANT_ADMIN_TENANT,
          itemId: "tenant-admin-tenant",
        },
        {
          text: "Users",
          route: routePath.TENANT_ADMIN_USERS,
          itemId: "tenant-admin-users",
        },
        {
          text: "Content control",
          route: routePath.TENANT_ADMIN_FIELDS,
          itemId: "tenant-admin-content-control",
        },
        {
          text: "Clients",
          itemId: "clients",
          children: [
            {
              text: "Autonomous components",
              route: routePath.AUTONOMOUS_COMPONENTS,
              itemId: "autonomous-components",
            },
            {
              text: "ILAP Analytics",
              route: routePath.ILAP_ANALYTICS,
              itemId: "ilap-analytics",
            },
          ]
        }
      ],
    },
    {
      text: "System Admin",
      itemId: "system-admin",
      shouldVisible: () => {
        return currentUser
          ? hasApplicationRole(
              currentUser.applicationRole,
              ApplicationRole.ApplicationAdmin
            )
          : false;
      },
      children: [
        {
          text: "Tenant",
          route: routePath.SYS_ADMIN_TENANTS,
          itemId: "system-admin-tenants",
        },
        {
          text: "Users",
          route: routePath.SYS_ADMIN_USERS,
          itemId: "system-admin-users",
        },
        {
          text: "ILAP terms",
          route: routePath.SYS_ADMIN_ILAP_TERMS,
          itemId: "system-admin-ilap-terms",
        },
      ],
    },
  ];

  const [
    isMobileNavigationMenuIconVisible,
    setIsMobileNavigationMenuIconVisible,
  ] = useState(false);

  useEffect(() => {
    const visibleItems = navItems.filter((it) => {
      return it.shouldVisible ? it.shouldVisible() : true;
    });
    setVisibleNavItems(visibleItems);
  }, [currentUser]);

  const handleMobileNavigationMenuClick = () => {
    setIsMobileNavigationMenuIconVisible((isVisible) => !isVisible);
  };

  const handleMobileNavigationPopOverHiding = () => {
    setIsMobileNavigationMenuIconVisible(false);
  };

  return (
    <div id={NavBarId}>
      <nav className="bg-darkBlue text-white px-7 h-48px flex">
        <div className="flex-1 flex items-center justify-between">
          <div className=" inline md:hidden flex-shrink-0 items-center">
            <div
              onClick={handleMobileNavigationMenuClick}
              id="mobile-navigation"
            >
              <div
                className={`${
                  isMobileNavigationMenuIconVisible ? `text-blue-900` : ``
                }`}
              >
                <i className="dx-icon-menu"></i>
              </div>
              <MobileNavigation
                target="#mobile-navigation"
                visible={isMobileNavigationMenuIconVisible}
                onHiding={handleMobileNavigationPopOverHiding}
                navigationItems={visibleNavItems}
              />
            </div>
          </div>
          <div className="flex-shrink-0 w-auto">
            <FlowIcon />
          </div>
          <div className="hidden md:block flex items-start w-full mx-8 h-full">
            <div className="space-x-16 float-left h-full ">
              <NavigationItems navItems={visibleNavItems} />
            </div>
          </div>
          <div className="w-auto">
            <div className="flex gap-x-6">
              <Setting />
              <Account />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
