import { ActivityFilter } from "interfaces/common/ActivityFilter";

export const primaveraActivityFilterList: ActivityFilter[] = [
  {
    title: `UDF field`,
    example: `(Activity.GetFieldValue("P6_Act_MyUDFName")="YES")`,
  },
  {
    title: `UDF w space in name (P6_Act_My UDFName)`,
    example: `(Activity.GetFieldValue("P6_Act_My_x0020_UDFName")="YES")`,
  },
  {
    title: `UDF w '-' in name (P6_Act_My-UDFName)`,
    example: `(Activity.GetFieldValue("P6_Act_My_x002D_UDFName")="YES")`,
  },
  {
    title: `UDF w '/' in name (P6_Act_My/UDFName)`,
    example: `(Activity.GetFieldValue("P6_Act_My_x002F_UDFName")="YES")`,
  },
  {
    title: `UDF w '&' in name (P6_Act_My&UDFName)`,
    example: `(Activity.GetFieldValue("P6_Act_My_x0026_UDFName")="YES")`,
  },
  {
    title: `Activity Code`,
    example: `(Activity.GetFieldValue("P6_Act_ACG_MyACName")="X")`,
  },
  {
    title: `Activity Code w space in name (P6_Act_ACG_My ACName)`,
    example: `(Acivity.GetFieldValue("P6_Act_ACG_My_x0020_ACName")="X")`,
  },
  {
    title: `Activity Code w '-' in name (P6_Act_ACG_My-ACName)`,
    example: `(Acivity.GetFieldValue("P6_Act_ACG_My_x002D_ACName")="X")`,
  },
  {
    title: `Activity Code w '/' in name (P6_Act_ACG_My/ACName)`,
    example: `(Acivity.GetFieldValue("P6_Act_ACG_My_x002F_ACName")="X")`,
  },
  {
    title: `Activity Code w '&' in name (P6_Act_ACG_My&ACName)`,
    example: `(Acivity.GetFieldValue("P6_Act_ACG_My_x0026_ACName")="X")`,
  },
];
