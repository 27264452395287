import LabelWithCircle from "./LabelWithCircle";
import { ConnectorEventStatus } from "shared/enums/feature/ConnectorEventStatus";
import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import { getLocalFormattedDateTime } from "shared/utilities/DateTimeUtility";

interface Props {
  status?: ConnectorEventStatus;
  lastExecutedAt?: string;
}

export default function ConnectorEventStatusCircle(props: Props) {
  let circleColor = PromineoColorVariant.Red;
  
  if (props.status === ConnectorEventStatus.Success) {
    circleColor = PromineoColorVariant.IlapGreen;
  } else if (
    props.status === ConnectorEventStatus.Pending
  ) {
    circleColor = PromineoColorVariant.Orange;
  }

  let label = props.lastExecutedAt
    ? getLocalFormattedDateTime(props.lastExecutedAt)
    : "";

  return <LabelWithCircle color={circleColor} text={label} />;
}
