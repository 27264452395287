import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DeleteTenantByIdAsync,
  GetAllTenantsAsync,
  GetTenantByIdAsync,
  UpdateTenantOwnerStatusAtSystemLevelAsync,
  createAcceptTenantRequestAsync,
  createRejectTenantRequestAsync,
  createTenantRequestAsync,
  getSimplifiedTenantsAsync,
} from "apis/TenantApi";
import SystemLevelOwnerStatusChangeRequest from "interfaces/request/SystemLevelOwnerStatusChangeRequest";

export const loadSimplifiedTenantsAsync = createAsyncThunk(
  "tenants/loadSimplifiedTenants",
  async (excludeOwn: boolean = false) => {
    return await getSimplifiedTenantsAsync(excludeOwn);
  }
);

export const loadAllTenantsAsync = createAsyncThunk(
  "tenants/loadAllTenants",
  async () => {
    return await GetAllTenantsAsync();
  }
);

export const loadTenantByIdAsync = createAsyncThunk(
  "tenants/loadTenantById",
  async (tenantId: number) => {
    return await GetTenantByIdAsync(tenantId);
  }
);

export const removeTenantByIdAsync = createAsyncThunk(
  "tenants/removeTenantById",
  async (tenantId: number) => {
    return await DeleteTenantByIdAsync(tenantId);
  }
);

export const modifyTenantOwnerStatusAtSystemLevelAsync = createAsyncThunk(
  "tenants/modifyTenantOwnerStatusAtSystemLevel",
  async (tenantRequest: {
    tenantId: number;
    tenantOwnerStatusChangeRequest: SystemLevelOwnerStatusChangeRequest;
  }) => {
    return await UpdateTenantOwnerStatusAtSystemLevelAsync(
      tenantRequest.tenantId,
      tenantRequest.tenantOwnerStatusChangeRequest
    );
  }
);

export const createTenantRegistrationRequestAsync = createAsyncThunk(
  "tenants/createTenantRegistrationRequest",
  async () => {
    return await createTenantRequestAsync();
  }
);

export const acceptTenantRegistrationRequestAsync = createAsyncThunk(
  "tenants/acceptTenantRegistrationRequest",
  async (tenantId: number) => {
    return createAcceptTenantRequestAsync(tenantId);
  }
);

export const rejectTenantRegistrationRequestAsync = createAsyncThunk(
  "tenants/rejectTenantRegistrationRequest",
  async (tenantId: number) => {
    return createRejectTenantRequestAsync(tenantId);
  }
);
