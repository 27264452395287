import { createAsyncThunk } from "@reduxjs/toolkit";
import { addLabelsAsync, getLabelsAsync } from "apis/LabelApi";
import LabelRequest from "interfaces/request/LabelRequest";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";

export const loadLabelsAsync = createAsyncThunk(
  "labels/loadLabelsForTemplate",
  async (supportedLabelEntity: SupportedLabelEntity) => {
    return await getLabelsAsync(supportedLabelEntity);
  }
);

export const addNewLabelsAsync = createAsyncThunk(
  "labels/addNewLabel",
  async (labelRequest: LabelRequest) => {
    return await addLabelsAsync(labelRequest);
  }
);
