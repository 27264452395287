import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import Circle from "./Circle";

interface Props {
  status: DataTransferStatus;
}

export default function DataTransferStatusCircle (props: Props) {
  const { status } = props;

  let circleColor = PromineoColorVariant.Orange;

  if (status === DataTransferStatus.Fail) {
    circleColor = PromineoColorVariant.Red;
  }
  else if (status === DataTransferStatus.Success) {
    circleColor = PromineoColorVariant.IlapGreen;
  }

  return (
    <>
      <Circle color={circleColor} />
    </>
  );
}