import { useState } from "react";
import AccountPopover from "./AccountPopover";
import Avatar from "components/common/Avatar";
import { useAppSelector } from "store/hooks";

export default function Account() {
  const [isAccountPopoverVisible, setIsAccountPopoverVisible] = useState(false);

  const handleAccountPopoverHiding = () => {
    setIsAccountPopoverVisible(false);
  };

  const handleAccountMenuClick = () => {
    setIsAccountPopoverVisible(!isAccountPopoverVisible);
  };

  const mySelfData = useAppSelector((store) => store.userData.mySelfResponse);

  return (
    <>
      <div className="flex justify-center">
        <div>
          <div
            id="account-menu"
            className={`cursor-pointer flex justify-center items-center`}
            onClick={handleAccountMenuClick}
          >
            <Avatar />
          </div>
        </div>
        {mySelfData && (
          <AccountPopover
            visible={isAccountPopoverVisible}
            userInformation={mySelfData}
            onHiding={handleAccountPopoverHiding}
            target="#account-menu"
          />
        )}
      </div>
    </>
  );
}
