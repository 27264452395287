import { useState } from "react";
import PromineoConfirmationDialog from "./controls/PromineoConfirmationDialog";
import PromineoButton, {
  PromineoButtonType,
} from "./controls/buttons/PromineoButton";

interface Props {
  buttonText: string;
  variant: PromineoButtonType;
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}
export default function ButtonWithConfirmationDialog(props: Props) {
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
    useState(false);

  const handleConfirmClicked = () => {
    setIsConfirmationDialogVisible(false);
    props.onConfirm?.();
  };

  const handleClick = () => {
    setIsConfirmationDialogVisible(true);
  };

  const handleCancelClicked = () => {
    setIsConfirmationDialogVisible(false);
    props.onCancel?.();
  };

  return (
    <div>
      <PromineoButton
        variant={props.variant}
        text={props.buttonText}
        onClick={handleClick}
      />
      {isConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleConfirmClicked}
          onCancel={handleCancelClicked}
          content={props.message}
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
        />
      )}
    </div>
  );
}
