import { createSlice } from "@reduxjs/toolkit";
import OwnTenantResponse from "interfaces/response/OwnTenantResponse";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import TemplateFieldUsageResponse from "interfaces/response/TemplateFieldUsageResponse";
import {
  getContentControlFieldsForTenantAdminAsync,
  getFieldForTenantAdminAsync,
  getTemplatesUsingFieldForTenantAdminAsync,
  getTenantForTenantAdminAsync,
  updateTenantFieldWithValuesAsync,
  updateFieldsContentControlForTenantAdminAsync,
  updateTemplateFieldWithValuesAsync,
  updateAdminTenantAsync,
  getTenantUsersForTenantAdminAsync,
  updateUserAsync,
  updateUserStatusAsync,
} from "store/actions/TenantAdminActions";
import TenantAdminFieldResponse from "interfaces/response/TenantAdminFieldResponse";
import TenantUserResponse from "interfaces/response/TenantUserResponse";

interface TemplatesUsingField {
  fieldId: number;
  templates: TemplateFieldUsageResponse[];
}

export interface TenantAdminState {
  tenant: OwnTenantResponse | null;
  fields: TenantAdminFieldResponse[];
  templatesUsingField: TemplatesUsingField;
  field: FieldDetailsResponse | null;
  tenantUsers: TenantUserResponse[];
  currentUser?: TenantUserResponse | null;
}

const templatesUsingFieldInitialState: TemplatesUsingField = {
  fieldId: 0,
  templates: [],
};

const initialState: TenantAdminState = {
  tenant: null,
  fields: [],
  templatesUsingField: templatesUsingFieldInitialState,
  field: null,
  tenantUsers: [],
  currentUser: null,
};

export const tenantAdminSlice = createSlice({
  name: "tenantAdmin",
  initialState,
  reducers: {
    resetCurrentUser: (state) => {
      state.currentUser = null;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTenantForTenantAdminAsync.pending, (state) => {
      state.tenant = null;
    });
    builder.addCase(getTenantForTenantAdminAsync.fulfilled, (state, action) => {
      state.tenant = action.payload;
    });
    builder.addCase(updateAdminTenantAsync.fulfilled, (state, action) => {
      state.tenant = action.payload;
    });
    builder.addCase(
      getContentControlFieldsForTenantAdminAsync.pending,
      (state) => {
        state.fields = [];
      }
    );
    builder.addCase(
      getContentControlFieldsForTenantAdminAsync.fulfilled,
      (state, action) => {
        state.fields = action.payload;
      }
    );
    builder.addCase(
      getTemplatesUsingFieldForTenantAdminAsync.pending,
      (state) => {
        state.templatesUsingField = templatesUsingFieldInitialState;
      }
    );
    builder.addCase(
      getTemplatesUsingFieldForTenantAdminAsync.fulfilled,
      (state, action) => {
        state.templatesUsingField.fieldId = action.meta.arg;
        state.templatesUsingField.templates = action.payload;
      }
    );
    builder.addCase(getFieldForTenantAdminAsync.pending, (state) => {
      state.field = null;
    });
    builder.addCase(getFieldForTenantAdminAsync.fulfilled, (state, action) => {
      state.field = action.payload;
    });
    builder.addCase(
      updateFieldsContentControlForTenantAdminAsync.fulfilled,
      (state, action) => {
        state.fields.forEach((field) => {
          if (field.id === action.meta.arg.fieldId) {
            field.contentControl = action.payload.contentControl;
            field.contentControlText = action.payload.contentControlText;
          }
        });

        if (state.field && state.field.id === action.meta.arg.fieldId) {
          state.field.contentControlLevel = action.payload.contentControl;
          state.field.contentControlLevelText = action.payload.contentControlText;
          state.field.contentControlFieldId = action.payload.tenantFieldId;
        }
      }
    );
    builder.addCase(
      updateTenantFieldWithValuesAsync.fulfilled,
      (state, action) => {
        state.field = action.payload;
      }
    );

    builder.addCase(
      updateTemplateFieldWithValuesAsync.fulfilled,
      (state, action) => {
        state.field = action.payload;
      }
    );

    builder.addCase(
      getTenantUsersForTenantAdminAsync.fulfilled,
      (state, action) => {
        state.tenantUsers = action.payload;
      }
    );

    builder.addCase(updateUserAsync.fulfilled, (state, action) => {
      const requestedUserId = action.meta.arg.userId;
      state.tenantUsers = state.tenantUsers.map((user) => {
        if (user.id === requestedUserId) {
          return {
            ...user,
            applicationRole: action.payload.applicationRole,
            applicationRoleDisplay: action.payload.applicationRoleDisplay ?? "",
          };
        }
        return user;
      });

      if (state.currentUser && state.currentUser.id === requestedUserId) {
        state.currentUser = state.tenantUsers.find(
          (user) => user.id === requestedUserId
        );
      }
    });

    builder.addCase(updateUserStatusAsync.fulfilled, (state, action) => {
      const requestedUserId = action.meta.arg.userId;

      state.tenantUsers = state.tenantUsers.map((user) => {
        if (user.id === requestedUserId) {
          return {
            ...user,
            status: action.payload.status,
            statusDisplay: action.payload.statusDisplay ?? "",
          };
        }
        return user;
      });

      if (state.currentUser && state.currentUser.id === requestedUserId) {
        state.currentUser = state.tenantUsers.find(
          (user) => user.id === requestedUserId
        );
      }
    });
  },
});

export default tenantAdminSlice.reducer;
export const { resetCurrentUser, setCurrentUser } = tenantAdminSlice.actions;
