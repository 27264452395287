import { createSlice } from "@reduxjs/toolkit";
import {
  acceptTenantRegistrationRequestAsync,
  loadAllTenantsAsync,
  loadSimplifiedTenantsAsync,
  loadTenantByIdAsync,
  modifyTenantOwnerStatusAtSystemLevelAsync,
  rejectTenantRegistrationRequestAsync,
  removeTenantByIdAsync,
} from "store/actions/TenantActions";
import TenantSimplifiedResponse from "interfaces/response/TenantSimplifiedResponse";
import TenantResponse from "interfaces/response/TenantResponse";
import TenantDetailResponse from "interfaces/common/TenantDetailResponse";
import { TenantStatusCodes } from "shared/enums/TenantStatus";

export interface TenantState {
  simplifiedTenants: TenantSimplifiedResponse[];
  tenants: TenantResponse[];
  tenant: TenantDetailResponse | null;
}

const initialState: TenantState = {
  simplifiedTenants: [],
  tenants: [],
  tenant: null,
};

export const tenantSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSimplifiedTenantsAsync.fulfilled, (state, action) => {
      state.simplifiedTenants = action.payload;
    });
    builder.addCase(loadAllTenantsAsync.fulfilled, (state, action) => {
      state.tenants = action.payload;
    });
    builder.addCase(loadTenantByIdAsync.fulfilled, (state, action) => {
      state.tenant = action.payload;
    });
    builder.addCase(removeTenantByIdAsync.fulfilled, (state, action) => {
      var tenants = state.tenants.filter((tn) => tn.id !== action.payload);
      state.tenants = tenants;
    });
    builder.addCase(
      modifyTenantOwnerStatusAtSystemLevelAsync.fulfilled,
      (state, action) => {
        if (state.tenant?.id === action.payload.id) {
          state.tenant.canBeOwner = action.payload.canBeOwner;
        }
        var tenants = state.tenants.map((tn) =>
          tn.id === action.payload.id ? action.payload : tn
        );
        state.tenants = tenants;
      }
    );
    builder.addCase(
      acceptTenantRegistrationRequestAsync.fulfilled,
      (state, action) => {
        const tenantId = action.meta.arg;
        state.tenants = state.tenants.map((tenant) => {
          if (tenant.id === tenantId) {
            tenant.tenantStatus = TenantStatusCodes.Active;
          }
          return tenant;
        });
      }
    );
    builder.addCase(
      rejectTenantRegistrationRequestAsync.fulfilled,
      (state, action) => {
        const tenantId = action.meta.arg;
        state.tenants = state.tenants.map((tenant) => {
          if (tenant.id === tenantId) {
            tenant.tenantStatus = TenantStatusCodes.Rejected;
          }
          return tenant;
        });
      }
    );
  },
});

export default tenantSlice.reducer;
