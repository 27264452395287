import SafranExportConfig from "interfaces/host-system-config/safran/SafranExportConfig";
import { EditorMode } from "shared/enums/EditorMode";
import { ScrollView } from "devextreme-react";
import SafranSendingParameterFields from "./SafranSendingParameterFields";

interface Props {
  mode: EditorMode;
  exportConfig: SafranExportConfig;
  height: number;
  selectedConnectorId: number;
  selectedCodeSet: number | null;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
}

export default function SafranSendingParameters(props: Props) {
  const {
    mode,
    exportConfig,
    height,
    selectedConnectorId,
    selectedCodeSet,
    isOverride,
    handleConfigurationValueChanged,
  } = props;

  if (!exportConfig) {
    return <></>;
  }

  return !isOverride ? (
    <ScrollView showScrollbar="always" height={height}>
      <div className="flex space-x-6">
        <div className="w-60 font-semibold text-base text-textGray">
          Safran Sending Parameter
        </div>
        <SafranSendingParameterFields
          exportConfig={exportConfig}
          mode={mode}
          selectedCodeSet={selectedCodeSet}
          selectedConnectorId={selectedConnectorId}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          isOverride={isOverride}
        />
      </div>
    </ScrollView>
  ) : (
    <SafranSendingParameterFields
      exportConfig={exportConfig}
      mode={mode}
      selectedCodeSet={selectedCodeSet}
      selectedConnectorId={selectedConnectorId}
      handleConfigurationValueChanged={handleConfigurationValueChanged}
      isOverride={isOverride}
    />
  );
}
