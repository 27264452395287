import {
  deleteDataAsync,
  getDataAsync,
  postDataAsync,
  putDataAsync,
} from "./API";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import NewExchangeAgreementListingResponse from "interfaces/response/NewExchangeAgreementListingResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import ExchangeAgreementSimplifiedResponse from "interfaces/response/ExchangeAgreementSimplifiedResponse";
import OwnerExchangeAgreementWriteRequest from "interfaces/request/OwnerExchangeAgreementWriteRequest";
import ExchangeAgreementUpdateRequest from "interfaces/request/ExchangeAgreementUpdateRequest";
import { ExchangeAgreementSignResponse } from "interfaces/response/ExchangeAgreementSignResponse";
import { ExchangeAgreementConfigUpdatePreviewResponse } from "interfaces/response/ExchangeAgreementConfigUpdatePreviewResponse";
import ArchiveExchangeAgreementListingResponse from "interfaces/response/ArchiveExchangeAgreementListingResponse";
import ExchangeAgreementConfigUpdateAcceptanceRequest from "interfaces/request/ExchangeAgreementConfigUpdateAcceptanceRequest";

const exhangeAgreementRouteUrl = "ExchangeAgreements";

export const getActiveExchangeAgreementsAsync = async () => {
  const url = `${exhangeAgreementRouteUrl}/Active`;

  return await getDataAsync<ActiveExchangeAgreementListingResponse[]>(url);
};

export const getNewExchangeAgreementsAsync = async () => {
  const url = `${exhangeAgreementRouteUrl}/New`;

  return await getDataAsync<NewExchangeAgreementListingResponse[]>(url);
};

export const getArchiveExchangeAgreementsAsync = async () => {
  const url = `${exhangeAgreementRouteUrl}/Archive`;

  return await getDataAsync<ArchiveExchangeAgreementListingResponse[]>(url);
};

export const getExchangeAgreementDetailsAsync = async (
  exchangeAgreementId: number
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}`;

  return await getDataAsync<ExchangeAgreementDetailedResponse>(url);
};

export const getActiveSimplifiedExchangeAgreementsAsync = async () => {
  const url = `Users/${exhangeAgreementRouteUrl}/Active/Simplified`;

  return await getDataAsync<ExchangeAgreementSimplifiedResponse[]>(url);
};

export const createExchangeAgreementForOwnerAsync = async (
  exchangeAgreementRequest: OwnerExchangeAgreementWriteRequest
) => {
  const url = `${exhangeAgreementRouteUrl}/Owner`;

  return await postDataAsync<
    OwnerExchangeAgreementWriteRequest,
    ExchangeAgreementDetailedResponse
  >(url, exchangeAgreementRequest);
};

export const updateExchangeAgreementForPartnerAsync = async (
  exchangeAgreementId: number,
  exchangeAgreementUpdateRequest: ExchangeAgreementUpdateRequest
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}/Partner`;

  return await putDataAsync<
    ExchangeAgreementUpdateRequest,
    ExchangeAgreementDetailedResponse
  >(url, exchangeAgreementUpdateRequest);
};

export const updateExchangeAgreementAsync = async (
  exchangeAgreementId: number,
  exchangeAgreementUpdateRequest: ExchangeAgreementUpdateRequest
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}`;

  return await putDataAsync<
    ExchangeAgreementUpdateRequest,
    ExchangeAgreementDetailedResponse
  >(url, exchangeAgreementUpdateRequest);
};

export const signExchangeAgreementByPartnerAsync = async (
  exchangeAgreementId: number
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}/Partner/Sign`;

  return await postDataAsync<any, ExchangeAgreementSignResponse>(url, null);
};

export const signExchangeAgreementByOwnerAsync = async (
  exchangeAgreementId: number
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}/Owner/Sign`;

  return await postDataAsync<any, ExchangeAgreementSignResponse>(url, null);
};

export const pauseExchangeAgreementAsync = async (
  exchangeAgreementId: number
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}/Pause`;

  return await postDataAsync<any, any>(url, null);
};

export const resumeExchangeAgreementAsync = async (
  exchangeAgreementId: number
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}/Resume`;

  return await postDataAsync<any, any>(url, null);
};

export const deleteInactiveExchangeAgreementAsync = async (
  exchangeAgreementId: number
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}`;

  return await deleteDataAsync(url);
};

export const generateExchangeAgreementEventAsync = async (
  exchangeAgreementId: number
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}/DataTransfer`;

  return await postDataAsync<any, any>(url, null);
};

export const getConfigChangesPreviewAsync = async (
  exchangeAgreementId: number
) => {
  let apiUrl = `ExchangeAgreements/${exchangeAgreementId}/Config/Changes`;
  let response =
    await getDataAsync<ExchangeAgreementConfigUpdatePreviewResponse>(apiUrl);
  return response;
};

export const republishExchangeAgreementAsync = async (
  exchangeAgreementId: number
) => {
  let apiUrl = `ExchangeAgreements/${exchangeAgreementId}/Republish`;
  let response = await postDataAsync<any, ExchangeAgreementSignResponse>(
    apiUrl,
    null
  );
  return response;
};

export const EditExchangeAgreementConfigUpdateAcceptanceStatusAsync = async (
  exchangeAgreementId: number,
  updateRequest: ExchangeAgreementConfigUpdateAcceptanceRequest
) => {
  const url = `${exhangeAgreementRouteUrl}/${exchangeAgreementId}/EditExchangeAgreementConfigUpdateAcceptanceStatus`;

  await putDataAsync<ExchangeAgreementConfigUpdateAcceptanceRequest, null>(
    url,
    updateRequest
  );
};
