import LabelWithContent from "components/common/LabelWithContent";
import { getLocalFormattedDate } from "shared/utilities/DateTimeUtility";
import DateEditor from "./DateEditor";

export function ExpirationDateFieldViewer(props: { expirationDate: string }) {
  const labelClassName =
    "ml-2 w-full pr-6 font-inter font-medium text-[12px] leading-[18px]";

  return (
    <LabelWithContent
      label="Expiration date"
      content={
        <div className={labelClassName}>
          {getLocalFormattedDate(props.expirationDate)}
        </div>
      }
    />
  );
}

interface Props {
  expirationDate?: string;
  onValueChange?: (field: string, value: string) => void;
  isEdit?: boolean;
  minimumExpirationDate?: string;
}

export default function ExpirationDateField(props: Props) {
  return props.isEdit ? (
    <DateEditor
      title="Expiration date"
      onValueChange={(value) => {
        props.onValueChange?.("expirationDate", value);
      }}
      defaultValue={props.expirationDate}
      min={props.minimumExpirationDate}
    />
  ) : (
    <ExpirationDateFieldViewer expirationDate={props.expirationDate!} />
  );
}
