import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";

interface Props {
  onCodeSetFilterChange: () => void;
  onFinishDateFilterChange: () => void;
  isCodeSetFilterOn: boolean;
  isFinishDateFilterOn: boolean;
  isDisabled?: boolean;
}

export default function ScheduleGridFilter(props: Props) {
  return (
    <div className="flex space-x-4 text-10px">
      <div className="flex space-x-2">
        <PromineoSwitchButton
          onChange={props.onCodeSetFilterChange}
          switchType={SwitchButtonType.Default}
          defaultChecked={props.isCodeSetFilterOn}
          disabled={props.isDisabled}
        />
        <div>Hide schedules outside of selected code set</div>
      </div>
      <div className="flex space-x-2">
        <PromineoSwitchButton
          onChange={props.onFinishDateFilterChange}
          switchType={SwitchButtonType.Default}
          defaultChecked={props.isFinishDateFilterOn}
          disabled={props.isDisabled}
        />
        <div>Hide schedules with finish date before today</div>
      </div>
    </div>
  );
}
