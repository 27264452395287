import { createSlice } from "@reduxjs/toolkit";

export interface LoadinPanelState {
  isLoading: boolean;
  loadingPanelCounter: number;
}

const initialState: LoadinPanelState = {
  isLoading: false,
  loadingPanelCounter: 0,
};

export const loadingPanelSlice = createSlice({
  name: "LoadingPanelSlice",
  initialState,
  reducers: {
    makeLoadingPanelHideForce: () => initialState,
    makeLoadingPanelVisible: (state) => {
      state.loadingPanelCounter++;
      if (state.loadingPanelCounter > 0) {
        state.isLoading = true;
      }
    },
    makeLoadingPanelHide: (state) => {
      if (state.loadingPanelCounter > 0) {
        state.loadingPanelCounter--;
      }
      if (state.loadingPanelCounter === 0) {
        state.isLoading = false;
      }
    },
  },
});

export default loadingPanelSlice.reducer;
export const {
  makeLoadingPanelVisible,
  makeLoadingPanelHide,
  makeLoadingPanelHideForce,
} = loadingPanelSlice.actions;
