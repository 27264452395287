import AllowAppendCalendarsField from "features/common/host-system-parameter-fields/safran/AllowAppendCalendarsField";
import AllowAppendResourceField from "features/common/host-system-parameter-fields/safran/AllowAppendResourceField";
import AllowAppendStructuresField from "features/common/host-system-parameter-fields/safran/AllowAppendStructuresField";
import AllowAppendingTasksField from "features/common/host-system-parameter-fields/safran/AllowAppendingTasksField";
import AllowUpdateStructureDescriptionField from "features/common/host-system-parameter-fields/safran/AllowUpdateStructureDescriptionField";
import CalendarPrefixField from "features/common/host-system-parameter-fields/safran/CalendarPrefixField";
import LinkOptionsField from "features/common/host-system-parameter-fields/safran/LinkOptionsField";
import OnlyIncludeSpecifiedMappingField from "features/common/host-system-parameter-fields/safran/OnlyIncludeSpecifiedMappingField";
import SafranImportConfig from "interfaces/host-system-config/safran/SafranImportConfig";
import { EditorMode } from "shared/enums/EditorMode";
import { useAppSelector } from "store/hooks";

interface Props {
  importConfig: SafranImportConfig;
  mode: EditorMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

const allowAppendCalendarsPropertyName = "allowAppendCalendars";
const allowAppendingTasksPropertyName = "allowAppendingTasks";
const allowAppendResourcePropertyName = "allowAppendResource";
const allowAppendStructuresPropertyName = "allowAppendStructures";
const includeMappingPropertyName = "includeMapping";
const allowUpdateOfStructureDescriptionPropertyName =
  "allowUpdateOfStructureDescription";
const activityLinkRebuildOptionPropertyName = "activityLinkRebuildOption";
const calendarPrefixPropertyName = "calendarPrefix";

export default function SafranReceivingParameterFields(props: Props) {
  const { mode, importConfig, handleConfigurationValueChanged } = props;
  const activityLinkRebuildOptions = useAppSelector(
    (state) => state.dropdownData.activityLinkRebuildOption
  );

  return (
    <>
      <AllowAppendCalendarsField
        mode={mode}
        fieldName={allowAppendCalendarsPropertyName}
        switchButtonValue={importConfig.allowAppendCalendars}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <AllowAppendingTasksField
        mode={mode}
        fieldName={allowAppendingTasksPropertyName}
        switchButtonValue={importConfig.allowAppendingTasks}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <AllowAppendResourceField
        mode={mode}
        fieldName={allowAppendResourcePropertyName}
        switchButtonValue={importConfig.allowAppendResource}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <AllowAppendStructuresField
        mode={mode}
        fieldName={allowAppendStructuresPropertyName}
        switchButtonValue={importConfig.allowAppendStructures}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <AllowUpdateStructureDescriptionField
        mode={mode}
        fieldName={allowUpdateOfStructureDescriptionPropertyName}
        switchButtonValue={importConfig.allowUpdateOfStructureDescription}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <OnlyIncludeSpecifiedMappingField
        mode={mode}
        fieldName={includeMappingPropertyName}
        includeMappingsTypeValue={importConfig.includeMapping}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <LinkOptionsField
        mode={mode}
        dropdownOptions={activityLinkRebuildOptions}
        fieldName={activityLinkRebuildOptionPropertyName}
        selectedValue={importConfig.activityLinkRebuildOption}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <CalendarPrefixField
        mode={mode}
        fieldName={calendarPrefixPropertyName}
        value={importConfig.calendarPrefix ? importConfig.calendarPrefix : ""}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
    </>
  );
}
