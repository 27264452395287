import { useAppSelector } from "store/hooks";
import SapParameterDropdown from "./SapParameterDropdown";
import { applyGridChangesToDataSource } from "shared/utilities/PromineoGridUtility";
import { SapHostParameterGridKey } from "shared/utilities/HostSystemConfigurationUtility";
import { EditorMode } from "shared/enums/EditorMode";

export default function ObjectTypeDropdownCellEdit(props: any) {
  const data = props.data.data;
  const objectTypes = useAppSelector(
    (store) => store.dropdownData.sapObjectTypes
  );

  const handleValueChange = (value: any) => {
    props.data.component.cellValue(
      props.data.row.rowIndex,
      "objectType",
      value
    );

    applyGridChangesToDataSource(SapHostParameterGridKey, props.data.component);
  };

  if (props.mode === EditorMode.View) {
    const objectTypeDisplay = (objectTypes || []).find(
      (o) => o.value === data.objectType
    )?.name ?? "-";
    
    return <>{objectTypeDisplay}</>;
  }

  return (
    <SapParameterDropdown
      dataSource={objectTypes}
      defaultValue={data.objectType}
      onValueChange={handleValueChange}
    />
  );
}
