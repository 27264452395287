import { useState } from "react";
import ServiceTokenCreateDialog from "./ServiceTokenCreateDialog";
import ServiceTokenViewerDialog from "./ServiceTokenViewerDialog";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import { ClientTypeEnum } from "shared/enums/ClientTypeEnum";

interface Props {
  onCancel: () => void;
  clientId: number;
  clientType: ClientTypeEnum
}

export default function ServiceTokenEditor(props: Props) {
  const [isTokenCreateDialogVisible, setIsTokenCreateDialogVisible] =
    useState(true);
  const [createdServiceToken, setCreatedServiceToken] = useState<
    ServiceTokenResponse | undefined
  >();

  const handleTokenCreationSuccess = (serviceTokne: ServiceTokenResponse) => {
    setIsTokenCreateDialogVisible(false);
    setCreatedServiceToken(serviceTokne);
  };

  const handleTokenViewerDismiss = () => {
    setCreatedServiceToken(undefined);
    props.onCancel();
  };

  return isTokenCreateDialogVisible ? (
    <ServiceTokenCreateDialog
      onTokenCreateSuccess={handleTokenCreationSuccess}
      onCancel={props.onCancel}
      clientId={props.clientId}
      clientType={props.clientType}
    />
  ) : createdServiceToken ? (
    <ServiceTokenViewerDialog
      serviceToken={createdServiceToken}
      onDone={handleTokenViewerDismiss}
    />
  ) : null;
}
