import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import { useEffect } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import { SapConnectorAuthenticationMode } from "shared/enums/feature/sap-host-system/SapConnectorAuthenticationMode";
import { checkIfHasFlag } from "shared/utilities/CommonUtility";
import { loadSAPConnectorAuthenticationModeValuesAsync } from "store/actions/DropdownValueActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface Props {
  mode: PromineoModalMode;
  configuration: SAPConnectorConfiguration;
  selectedAuthenticationMode: SapConnectorAuthenticationMode;
  validateOnFocusOut?: (evt: any) => void;
  onValueChange?: (value: number) => void;
  executionComponent: ExecutionComponent;
}

export default function SAPConnectorAuthenticationModeField(props: Props) {
  const {
    mode,
    selectedAuthenticationMode,
    configuration,
    validateOnFocusOut,
  } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadSAPConnectorAuthenticationModeValuesAsync()).finally(
      hideLoadingPanel
    );
  }, []);

  const authenticationModes = useAppSelector(
    (store) => store.dropdownData.sapConnectorAuthenticationMode
  );

  const handleValueChange = (value: number) => {
    props.onValueChange?.(value);
  };

  return (
    <>
      {(mode === PromineoModalMode.Create ||
        mode === PromineoModalMode.Modify) && (
        <PromineoSelectBox
          placeholder="Select"
          width={mode === PromineoModalMode.Create ? 330 : 188}
          fieldName="Authentication Mode"
          required={true}
          items={authenticationModes.filter((m) =>
            checkIfHasFlag(
              m.allowedExecutionComponents,
              props.executionComponent
            )
          )}
          value={selectedAuthenticationMode}
          valueExpr={"value"}
          displayExpr={"name"}
          onValueChange={handleValueChange}
          onFocusOut={(event: any) => {
            validateOnFocusOut?.(event);
          }}
        />
      )}

      {mode === PromineoModalMode.View &&
        configuration.authenticationModeDisplayText}
    </>
  );
}
