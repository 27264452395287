import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import IlapTermsGrid from "./IlapTermsGrid";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect } from "react";
import { getIlapTermFieldsForTenantAdminAsync } from "store/actions/SystemAdminActions";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadFieldContentControlLevelAsync } from "store/actions/DropdownValueActions";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function IlapTerms() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(getIlapTermFieldsForTenantAdminAsync()).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    // content control field options are required in dropdowns inside grid.
    displayLoadingPanel();
    dispatch(loadFieldContentControlLevelAsync()).finally(hideLoadingPanel);
  }, []);

  const ilapTermFields = useAppSelector(
    (store) => store.systemAdminData.fields
  );

  const headerDivId: string = "ialp-terms-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });
  

  return (
    <div>
      <div className="flex mb-4" id={headerDivId}>
        <PageHeaderTitle title={"ILAP terms "} />
      </div>
      <IlapTermsGrid fields={ilapTermFields} height={gridHeight} />
    </div>
  );
}
