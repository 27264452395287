interface Props {
  size?: number;
}

export default function CopyIcon(props: Props) {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66732 0.666016L1.66732 0.666016C0.933984 0.666016 0.333984 1.26602 0.333984 1.99935L0.333984 11.3327H1.66732L1.66732 1.99935L9.66732 1.99935V0.666016ZM11.6673 3.33268L4.33398 3.33268C3.60065 3.33268 3.00065 3.93268 3.00065 4.66602L3.00065 13.9993C3.00065 14.7327 3.60065 15.3327 4.33398 15.3327L11.6673 15.3327C12.4007 15.3327 13.0007 14.7327 13.0007 13.9993L13.0007 4.66602C13.0007 3.93268 12.4007 3.33268 11.6673 3.33268ZM11.6673 13.9993L4.33398 13.9993L4.33398 4.66602L11.6673 4.66602L11.6673 13.9993Z"
        fill="#727272"
      />
    </svg>
  );
}
