export default function ExpandIcon() {
  return (
    <svg 
      width="8" 
      height="6" 
      viewBox="0 0 8 6" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M4 5.24992L0 1.24992L0.933333 0.316589L4 3.38326L7.06667 0.316589L8 1.24992L4 5.24992Z" 
        fill="#727272"
      />
    </svg>
  );
}