import CopyIcon from "components/icons/CopyIcon";
import DataTransferConflictResponse from "interfaces/response/DataTransferConflictResponse";
import { copyToClipboard } from "shared/utilities/CommonUtility";
import { toastSuccess } from "shared/utilities/ToastUtility";

interface Props {
  conflicts: DataTransferConflictResponse[];
}

export default function CopyConflictsHeaderComponent(props: Props) {
  const handleCopyClick = () => {
    var conflicts = props.conflicts || [];
    if (conflicts.length) {
      var formattedConflicts = conflicts
        .map((c) => `${c.planningObjectCode}\t${c.conflictDescription}\t CanBeResolvedAutomatically: ${c.canBeResolvedAutomatically}`)
        .join("\n");
      copyToClipboard(formattedConflicts);
      toastSuccess("Copied to clipboard.");
    }
  };

  return (
    <div
      onClick={handleCopyClick}
      className="cursor-pointer"
      title="Copy conflicts"
    >
      <CopyIcon />
    </div>
  );
}
