import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import SAPConnectorBaseUrlField from "./SAPConnectorBaseUrlField";
import SAPAuthentication from "./SAPAuthentication";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";

interface Props {
  configuration: SAPConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
  executionComponent: ExecutionComponent;
}

export default function SAPConnectorInformation(props: Props) {
  return (
    <div>
      <SAPConnectorBaseUrlField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPAuthentication
        configuration={props.configuration}
        executionComponent={props.executionComponent}
        mode={props.mode}
        handleAuthenticationModeValueChanged={
          props.handleAuthenticationModeValueChanged
        }
        handleConfigurationValueChanged={props.handleConfigurationValueChanged}
      />
    </div>
  );
}
