import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { ConfigGridSummaryProps } from "features/setup/configs/ConfigGridSummary";
import ConfigResponse from "interfaces/response/ConfigResponse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfigGridSummaryFilterOptions } from "shared/enums/feature/ConfigGridSummaryFilterOptions";
import { ConfigStatus } from "shared/enums/feature/ConfigStatus";
import { loadConfigsAsync } from "store/actions/ConfigActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface ConfigData {
  filteredConfigs: ConfigResponse[];
  filterComponentProps: ConfigGridSummaryProps;
}

export default function useConfigGridDataHook(): ConfigData {
  const dispatch = useAppDispatch();
  const [selectedFilter, setSelectedFilter] =
    useState<ConfigGridSummaryFilterOptions>(
      ConfigGridSummaryFilterOptions.All
    );

  const [filteredRecords, setFilteredRecord] = useState<
    Record<ConfigGridSummaryFilterOptions, ConfigResponse[]>
  >({
    [ConfigGridSummaryFilterOptions.All]: [],
    [ConfigGridSummaryFilterOptions.BeingCreated]: [],
    [ConfigGridSummaryFilterOptions.BeingEdited]: [],
    [ConfigGridSummaryFilterOptions.Published]: [],
    [ConfigGridSummaryFilterOptions.UpdateAvailable]: [],
  });

  const configs = useAppSelector((store) => store.configData.configs);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadConfigsAsync()).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    let allConfigs: ConfigResponse[] = [];
    let published: ConfigResponse[] = [];
    let beingCreated: ConfigResponse[] = [];
    let beingEdited: ConfigResponse[] = [];
    let updateAvailable: ConfigResponse[] = [];

    (configs || []).forEach((config) => {
      allConfigs.push(config);

      if (config.updateRequired) {
        updateAvailable.push(config);
      }

      if (config.status === ConfigStatus.Published) {
        published.push(config);
      } else if (config.status === ConfigStatus.UnderConstruction) {
        if (config.isPublishedPreviously) {
          beingEdited.push(config);
        } else {
          beingCreated.push(config);
        }
      }
    });

    setFilteredRecord({
      [ConfigGridSummaryFilterOptions.All]: allConfigs,
      [ConfigGridSummaryFilterOptions.BeingCreated]: beingCreated,
      [ConfigGridSummaryFilterOptions.BeingEdited]: beingEdited,
      [ConfigGridSummaryFilterOptions.Published]: published,
      [ConfigGridSummaryFilterOptions.UpdateAvailable]: updateAvailable,
    });
  }, [configs]);

  const handleFilterChange = useCallback(
    (selectedFilter: ConfigGridSummaryFilterOptions) => {
      setSelectedFilter(selectedFilter);
    },
    []
  );

  const configGridData = useMemo<ConfigData>(() => {
    return {
      filteredConfigs: filteredRecords[selectedFilter],
      filterComponentProps: {
        beingCreated:
          filteredRecords[ConfigGridSummaryFilterOptions.BeingCreated].length,
        beingEdited:
          filteredRecords[ConfigGridSummaryFilterOptions.BeingEdited].length,
        total: filteredRecords[ConfigGridSummaryFilterOptions.All].length,
        published:
          filteredRecords[ConfigGridSummaryFilterOptions.Published].length,
        updateAvailable:
          filteredRecords[ConfigGridSummaryFilterOptions.UpdateAvailable]
            .length,
        selectedFilter,
        onFilterChange: handleFilterChange,
      },
    };
  }, [selectedFilter, filteredRecords, handleFilterChange]);

  return configGridData;
}
