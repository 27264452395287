import AvatarIcon from "components/icons/AvatarIcon";

interface Props {}

export default function Avatar(props: Props) {
  return (
    <>
      <AvatarIcon color="white" />
    </>
  );
}
