import { createSlice } from "@reduxjs/toolkit";
import CoreFieldResponse from "interfaces/response/CoreFieldResponse";
import { loadCoreFieldsAsync } from "store/actions/CoreFieldActions";

export interface CoreFieldState {
  coreFields: CoreFieldResponse[];
}

const initialState: CoreFieldState = {
  coreFields: [],
};

export const coreFieldSlice = createSlice({
  name: "coreFields",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCoreFieldsAsync.pending, (state, action) => {
      state.coreFields = [];
    });
    builder.addCase(loadCoreFieldsAsync.fulfilled, (state, action) => {
      state.coreFields = action.payload;
    });
  },
});

export default coreFieldSlice.reducer;
