import OwnTenantResponse from "interfaces/response/OwnTenantResponse";
import TenantInfoSectionTitle from "./TenantInfoSectionTitle";
import TenantFieldSection from "./TenantFieldSection";
import LabelWithContent from "components/common/LabelWithContent";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import SharePointSetting from "interfaces/common/SharePointSetting";
import TenantInfoLabelTextContent from "./TenantInfoLabelTextContent";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadStorageAtHomeOptionValuesAsync } from "store/actions/DropdownValueActions";
import { StorageAtHomeOption } from "shared/enums/feature/StorageAtHomeOption";

interface Props {
  tenantDetail: OwnTenantResponse;
  isEdit: boolean;
  onChange: (tenantInfo: OwnTenantResponse) => void;
}

export default function StorageViewOrEdit(props: Props) {
  const dispatch = useAppDispatch();
  const initSharePointSetting: SharePointSetting = {
    clientId: "",
    sharePointTenantId: "",
    url: "",
  };

  const handleValueChange = (evt: any) => {
    let previousSharePointSetting: SharePointSetting = props.tenantDetail
      .sharePointSetting
      ? { ...props.tenantDetail.sharePointSetting }
      : initSharePointSetting;

    props.onChange({
      ...props.tenantDetail,
      sharePointSetting: {
        ...previousSharePointSetting,
        [evt.event.currentTarget.name]: evt.value,
      },
    });
  };

  const handleStorageAtHomeOption = (evt: any) => {
    props.onChange({
      ...props.tenantDetail,
      storageAtHomeOption: evt.value,
      sharePointSetting:
        evt.value === StorageAtHomeOption.None
          ? undefined
          : initSharePointSetting,
    });
  };

  const storageAtHomeOptions = useAppSelector(
    (store) => store.dropdownData.storageAtHomeOptions
  );

  useEffect(() => {
    if (!storageAtHomeOptions.length) {
      displayLoadingPanel();
      dispatch(loadStorageAtHomeOptionValuesAsync()).finally(hideLoadingPanel);
    }
  }, []);

  return (
    <div className="flex space-x">
      <TenantInfoSectionTitle title="Storage" />
      <div className="space-y-2 ml-2">
        {props.isEdit ? (
          <LabelWithContent
            label="Storage at home option"
            content={
              <PromineoSelectBox
                width={188}
                items={storageAtHomeOptions}
                displayExpr={"name"}
                valueExpr={"value"}
                onValueChanged={handleStorageAtHomeOption}
                defaultValue={props.tenantDetail.storageAtHomeOption}
              />
            }
          />
        ) : null}
        {props.tenantDetail.storageAtHomeOption ===
        StorageAtHomeOption.SharePoint ? (
          <>
            <div className="flex">
              <TenantFieldSection>
                <LabelWithContent
                  label="SharePoint ClientId"
                  addAsterisksymbol={props.isEdit}
                  content={
                    props.isEdit ? (
                      <PromineoTextBox
                        width={188}
                        name="clientId"
                        defaultValue={
                          props.tenantDetail.sharePointSetting?.clientId ?? ""
                        }
                        onValueChanged={handleValueChange}
                      />
                    ) : (
                      <TenantInfoLabelTextContent
                        text={
                          props.tenantDetail.sharePointSetting?.clientId
                            ? props.tenantDetail.sharePointSetting?.clientId
                            : "-"
                        }
                      />
                    )
                  }
                />
              </TenantFieldSection>
              <TenantFieldSection>
                <LabelWithContent
                  label="SharePoint TenantId"
                  addAsterisksymbol={false}
                  content={
                    props.isEdit ? (
                      <PromineoTextBox
                        width={188}
                        name="sharePointTenantId"
                        defaultValue={
                          props.tenantDetail.sharePointSetting
                            ?.sharePointTenantId ?? ""
                        }
                        onValueChanged={handleValueChange}
                      />
                    ) : (
                      <TenantInfoLabelTextContent
                        text={
                          props.tenantDetail.sharePointSetting
                            ?.sharePointTenantId
                            ? props.tenantDetail.sharePointSetting
                                ?.sharePointTenantId
                            : "-"
                        }
                      />
                    )
                  }
                />
              </TenantFieldSection>
            </div>
            <div>
              <LabelWithContent
                label="Url"
                addAsterisksymbol={props.isEdit}
                content={
                  props.isEdit ? (
                    <PromineoMultilineTextEdit
                      defaultValue={
                        props.tenantDetail.sharePointSetting?.url ?? ""
                      }
                      onValueChanged={handleValueChange}
                      width={680}
                      name="url"
                      className="text-xs"
                    />
                  ) : (
                    <TenantInfoLabelTextContent
                      text={
                        props.tenantDetail.sharePointSetting?.url
                          ? props.tenantDetail.sharePointSetting?.url
                          : "-"
                      }
                    />
                  )
                }
              ></LabelWithContent>
            </div>
          </>
        ) : !props.isEdit ? (
          <span className="ml-2 font-poppins text-xs leading-[18px] text-textGray font-semibold">
            None
          </span>
        ) : null}
      </div>
    </div>
  );
}
