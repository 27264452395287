import DialogHeader from "features/common/DialogHeader";

export default function BulkValueMapInsertDialogHeader() {
  const instructions = [
    "Each line contains a Template value, a Host value and a Comment",
    "Template value, Host value and Comment are separated by semicolons",
    "Template value, Host value and Comment must not contain internal semicolons",
  ];
  return <DialogHeader title={"Upload values"} instructions={instructions} />;
}
