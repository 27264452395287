export default function PendingColoredIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.88889 5.55556V11.1111L13.6111 13.9111L14.4667 12.4889L10.5556 10.1667V5.55556H8.88889ZM20 7.77778V0L17.0667 2.93333C15.2667 1.12222 12.7667 0 10 0C4.47778 0 0 4.47778 0 10C0 15.5222 4.47778 20 10 20C15.5222 20 20 15.5222 20 10H17.7778C17.7778 14.2889 14.2889 17.7778 10 17.7778C5.71111 17.7778 2.22222 14.2889 2.22222 10C2.22222 5.71111 5.71111 2.22222 10 2.22222C12.1444 2.22222 14.0889 3.1 15.5 4.5L12.2222 7.77778H20Z"
        fill="#FFD18B"
      />
    </svg>
  );
}
