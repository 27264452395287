import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import EditIcon from "components/icons/EditIcon";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import { loadTemplateFieldByTemplateFieldIdAsync } from "store/actions/FieldActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import FieldHeader from "../edit-field/FieldHeader";
import "components/common/grid/styles/PromineoUIGrid.css";
import ViewFieldValueGrid from "features/common/view-field-value-grid/ViewFieldValueGrid";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import { FieldContentControlLevel } from "shared/enums/feature/FieldContentControlLevel";
import { ContentControl } from "shared/enums/feature/ContentControl";

export default function FieldViewer() {
  const param = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadcrumbItem[]>([]);

  useEffect(() => {
    if (param.templateFieldId) {
      var templateFieldId = Number(param.templateFieldId);
      displayLoadingPanel();
      dispatch(
        loadTemplateFieldByTemplateFieldIdAsync(templateFieldId)
      ).finally(hideLoadingPanel);
    }
  }, []);

  const templateFieldWithValue = useAppSelector(
    (root) => root.fieldData.currentTemplateField
  );

  const currentUser = useAppSelector((state) => state.userData.mySelfResponse);

  useEffect(() => {
    if (templateFieldWithValue) {
      const items: BreadcrumbItem[] = [
        {
          key: "templates",
          text: "Templates",
          data: { url: TEMPLATES },
        },
        {
          key: "templates-name",
          text: templateFieldWithValue.templateName,
          data: { url: `${TEMPLATES}/${templateFieldWithValue.templateId}` },
        },
        {
          key: "field-name",
          text: templateFieldWithValue.name,
        },
      ];

      setBreadCrumbItems(items);
    }
  }, [templateFieldWithValue]);

  const handleBreadCrumbSelectionChange = (
    ind: number,
    item: BreadcrumbItem
  ) => {
    if (item.data?.url) {
      navigate(item.data.url);
    }
  };

  const handleEditClick = () => {
    if (templateFieldWithValue) {      
      navigate(`/setup/templates/fields/${templateFieldWithValue.id}/edit`);
    }
  };

  const showFieldEditIcon = () => {
    var showIcon = false;

    if (templateFieldWithValue) {
      if (currentUser && currentUser.tenant) {
        showIcon = currentUser.tenant.id === templateFieldWithValue.tenantId;
      }
      showIcon =
        templateFieldWithValue.controlledOn ===
          FieldContentControlLevel.TenantOrTemplate &&
        templateFieldWithValue.contentControlLevel === ContentControl.Template;
    }

    return showIcon;
  };

  const headerDivId: string = "template-field-viewer-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  if (!templateFieldWithValue) return <></>;

  return (
    <div>
      <div id={headerDivId}>
        <div className="mb-1">
          <Breadcrumb
            items={breadCrumbItems}
            onSelectionChange={handleBreadCrumbSelectionChange}
          />
        </div>
        <FieldHeader
          template={templateFieldWithValue}
          titleWidget={
            showFieldEditIcon() ? (
              <div className="flex gap-2 cursor-pointer">
                <div onClick={handleEditClick}>
                  <EditIcon />
                </div>
              </div>
            ) : (
              <></>
            )
          }
        />
      </div>

      <div className="flex justify-center">
        <ViewFieldValueGrid
          dataSource={templateFieldWithValue.values}
          height={gridHeight}
        />
      </div>
    </div>
  );
}
