import { ActivityFilter } from "interfaces/common/ActivityFilter";

export const SapOperationFilterList: ActivityFilter[] = [
  {
    title: "SystemStatusText: Operation.SystemStatusText.Contains(\"DSEX\")",
    example: "Operation.SystemStatusText.Contains(\"DSEX\")",
  },
  {
    title: "Activity: Operation.Activity.StartsWith(\"A\")",
    example: "Operation.Activity.StartsWith(\"A\")",
  },
  {
    title: "ControlKey: Operation.ControlKey = \"Test\"",
    example: "Operation.ControlKey=\"Test\"",
  },
  {
    title: "WorkCntr: Operation.WorkCntr = \"Test\"",
    example: "Operation.WorkCntr=\"Test\"",
  },
  {
    title: "Plant: Operation.Plant = \"Test\"",
    example: "Operation.Plant=\"Test\"",
  },
  {
    title: "Description: Operation.Description.Contains(\"Test\")",
    example: "Operation.Description.Contains(\"Test\")",
  },
  {
    title: "NumberOfCapacities: Operation.NumberOfCapacities > 5",
    example: "Operation.NumberOfCapacities>5",
  },
  {
    title: "DurationNormal: Operation.DurationNormal > 10.0",
    example: "Operation.DurationNormal>10.0",
  },
  {
    title: "DurationNormalUnit: Operation.DurationNormalUnit != \"H\"",
    example: "Operation.DurationNormalUnit!=\"H\"",
  },
  {
    title: "ConstraintTypeStart: Operation.ConstraintTypeStart <> \"Test\"",
    example: "Operation.ConstraintTypeStart<>\"Test\"",
  },
  {
    title: "ConstraintTypeFinish: Operation.ConstraintTypeFinish <> \"Test\"",
    example: "Operation.ConstraintTypeFinish<>\"Test\"",
  },
  {
    title: "WorkActivity: Operation.WorkActivity > 99",
    example: "Operation.WorkActivity>99",
  },
  {
    title: "UnWork: Operation.UnWork = \"H\"",
    example: "Operation.UnWork=\"H\"",
  },
  {
    title: "StartTimeConstraint: Operation.StartTimeConstraint < DateTime.Today",
    example: "Operation.StartTimeConstraint<DateTime.Today",
  },
  {
    title: "FinishTimeConstraint: Operation.FinishTimeConstraint < DateTime.Today",
    example: "Operation.FinishTimeConstraint<DateTime.Today",
  },
  {
    title: "Objnr: Operation.Objnr.Length > 6",
    example: "Operation.Objnr.Length>6",
  },
  {
    title: "CalcKey: Operation.CalcKey = \"X\"",
    example: "Operation.CalcKey=\"X\"",
  },
  {
    title: "FieldUserStatus: Operation.FieldUserStatus = \"X\"",
    example: "Operation.FieldUserStatus=\"X\"",
  },
  {
    title: "WorkActual: Operation.WorkActual > 100",
    example: "Operation.WorkActual>100",
  },
  {
    title: "SystemCondition: Operation.SystemCondition = \"X\"",
    example: "Operation.SystemCondition=\"X\"",
  },
  {
    title: "StandardTextKey: Operation.StandardTextKey = \"X\"",
    example: "Operation.StandardTextKey=\"X\"",
  },
  {
    title: "ScheduledEarlyStartTime: Operation.ScheduledEarlyStartTime < DateTime.Today",
    example: "Operation.ScheduledEarlyStartTime<DateTime.Today",
  },
  {
    title: "ScheduledEarlyFinishTime: Operation.ScheduledEarlyFinishTime < DateTime.Today",
    example: "Operation.ScheduledEarlyFinishTime<DateTime.Today",
  },
  {
    title: "Transferdate: Operation.Transferdate < DateTime.Today",
    example: "Operation.Transferdate<DateTime.Today",
  },
];
