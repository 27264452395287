import { ValueTransformationProps } from "features/setup/configs/config-edit/config-value-transformation/ConfigValueTransformationEditor";
import { DirectMappingEditorProps } from "features/setup/configs/config-edit/direct-mapping/DirectMappingEditor";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { useEffect, useMemo, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import {
  checkIfResponseIsEqual,
  deepCopyObject,
} from "shared/utilities/CommonUtility";

type ConfigMapapingAndValueTransformationHookReturnType = {
  configDirectMappings: FieldMappingResponse[];
  configValueTransformations: FieldMappingResponse[];
  directMappingEditorProps: DirectMappingEditorProps;
  valueTransformationGridProps: ValueTransformationProps;
};

interface Props {
  isEdit: boolean;
  height: number;
  selectedCodeSet: number | null;
  configDirection: ConfigDirection;
  initialDirectMappings: FieldMappingResponse[];
  initialValueTranformations: FieldMappingResponse[];
}

export default function useConfigMapapingAndValueTransformation(
  props: Props
): ConfigMapapingAndValueTransformationHookReturnType {
  const [configDirectMappings, setConfigDirectMappings] = useState<
    FieldMappingResponse[]
  >(props.initialDirectMappings);

  const [configValueTransformations, setConfigValueTransformations] = useState<
    FieldMappingResponse[]
  >(props.initialValueTranformations);

  useEffect(() => {
    setConfigDirectMappings(props.initialDirectMappings);
  }, [props.initialDirectMappings]);

  useEffect(() => {
    setConfigValueTransformations(props.initialValueTranformations);
  }, [props.initialValueTranformations]);

  const handleHostFieldChange = (
    mappingToModify: FieldMappingResponse,
    hostField: HostFieldResponse
  ) => {
    setConfigDirectMappings((prev: FieldMappingResponse[]) => {
      let modifiedMappings = prev.map((item) => {
        if (hostField && checkIfResponseIsEqual(item, mappingToModify)) {
          return {
            ...item,
            mappedConnectorHostFieldId: hostField.id,
            mappedConnectorHostFieldName: hostField.name,
          };
        }
        return item;
      });
      return modifiedMappings;
    });
  };

  const updateConfigValueTransformation = (
    updatedMapping: FieldMappingResponse,
    hasDirectMapping: boolean
  ) => {
    if (hasDirectMapping) {
      setConfigValueTransformations((prev) => {
        return prev.filter(
          (mapping) => !checkIfResponseIsEqual(mapping, updatedMapping)
        );
      });
    } else {
      setConfigValueTransformations((prev) => {
        return [
          ...prev,
          { ...updatedMapping, hasDirectMapping: false, formula: null },
        ];
      });
    }
  };

  const handleDirectMappingChange = (
    mappingToModify: FieldMappingResponse,
    hasDirectMapping: boolean
  ) => {
    setConfigDirectMappings((prev: FieldMappingResponse[]) => {
      let modifiedMappings = prev.map((item) => {
        if (checkIfResponseIsEqual(item, mappingToModify)) {
          return {
            ...item,
            hasDirectMapping: hasDirectMapping,
            formula: hasDirectMapping ? null : item.formula,
            valueMaps: hasDirectMapping ? [] : item.valueMaps,
          };
        }
        return item;
      });
      return modifiedMappings;
    });

    updateConfigValueTransformation(mappingToModify, hasDirectMapping);
  };

  const onMappingFormulaUpdate = (data: FieldMappingResponse) => {
    const updateConfigFieldMappings: FieldMappingResponse[] =
      configValueTransformations.map((fm) => {
        if (fm.id === 0) {
          return fm._key_ === data._key_
            ? deepCopyObject(data)
            : deepCopyObject(fm);
        } else {
          return fm.id === data.id ? deepCopyObject(data) : deepCopyObject(fm);
        }
      });
    setConfigValueTransformations(updateConfigFieldMappings);
  };

  const onValueMapsUpdate = (data: FieldMappingResponse) => {
    const updateConfigFieldMappings: FieldMappingResponse[] =
      configValueTransformations.map((fm) => {
        if (fm.id === 0) {
          return fm._key_ === data._key_
            ? deepCopyObject(data)
            : deepCopyObject(fm);
        } else {
          return fm.id === data.id ? deepCopyObject(data) : deepCopyObject(fm);
        }
      });
    setConfigValueTransformations(updateConfigFieldMappings);
  };

  const onNewIlapCoreFieldAdd = (data: FieldMappingResponse) => {
    const updateConfigFieldMappings: FieldMappingResponse[] =
      configValueTransformations.map((fm) => deepCopyObject(fm));
    updateConfigFieldMappings.push(data);
    setConfigValueTransformations(updateConfigFieldMappings);
  };

  const onIlapCoreFieldDelete = (data: FieldMappingResponse) => {
    var updateConfigFieldMappings: FieldMappingResponse[] =
      configValueTransformations
        .filter((fm) =>
          fm._key_ ? fm._key_ !== data._key_ : fm.id !== data.id
        )
        .map((fm) => deepCopyObject(fm));

    setConfigValueTransformations(updateConfigFieldMappings);
  };

  const directMappingEditorProps = useMemo<DirectMappingEditorProps>(() => {
    return {
      fieldMappings: configDirectMappings,
      height: props.height,
      onDirectMappingToggle: handleDirectMappingChange,
      onHostFieldChange: handleHostFieldChange,
      selectedCodeSet: props.selectedCodeSet,
      isEdit: props.isEdit,
    };
  }, [configDirectMappings, props.height, props.selectedCodeSet]);

  const valueTransformationGridProps = useMemo<ValueTransformationProps>(() => {
    return {
      configDirection: props.configDirection,
      configFieldMappings: configValueTransformations,
      height: props.height,
      onIlapCoreFieldDelete: onIlapCoreFieldDelete,
      onMappingFormulaUpdate: onMappingFormulaUpdate,
      onNewIlapCoreFieldAdd: onNewIlapCoreFieldAdd,
      onValueMapsUpdate: onValueMapsUpdate,
      selectedCodeSet: props.selectedCodeSet,
    };
  }, [
    props.configDirection,
    configValueTransformations,
    props.selectedCodeSet,
    props.height,
  ]);

  return {
    configDirectMappings,
    configValueTransformations,
    directMappingEditorProps,
    valueTransformationGridProps,
  };
}
