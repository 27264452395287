import PromineoModal from "components/modal/PromineoModal";
import { ReactNode, useEffect, useRef } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import BulkInsertToggleButton from "./BulkInsertToggleButton";
import BulkEditorInput from "./BulkEditorInput";
import { ScrollView } from "devextreme-react";
import { updateScrollViewToDisplayScrollbar } from "shared/utilities/ControlUtility";
import BulkEditorErrorPreview from "./BulkEditorErrorPreview";
import { FieldValueBulkEditorInputHeight } from "shared/constants/GridConstants";

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
  onEditModeSet: () => void;
  onPreviewModeSet: () => void;
  userInput: string;
  onUserInputChange: (value: string) => void;
  editorMode: "edit" | "preview";
  validationErrors: string[];
  headerComponent?: ReactNode;
  gridPreviewComponent?: ReactNode;
}

export default function BulkValueInsertDialog(props: Props) {
  const scrollViewRef = useRef<ScrollView>(null);

  useEffect(() => {
    // Update scrollview to display scrollbar(if scrollview should be visible.)
    if (props.editorMode === "preview") {
      updateScrollViewToDisplayScrollbar(scrollViewRef);
    }
  }, [props.editorMode]);
  return (
    <PromineoModal
      isVisible={true}
      displayDefaultActions={true}
      innerClassName={"mx-6 my-4"}
      coloredBg={true}
      width={720}
      height={640}
      editorActionButtonProps={{
        mode: PromineoModalMode.Create,
        cancelButtonCaption: "Cancel",
        createButtonCaption: "Submit",
        onCancel: props.onCancel,
        onCreate: props.onSubmit,
      }}
    >
      {props.headerComponent}

      <div className="bg-white">
        <div className="flex justify-end leading-3">
          <BulkInsertToggleButton
            editorMode={props.editorMode}
            onEditModeSet={props.onEditModeSet}
            onPreviewModeSet={props.onPreviewModeSet}
          />
        </div>
        <div className="mt-1.5">
          {props.editorMode === "edit" ? (
            <BulkEditorInput
              height={FieldValueBulkEditorInputHeight}
              value={props.userInput}
              onValueChanged={props.onUserInputChange}
            />
          ) : (
            <ScrollView
              showScrollbar="always"
              height={FieldValueBulkEditorInputHeight}
              ref={scrollViewRef}
            >
              <div className="h-full">
                {props.validationErrors.length ? (
                  <BulkEditorErrorPreview validationErrors={props.validationErrors} />
                ) : (
                  props.gridPreviewComponent
                )}
              </div>
            </ScrollView>
          )}
        </div>
      </div>
    </PromineoModal>
  );
}
