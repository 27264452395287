import LabelWithContent from "components/common/LabelWithContent";
import TenantUsersDropdown from "../TenantUsersDropdown";
import RepresentativeDropdown from "../RepresentativeDropdown";
import SaveButton from "components/common/controls/buttons/SaveButton";
import EditButton from "components/common/controls/buttons/EditButton";
import { useEffect, useRef, useState } from "react";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useAppDispatch } from "store/hooks";
import { loadUsersByTenantAsync } from "store/actions/UserActions";

const labelClassName =
  "ml-2 w-44 font-inter font-medium text-10px leading-15px";

interface RepresentativeCreateConfig {
  representatives: number[];
  onValueChange: (value: number[]) => void;
}

interface RepresentativeEditConfig {
  isOwner?: boolean;
  partnerRepresentativeIds: number[];
  onPartnerRepresentativeChange: (representativeIds: number[]) => void;
  selectedRepresentatives: UserSimplifiedResponse[];
  onSavePartnerRepresentative: () => void;
  allowEdit?: boolean;
}

interface Props {
  editStatus: "create" | "edit";
  createConfig?: RepresentativeCreateConfig;
  editConfig?: RepresentativeEditConfig;
  partnerTenantId: number;
}

const AddRepresentativeField = (
  props: RepresentativeCreateConfig & {
    tenantUsers: UserSimplifiedResponse[];
  }
) => {
  return (
    <LabelWithContent
      label="Partner representatives"
      content={
        <div className="space-y-2 py-2">
          <TenantUsersDropdown
            users={props.representatives}
            maximumNumberOfUsers={4}
            minimumNumberOfUsers={0}
            userList={props.tenantUsers}
            onValueChange={(users: number[]) => {
              props.onValueChange(users);
            }}
          />
        </div>
      }
    />
  );
};

const EditRepresentativeField = (
  props: RepresentativeEditConfig & {
    onLoadTenantUser: () => void;
    tenantUsers: UserSimplifiedResponse[];
  }
) => {
  const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(false);
  const [isPartnerRepresentativeEditable, setIsPartnerRepresentativeEditable] =
    useState(false);

  const handleSavePartnerRepresentativeClick = () => {
    setIsPartnerRepresentativeEditable(false);
    props.onSavePartnerRepresentative();
  };

  const updateDisableButtonStatus = (status: boolean) => {
    setIsSaveButtonDisable(status);
  };

  const handleEditPartnerRepresentativeClick = () => {
    props.onLoadTenantUser();
    setIsPartnerRepresentativeEditable(true);
  };

  return (
    <LabelWithContent
      label={
        <div className="flex space-x-2">
          <div>Partner representatives</div>
          <div className="-mt-1">
            {props.allowEdit ? (
              isPartnerRepresentativeEditable ? (
                <SaveButton
                  classNames="w-6 h-6"
                  isDisabled={isSaveButtonDisable}
                  onSaveClick={handleSavePartnerRepresentativeClick}
                />
              ) : (
                <EditButton
                  classNames="w-6 h-6"
                  onEditClick={handleEditPartnerRepresentativeClick}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      }
      content={
        <RepresentativeDropdown
          representatives={props.selectedRepresentatives}
          isEditable={isPartnerRepresentativeEditable}
          labelClassName={labelClassName}
          representativeIds={props.partnerRepresentativeIds}
          usersByTenant={props.tenantUsers}
          handleRepresentativeChange={props.onPartnerRepresentativeChange}
          updateDisableButtonStatus={updateDisableButtonStatus}
        />
      }
    />
  );
};

export default function PartnerRepresentativeField(props: Props) {
  const [shouldLoadTenantUsers, setShouldLoadTeantUsers] = useState(false);
  const dispatch = useAppDispatch();
  const areUsersLoaded = useRef(false);
  const [usersByTenant, setUsersByTenant] = useState<UserSimplifiedResponse[]>(
    []
  );

  useEffect(() => {
    if (
      props.createConfig ||
      shouldLoadTenantUsers ||
      (props.editConfig && props.editConfig.allowEdit)
    ) {
      if (props.partnerTenantId && !areUsersLoaded.current) {
        areUsersLoaded.current = true;
        displayLoadingPanel();
        dispatch(loadUsersByTenantAsync({ tenantId: props.partnerTenantId }))
          .unwrap()
          .then((userList: UserSimplifiedResponse[]) => {
            setUsersByTenant(userList);
          })
          .finally(hideLoadingPanel);
      }
    }
  }, [props.createConfig, shouldLoadTenantUsers]);

  return props.editStatus === "create" &&
    props.editConfig?.allowEdit &&
    props.createConfig ? (
    <AddRepresentativeField
      {...props.createConfig}
      tenantUsers={usersByTenant}
    />
  ) : props.editConfig ? (
    <EditRepresentativeField
      {...props.editConfig}
      tenantUsers={usersByTenant}
      onLoadTenantUser={() => setShouldLoadTeantUsers(true)}
    />
  ) : (
    <></>
  );
}
