import TenantRequestDialog from "./TenantRequestDialog";

interface Props {
  onClose: () => void;
}

export default function TenantUserDisabledDialog(props: Props) {
  return (
    <TenantRequestDialog
      title={"Your user has been disabled by a tenant admin."}
      contentText={"Contact one of your tenant admins."}
      confirmButtonText={"Close"}
      onConfirm={props.onClose}
      hideCancelButton={true}
    />
  );
}
