import ContentControlDropdown from "features/common/ContentControlDropdown";
import { ContentControl } from "shared/enums/feature/ContentControl";
import { TenantContentControlLevel } from "shared/enums/feature/TenantContentControlLevel";
import { useAppSelector } from "store/hooks";

interface Props {
  contentControl?: ContentControl | TenantContentControlLevel;
  isDisabled?: boolean;
  onValueChange?: (value: number) => void;
  displayConfirmation?: boolean;
  confirmationMessage?: string;
}

export default function TenantAdminContentControlDropdown(props: Props) {
  const contentControls = useAppSelector((store) => {
    // These field options are loaded in parent grid.
    const visibleContentControls = (
      store.dropdownData.contentControls || []
    ).filter((c) => c.value !== ContentControl.System);
    return visibleContentControls;
  });

  return (
    <>
      <ContentControlDropdown
        isDisabled={props.isDisabled}
        contentControl={props.contentControl}
        datasource={contentControls}
        onValueChange={props.onValueChange}
        displayConfirmation={props.displayConfirmation}
        confirmationMessage={props.confirmationMessage}
      />
    </>
  );
}
