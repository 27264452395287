import { useLayoutEffect, useState } from "react";
import * as DivId from "shared/constants/DivIdConstants";

interface Props {
  excludedContainerIds: string[];
  marginHeight?: number;
  doNotExcludeNavHeightByDefault?: boolean;
}

export default function useRemainingContentLayoutHeight(props: Props) {
  let { excludedContainerIds, marginHeight } = props;
  const [remainingHeight, setRemainingHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (props.doNotExcludeNavHeightByDefault !== true) {
      const navbarHeight =
        document.getElementById(DivId.NavBarId)?.clientHeight ?? 0;
      marginHeight = (marginHeight ?? 0) + navbarHeight;
    }

    const calculateRemainingHeight = () => {
      let heightToExclude = 0;
      const currentWindowHeight = window.innerHeight;
      if (excludedContainerIds) {
        for (let index = 0; index < excludedContainerIds.length; index++) {
          let containerHeight =
            document.getElementById(excludedContainerIds[index])
              ?.clientHeight ?? 0;
          heightToExclude += containerHeight;
        }
      }
      const calculatedRemaningHeight =
        currentWindowHeight -
        heightToExclude -
        (marginHeight ? marginHeight : 0);
      setRemainingHeight(calculatedRemaningHeight);
    };

    var resizeObserver = new ResizeObserver(calculateRemainingHeight);

    (excludedContainerIds || []).forEach((divId) => {
      const element = document.getElementById(divId);
      if (element) {
        resizeObserver.observe(element);
      }
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, [
    props.doNotExcludeNavHeightByDefault,
    props.excludedContainerIds,
    props.marginHeight,
  ]);

  return remainingHeight;
}
