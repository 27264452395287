import LabelWithContent from "components/common/LabelWithContent";
import TenantContactInformationBase from "interfaces/common/TenantContactInformationBase";

interface Props {
  showMainContact: boolean;
  contactInformation: TenantContactInformationBase[];
  labelClassName: string;
}

export default function ContactInformationComponent(props: Props) {
  const { showMainContact, contactInformation, labelClassName } = props;
  var count = 1;

  return (
    <>
      {contactInformation.map((ci, ind) => {
        return showMainContact === ci.isMainContact ? (
          <div key={ind}>
            <LabelWithContent
              label={
                ci.isMainContact
                  ? "Main contact"
                  : `Additional contact ${count++}`
              }
              content={
                <div className={labelClassName}>
                  <div className="mt-2">{ci.phoneNumber}</div>
                  <div className="mt-2">{ci.email}</div>
                </div>
              }
            />
          </div>
        ) : (
          <></>
        );
      })}
    </>
  );
}
