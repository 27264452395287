import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import { EditorMode } from "shared/enums/EditorMode";
import TextViewerComponent from "./safran/TextViewerComponent";

interface Props {
  mode: EditorMode;
  dropdownOptions: DropdownValuesResponse[];
  selectedValue: number;
  dropdownWidth?: number | string;
  fieldName: string;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function HostSystemConfigurationDropdown(props: Props) {
  const {
    mode,
    dropdownOptions,
    selectedValue,
    dropdownWidth,
    fieldName,
    handleConfigurationValueChanged,
  } = props;

  const displayText =
    dropdownOptions.find((o) => o.value === selectedValue)?.name ?? "";

  return (
    <>
      {mode === EditorMode.Edit && (
        <PromineoSelectBox
          width={dropdownWidth ?? 320}
          placeholder="Select"
          items={dropdownOptions}
          defaultValue={selectedValue}
          valueExpr={"value"}
          displayExpr={"name"}
          onValueChange={(value: number) => {
            if (handleConfigurationValueChanged) {
              handleConfigurationValueChanged(fieldName, value);
            }
          }}
        />
      )}

      {mode === EditorMode.View && <TextViewerComponent text={displayText} />}
    </>
  );
}
