interface Props {
  title: string;
}

export default function TenantInfoSectionTitle(props: Props) {
  return (
    <div className="font-poppins font-semibold text-[15px] leading-snug w-[130px] text-textGray">
      {props.title}
    </div>
  );
}
