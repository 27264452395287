import {
  SapImportConfig,
  SapImportConfigFunction,
} from "interfaces/host-system-config/sap/SapImportConfig";
import { EditorMode } from "shared/enums/EditorMode";
import SapFunctionHostSystemParameterGrid from "./SapFunctionHostSystemParameterGrid";
import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import AddNewButton from "components/common/controls/buttons/AddNewButton";
import { SapObjectType } from "shared/enums/feature/sap-host-system/SapObjectType";
import { SapFunctionType } from "shared/enums/feature/sap-host-system/SapFunctionType";
import { SapFunctionContext } from "shared/enums/feature/sap-host-system/SapFunctionContext";
import { SapFunctionSequenceType } from "shared/enums/feature/sap-host-system/SapFunctionSequenceType";
import { GetNewId } from "shared/utilities/CommonUtility";
import { useAppDispatch } from "store/hooks";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import {
  loadSapFunctionContextOptionValuesAsync,
  loadSapFunctionTypeOptionValuesAsync,
  loadSapObjectTypeOptionValuesAsync,
  loadSapSequenceTypeOptionValuesAsync,
} from "store/actions/DropdownValueActions";
import { SapHostParameterGridKey } from "shared/utilities/HostSystemConfigurationUtility";
import OnlyIncludeSpecifiedMappingField from "features/common/host-system-parameter-fields/safran/OnlyIncludeSpecifiedMappingField";

interface Props {
  mode: EditorMode;
  config: SapImportConfig;
  height: number;
  isOverride?: boolean;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

const includeMappingPropertyName = "includeMapping";

const SapReceivingParameters = forwardRef((props: Props, ref) => {
  const dispatch = useAppDispatch();
  const sapHostParameterGridRef = useRef<any>();

  const { mode, config, height, isOverride, handleConfigurationValueChanged } =
    props;

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadSapObjectTypeOptionValuesAsync()).finally(hideLoadingPanel);

    displayLoadingPanel();
    dispatch(loadSapFunctionTypeOptionValuesAsync()).finally(hideLoadingPanel);

    displayLoadingPanel();
    dispatch(loadSapFunctionContextOptionValuesAsync()).finally(
      hideLoadingPanel
    );

    displayLoadingPanel();
    dispatch(loadSapSequenceTypeOptionValuesAsync()).finally(hideLoadingPanel);
  }, []);

  const [sapFunctions, setSapFunctions] = useState<SapImportConfigFunction[]>(
    []
  );

  useEffect(() => {
    setSapFunctions(() => {
      let configFunctions = config?.functions ?? [];
      const functions = configFunctions.map((fn) => {
        return { ...fn, [SapHostParameterGridKey]: GetNewId() };
      });

      return functions;
    });
  }, [config.functions]);

  useImperativeHandle(
    ref,
    () => ({
      getUpdateSapHostParameters() {
        const parameters = {
          ...config,
          includeMapping: config.includeMapping,
          functions: sapHostParameterGridRef.current
            .getParameters()
            .map((fn: any) => {
              const parameter = { ...fn };
              delete parameter[SapHostParameterGridKey];
              return parameter;
            }),
        };
        return parameters;
      },
    }),
    [config.includeMapping]
  );

  const handleAddNew = useCallback(() => {
    const generateNewIndex = () => {
      let maxIndex =
        sapFunctions.length > 0
          ? sapFunctions.slice().sort((a, b) => b.index - a.index)[0].index
          : 0;

      return maxIndex + 1;
    };

    const getNewSapParameter = () => {
      return {
        [SapHostParameterGridKey]: GetNewId(),
        objectType: SapObjectType.Order,
        functionType: SapFunctionType.UserStatus,
        context: SapFunctionContext.Update,
        sequenceType: SapFunctionSequenceType.BeforeConfirmations,
        setInactive: false,
        conditionFormula: "",
        parameter: "",
        index: generateNewIndex(),
      };
    };

    setSapFunctions((prev) => {
      return [...prev, getNewSapParameter()];
    });
  }, [sapFunctions]);

  const handleDelete = useCallback((data: any) => {
    setSapFunctions((prev) => {
      const functionsWithoutDeletedOne = prev.filter(
        (f: any) =>
          f[SapHostParameterGridKey] !== data.data[SapHostParameterGridKey]
      );
      return functionsWithoutDeletedOne;
    });
  }, []);

  const getGridHeight = useCallback(() => {
    return height - (mode === EditorMode.Edit ? 90 : 78);
  }, [mode, height]);

  return (
    <div className="space-y-4">
      <OnlyIncludeSpecifiedMappingField
        mode={mode}
        fieldName={includeMappingPropertyName}
        includeMappingsTypeValue={config.includeMapping}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <div>
        <div className="flex justify-between mb-2 items-center">
          <div className="text-xs textGray font-semibold font-poppins">
            {!isOverride && <>SAP Function Setup</>}
          </div>
          {props.mode === EditorMode.Edit ? (
            <div>
              <AddNewButton onClick={handleAddNew} text="Add row" />
            </div>
          ) : null}
        </div>
        <div className="w-full">
          <SapFunctionHostSystemParameterGrid
            mode={props.mode}
            height={getGridHeight()}
            sapFunctions={sapFunctions}
            onDelete={handleDelete}
            ref={sapHostParameterGridRef}
          />
        </div>
      </div>
    </div>
  );
});

export default SapReceivingParameters;
