import SummaryComponent from "components/common/SummaryComponent";
import OverdueIcon from "components/icons/OverdueIcon";
import PauseIcon from "components/icons/PauseIcon";
import RefreshIcon from "components/icons/RefreshIcon";
import SuccessIcon from "components/icons/SuccessIcon";
import WarningIcon from "components/icons/WarningIcon";
import { ActiveIEASummaryFilterOption } from "shared/enums/feature/ActiveIEASummaryFilterOption";

interface Props {
  updateAvailablIEAs: number;
  failedIEAs: number;
  overdueIEAs: number;
  pausedIEAs: number;
  totalIEAs: number;
  successfulEAs: number;
  selectedFilter: ActiveIEASummaryFilterOption;
  onFailedIEASummaryButtonClick: () => void;
  onOverdueIEASummaryButtonClick: () => void;
  onPausedIEASummaryButtonClick: () => void;
  onUpdateAvailableIEASummeryButtonClick: () => void;
  onTotalIEASummaryButtonClick: () => void;
  onSuccessIeaSummaryButtonClick: () => void;
}

export default function ActiveIEAGridSummary(props: Props) {
  const {
    updateAvailablIEAs,
    failedIEAs,
    overdueIEAs,
    pausedIEAs,
    totalIEAs,
    selectedFilter,
    successfulEAs,
    onFailedIEASummaryButtonClick,
    onOverdueIEASummaryButtonClick,
    onPausedIEASummaryButtonClick,
    onUpdateAvailableIEASummeryButtonClick,
    onTotalIEASummaryButtonClick,
    onSuccessIeaSummaryButtonClick,
  } = props;

  return (
    <div className="flex space-x-2">
      <SummaryComponent
        value={totalIEAs}
        label="All active IEAs"
        isSelected={selectedFilter === ActiveIEASummaryFilterOption.Total}
        onSummaryButtonClick={onTotalIEASummaryButtonClick}
      />
      <SummaryComponent
        value={updateAvailablIEAs}
        label="Updates"
        isSelected={
          selectedFilter === ActiveIEASummaryFilterOption.UpdateAvailable
        }
        iconComponent={<RefreshIcon />}
        onSummaryButtonClick={onUpdateAvailableIEASummeryButtonClick}
      />
      <SummaryComponent
        value={failedIEAs}
        label="Failed"
        isSelected={selectedFilter === ActiveIEASummaryFilterOption.Failed}
        iconComponent={<WarningIcon />}
        onSummaryButtonClick={onFailedIEASummaryButtonClick}
      />
      <SummaryComponent
        value={overdueIEAs}
        label="Overdue"
        isSelected={selectedFilter === ActiveIEASummaryFilterOption.Overdue}
        iconComponent={<OverdueIcon />}
        onSummaryButtonClick={onOverdueIEASummaryButtonClick}
      />
      <SummaryComponent
        value={pausedIEAs}
        label="Paused"
        isSelected={selectedFilter === ActiveIEASummaryFilterOption.Paused}
        iconComponent={<PauseIcon />}
        onSummaryButtonClick={onPausedIEASummaryButtonClick}
      />
      <SummaryComponent
        value={successfulEAs}
        label="Successful"
        isSelected={selectedFilter === ActiveIEASummaryFilterOption.Success}
        iconComponent={<SuccessIcon />}
        onSummaryButtonClick={onSuccessIeaSummaryButtonClick}
      />
    </div>
  );
}
