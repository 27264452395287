import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import SAPConnectorSapAuthorityField from "./SAPConnectorSapAuthorityField";
import SAPConnectorSapClientIdField from "./SAPConnectorSapClientIdField";
import SAPConnectorSapApiAreaField from "./SAPConnectorSapApiAreaField";
import SAPConnectorSapApiVersionField from "./SAPConnectorSapApiVersionField";
import SAPConnectorSapGrantTypeField from "./SAPConnectorSapGrantTypeField";
import SAPConnectorSapApimSubscriptionField from "./SAPConnectorSapApimSubscriptionField";
import SAPConnectorSapSecretKeyvaultUrlField from "./SAPConnectorSapSecretKeyvaultUrlField";
import SAPConnectorSapApimTranceField from "./SAPConnectorSapApimTranceField";
import SAPConnectorClientIdField from "./SAPConnectorClientIdField";
import SAPConnectorSapSecretKeyvaultKeyField from "./SAPConnectorSapSecretKeyvaultKeyField";
import SAPConnectorClientSecretField from "./SAPConnectorClientSecretField";
import SAPConnectorTenantIdField from "./SAPConnectorTenantIdField";
import SAPConnectorScopeField from "./SAPConnectorScopeField";
import SAPConnectorAuthorityField from "./SAPConnectorAuthorityField";

interface Props {
  configuration: SAPConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
}

export default function SAPAzureSamlOauthTokenAuthentication(props: Props) {
  return (
    <div
      className={
        props.mode === PromineoModalMode.View ||
        props.mode === PromineoModalMode.Modify
          ? "grid grid-cols-2 gap-x-60"
          : ""
      }
    >
      <SAPConnectorSapAuthorityField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorSapClientIdField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorSapApiAreaField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorSapApiVersionField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorSapGrantTypeField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorSapApimSubscriptionField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorSapSecretKeyvaultUrlField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorSapSecretKeyvaultKeyField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorSapApimTranceField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorClientIdField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorClientSecretField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorTenantIdField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorAuthorityField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
      <SAPConnectorScopeField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
    </div>
  );
}
