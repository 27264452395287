import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";
import { FrequencyType } from "shared/enums/feature/FrequencyType";

interface Props {
  isEdit: boolean;
  frequencyType: FrequencyType;
  isPredecessorIEASelected: boolean;
  handlePredecessorIEASelectionChange: (selectedValue: boolean) => void;
}

export default function SapPredecessorIEAFilterComponent(props: Props) {
  const {
    isEdit,
    frequencyType,
    isPredecessorIEASelected,
    handlePredecessorIEASelectionChange,
  } = props;

  return (
    <div className="flex justify-between items-center">
      <div className="w-10/12">
        <div className="text-textGray font-poppins font-semibold text-xs leading-18px">
          Inherit predecessor IEA filter
        </div>
        <div className="text-gray3 font-inter font-medium text-xs">
          Turn this option on if you want to retrieve the same operations that
          were transferred by the predecessor IEA.
        </div>
      </div>

      <div className="pr-5">
        <PromineoSwitchButton
          disabled={(frequencyType !== FrequencyType.Predecessor) || (!isEdit)}
          checked={isPredecessorIEASelected}
          switchType={SwitchButtonType.Default}
          onChange={() => {
            handlePredecessorIEASelectionChange(!isPredecessorIEASelected);
          }}
        />
      </div>
    </div>
  );
}
