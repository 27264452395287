import { IButtonOptions } from "devextreme-react/button";
import { ReactNode } from "react";
import PromineoButton, { PromineoButtonType } from "./PromineoButton";
import "./styles/PromineoButtonWithIcon.css";

interface Props {
  Icon?: ReactNode;
  RightIcon?: ReactNode;
  variant?: PromineoButtonType;
}

type PropType = Omit<Props & IButtonOptions, "children">;

export default function PromineoButtonWithIcon(props: PropType) {
  const { Icon, RightIcon, text, variant, className, ...rest } = props;
  return (
    <PromineoButton
      variant={variant}
      {...rest}
      className={`promineo-button-with-icon ${className ?? ""}`}
    >
      <div className="flex justify-center items-center h-full space-x-1 button-with-icon">
        {Icon && <div>{Icon}</div>}
        <span className="button-with-icon-text">{text}</span>
        {RightIcon && <div>{RightIcon}</div>}
      </div>
    </PromineoButton>
  );
}
