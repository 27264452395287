import { createSlice } from "@reduxjs/toolkit";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import FieldResponse from "interfaces/response/FieldResponse";
import TemplateFieldDetailsResponse from "interfaces/response/TemplateFieldDetailsResponse";
import {
  loadFieldDetailsWithContentControlValuesByTemplateIdAsync,
  loadFieldsAsync,
  loadTemplateFieldByTemplateFieldIdAsync,
  loadTemplateFieldByTemplateIdAndFieldIdAsync,
  updateTemplateFieldAsync,
} from "store/actions/FieldActions";

export interface FieldState {
  fields: FieldResponse[];
  fieldsWithContentControlDetails : FieldDetailsResponse[];
  currentTemplateField: TemplateFieldDetailsResponse | null;
}

const initialState: FieldState = {
  fields: [],
  fieldsWithContentControlDetails : [],
  currentTemplateField: null,
};

export const fieldSlice = createSlice({
  name: "fields",
  initialState,
  reducers: {
    resetCurrentTemplateField: (state) => {
      state.currentTemplateField = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFieldsAsync.fulfilled, (state, action) => {
      state.fields = action.payload;
    });
    builder.addCase(
      loadTemplateFieldByTemplateFieldIdAsync.pending,
      (state, action) => {
        state.currentTemplateField = null;
      }
    );
    builder.addCase(
      loadTemplateFieldByTemplateFieldIdAsync.fulfilled,
      (state, action) => {
        state.currentTemplateField = action.payload;
      }
    );
    builder.addCase(
      loadTemplateFieldByTemplateIdAndFieldIdAsync.pending,
      (state, action) => {
        state.currentTemplateField = null;
      }
    );
    builder.addCase(
      loadTemplateFieldByTemplateIdAndFieldIdAsync.fulfilled,
      (state, action) => {
        state.currentTemplateField = action.payload;
      }
    );
    builder.addCase(updateTemplateFieldAsync.fulfilled, (state, action) => {
      state.currentTemplateField = action.payload;
    });
    builder.addCase(loadFieldDetailsWithContentControlValuesByTemplateIdAsync.fulfilled, (state, action) => {
      state.fieldsWithContentControlDetails = action.payload;
    });
  },
});

export const { resetCurrentTemplateField } = fieldSlice.actions;

export default fieldSlice.reducer;
