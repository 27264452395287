import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import PromineoTextBox from "components/common/controls/PromineoTextBox";

interface Props {
  mode: PromineoModalMode;
  configuration: AnalyticsConnectorConfiguration;
  validateOnFocusOut?: (evt: any) => void;
  onValueChange?: (fieldName: string, value: string) => void;
}

const ClientIdField = (props: Props) => {
  const handleValueChanged = (evt: any) => {
    props.onValueChange?.(evt.event.currentTarget.name, evt.event.currentTarget.value);
  };

  return (
    <>
      {(props.mode === PromineoModalMode.Create ||
        props.mode === PromineoModalMode.Modify) && (
        <PromineoTextBox
          placeholder="Type..."
          width={props.mode === PromineoModalMode.Create ? 330 : 188}
          required={true}
          name="azureAdClientId"
          fieldName="Client Id"
          defaultValue={props.configuration.azureAdClientId}
          onValueChanged={handleValueChanged}
          onFocusOut={props.validateOnFocusOut}
        />
      )}

      {props.mode === PromineoModalMode.View &&
        props.configuration.azureAdClientId}
    </>
  );
};

export default function AnalyticsClientIdField(props: Props) {
  return (
    <ConnectorLabelWithContent
      mode={props.mode}
      addAsterisksymbol={true}
      label="Client ID"
      content={
        <ClientIdField
          mode={props.mode}
          configuration={props.configuration}
          validateOnFocusOut={props.validateOnFocusOut}
          onValueChange={props.onValueChange}
        />
      }
    />
  );
}
