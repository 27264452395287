import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { loadConfigByIdAsync } from "store/actions/ConfigActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ConfigEditor from "./config-edit/ConfigEditor";
import { resetCurrentConfig } from "store/slices/ConfigSlice";
import ErrorDisplayModal from "features/common/error-display-modal/ErrorDisplayModal";
import useImportConfigFromClipboard from "hooks/ImportConfigFromClipboardHook";
import { loadCoreFieldsAsync } from "store/actions/CoreFieldActions";

export default function EditConfig() {
  const dispatch = useAppDispatch();
  const param = useParams();
  const location = useLocation();
  const isConfigDataLoaded = useRef(false);
  const [isErrorModalVisible, setIsErrorModalVisible] =
    useState<boolean>(false);

  const configToEdit = useAppSelector(
    (state) => state.configData.currentConfig
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadCoreFieldsAsync()).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadConfigByIdAsync(Number(param.configId))).finally(
      hideLoadingPanel
    );
    isConfigDataLoaded.current = true;

    return () => {
      dispatch(resetCurrentConfig());
    };
  }, []);

  const selectedConnectorDetail = useAppSelector(
    (state) => state.connectorData.selectedConnector
  );

  const coreFields = useAppSelector((store) => store.coreFieldData.coreFields);

  const showErrorModal = () => setIsErrorModalVisible(true);
  const hideErrorModal = () => setIsErrorModalVisible(false);

  const { configFromClipboard, importSuccessful, validationErrors } =
    useImportConfigFromClipboard({
      destinationConfig: configToEdit,
      selectedConnectorDetail,
      coreFields,
      showErrorModal,
    }); // we send a callback to show error modal

  return (
    <div>
      {configToEdit && isConfigDataLoaded.current && (
        <ConfigEditor config={importSuccessful ? configFromClipboard : configToEdit} selectedTab={location.state?.selectedTab ?? 0}/>
      )}
      {isErrorModalVisible && (
        <ErrorDisplayModal errors={validationErrors.errors} count={validationErrors.count} onHideDialog={hideErrorModal} asWarnings={true}/>
      )}
    </div>
  );
}
