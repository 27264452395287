import PromineoModal from "components/modal/PromineoModal";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ViewValueMapsDialogHeader from "./ViewValueMapsDialogHeader";

interface Props {
  fieldMapping: FieldMappingResponse | null;
  onCancel: () => void;
}

export default function ViewValueMapsWithNoContentControlDialog(props: Props) {
  return (
    <PromineoModal
      isVisible={true}
      displayDefaultActions={true}
      innerClassName={"mx-6 my-4"}
      coloredBg={true}
      width={720}
      height={640}
    >
      {props.fieldMapping && (
        <div>
          <ViewValueMapsDialogHeader
            onCancel={props.onCancel}
            fieldMappingName={props.fieldMapping.name}
          />
          <div className="mt-20.5px">
            <PromineoViewGrid
              dataSource={props.fieldMapping?.valueMaps}
              height={560}
              className="promineo-grid-in-modal"
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column
                dataField={"templateValue"}
                caption="Template Value"
                alignment="left"
              />
              <Column
                dataField={"value"}
                caption="Host Value"
                alignment="left"
              />
              <Column
                dataField={"description"}
                caption="Comment"
                alignment="left"
              />
            </PromineoViewGrid>
          </div>
        </div>
      )}
    </PromineoModal>
  );
}
