import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import TokenViewer from "./TokenViewer";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";

interface Props {
  serviceToken: ServiceTokenResponse;
  onDone: () => void;
}

export default function ServiceTokenViewerDialog(props: Props) {
  return (
    <PromineoConfirmationDialog
      width="430"
      content="Token created"
      contentComponent={() => (
        <TokenViewer serviceToken={props.serviceToken} onClose={props.onDone} />
      )}
    />
  );
}
