import SafranApiConnectorConfiguration from "interfaces/common/SafranApiConnectorConfiguration";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import PrimaveraConnectorConfiguration from "interfaces/response/connector/PrimaveraConnectorConfiguration";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import { AnalyticsConnectorAuthenticationMode } from "shared/enums/feature/AnalyticsConnectorAuthenticationMode";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { PrimaveraConnectorAuthenticationMode } from "shared/enums/feature/PrimaveraConnectorAuthenticationMode";
import { SapConnectorAuthenticationMode } from "shared/enums/feature/sap-host-system/SapConnectorAuthenticationMode";
import { SafranApiConnectorAuthenticationMode } from "shared/enums/feature/safran-host-system/SafranApiConnectorAuthenticationMode";
import MicrosoftProjectConnectorConfiguration from "interfaces/response/connector/MicrosoftProjectConnectorConfiguration";

export const isSafranConfigurationValid = (
  safranConnectorConfiguration: SafranApiConnectorConfigurationResponse
): boolean => {
  let isValid = true;

  // value is empty string, null, undefined.
  if (!safranConnectorConfiguration.baseUrl) {
    isValid = false;
  }

  if (
    safranConnectorConfiguration.useOwnerVerification &&
    !safranConnectorConfiguration.owner
  ) {
    isValid = false;
  }

  if (
    !(
      safranConnectorConfiguration.authenticationMode in
      SafranApiConnectorAuthenticationMode
    )
  ) {
    isValid = false;
  }

  if (
    safranConnectorConfiguration.authenticationMode ===
    SafranApiConnectorAuthenticationMode.Basic
  ) {
    if (
      !safranConnectorConfiguration.username ||
      !safranConnectorConfiguration.password
    ) {
      isValid = false;
    }
  } else if (
    safranConnectorConfiguration.authenticationMode ===
    SafranApiConnectorAuthenticationMode.AzureAdAuthentication
  ) {
    if (
      !safranConnectorConfiguration.adTenantId ||
      !safranConnectorConfiguration.clientApplicationId ||
      !safranConnectorConfiguration.redirectUri
    ) {
      isValid = false;
    }
  }

  return isValid;
};

export const isAnalyticsConfigurationValid = (
  connectorConfiguration: AnalyticsConnectorConfiguration
): boolean => {
  let isValid = true;

  if (
    !(
      connectorConfiguration.authenticationMode in
      SafranApiConnectorAuthenticationMode
    )
  ) {
    isValid = false;
  }

  if (!connectorConfiguration.baseUrl) {
    isValid = false;
  }

  if (
    connectorConfiguration.authenticationMode ===
    AnalyticsConnectorAuthenticationMode.ServiceAccountToken
  ) {
    if (!connectorConfiguration.ilapAnalyticsToken) {
      isValid = false;
    }
  } else if (
    connectorConfiguration.authenticationMode ===
    AnalyticsConnectorAuthenticationMode.AzureAdAuthentication
  ) {
    if (
      !connectorConfiguration.azureAdAuthority ||
      !connectorConfiguration.azureAdClientId ||
      !connectorConfiguration.azureAdScopes
    ) {
      isValid = false;
    }
  }

  return isValid;
};

export const isSAPConfigurationValid = (
  connectorConfiguration: SAPConnectorConfiguration
): boolean => {
  let isValid = true;

  if (!connectorConfiguration.baseUrl) {
    isValid = false;
  }

  if (
    !(
      connectorConfiguration.authenticationMode in
      SapConnectorAuthenticationMode
    )
  ) {
    isValid = false;
  } else if (
    connectorConfiguration.authenticationMode ===
    SapConnectorAuthenticationMode.Basic
  ) {
    isValid =
      !!connectorConfiguration.username && !!connectorConfiguration.password;
  } else if (
    connectorConfiguration.authenticationMode ===
    SapConnectorAuthenticationMode.AzureSamlOauthToken
  ) {
    isValid =
      !!connectorConfiguration.baseUrl &&
      !!connectorConfiguration.authority &&
      !!connectorConfiguration.clientId &&
      !!connectorConfiguration.clientSecret &&
      !!connectorConfiguration.tenantId &&
      !!connectorConfiguration.sapApiArea &&
      !!connectorConfiguration.sapApiVersion &&
      !!connectorConfiguration.sapApimSubscription &&
      !!connectorConfiguration.sapApimTrace &&
      !!connectorConfiguration.sapAuthority &&
      !!connectorConfiguration.sapGrantType &&
      !!connectorConfiguration.sapSecretKeyVaultKey &&
      !!connectorConfiguration.sapSecretKeyVaultUrl &&
      !!connectorConfiguration.sapTokenClientId &&
      !!connectorConfiguration.scope;
  }

  return isValid;
};

export const isPrimaveraConfigurationValid = (
  primaveraConnectorConfiguration: PrimaveraConnectorConfiguration
): boolean => {
  let isValid = true;

  if (!primaveraConnectorConfiguration.baseUrl) {
    isValid = false;
  }

  if (
    !(
      primaveraConnectorConfiguration.authenticationMode in
      PrimaveraConnectorAuthenticationMode
    )
  ) {
    isValid = false;
  }

  if (
    primaveraConnectorConfiguration.authenticationMode ===
    PrimaveraConnectorAuthenticationMode.UserNameTokenProfile
  ) {
    if (
      !primaveraConnectorConfiguration.username ||
      !primaveraConnectorConfiguration.password
    ) {
      isValid = false;
    }
  }

  return isValid;
};

const isMicrosoftProjectConfigurationValid = (
  configuration: MicrosoftProjectConnectorConfiguration
) => {
  return (
    !!configuration &&
    !!configuration.sharePointClientId &&
    !!configuration.sharePointTenantId &&
    !!configuration.sharePointUrl
  );
};

export const isConnectorInputValid = (
  connectorInput: ConnectorResponse,
  connectorConfiguration:
    | SafranApiConnectorConfigurationResponse
    | AnalyticsConnectorConfiguration
    | SAPConnectorConfiguration
    | PrimaveraConnectorConfiguration
    | MicrosoftProjectConnectorConfiguration
): boolean => {
  let isValid = true;

  if (!connectorInput.name) {
    isValid = false;
  }

  if (!(connectorInput.hostSystem in HostSystem)) {
    isValid = false;
  }

  if (!(connectorInput.executionComponent in ExecutionComponent)) {
    isValid = false;
  }

  if (
    connectorInput.hostSystem === HostSystem.Safran &&
    connectorConfiguration
  ) {
    isValid =
      isValid &&
      isSafranConfigurationValid(
        connectorConfiguration as SafranApiConnectorConfigurationResponse
      );
  } else if (
    connectorInput.hostSystem === HostSystem.IlapAnalytics &&
    connectorConfiguration
  ) {
    isValid =
      isValid &&
      isAnalyticsConfigurationValid(
        connectorConfiguration as AnalyticsConnectorConfiguration
      );
  } else if (
    connectorInput.hostSystem === HostSystem.SAP &&
    connectorConfiguration
  ) {
    isValid =
      isValid &&
      isSAPConfigurationValid(
        connectorConfiguration as SAPConnectorConfiguration
      );
  } else if (
    connectorInput.hostSystem === HostSystem.PrimaveraP6 &&
    connectorConfiguration
  ) {
    isValid =
      isValid &&
      isPrimaveraConfigurationValid(
        connectorConfiguration as PrimaveraConnectorConfiguration
      );
  } else if (
    connectorInput.hostSystem === HostSystem.MicrosoftProject &&
    connectorConfiguration
  ) {
    isValid =
      isValid &&
      isMicrosoftProjectConfigurationValid(
        connectorConfiguration as MicrosoftProjectConnectorConfiguration
      );
  }

  return isValid;
};

export const getSafranConnectorDefaultConfiguration =
  (): SafranApiConnectorConfigurationResponse => {
    const defaultConfiguration: SafranApiConnectorConfigurationResponse = {
      baseUrl: "",
      useOwnerVerification: false,
      username: "",
      password: "",
      owner: "",
      authenticationMode: -1 as SafranApiConnectorAuthenticationMode,
      authenticationModeDisplayText: "",
      adTenantId: "",
      clientApplicationId: "",
      redirectUri: "",
    };

    return defaultConfiguration;
  };

export const getAnalyticsConnectorDefaultConfiguration =
  (): AnalyticsConnectorConfiguration => {
    const defaultConfiguration: AnalyticsConnectorConfiguration = {
      authenticationMode: -1 as AnalyticsConnectorAuthenticationMode,
      azureAdAuthority: "",
      azureAdClientId: "",
      azureAdScopes: "",
      baseUrl: "",
      ilapAnalyticsToken: "",
    };

    return defaultConfiguration;
  };

export const getSAPConnectorDefaultConfiguration =
  (): SAPConnectorConfiguration => {
    const defaultConfiguration: SAPConnectorConfiguration = {
      authenticationMode: -1 as SapConnectorAuthenticationMode,
      baseUrl: "",
      authority: "",
      clientId: "",
      clientSecret: "",
      password: "",
      sapApiArea: "",
      sapApiVersion: "",
      sapApimSubscription: "",
      sapApimTrace: "",
      sapAuthority: "",
      sapTokenClientId: "",
      sapGrantType: "",
      sapSecretKeyVaultKey: "",
      sapSecretKeyVaultUrl: "",
      scope: "",
      tenantId: "",
      username: "",
    };

    return defaultConfiguration;
  };

export const getPrimaveraConnectorDefaultConfiguration =
  (): PrimaveraConnectorConfiguration => {
    const defaultConfiguration: PrimaveraConnectorConfiguration = {
      baseUrl: "",
      username: "",
      password: "",
      authenticationMode: -1 as PrimaveraConnectorAuthenticationMode,
      authenticationModeDisplayText: "UserNameTokenProfile",
    };

    return defaultConfiguration;
  };

export const getMicrosoftProjectConnectorDefaultConfiguration =
  (): MicrosoftProjectConnectorConfiguration => {
    const defaultConfiguration: MicrosoftProjectConnectorConfiguration = {
      sharePointClientId: "",
      sharePointUrl: "",
      sharePointTenantId: "",
      allFilesHaveSameUserFields: true,
    };

    return defaultConfiguration;
  };

export const getConnectorConfigurationBasedOnHostSystem = (
  connectorConfiguration:
    | SafranApiConnectorConfigurationResponse
    | AnalyticsConnectorConfiguration
    | SAPConnectorConfiguration
    | PrimaveraConnectorConfiguration
    | MicrosoftProjectConnectorConfiguration,
  hostSystem: HostSystem
) => {
  if (connectorConfiguration && hostSystem) {
    if (hostSystem === HostSystem.IlapAnalytics) {
      const config = connectorConfiguration as AnalyticsConnectorConfiguration;
      const configuration: AnalyticsConnectorConfiguration = {
        authenticationMode: config.authenticationMode,
        azureAdAuthority: config.azureAdAuthority,
        azureAdClientId: config.azureAdClientId,
        ilapAnalyticsToken: config.ilapAnalyticsToken,
        azureAdScopes: config.azureAdScopes,
        baseUrl: config.baseUrl,
      };

      return configuration;
    } else if (hostSystem === HostSystem.Safran) {
      const config =
        connectorConfiguration as SafranApiConnectorConfigurationResponse;
      const configuration: SafranApiConnectorConfiguration = {
        baseUrl: config.baseUrl,
        useOwnerVerification: config.useOwnerVerification,
        username: config.username,
        password: config.password,
        owner: config.owner,
        authenticationMode: config.authenticationMode,
        adTenantId: config.adTenantId,
        clientApplicationId: config.clientApplicationId,
        redirectUri: config.redirectUri,
      };

      return configuration;
    } else if (hostSystem === HostSystem.SAP) {
      const config = connectorConfiguration as SAPConnectorConfiguration;
      const configuration: SAPConnectorConfiguration = {
        baseUrl: config.baseUrl,
        authenticationMode: config.authenticationMode,
        authority: config.authority,
        clientId: config.clientId,
        clientSecret: config.clientSecret,
        password: config.password,
        sapApiArea: config.sapApiArea,
        sapApimSubscription: config.sapApimSubscription,
        sapApimTrace: config.sapApimTrace,
        sapApiVersion: config.sapApiVersion,
        sapAuthority: config.sapAuthority,
        sapTokenClientId: config.sapTokenClientId,
        sapGrantType: config.sapGrantType,
        sapSecretKeyVaultKey: config.sapSecretKeyVaultKey,
        sapSecretKeyVaultUrl: config.sapSecretKeyVaultUrl,
        scope: config.scope,
        tenantId: config.tenantId,
        username: config.username,
      };

      return configuration;
    } else if (hostSystem === HostSystem.PrimaveraP6) {
      const config = connectorConfiguration as PrimaveraConnectorConfiguration;
      const configuration: PrimaveraConnectorConfiguration = {
        baseUrl: config.baseUrl,
        authenticationMode: config.authenticationMode,
        username: config.username,
        password: config.password,
      };

      return configuration;
    } else if (hostSystem === HostSystem.MicrosoftProject) {
      const config =
        connectorConfiguration as MicrosoftProjectConnectorConfiguration;
      const configuration: MicrosoftProjectConnectorConfiguration = {
        sharePointTenantId: config.sharePointTenantId,
        sharePointUrl: config.sharePointUrl,
        sharePointClientId: config.sharePointClientId,
        allFilesHaveSameUserFields: true,
        // For now, we are hiding allFilesHaveSameUserFields setting from UI. Considering it as true always.
        // Though backend support having different userFields, we do not have any usecase having multiple userFieldSet for now.
        // Also we need to change the userFieldSet dataType as we have string userFieldSet(file name) for MsProject now.
        // allFilesHaveSameUserFields: config.allFilesHaveSameUserFields
      };

      return configuration;
    }
  }

  return null;
};
