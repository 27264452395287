import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import TenantResponse from "interfaces/response/TenantResponse";
import TenantStatusLabel from "./TenantStatusLabel";

interface Props {
  data: { data: TenantResponse };
  tenantStatusDropdownOptions: DropdownValuesResponse[];
}

export default function StatusDisplayComponent(props: Props) {
  const tenant = props.data.data;
  return (
    <TenantStatusLabel
      tenantStatuses={props.tenantStatusDropdownOptions}
      tenantStatus={tenant.tenantStatus}
      tenantId={tenant.id}
    />
  );
}
