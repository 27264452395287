import DropDownButton, {
  IDropDownButtonOptions,
} from "devextreme-react/drop-down-button";
import "./styles/PromineoOptionDropdown.css";
import _uniqueId from "lodash/uniqueId";
import { useState } from "react";
import OptionIcon from "static/option-icon.svg";

export default function PromineoOptionDropdown(props: IDropDownButtonOptions) {
  const [dropdownControlId] = useState(
    `promineo-option-dropdown-${_uniqueId()}`
  );

  return (
    <DropDownButton
      className="promineo-option-dropdown"
      id={dropdownControlId}
      icon={OptionIcon}
      dropDownOptions={{
        width: "auto",
        minWidth: "170",
        wrapperAttr: { class: "promineo-option-dropdown-popup" },
        position: {
          my: "right top",
          at: "right bottom",
          of: `#${dropdownControlId}`,
        },
      }}
      {...props}
    />
  );
}
