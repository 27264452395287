import PromineoButton from "components/common/controls/buttons/PromineoButton";
import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import React, { useEffect, useMemo, useState } from "react";

const Dummy = ({ data }: { data: number }) => {
  useEffect(() => {
    return () => {
      debugger;
    };
  }, []);

  return <>Dummy Component {data}</>;
};

const ParentComp = (props: any) => {
  return props.data.comp ? props.data.comp : <>{console.log(props.data)}</>;
};

export default function Test() {
  var [data, setData] = useState<number>(1);
  const memo = useMemo(() => {
    return {
      data: data,
      comp: (<Dummy data={data} />),
    };
  }, [data]);

  return (
    <div>
      <PromineoButton onClick={() => setData((d) => d + 1)}>
        Click
      </PromineoButton>
      <ParentComp data={memo} />
    </div>
  );
}
