import Circle from "components/common/Circle";
import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";

interface Props {
  status?: DataTransferStatus | null;
  tooltipText?: string;
}

export default function IEAStatusCellComponent(props: Props) {
  // If status is null, then we are considering it as failed status.
  const status = props.status;

  let circleColor = PromineoColorVariant.Red;

  if (status) {
    if (status === DataTransferStatus.Success) {
      circleColor = PromineoColorVariant.IlapGreen;
    } else if (status === DataTransferStatus.Overdue) {
      circleColor = PromineoColorVariant.Orange;
    }
  }

  return (
    <>
      <Circle tooltipText={props.tooltipText} color={circleColor} />
    </>
  );
}
