import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import TenantRequestDialog from "./TenantRequestDialog";
import { createTenantRegistrationRequestAsync } from "store/actions/TenantActions";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import TenantRequestPendingDialog from "./TenantRequestPendingDialog";

interface Props {
  onClose: () => void;
  message: string;
}

export default function NoTenantDialogs(props: Props) {
  const requestAlreadyPendingMessageParts =
    "There is a pending request in your organization";
  const dispatch = useAppDispatch();
  const [isRequested, setIsRequested] = useState(false);
  const [tenantRequestPendingMessage, setTenantRequestPendingMessage] =
    useState();

  const handleRegisterTenantClick = () => {
    displayLoadingPanel();
    dispatch(createTenantRegistrationRequestAsync())
      .unwrap()
      .then(() => {
        setIsRequested(true);
      })
      .catch((error) => {
        if (
          error &&
          error.message &&
          error.message.indexOf(requestAlreadyPendingMessageParts) >= 0
        ) {
          setTenantRequestPendingMessage(error.message);
        }
      })
      .finally(() => {
        hideLoadingPanel();
      });
  };

  if (tenantRequestPendingMessage) {
    return (
      <TenantRequestPendingDialog
        onClose={props.onClose}
        message={tenantRequestPendingMessage}
      />
    );
  }

  if (!isRequested) {
    return (
      <TenantRequestDialog
        title={"Would you like to register your domain as a tenant?"}
        contentText={props.message}
        confirmButtonText={"Submit request"}
        onConfirm={handleRegisterTenantClick}
        onCancel={props.onClose}
      />
    );
  }

  if (isRequested) {
    return (
      <TenantRequestDialog
        title={"Your request has been sent!"}
        contentText={"Keep an eye on your e-mail inbox for updates."}
        confirmButtonText={"Close"}
        onConfirm={props.onClose}
        hideCancelButton={true}
      />
    );
  }

  return null;
}
