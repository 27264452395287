import PromineoModal from "components/modal/PromineoModal";
import DownloadConflictsViewer from "./DownloadConflictsViewer";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";

interface Props {
  onClose: () => void;
  iea: ActiveExchangeAgreementListingResponse;
}

export default function DownloadConflictsDialog(props: Props) {
  return (
    <PromineoModal isVisible={true} width={720} height={"auto"}>
      <div className="pb-4">
        <DownloadConflictsViewer iea={props.iea} onClose={props.onClose} />
      </div>
    </PromineoModal>
  );
}
