import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import Circle from "./Circle";

interface Props {
  text: string;
  color: PromineoColorVariant;
  tooltip?: string;
}

export default function LabelWithCircle(props: Props) {
  return (
    <div
      className={`flex items-center ${props.tooltip ? "cursor-pointer" : ""}`}
      title={props.tooltip ?? undefined}
    >
      <div>
        <Circle color={props.color} />
      </div>
      <div className="ml-2">{props.text}</div>
    </div>
  );
}
