import ForwardArrowIcon from "components/icons/ForwardArrowIcon";

export interface BreadcrumbItem {
  key: string;
  text: string;
  data?: any;
}

interface Props {
  items: BreadcrumbItem[];
  onSelectionChange?: (index: number, selectedItem: BreadcrumbItem) => void;
  isReadonly?: boolean;
}

export default function Breadcrumb(props: Props) {
  const isLastItemToRender = (index: number) => {
    return index >= props.items.length - 1;
  };

  const handleChange = (index: number, item: BreadcrumbItem) => {
    !props.isReadonly &&
      !isLastItemToRender(index) &&
      props.onSelectionChange?.(index, item);
  };

  return (
    <div className={"flex space-x-2"}>
      {props.items.map((item: BreadcrumbItem, index: number) => {
        return (
          <div key={item.key} className={"flex space-x-2"}>
            {/* We are using these two classes "max-w-[235px] truncate" because we only want to display max 35 characters
            in the breadcrumb. After that we want to truncate the title. */}
            <div
              className={`${
                isLastItemToRender(index) ? "text-textGray-900" : "text-gray"
              } text-10px leading-3 font-poppins max-w-[235px] truncate ${
                !isLastItemToRender(index) && !props.isReadonly
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={() => handleChange(index, item)}
            >
              <span>{item.text}</span>
            </div>
            {isLastItemToRender(index) ? (
              ""
            ) : (
              <div className="m-auto">
                <ForwardArrowIcon />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
