import TemplateChangePreviewGrid from "features/setup/configs/template-update/TemplateChangePreviewGrid";
import FieldDetailsWithSummaryResponse from "interfaces/response/FieldDetailsWithSummaryResponse";
import { DirectionEnum } from "shared/enums/feature/DirectionEnum";

interface Props {
  ownerConfigUpdatedFields: FieldDetailsWithSummaryResponse[];
  partnerConfigUpdatedFields: FieldDetailsWithSummaryResponse[];
  exchangeAgreementDirection: DirectionEnum;
  displayUpdateColumn?: boolean;
}

export default function ConfigUpdateContentControlPreview(props: Props) {
  return (
    <div>
      <div>
        <div className="mb-1 font-bold font-poppins">Sender</div>
        <TemplateChangePreviewGrid
          fields={
            props.exchangeAgreementDirection === DirectionEnum.OwnerToPartner
              ? props.ownerConfigUpdatedFields
              : props.partnerConfigUpdatedFields
          }
          noDataText="No fields are changed"
          height={190}
          displayUpdateColumn={props.displayUpdateColumn}
        />
      </div>
      <div className="mt-3">
        <div className="mb-1 font-bold font-poppins">Receiver</div>
        <TemplateChangePreviewGrid
          fields={
            props.exchangeAgreementDirection === DirectionEnum.OwnerToPartner
              ? props.partnerConfigUpdatedFields
              : props.ownerConfigUpdatedFields
          }
          noDataText="No fields are changed"
          height={190}
          displayUpdateColumn={props.displayUpdateColumn}
        />
      </div>
    </div>
  );
}
