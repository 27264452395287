import { createSlice } from "@reduxjs/toolkit";
import LabelResponse from "interfaces/response/LabelResponse";
import {
  addNewLabelsAsync,
  loadLabelsAsync,
} from "store/actions/LabelActions";

export interface LabelState {
  allLabels: LabelResponse[];
}

const initialState: LabelState = {
  allLabels: [],
};

export const labelSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadLabelsAsync.fulfilled, (state, action) => {
      state.allLabels = action.payload;
    });
    builder.addCase(addNewLabelsAsync.fulfilled, (state, action) => {
      state.allLabels.push(action.payload);
    });
  },
});

export default labelSlice.reducer;
