import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  changeFieldMappingFormulaFavoriteStatusAsync,
  createFieldMappingFormulaAsync,
  deleteFieldMappingFormulaAsync,
  getCurrentUserFieldMappingFormulaAsync,
  updateFieldMappingFormulaAsync,
} from "apis/FieldMappingFormulaApi";
import FieldMappingFormulaCreateRequest from "interfaces/request/FieldMappingFormulaCreateRequest";
import FieldMappingFormulaFavoriteStatusChangeRequest from "interfaces/request/FieldMappingFormulaFavoriteStatusChangeRequest";
import FieldMappingFormulaUpdateRequest from "interfaces/request/FieldMappingFormulaUpdateRequest";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import { RootState } from "store/store";

export const loadCurrentUserFieldMappingFormulaAsync = createAsyncThunk<
  FieldMappingFormulaResponse[],
  void,
  { state: RootState }
>(
  "fieldMappingFormula/loadCurrentUserFieldMappingFormula",
  async (request, thunkApi) => {
    const state = thunkApi.getState();
    const existingFieldMappingFormulaValues =
      state.fieldMappingFormulaData.fieldMappingFormulas;
    if (existingFieldMappingFormulaValues.length === 0) {
      return await getCurrentUserFieldMappingFormulaAsync();
    } else {
      return existingFieldMappingFormulaValues;
    }
  }
);

export const createNewFieldMappingFormulaAsync = createAsyncThunk(
  "fieldMappingFormula/createNewFieldMappingFormula",
  async (requestDto: FieldMappingFormulaCreateRequest) => {
    return await createFieldMappingFormulaAsync(requestDto);
  }
);

export const modifyFieldMappingFormulaAsync = createAsyncThunk(
  "fieldMappingFormula/modifyFieldMappingFormula",
  async (request: {
    fieldMappingFormulaId: number;
    requestDto: FieldMappingFormulaUpdateRequest;
  }) => {
    return await updateFieldMappingFormulaAsync(
      request.fieldMappingFormulaId,
      request.requestDto
    );
  }
);

export const modifyFieldMappingFormulaFavoriteStatusAsync = createAsyncThunk(
  "fieldMappingFormula/modifyFieldMappingFormulaFavoriteStatus",
  async (request: {
    fieldMappingFormulaId: number;
    requestDto: FieldMappingFormulaFavoriteStatusChangeRequest;
  }) => {
    return await changeFieldMappingFormulaFavoriteStatusAsync(
      request.fieldMappingFormulaId,
      request.requestDto
    );
  }
);

export const removeFieldMappingFormulaAsync = createAsyncThunk(
  "fieldMappingFormula/removeFieldMappingFormula",
  async (fieldMappingFormulaId: number) => {
    return await deleteFieldMappingFormulaAsync(fieldMappingFormulaId);
  }
);
