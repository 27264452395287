import { useAppSelector } from "store/hooks";
import SapParameterDropdown from "./SapParameterDropdown";
import { applyGridChangesToDataSource } from "shared/utilities/PromineoGridUtility";
import { SapHostParameterGridKey } from "shared/utilities/HostSystemConfigurationUtility";
import { EditorMode } from "shared/enums/EditorMode";

export default function FunctionSequenceDropdownCellEdit(props: any) {
  const data = props.data.data;
  const sequenceTypeOptions = useAppSelector(
    (store) => store.dropdownData.sapSequenceTypes
  );

  const handleValueChange = (value: any) => {
    props.data.component.cellValue(
      props.data.row.rowIndex,
      "sequenceType",
      value
    );

    applyGridChangesToDataSource(SapHostParameterGridKey, props.data.component);
  };

  if (props.mode === EditorMode.View) {
    const sequenceTypeOptionsDisplay =
      (sequenceTypeOptions || []).find((o) => o.value === data.sequenceType)?.name ??
      "-";

    return <>{sequenceTypeOptionsDisplay}</>;
  }

  return (
    <SapParameterDropdown
      dataSource={sequenceTypeOptions}
      defaultValue={data.sequenceType}
      onValueChange={handleValueChange}
    />
  );
}
