interface Props {
  title: string;
  description: string;
}

export default function TemplateConfigurationUpdatePreviewModalHeader(
  props: Props
) {
  return (
    <>
      <div className="mb-4">
        <div className="font-bold font-poppins">{props.title}</div>
        <div className="border-t border-lightGray mt-4"></div>
      </div>
      <p className="font-inter">{props.description}</p>
    </>
  );
}
