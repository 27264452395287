import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import { getHiddenPasswordPlaceHolder } from "shared/utilities/CommonUtility";

interface Props {
  mode: PromineoModalMode;
  configuration: SAPConnectorConfiguration;
  validateOnFocusOut?: (evt: any) => void;
  onValueChange?: (fieldName: string, value: string) => void;
}

const ClientSecretField = (props: Props) => {
  const handleValueChanged = (evt: any) => {
    props.onValueChange?.(
      evt.event.currentTarget.name,
      evt.event.currentTarget.value
    );
  };

  return (
    <>
      {(props.mode === PromineoModalMode.Create ||
        props.mode === PromineoModalMode.Modify) && (
        <PromineoTextBox
          placeholder="Type..."
          width={props.mode === PromineoModalMode.Create ? 330 : 188}
          required={true}
          name="clientSecret"
          fieldName="clientSecret"
          mode="password"
          defaultValue={props.configuration.clientSecret}
          onValueChanged={handleValueChanged}
          onFocusOut={props.validateOnFocusOut}
        />
      )}

      {props.mode === PromineoModalMode.View &&
        getHiddenPasswordPlaceHolder(props.configuration.clientSecret)
      }
    </>
  );
};

export default function SAPConnectorClientSecretField(props: Props) {
  return (
    <ConnectorLabelWithContent
      mode={props.mode}
      addAsterisksymbol={true}
      label="Client Secret"
      content={
        <ClientSecretField
          mode={props.mode}
          configuration={props.configuration}
          validateOnFocusOut={props.validateOnFocusOut}
          onValueChange={props.onValueChange}
        />
      }
    />
  );
}
