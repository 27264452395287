import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { ISelectBoxOptions } from "devextreme-react/select-box";
import ExchangeAgreementSimplifiedResponse from "interfaces/response/ExchangeAgreementSimplifiedResponse";

interface Props {
  exchangeAgreements: ExchangeAgreementSimplifiedResponse[];
  defaultValue?: number;
  onValueChange: (value: number) => void;
}
export default function PredecessorIeaSelectionDropdown(
  props: Props & ISelectBoxOptions
) {
  const { exchangeAgreements, onValueChange, dataSource, ...rest } = props;
  const handleChange = (evt : any)=>{
    if (evt.value !== null) {
      onValueChange(evt.value);
    } 
  }
  return (
    <PromineoSelectBox
      placeholder="Select IEA ID"
      items={exchangeAgreements}
      defaultValue={props.defaultValue}
      valueExpr="id"
      displayExpr="title"
      searchEnabled={true}
      searchMode="contains"
      onValueChanged={handleChange}
      {...rest}
    />
  );
}
