import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCoreFieldsAsync } from "apis/CoreFieldApi";
import { RootState } from "store/store";

export const loadCoreFieldsAsync = createAsyncThunk<any, void, { state: RootState }>("coreFields/loadCoreFields", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const coreFields = state.coreFieldData.coreFields;

  if (coreFields.length === 0) {
    return await getCoreFieldsAsync();
  } else {
    return coreFields;
  }
});
