export default function PauseIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.84821"
        y="1.84821"
        width="18.3036"
        height="18.3036"
        rx="9.15179"
        stroke="#ABABAB"
        strokeWidth="2.19643"
      />
      <path
        d="M8.80273 7.33594L8.80273 14.6574"
        stroke="#ABABAB"
        strokeWidth="2.19643"
        strokeLinecap="round"
      />
      <path
        d="M13.1953 7.33594L13.1953 14.6574"
        stroke="#ABABAB"
        strokeWidth="2.19643"
        strokeLinecap="round"
      />
    </svg>
  );
}
