export default function PendingIcon() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8344_26103)">
        <mask
          id="mask0_8344_26103"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="13"
          height="12"
        >
          <rect x="0.199219" width="12" height="12" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_8344_26103)">
          <path
            d="M6.72461 11V9.97503C7.00794 9.93336 7.28511 9.85836 7.55611 9.75002C7.82678 9.64169 8.08294 9.50002 8.32461 9.32502L9.02461 10.05C8.67461 10.3167 8.30794 10.5312 7.92461 10.6935C7.54128 10.8562 7.14128 10.9584 6.72461 11ZM5.72461 11C4.57461 10.85 3.62044 10.3522 2.86211 9.50653C2.10378 8.66053 1.72461 7.66669 1.72461 6.52502C1.72461 5.90002 1.84344 5.31452 2.08111 4.76852C2.31844 4.22286 2.63928 3.74786 3.04361 3.34352C3.44761 2.93952 3.92261 2.61869 4.46861 2.38102C5.01428 2.14369 5.59961 2.02502 6.22461 2.02502H6.29961L5.52461 1.25002L6.22461 0.525024L8.22461 2.52502L6.22461 4.52502L5.52461 3.82502L6.32461 3.02502H6.22461C5.24961 3.02502 4.42261 3.36469 3.74361 4.04402C3.06428 4.72302 2.72461 5.55002 2.72461 6.52502C2.72461 7.39169 3.00794 8.15219 3.57461 8.80653C4.14128 9.46053 4.85794 9.85003 5.72461 9.97503V11ZM9.74961 9.32502L9.02461 8.62502C9.19961 8.38336 9.34128 8.12702 9.44961 7.85602C9.55794 7.58536 9.63294 7.30836 9.67461 7.02502H10.6996C10.6579 7.44169 10.5559 7.84169 10.3936 8.22502C10.2309 8.60836 10.0163 8.97502 9.74961 9.32502ZM10.6996 6.02502H9.67461C9.63294 5.74169 9.55794 5.46452 9.44961 5.19352C9.34128 4.92286 9.19961 4.66669 9.02461 4.42502L9.74961 3.72502C10.0163 4.05002 10.2288 4.40836 10.3871 4.80002C10.5454 5.19169 10.6496 5.60002 10.6996 6.02502Z"
            fill="#434343"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8344_26103">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.199219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
