import "./styles/LoadingIcon.css";
export default function TrashIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66602 12C2.29935 12 1.98557 11.8696 1.72468 11.6087C1.46335 11.3473 1.33268 11.0333 1.33268 10.6667V2H0.666016V0.666667H3.99935V0H7.99935V0.666667H11.3327V2H10.666V10.6667C10.666 11.0333 10.5356 11.3473 10.2747 11.6087C10.0133 11.8696 9.69935 12 9.33268 12H2.66602ZM9.33268 2H2.66602V10.6667H9.33268V2ZM3.99935 9.33333H5.33268V3.33333H3.99935V9.33333ZM6.66602 9.33333H7.99935V3.33333H6.66602V9.33333Z"
        fill="#727272"
      />
    </svg>
  );
}
