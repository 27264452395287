import ConnectorScheduleResponse from "interfaces/response/ConnectorScheduleResponse";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { getLocalFormattedDate, getLocalFormattedDateTime } from "shared/utilities/DateTimeUtility";
import "components/common/grid/styles/PromineoUIGrid.css";
import { stringAndNumberCombinationSortComparator } from "shared/utilities/CommonUtility";

interface Props {
  scheduleList: ConnectorScheduleResponse[];
  height: number;
}

export default function ScheduleGrid(props: Props) {
  return (
    <>
      <PromineoViewGrid
        dataSource={props.scheduleList}
        className="connectors-schedule-grid disable-hover promineo-ui-grid"
        toolbarConfig={{
          dislplaySearchPanel: true,
        }}
        height={props.height}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column
          caption={"Schedule ID"}
          dataField="scheduleId"
          alignment="left"
          width={200}
          sortingMethod={stringAndNumberCombinationSortComparator}
          sortOrder={"asc"}
        />
        <Column caption={"Title"} dataField="title" alignment="left" />
        <Column
          caption={"Start Date"}
          dataField="startDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getLocalFormattedDate(data.value) : "-"
          }
          width={200}
        />
        <Column
          caption={"Finish Date"}
          dataField="finishDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getLocalFormattedDate(data.value) : "-"
          }
          width={200}
        />
        <Column
          caption={"Last Updated"}
          dataField="lastUpdatedDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getLocalFormattedDateTime(data.value) : "-"
          }
          width={200}
        />
        <Column
          caption={"Code set"}
          cssClass="add-left-border"
          dataField="userFieldSetId"
          alignment="left"
          width={200}
        />
      </PromineoViewGrid>
    </>
  );
}
