import PromineoModal from "components/modal/PromineoModal";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { useState } from "react";
import { HostSystem } from "shared/enums/feature/HostSystem";
import IEAActivityFilter from "./IEAActivityFilter";

interface Props {
  senderHostSystem: HostSystem | undefined;
  isEdit: boolean;
  onCancel: () => void;
  onSave: (newFilter: string) => void;
  filterValue: string;
}

export default function IEAActivityFilterDialog(props: Props) {
  const { isEdit, onCancel, onSave } = props;
  const [currentFilterValue, setCurrentFilterValue] = useState<string>("");

  const handleCurrentFilterChange = (value: string) => {
    setCurrentFilterValue(value);
  };

  return (
    <PromineoModal
      isVisible={true}
      displayDefaultActions={true}
      coloredBg={true}
      width={720}
      height={isEdit ? 580 : 500}
      editorActionButtonProps={{
        mode: isEdit ? PromineoModalMode.Create : PromineoModalMode.View,
        cancelButtonCaption: "Cancel",
        createButtonCaption: "Save",
        doneButtonCaption: "Cancel",
        onDone: onCancel,
        onCancel: onCancel,
        onCreate: () => {
          onSave(currentFilterValue);
        },
      }}
    >
      <IEAActivityFilter
        isEdit={props.isEdit}
        filterValue={props.filterValue}
        senderHostSystem={props.senderHostSystem}
        onCurrentFilterValueChange={handleCurrentFilterChange}
      />
    </PromineoModal>
  );
}
