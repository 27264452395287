import {
  addToFavorite,
  removeFromFavorite,
} from "shared/utilities/PromineoGridFavoriteItemUtility";
import Favorite from "../controls/Favorite";

interface Props {
  dataIdentifier: string;
  gridNameWithUserIdentifer: string;
  favorites: any[];
  data: any;
}

export default function PromineoGridFavorite(props: Props) {
  const checkIfDataRowIsInFavorite = () => {
    const identifierValueInData = props.data.data[props.dataIdentifier];
    return props.favorites.indexOf(identifierValueInData) >= 0;
  };

  const handleChange = (isFavorite: boolean) => {
    if (isFavorite) {
      addToFavorite(
        props.gridNameWithUserIdentifer,
        props.data.data[props.dataIdentifier]
      );
    } else {
      removeFromFavorite(
        props.gridNameWithUserIdentifer,
        props.data.data[props.dataIdentifier]
      );
    }
  };

  return (
    <Favorite
      value={checkIfDataRowIsInFavorite()}
      onValueChange={handleChange}
    />
  );
}
