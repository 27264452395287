export default function FavoriteIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8931 6.89471C17.0266 6.77889 16.9553 6.55959 16.7792 6.54441L11.0786 6.05284C11.0049 6.04648 10.9407 5.99988 10.9117 5.93172L8.68424 0.684046C8.61525 0.521526 8.38489 0.521588 8.316 0.684146L6.08831 5.94021C6.05937 6.00851 5.99499 6.05517 5.92108 6.06145L0.222326 6.54493C0.0460403 6.55988 -0.0254407 6.77954 0.108279 6.89538L4.43626 10.6447C4.49237 10.6933 4.51694 10.769 4.50007 10.8413L3.20018 16.4134C3.15999 16.5857 3.34684 16.7215 3.4983 16.6301L8.39665 13.6736C8.46021 13.6353 8.53979 13.6353 8.60335 13.6736L13.5025 16.6305C13.6538 16.7219 13.8406 16.5863 13.8006 16.4141L12.5084 10.841C12.4917 10.7689 12.5162 10.6934 12.5722 10.6448L16.8931 6.89471ZM8.60341 12.0375C8.53982 11.9991 8.46016 11.9991 8.39654 12.0375L5.61603 13.7161C5.46454 13.8076 5.27765 13.6717 5.31791 13.4994L6.05754 10.3338C6.07443 10.2615 6.04991 10.1858 5.99385 10.1372L3.53679 8.00575C3.40333 7.88999 3.47455 7.6707 3.65056 7.65543L6.89238 7.37417C6.96626 7.36776 7.03054 7.32099 7.05936 7.25265L8.31604 4.2736C8.38476 4.11069 8.61557 4.11055 8.68449 4.27338L9.94932 7.26161C9.97819 7.32983 10.0424 7.3765 10.1162 7.38291L13.3582 7.66418C13.5342 7.67945 13.6054 7.89874 13.472 8.0145L11.0149 10.1459C10.9588 10.1946 10.9343 10.2702 10.9512 10.3425L11.6907 13.5077C11.731 13.68 11.544 13.8159 11.3925 13.7243L8.60341 12.0375Z"
        fill="#727272"
      />
    </svg>
  );
}
