import applyChanges from "devextreme/data/apply_changes";

export const applyGridChangesToDataSource = (
  key: string,
  gridComponent: any
) => {
  gridComponent
    .getDataSource()
    .store()
    .load()
    .then((data: any) => {
      var changes = gridComponent.option("editing.changes");
      applyChanges(data, changes, {
        keyExpr: key,
        immutable: false,
      });
    });
};
