import {
  EditorActionButtonProp,
} from "components/modal/PromineoModalActionFooter";
import { IPopupOptions, Popup } from "devextreme-react/popup";
import { ReactElement, ReactNode } from "react";
import "../../modal/styles/PromineoModal.css";
import PromineoButton, { PromineoButtonType } from "./buttons/PromineoButton";

export interface Props extends IPopupOptions {
  className?: string;
  children?: ReactNode;
  title?: string;
  content?: string | ReactElement;
  subContent?: string | ReactElement;
  width?: string;
  height?: string;
  onCancel?: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  additionalButtonText?: string;
  onConfirm?: () => void;
  onAdditionalButtonClick?: () => void;
  actionOptions?: EditorActionButtonProp;
  isDeleteConfirm?: boolean;
  hideCancelButton?: boolean;
}

export default function PromineoConfirmationDialog(props: Props) {
  const {
    title,
    className,
    children,
    content,
    subContent,
    width,
    height,
    onCancel,
    cancelButtonText,
    onConfirm,
    confirmButtonText,
    additionalButtonText,
    onAdditionalButtonClick,
    actionOptions,
    isDeleteConfirm,
    ...rest
  } = props;

  return (
    <Popup
      wrapperAttr={{ class: "promineo-popup" }}
      visible={true}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={false}
      width={width ?? "400"}
      height={height ?? "auto"}
      position={"center"}
      showTitle={false}
      className={`${className ?? ""}`}
      {...rest}
    >
      <div className="px-8 pb-6 pt-8 flex flex-col justify-between h-full">
        <div className="text-center font-medium font-poppins text-lg">
          {content}
        </div>
        {subContent && (
          <div className="text-sm mt-8 text-center font-inter flex-grow h-full mb-auto">
            {subContent}
          </div>
        )}
        <div className="mt-10">
          <div className="flex justify-between">
            <div>
              {!props.hideCancelButton && (
                <PromineoButton
                  text={cancelButtonText ?? "Cancel"}
                  variant={PromineoButtonType.Secondary}
                  onClick={() => onCancel?.()}
                />
              )}
            </div>
            {additionalButtonText && onAdditionalButtonClick && (
              <PromineoButton
                text={additionalButtonText}
                variant={PromineoButtonType.Borderless}
                onClick={onAdditionalButtonClick}
              />
            )}
            {isDeleteConfirm ? (
              <PromineoButton
                text={confirmButtonText ?? "Ok"}
                variant={PromineoButtonType.BorderlessDanger}
                onClick={() => onConfirm?.()}
              />
            ) : (
              <PromineoButton
                text={confirmButtonText ?? "Ok"}
                variant={PromineoButtonType.Primary}
                onClick={() => onConfirm?.()}
              />
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
}
