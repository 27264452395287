import LabelHolder from "components/common/LabelHolder";
import LabelResponse from "interfaces/response/LabelResponse";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";

interface Props {
  className: string;
  labels: LabelResponse[];
  readonly: boolean;
  onDelete?: (label: LabelResponse) => void;
  onAddLabel?: (label: LabelResponse) => void;
}

export default function ConfigLabelHolder(props: Props) {
  return (
    <div className={props.className ? props.className : ""}>
      <div>Labels:</div>
      <LabelHolder
        labelType={SupportedLabelEntity.Config}
        labels={props.labels}
        allowAdd={!props.readonly}
        allowDelete={!props.readonly}
        onAddLabel={props.onAddLabel}
        onDelete={props.onDelete}
      />
    </div>
  );
}
