import { createSlice } from "@reduxjs/toolkit";
import HostSystemDefinitionResponse from "interfaces/response/HostSystemDefinitionResponse";
import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { HostSystem } from "shared/enums/feature/HostSystem";
import {
  loadHostSystemConfigurationDefinitionAsync,
  loadStructureToExportCandidatesAsync,
  loadWbsStructureCandidatesAsync,
} from "store/actions/HostSystemActions";

export interface WbsStructureCandidatesListByConnectorAndCodeSet {
  connectorId: number;
  codeSetId: number;
  wbsStructureCandidates: HostFieldDropdownValueResponse[];
}

export interface StructuresToExportCandidatesListByConnectorAndCodeSet {
  connectorId: number;
  codeSetId: number;
  structuresToExportCandidates: HostFieldDropdownValueResponse[];
}

export interface HostSystemState {
  hostSystemConfigurationDefinitionDict: Record<
    string,
    HostSystemDefinitionResponse[]
  >;
  hostSystemConfigurationDefinition: HostSystemDefinitionResponse[];
  wbsStructureCandidatesListByConnectorAndCodeSet: WbsStructureCandidatesListByConnectorAndCodeSet[];
  structuresToExportCandidatesListByConnectorAndCodeSet: StructuresToExportCandidatesListByConnectorAndCodeSet[];
}

const initialState: HostSystemState = {
  hostSystemConfigurationDefinitionDict: {},
  hostSystemConfigurationDefinition: [],
  wbsStructureCandidatesListByConnectorAndCodeSet: [],
  structuresToExportCandidatesListByConnectorAndCodeSet: [],
};

export const getHostSystemConfigDefinitionDictKey = (
  hostSystem: HostSystem,
  exchangeRole: ExchangeRoleEnum
) => {
  const dictKey = `${hostSystem}|${exchangeRole}`;

  return dictKey;
};

export const hostSystemSlice = createSlice({
  name: "hostSystem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadHostSystemConfigurationDefinitionAsync.fulfilled,
      (state, action) => {
        const { hostSystem, exchangeRole } = action.meta.arg;
        const dictKey = getHostSystemConfigDefinitionDictKey(
          hostSystem,
          exchangeRole
        );
        state.hostSystemConfigurationDefinitionDict[dictKey] = action.payload;
        state.hostSystemConfigurationDefinition = action.payload;
      }
    );

    builder.addCase(
      loadWbsStructureCandidatesAsync.fulfilled,
      (state, action) => {
        const requestedCodeSetId = action.meta.arg.codeSetId;
        const requestedconnectorId = action.meta.arg.connectorId;

        const wbsStructureCandidatesByConnectorAndCodeSet: WbsStructureCandidatesListByConnectorAndCodeSet =
          {
            codeSetId: requestedCodeSetId,
            connectorId: requestedconnectorId,
            wbsStructureCandidates: action.payload,
          };

        const existingWbsStructureCandidateIndex =
          state.wbsStructureCandidatesListByConnectorAndCodeSet.findIndex(
            (wbsCandidates: WbsStructureCandidatesListByConnectorAndCodeSet) =>
              wbsCandidates.codeSetId === requestedCodeSetId &&
              wbsCandidates.connectorId === requestedconnectorId
          );

        if (existingWbsStructureCandidateIndex > -1) {
          state.wbsStructureCandidatesListByConnectorAndCodeSet[
            existingWbsStructureCandidateIndex
          ].wbsStructureCandidates = action.payload;
        } else {
          state.wbsStructureCandidatesListByConnectorAndCodeSet.push(
            wbsStructureCandidatesByConnectorAndCodeSet
          );
        }
      }
    );

    builder.addCase(
      loadStructureToExportCandidatesAsync.fulfilled,
      (state, action) => {
        const requestedCodeSetId = action.meta.arg.codeSetId;
        const requestedconnectorId = action.meta.arg.connectorId;

        const structuresToExportCandidatesByConnectorAndCodeSet: StructuresToExportCandidatesListByConnectorAndCodeSet =
          {
            codeSetId: requestedCodeSetId,
            connectorId: requestedconnectorId,
            structuresToExportCandidates: action.payload,
          };

        const existingStructuresToExportCandidateIndex =
          state.structuresToExportCandidatesListByConnectorAndCodeSet.findIndex(
            (
              candidates: StructuresToExportCandidatesListByConnectorAndCodeSet
            ) =>
              candidates.codeSetId === requestedCodeSetId &&
              candidates.connectorId === requestedconnectorId
          );

        if (existingStructuresToExportCandidateIndex > -1) {
          state.structuresToExportCandidatesListByConnectorAndCodeSet[
            existingStructuresToExportCandidateIndex
          ].structuresToExportCandidates = action.payload;
        } else {
          state.structuresToExportCandidatesListByConnectorAndCodeSet.push(
            structuresToExportCandidatesByConnectorAndCodeSet
          );
        }
      }
    );
  },
});

export default hostSystemSlice.reducer;
