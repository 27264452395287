export default function DisabledIcon() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8344_13921)">
        <path
          d="M9.69922 9.5H2.69922V2.5H9.69922V9.5ZM1.69922 1.5V10.5H10.6992V1.5H1.69922ZM8.69922 7.795L7.99422 8.5L6.19922 6.705L4.40422 8.5L3.69922 7.795L5.49422 6L3.69922 4.205L4.40422 3.5L6.19922 5.295L7.99422 3.5L8.69922 4.205L6.90422 6L8.69922 7.795Z"
          fill="#434343"
        />
      </g>
      <defs>
        <clipPath id="clip0_8344_13921">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.199219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
