import PromineoEditGrid from "components/common/grid/PromineoEditGrid";
import { Column, Editing, Scrolling } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import PopoverMenuItem from "components/common/controls/popover-with-menu/PopoverMenuItem";
import { GetNewId } from "shared/utilities/CommonUtility";
import "components/common/grid/styles/PromineoUIGrid.css";
import BulkFieldValueInsertDialog from "features/common/bulk-value-insert-dialog/BulkFieldValueInsertDialog";
import EditFieldValueToggleButtons from "./EditFieldValueToggleButtons";
import NewFieldValueAddButton from "./NewFieldValueAddButton";
import FieldValue from "interfaces/common/FieldValue";

interface Props {
  allowContentControl: boolean;
  setAllowContentControl: (value: boolean) => void;
  allowBlanks: boolean;
  setAllowBlanks: (value: boolean) => void;
  fieldValueDataSource: FieldValue[];
  setFieldValueDataSource: (value: any) => void;
  disableContentControl?: boolean;
  height: number;
  onRowUpdated: (evt: any) => void;
}

export default function EditFieldValueGrid(props: Props) {
  const [
    displayBulkInsertFieldValueDialog,
    setDisplayBulkInsertFieldValueDialog,
  ] = useState(false);

  const onSingleOptionClick = () => {
    var newDataSource = [...props.fieldValueDataSource];
    newDataSource.unshift({
      valueCode: "",
      description: "",
      __KEY__: GetNewId(),
    });
    props.setFieldValueDataSource(newDataSource);
  };
  const onBulkOptionClick = () => setDisplayBulkInsertFieldValueDialog(true);

  const onBulkInsertSubmit = (newFieldValuesToInsert: FieldValue[]) => {
    const fieldValues = [
      ...newFieldValuesToInsert,
      ...props.fieldValueDataSource,
    ];
    props.setFieldValueDataSource(fieldValues);
    setDisplayBulkInsertFieldValueDialog(false);
  };

  const selectionItems: PopoverMenuItem[] = [
    {
      itemId: "1",
      text: "Single Value",
      onClick: () => onSingleOptionClick(),
    },
    {
      itemId: "2",
      text: "Update in Bulk",
      onClick: () => onBulkOptionClick(),
    },
  ];

  const onRowDelete = useCallback(
    (evt: { data: FieldValue }) => {
      var newDataSource = props.fieldValueDataSource.filter((fd) => {
        return evt.data.id
          ? evt.data.id !== fd.id
          : evt.data.__KEY__ !== fd.__KEY__;
      });
      props.setFieldValueDataSource(newDataSource);
    },
    [props]
  );

  const rowHighlightingConfig = useRef({
    highlightRow: true,
  });

  const rowDeletionConfig = useMemo(() => {
    return { visible: true, onDelete: onRowDelete };
  }, [onRowDelete]);

  return (
    <div>
      <div className="flex justify-between mb-2 ">
        <EditFieldValueToggleButtons
          isContentControlChecked={
            props.allowContentControl && props.disableContentControl !== true
          }
          isAllowBlanksChecked={props.allowBlanks}
          onContentControlChange={() =>
            props.setAllowContentControl(!props.allowContentControl)
          }
          onAllowBlanksChange={() => props.setAllowBlanks(!props.allowBlanks)}
          disableAllowContent={props.disableContentControl}
        />

        <NewFieldValueAddButton
          id={"new-field-add-button"}
          menuItems={selectionItems}
          disabled={
            !props.allowContentControl || props.disableContentControl === true
          }
        />
      </div>

      {props.allowContentControl ? (
        <PromineoEditGrid
          dataSource={props.fieldValueDataSource}
          className="promineo-ui-grid"
          rowHighlighting={rowHighlightingConfig.current}
          displayDeleteRowOption={rowDeletionConfig}
          height={props.height}
          onRowUpdated={props.onRowUpdated}
          noDataText="Values will appear here once they are added!"
        >
          <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
          <Editing mode={"cell"} allowUpdating={true} />
          <Column
            caption={"Value Code"}
            dataField={"valueCode"}
            showEditorAlways={true}
          ></Column>
          <Column
            caption={"Description"}
            showEditorAlways={true}
            dataField={"description"}
          ></Column>
        </PromineoEditGrid>
      ) : (
        <div className="w-full flex items justify-center items-center mt-16 mb-16 text-10px">
          No Content Control
        </div>
      )}

      {displayBulkInsertFieldValueDialog && (
        <BulkFieldValueInsertDialog
          onCancel={() => setDisplayBulkInsertFieldValueDialog(false)}
          onSubmit={onBulkInsertSubmit}
        />
      )}
    </div>
  );
}
