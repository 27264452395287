import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import EditIcon from "components/icons/EditIcon";
import AddButtonCellTemplate from "../../config-common/AddButtonCellTemplate";

interface Props {
  data: { data: FieldMappingResponse };
  onClick: () => void;
}

export default function MappingFormulaCellTemplate(props: Props) {
  const { data, onClick } = props;
  return data.data.formula ? (
    <div className="flex gap-2 mt-2" title={data.data.formula}>
      <div className="font-inter text-10px leading-3 overflow-hidden">
        {data.data.formula}
      </div>
      <div onClick={onClick} className="hover:cursor-pointer">
        <EditIcon />
      </div>
    </div>
  ) : (
    <AddButtonCellTemplate onClick={onClick} />
  );
}
