import LabelRequest from "interfaces/request/LabelRequest";
import LabelResponse from "interfaces/response/LabelResponse";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { getDataAsync, postDataAsync } from "./API";

export const getLabelsAsync = async (entity: SupportedLabelEntity) => {
  let apiUrl = `Labels/${entity}`;
  let response = await getDataAsync<LabelResponse[]>(apiUrl);
  
  return response;
};

export const addLabelsAsync = async (labelRequest: LabelRequest) => {
  let apiUrl = `Labels`;
  let response = await postDataAsync<LabelRequest, LabelResponse>(
    apiUrl,
    labelRequest
  );

  return response;
};
