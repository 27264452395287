import PromineoTextBox from "components/common/controls/PromineoTextBox";
import TenantInfoLabelTextContent from "./TenantInfoLabelTextContent";
import TenantContactInformationBase from "interfaces/common/TenantContactInformationBase";

export default function TenantContactFieldViewOrEdit(props: {
  contactDetail: TenantContactInformationBase;
  isEdit: boolean;
  onChange: (tenantContactInfo: TenantContactInformationBase) => void;
}) {
  const handleChange = (evt: any) => {
    props.onChange({
      ...props.contactDetail,
      [evt.event.currentTarget.name]: evt.value,
    });
  };

  if (!props.isEdit) {
    return (
      <div className="space-y-2">
        <TenantInfoLabelTextContent
          text={`${props.contactDetail.name ?? ""}`}
        />
        <TenantInfoLabelTextContent
          text={`${props.contactDetail.phoneNumber ?? ""}`}
        />
        <TenantInfoLabelTextContent
          text={`${props.contactDetail.email ?? ""}`}
        />
      </div>
    );
  }

  return (
    <div className="space-y-1">
      <PromineoTextBox
        width={188}
        name="name"
        defaultValue={props.contactDetail.name}
        placeholder="Type name"
        onValueChanged={handleChange}
      />
      <PromineoTextBox
        name="phoneNumber"
        width={188}
        placeholder="Type phone number"
        defaultValue={props.contactDetail.phoneNumber}
        onValueChanged={handleChange}
      />
      <PromineoTextBox
        name="email"
        placeholder="Type email"
        width={188}
        defaultValue={props.contactDetail.email}
        onValueChanged={handleChange}
      />
    </div>
  );
}