export default function DoneIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_112_11536)">
        <g clipPath="url(#clip1_112_11536)">
          <g clipPath="url(#clip2_112_11536)">
            <g clipPath="url(#clip3_112_11536)">
              <path
                d="M6 10.7999L3.2 7.99994L2.26666 8.93328L6 12.6666L14 4.66661L13.0667 3.73328L6 10.7999Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_112_11536">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_112_11536">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip2_112_11536">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip3_112_11536">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
