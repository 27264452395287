import SystemLevelContentControlChangeRequest from "interfaces/request/SystemLevelContentControlChangeRequest";
import { getDataAsync, postDataAsync, putDataAsync } from "./API";
import SystemAdminFieldResponse from "interfaces/response/SystemAdminFieldResponse";
import SystemLevelContentControlChangeResponse from "interfaces/response/SystemLevelContentControlChangeResponse";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import SystemFieldCreateRequest from "interfaces/request/FieldCreateRequest";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import FieldUpdateRequest from "interfaces/request/FieldUpdateRequest";
import SystemUserResponse from "interfaces/response/SystemUserResponse";

export const loadIlapTermFieldsForSystemAdminAsync = async () => {
  const url = "Fields/SystemLevel";
  const response = getDataAsync<SystemAdminFieldResponse[]>(url);
  return response;
};

export const createFieldsForSystemAdminAsync = async (
  systemFieldCreateRequest: SystemFieldCreateRequest
) => {
  const url = "Fields";
  const response = postDataAsync<SystemFieldCreateRequest, number>(url, systemFieldCreateRequest);
  return response;
};

export const changeFieldsContentControlForSystemAdminAsync = async (
  fieldId: number,
  systemLevelContentControlChangeRequest: SystemLevelContentControlChangeRequest
) => {
  const url = `Fields/${fieldId}/ContentControl/SystemLevel`;
  const response = postDataAsync<
    SystemLevelContentControlChangeRequest,
    SystemLevelContentControlChangeResponse
  >(url, systemLevelContentControlChangeRequest);
  return response;
};

export const loadSystemLevelFieldDetailsAsync = async (fieldId: number) => {
  const url = `Fields/${fieldId}/Details/SystemLevel`;
  const response = await getDataAsync<FieldDetailsResponse>(url);
  return response;
};

export const modifyIlapTermWithValuesAsync = async (
  systemFieldId: number,
  fieldManageRequest: FieldManageRequest
) => {
  const url = `SystemFields/${systemFieldId}/Manage`;
  const response = postDataAsync<FieldManageRequest, FieldDetailsResponse>(url, fieldManageRequest);
  return response;
};

export const modifyIlapTermBySystemAdminAsync = async (
  fieldId: number,
  fieldUpdateRequest: FieldUpdateRequest
) => {
  const url = `Fields/${fieldId}/ContentControl/SystemLevel`;
  const response = putDataAsync<FieldUpdateRequest, FieldDetailsResponse>(url, fieldUpdateRequest);
  return response;
};

export const getSystemUsersAsync = async (excludeSelf : boolean = false, includeAutonomousComponentUsers : boolean = false) => {
  let apiUrl = `System/Users?excludeSelf=${excludeSelf}&includeAutonomousComponentUsers=${includeAutonomousComponentUsers}`;
  
  return await getDataAsync<SystemUserResponse[]>(apiUrl);
};
