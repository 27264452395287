import PromineoViewGrid, {
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import TenantUserResponse from "interfaces/response/TenantUserResponse";
import { useCallback, useEffect, useRef, useState } from "react";
import { UserStatus } from "shared/enums/UserStatus";
import StatusDisplayComponent from "./StatusDisplayComponent";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { copyToClipboard } from "shared/utilities/CommonUtility";
import EditUserDialog from "./EditUserDialog";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  resetCurrentUser,
  setCurrentUser,
} from "store/slices/TenantAdminSlice";
import { getLocalFormattedDateTime } from "shared/utilities/DateTimeUtility";
import { UserType } from "shared/enums/feature/UserType";

interface Props {
  tenantUsers: TenantUserResponse[];
  height: number;
}

export default function TenantAdminUserGrid(props: Props) {
  const dispatch = useAppDispatch();
  const [userToEdit, setUserToEdit] = useState<TenantUserResponse | null>();

  const handleInviteUserClick = () => {};
  const handleWithdrawInvitation = (data: { data: TenantUserResponse }) => {};
  const handleCopyEmailClick = async (data: { data: TenantUserResponse }) => {
    await copyToClipboard(data.data.email);
    toastSuccess("Email address copied to clipboard.");
  };
  const handleEditInvitationClick = (data: { data: TenantUserResponse }) => {};
  const handleEditUserClick = (data: { data: TenantUserResponse }) => {
    dispatch(setCurrentUser(data.data));
  };
  const handleEditUserDialogClose = () => {
    dispatch(resetCurrentUser());
  };

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    displayDefault: false,
    items: [
      {
        text: "Withdraw invitation",
        visibleFn: (data: { data: TenantUserResponse }) => {
          return data.data.status === UserStatus.Invited;
        },
        onClick: handleWithdrawInvitation,
      },
      {
        text: "Copy email address",
        visible: true,
        onClick: handleCopyEmailClick,
      },
      {
        text: "Edit invitation",
        visibleFn: (data: { data: TenantUserResponse }) => {
          return data.data.status === UserStatus.Invited;
        },
        onClick: handleEditInvitationClick,
      },
      {
        text: "Edit user",
        visibleFn: (data: { data: TenantUserResponse }) => {
          return (
            data.data.type === UserType.AdB2C &&
            (data.data.status === UserStatus.Active ||
              data.data.status === UserStatus.Disabled)
          );
        },
        onClick: handleEditUserClick,
      },
    ],
  });

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
    displayAddNewButton: false,
    addNewButtonText: "Invite User",
    onClickAddNew: handleInviteUserClick,
  });

  const currentUserToEdit = useAppSelector(
    (store) => store.tenantAdminData.currentUser
  );

  useEffect(() => {
    setUserToEdit(currentUserToEdit);
  }, [currentUserToEdit]);

  const LastLoginDisplay = useCallback((data: TenantUserResponse) => {
    return data.lastLogin
      ? getLocalFormattedDateTime(data.lastLogin.toString())
      : "-";
  }, []);

  return (
    <>
      <PromineoViewGrid
        dataSource={props.tenantUsers}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig.current}
        height={props.height}
        noDataText="Tenant users will appear here once they are added!"
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"User ID"}
          dataField="id"
          alignment="left"
          width={100}
        />
        <Column caption={"Username"} dataField="fullName" />
        <Column
          caption={"Status"}
          dataField="statusDisplay"
          width={180}
          cssClass={"custom-control-padding"}
          cellComponent={StatusDisplayComponent}
        />
        <Column caption={"E-mail address"} dataField={"email"} />
        <Column
          caption={"Application role"}
          dataField="applicationRoleDisplay"
          alignment={"left"}
        />
        <Column
          caption={"Last login"}
          dataField="lastLogin"
          width={180}
          calculateCellValue={LastLoginDisplay}
        />
        <Column
          caption={"# of IEAs"}
          dataField="ieaCount"
          alignment="left"
          width={100}
        />
      </PromineoViewGrid>
      {!!userToEdit && (
        <EditUserDialog user={userToEdit} onClose={handleEditUserDialogClose} />
      )}
    </>
  );
}
