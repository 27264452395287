import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import { useState } from "react";
import { ContentControl } from "shared/enums/feature/ContentControl";
import { FieldContentControlLevel } from "shared/enums/feature/FieldContentControlLevel";
import { TenantContentControlLevel } from "shared/enums/feature/TenantContentControlLevel";

interface Props {
  contentControl?: ContentControl | TenantContentControlLevel | FieldContentControlLevel;
  isDisabled?: boolean;
  onValueChange?: (value: number) => void;
  displayConfirmation?: boolean;
  confirmationMessage?: string;
  datasource: DropdownValuesResponse[];
  width?: number;
}

export default function ContentControlDropdown(props: Props) {
  const [value, setValue] = useState<
    ContentControl | TenantContentControlLevel | FieldContentControlLevel | undefined
  >(props.contentControl);

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);

  const handleContentControlChange = (value: number) => {
    setValue(value);
    if (props.displayConfirmation) {
      setIsConfirmationDialogVisible(true);
    } else {
      props.onValueChange?.(value);
    }
  };

  const handleConfirmClicked = () => {
    setIsConfirmationDialogVisible(false);
    if (value) {
      props.onValueChange?.(value);
    }
  };

  const handleCancelClicked = () => {
    setIsConfirmationDialogVisible(false);
  };

  return (
    <>
      <PromineoSelectBox
        width={props.width}
        disabled={props.isDisabled}
        value={props.contentControl}
        items={props.datasource}
        displayExpr={"name"}
        valueExpr={"value"}
        onValueChange={handleContentControlChange}
      />
      {isConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleConfirmClicked}
          onCancel={handleCancelClicked}
          content={props.confirmationMessage ?? "Are you sure?"}
          subContent={"All your unsaved changes will be lost."}
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
        />
      )}
    </>
  );
}
