import { DropdownOptions } from "components/common/dropdown-box/PromineoDropdownBox";
import PromineoTreeViewDropdown from "components/common/tree-view-dropdown/PromineoTreeViewDropdown";
import { IDropDownBoxOptions } from "devextreme-react/drop-down-box";
import { ITreeViewOptions } from "devextreme-react/tree-view";
import { useCallback, useRef } from "react";
import { SapOrderFilterList } from "shared/constants/SapOrderFilter";

interface Props {
  handleOrderFilterValueChange: (selectedFilter: string, parentName: string, valueChangedFromDropdown: boolean) => void;
}

export default function SapOrderFilterExpressionPickerDropdown(props: Props) {
  const { handleOrderFilterValueChange } = props;

  const handleOrderFilterSelectionChanged = (data: any) => {
    if (data && data.length) {
      var selectedFilterValue = data[0];
      var parentName = SapOrderFilterList.find((filter) => filter.example === selectedFilterValue)?.parent ?? "";

      handleOrderFilterValueChange(selectedFilterValue, parentName, true);
    }
  };

  const treeViewOptions = useRef<ITreeViewOptions>({
    showCheckBoxesMode: "none",
    selectionMode: "single",
    selectByClick: true,
    displayExpr: "title",
    dataStructure: "plain",
    parentIdExpr: "parent",
    keyExpr: "example",
  });

  const dropdownOptions = useRef<DropdownOptions & IDropDownBoxOptions>({
    displayExpr: "title",
    valueExpr: "example",
    borderRound: "small",
    placeholder: "Select an example filter",
    width: 240,
  });

  const handleCheckIfSelectable = useCallback((data: any) => {
    return !!data.parent;
  }, []);

  return (
    <PromineoTreeViewDropdown
      treeViewOptions={treeViewOptions.current}
      checkIfIsSelectable={handleCheckIfSelectable}
      dataSource={SapOrderFilterList}
      shouldClosePopupOnSelect={true}
      onSelectionChanged={handleOrderFilterSelectionChanged}
      popupWidth={400}
      dropdownOptions={dropdownOptions.current}
      preventTogggleSelection={true}
    />
  );
}