interface Props {
  fillColor?: "white" | "black" | "gray" | "blue" | "blue600";
}
export default function AddIcon(props: Props) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={`${
          props.fillColor && props.fillColor === "blue"
            ? "#3459DB"
            : props.fillColor === "blue600"
            ? "#1839AD"
            : props.fillColor ?? "white"
        }`}
        d="M9.66732 5.66634H5.66732V9.66634H4.33398V5.66634H0.333984V4.33301H4.33398V0.333008H5.66732V4.33301H9.66732V5.66634Z"
      />
    </svg>
  );
}
