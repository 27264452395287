import DataGrid, { IDataGridOptions } from "devextreme-react/data-grid";
import { ReactNode, forwardRef, useCallback } from "react";
import PromineoBaseGrid, { RowDeletionOption } from "./PromineoBaseGrid";
import "./styles/PromineoEditGrid.css";

interface RowHighlighting {
  highlightRow: boolean;
  rowKey?: string;
  highlightFn?: (e: any) => boolean;
}

interface Props {
  children?: ReactNode;
  rowHighlighting?: RowHighlighting;
  displayDeleteRowOption?: RowDeletionOption;
}

const PromineoEditGrid = forwardRef(
  (props: Props & IDataGridOptions, ref: React.Ref<DataGrid<any, any>>) => {
    const { rowHighlighting, ...rest } = props;

    const highlightRow = useCallback((e: any) => {
      const classToAdd = "bg-lighterBlue";
      e.rowElement.classList.remove(classToAdd);
      e.rowElement.classList.add(classToAdd);
    }, []);

    const handleRowPrepared = useCallback(
      (e: any) => {
        if (e.rowType === "data") {
          if (rowHighlighting && rowHighlighting.highlightRow) {
            let rowKey = rowHighlighting.rowKey ?? "id";
            if (rowHighlighting.highlightFn && rowHighlighting.highlightFn(e)) {
              highlightRow(e);
            } else if (!e.data[rowKey]) {
              highlightRow(e);
            }
          }
        }
      },
      [highlightRow]
    );

    return (
      <PromineoBaseGrid
        ref={ref}
        {...rest}
        repaintChangesOnly={true}
        onRowPrepared={handleRowPrepared}
        children={rest.children}
        className={`promineo-edit-grid ${props.className ?? ""}`}
        rowDeletoinOption={props.displayDeleteRowOption}
      />
    );
  }
);

export default PromineoEditGrid;
