import {
  IPublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { getTokenRequest, loginRequest } from "./msalConfig";
import UserAccountInfo from "interfaces/response/UserAccountInfo";

let msalInstance: IPublicClientApplication;

export const injectMsalInstance = (instance: IPublicClientApplication) => {
  msalInstance = instance;
};

export const getTokenAsync = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  return await msalInstance
    .acquireTokenSilent(getTokenRequest(activeAccount))
    .then((response) => {
      return response.accessToken;
    })
    .catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        return await msalInstance
          .acquireTokenPopup(getTokenRequest(activeAccount))
          .then((response) => {
            return response.accessToken;
          })
          .catch(async (error) => {
            await signOutAsync();
          });
      } else {
        await signOutAsync();
      }
    });
};

export const signIn = () => {
  return msalInstance.loginPopup(loginRequest);
};

export const signOutAsync = async () => {
  await msalInstance.logoutRedirect();
};

export const getCurrentAccountInfo = () => {
  return msalInstance.getActiveAccount() as UserAccountInfo;
};
