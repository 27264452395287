import DialogHeader from "features/common/DialogHeader";

interface Props {
  isEdit: boolean;
}

export default function IEAActivityFilterDialogHeader(props: Props) {
  const instructions: string[] = [];
  if (props.isEdit) {
    instructions.push("Type or paste activity filter or select from picker");
  }
  return <DialogHeader title={""} instructions={instructions} />;
}
