import MySelfResponse from "interfaces/response/MySelfResponse";
import { getDataAsync } from "./API";
import UserResponse from "interfaces/response/UserResponse";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";

const routeUrl = "Users";

// This is only for testing. We are checking if we can make an api request with the access token.
export const getUsersAsync = async () => {
  const users = await getDataAsync<UserResponse[]>(routeUrl);

  return users;
};

export const getUsersByTenantAsync = async (tenantId: number) => {
  const apiUrl = `Tenants/${tenantId}/${routeUrl}`;

  return await getDataAsync<UserSimplifiedResponse[]>(apiUrl);
};

export const getLoggedInUserInfo = async () => {
  const apiUrl = `${routeUrl}/MySelf`;

  return await getDataAsync<MySelfResponse>(apiUrl);
};