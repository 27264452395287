import ButtonWithConfirmationDialog from "components/common/ButtonWithConfirmationDialog";
import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";

interface Props {
  onCancel: () => void;
}

export default function TenantAdminCancelButton(props: Props) {
  return (
    <ButtonWithConfirmationDialog
      buttonText="Cancel"
      message="Are you sure you want to abandon any changes made to Tenant?"
      variant={PromineoButtonType.Secondary}
      onConfirm={props.onCancel}
    />
  );
}
