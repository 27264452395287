import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadArchiveExchangeAgreementsAsync } from "store/actions/ExchangeAgreementActions";
import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import ArchiveIEAGrid from "./ArchiveIEAGrid";
import ArchiveExchangeAgreementListingResponse from "interfaces/response/ArchiveExchangeAgreementListingResponse";

export default function ArchiveIEAs() {
  const headerTitleDivId: string = "inactive-ieas-header-title";
  const excludedContainerIds: string[] = [headerTitleDivId];

  const dispatch = useDispatch<AppDispatch>();
  const [archiveExchangeAgreementList, setArchiveExchangeAgreementList] =
    useState<ArchiveExchangeAgreementListingResponse[]>([]);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadArchiveExchangeAgreementsAsync())
      .then(unwrapResult)
      .then(
        (archiveIEAsResponse: ArchiveExchangeAgreementListingResponse[]) => {
          setArchiveExchangeAgreementList(archiveIEAsResponse);
        }
      )
      .finally(hideLoadingPanel);
  }, [dispatch]);

  const ieasGridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 70,
  });

  return (
    <>
      <div className="mb-4" id={headerTitleDivId}>
        <PageHeaderTitle title="Archive IEAs" />
      </div>
      <div>
        <ArchiveIEAGrid
          ieas={archiveExchangeAgreementList}
          height={ieasGridHeight}
        />
      </div>
    </>
  );
}
