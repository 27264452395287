import TenantRequestDialog from "./TenantRequestDialog";

interface Props {
  onClose: () => void;
}

export default function TenantRequestRejectedDialog(props: Props) {
  return (
    <TenantRequestDialog
      title={
        "The request to register your domain as a tenant has been rejected."
      }
      contentText={
        "[Name of person that signed up] ([e-mail address]) has received an e-mail regarding this issue. Consult this person for more information, or go to https://collabor8.no/services/ilap/ for support."
      }
      confirmButtonText={"Close"}
      onConfirm={props.onClose}
      hideCancelButton={true}
    />
  );
}
