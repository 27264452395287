import ButtonWithConfirmationDialog from "components/common/ButtonWithConfirmationDialog";
import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";

interface Props {
  onSave: () => void;
}

export default function TenantAdminSaveButton(props: Props) {
  return (
    <ButtonWithConfirmationDialog
      buttonText="Save changes"
      message="Are you sure you want to overwrite your tenant information?"
      variant={PromineoButtonType.Primary}
      onConfirm={props.onSave}
    />
  );
}
