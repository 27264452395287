import { createSlice } from "@reduxjs/toolkit";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import { FieldMappingFormulaType } from "shared/enums/FieldMappingFormulaType";
import {
  addOrUpdateCustomFormulaInFormulaPickerDataSource,
  removeCustomFormulaFromFormulaPickerDataSource,
  updateSystemFormulaInFormulaPickerDataSource,
} from "store/actions/FieldMappingFormulaPickerUIAction";

interface FieldMappingFormulaPickerUISlice {
  showFavoriteSystemFormulaOnly: boolean;
  showFavoriteCustomFormulaOnly: boolean;
  coreFieldFormulas: FieldMappingFormulaResponse[];
  systemFormulas: FieldMappingFormulaResponse[];
  customFormulas: FieldMappingFormulaResponse[];
  formulaSearchText: string;
}

const initialState: FieldMappingFormulaPickerUISlice = {
  showFavoriteSystemFormulaOnly: false,
  showFavoriteCustomFormulaOnly: false,
  coreFieldFormulas: [],
  systemFormulas: [],
  customFormulas: [],
  formulaSearchText: ""
};

export const fieldMappingFormulaPickerUISlice = createSlice({
  name: "fieldMappingFormulaPickerUISlice",
  initialState,
  reducers: {
    resetFieldMappingFormulaUIState: () => initialState,
    toggleShowFavoriteSystemFormulaOnly: (state) => {
      state.showFavoriteSystemFormulaOnly =
        !state.showFavoriteSystemFormulaOnly;
    },
    toggleShowFavoriteCustomFormulaOnly: (state) => {
      state.showFavoriteCustomFormulaOnly =
        !state.showFavoriteCustomFormulaOnly;
    },
    setFieldMappingFormulaPickerAccordionDataSource: (
      state,
      { payload }: { payload: FieldMappingFormulaResponse[] }
    ) => {
      state.showFavoriteSystemFormulaOnly = false;
      state.showFavoriteCustomFormulaOnly = false;
      state.coreFieldFormulas = payload.filter(
        (f) => f.formulaType === FieldMappingFormulaType.CoreField
      );
      state.systemFormulas = payload.filter(
        (f) => f.formulaType === FieldMappingFormulaType.System
      );
      state.customFormulas = payload.filter(
        (f) => f.formulaType === FieldMappingFormulaType.Custom
      );
    },
    setFormulaSearchText: (state, { payload }: { payload: string }) => {
        state.formulaSearchText = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateSystemFormulaInFormulaPickerDataSource.fulfilled,
      (state, action) => {
        state.systemFormulas = action.payload;
      }
    );
    builder.addCase(
      addOrUpdateCustomFormulaInFormulaPickerDataSource.fulfilled,
      (state, action) => {
        state.customFormulas = action.payload;
      }
    );
    builder.addCase(
      removeCustomFormulaFromFormulaPickerDataSource.fulfilled,
      (state, action) => {
        state.customFormulas = action.payload;
      }
    );
  },
});

export default fieldMappingFormulaPickerUISlice.reducer;
export const {
  resetFieldMappingFormulaUIState,
  toggleShowFavoriteSystemFormulaOnly,
  toggleShowFavoriteCustomFormulaOnly,
  setFieldMappingFormulaPickerAccordionDataSource,
  setFormulaSearchText,
} = fieldMappingFormulaPickerUISlice.actions;
