import { createSlice } from "@reduxjs/toolkit";
import ClientResponse from "interfaces/response/autonomous-components/ClientResponse";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import {
  addNewAutonomousComponentAsync,
  addNewAutonomousComponentServiceTokenAsync,
  loadAutonomousComponentsAsync,
  loadAutonomousComponentServiceTokensAsync,
  modifyAutonomousComponentsAsync,
  modifyAutonomousComponentServiceTokenAsync,
  removeAutonomousComponentAsync,
  removeAutonomousComponentServiceTokenAsync,
} from "store/actions/AutonomousComponentActions";

export interface AutonomousComponentState {
  autonomousComponents: ClientResponse[];
  serviceTokens: ServiceTokenResponse[];
}

const initialState: AutonomousComponentState = {
  autonomousComponents: [],
  serviceTokens: [],
};

export const autonomousComponentSlice = createSlice({
  name: "autonomousComponents",
  initialState,
  reducers: {
    resetAutonomousComponentServiceTokens: (action) => {
      action.serviceTokens = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadAutonomousComponentsAsync.fulfilled,
      (state, action) => {
        state.autonomousComponents = action.payload;
      }
    );

    builder.addCase(
      addNewAutonomousComponentAsync.fulfilled,
      (state, action) => {
        state.autonomousComponents.push(action.payload);
      }
    );

    builder.addCase(
      modifyAutonomousComponentsAsync.fulfilled,
      (state, action) => {
        state.autonomousComponents = state.autonomousComponents.map(
          (component) => {
            if (component.id === action.payload.id) {
              return action.payload;
            }
            return component;
          }
        );
      }
    );

    builder.addCase(
      removeAutonomousComponentAsync.fulfilled,
      (state, action) => {
        state.autonomousComponents = state.autonomousComponents.filter(
          (component) => {
            return component.id !== action.meta.arg;
          }
        );
      }
    );

    builder.addCase(loadAutonomousComponentServiceTokensAsync.fulfilled, (state, action) => {
      state.serviceTokens = action.payload;
    });

    builder.addCase(addNewAutonomousComponentServiceTokenAsync.fulfilled, (state, action) => {
      state.serviceTokens.push(action.payload);
    });

    builder.addCase(modifyAutonomousComponentServiceTokenAsync.fulfilled, (state, action) => {
      state.serviceTokens = state.serviceTokens.map((serviceToken) => {
        if (serviceToken.id === action.payload.id) {
          return action.payload;
        }
        return serviceToken;
      });
    });

    builder.addCase(removeAutonomousComponentServiceTokenAsync.fulfilled, (state, action) => {
      state.serviceTokens = state.serviceTokens.filter((serviceToken) => {
        return serviceToken.id !== action.meta.arg;
      });
    });
  },
});

export const { resetAutonomousComponentServiceTokens } = autonomousComponentSlice.actions;
export default autonomousComponentSlice.reducer;
