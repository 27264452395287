import PromineoButtonWithIcon from "components/common/controls/buttons/PromineoButtonWithIcon";
import PromineoButtonWithPopover from "components/common/controls/buttons/PromineoButtonWithPopOver";
import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";
import AddIcon from "components/icons/AddIcon";
import UpIcon from "components/icons/UpIcon";
import PopoverMenuItem from "components/common/controls/popover-with-menu/PopoverMenuItem";

export default function Filter() {
  const selectionItems: PopoverMenuItem[] = [];
  return (
    <div className="flex space-x-2">
      <PromineoButtonWithIcon
        Icon={<AddIcon />}
        text="Add filter"
        variant={PromineoButtonType.Basic}
      />
      <PromineoButtonWithPopover
        id="my-filter-button-id"
        RightIcon={<UpIcon />}
        text="My filters"
        variant={PromineoButtonType.Basic}
        menuItems={selectionItems}
      />
    </div>
  );
}
