interface Props {
  fillColor?: "white" | "black";
}
export default function LinkIcon(props: Props) {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33203 7.33366H3.66536C2.74314 7.33366 1.95703 7.00866 1.30703 6.35866C0.657031 5.70866 0.332031 4.92255 0.332031 4.00033C0.332031 3.0781 0.657031 2.29199 1.30703 1.64199C1.95703 0.991992 2.74314 0.666992 3.66536 0.666992H6.33203V2.00033H3.66536C3.10981 2.00033 2.63759 2.19477 2.2487 2.58366C1.85981 2.97255 1.66536 3.44477 1.66536 4.00033C1.66536 4.55588 1.85981 5.0281 2.2487 5.41699C2.63759 5.80588 3.10981 6.00033 3.66536 6.00033H6.33203V7.33366ZM4.33203 4.66699V3.33366H9.66536V4.66699H4.33203ZM7.66536 7.33366V6.00033H10.332C10.8876 6.00033 11.3598 5.80588 11.7487 5.41699C12.1376 5.0281 12.332 4.55588 12.332 4.00033C12.332 3.44477 12.1376 2.97255 11.7487 2.58366C11.3598 2.19477 10.8876 2.00033 10.332 2.00033H7.66536V0.666992H10.332C11.2543 0.666992 12.0404 0.991992 12.6904 1.64199C13.3404 2.29199 13.6654 3.0781 13.6654 4.00033C13.6654 4.92255 13.3404 5.70866 12.6904 6.35866C12.0404 7.00866 11.2543 7.33366 10.332 7.33366H7.66536Z"
        fill="#3459DB"
      />
    </svg>
  );
}
