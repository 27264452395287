import LabelWithBorder from "components/common/LabelWithBorder";
import LabelWithContent from "components/common/LabelWithContent";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import { useState } from "react";

interface Props {
  comments: string;
  showEditor?: boolean;
  onChange: (field: string, value: string) => void;
}

const labelClassName =
  "ml-2 w-full pr-6 font-inter font-medium text-[12px] leading-[18px]";

export default function CommentField(props: Props) {
  const { comments } = props;
  const maxCommentLength = 300;

  const [showFullComments, setShowFullComments] = useState<boolean>(
    comments.length <= maxCommentLength
  );

  const visibleComments = showFullComments
    ? comments
    : `${comments.slice(0, maxCommentLength)}...`;

  return (
    <>
      {props.showEditor ? (
        <LabelWithContent
          label="Comments"
          content={
            <PromineoMultilineTextEdit
              defaultValue={comments}
              height={144}
              width={"100%"}
              placeholder="Type..."
              className="whitespace-pre-line text-10px font-Inter default-background"
              onValueChanged={(e: any) => {
                props.onChange("comments", e.event?.target.value);
              }}
            />
          }
        />
      ) : (
        <LabelWithContent
          label="Comments"
          content={
            <div className={labelClassName}>
              {visibleComments}
              {comments !== visibleComments && !showFullComments && (
                <LabelWithBorder
                  text="See more"
                  color="ilapBlue"
                  onClick={() => {
                    setShowFullComments(true);
                  }}
                  isItalic={true}
                />
              )}
              {visibleComments.length > maxCommentLength &&
                showFullComments && (
                  <LabelWithBorder
                    text="See less"
                    color="ilapBlue"
                    onClick={() => {
                      setShowFullComments(false);
                    }}
                    isItalic={true}
                  />
                )}
            </div>
          }
        />
      )}
    </>
  );
}
