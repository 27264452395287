import CrossIcon from "components/icons/CrossIcon";
interface Props {
  onCancel: () => void;
}
export default function ShareTemplateDialogHeader(props: Props) {
  return (
    <div>
      <div className="flex justify-between w-full">
        <div className="font-Poppins font-bold leading-4 text-10px">
          Shared with
        </div>
        <div onClick={props.onCancel} className="cursor-pointer">
          <CrossIcon />
        </div>
      </div>
    </div>
  );
}
