import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUploadDataTransferLogAsync,
  getDownloadDataTransferLogAsync
} from "apis/DataTransferApi";

export const loadUploadDataTransferLogAsync = createAsyncThunk(
  "DataTransfer/loadUploadDataTransferLogAsync",
  async (dataTransferId: number) => {
    return await getUploadDataTransferLogAsync(dataTransferId);
  }
);

export const loadDownloadDataTransferLogAsync = createAsyncThunk(
  "DataTransfer/loadDownloadDataTransferLogAsync",
  async (dataTransferId: number) => {
    return await getDownloadDataTransferLogAsync(dataTransferId);
  }
);
