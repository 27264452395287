import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";

interface Props {
  onSave: () => void;
  onCancel: () => void;
  cancelButtonText?: string;
  saveButtonText?: string;
}

export default function SaveOrCancelFooter(props: Props) {
  return (
    <div className="flex justify-between mt-6">
      <PromineoButton
        variant={PromineoButtonType.Secondary}
        text={props.cancelButtonText ? props.cancelButtonText : "Cancel"}
        onClick={props.onCancel}
      />
      <PromineoButton
        variant={PromineoButtonType.Success}
        text={props.saveButtonText ? props.saveButtonText : "Save"}
        onClick={props.onSave}
      />
    </div>
  );
}
