import LabelWithContent from "components/common/LabelWithContent";
import OnlyIncludeSpecifiedMappings from "features/setup/configs/host-system/common/OnlyIncludeSpecifiedMappingsConponent";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";
import { IncludeMappingType } from "shared/enums/feature/IncludeMappingTypeEnum";

interface Props extends BaseHostSystemComponentProps {
  includeMappingsTypeValue: IncludeMappingType;
}

export default function OnlyIncludeSpecifiedMappingField(props: Props) {
  const {
    mode,
    includeMappingsTypeValue,
    fieldName,
    handleConfigurationValueChanged,
  } = props;

  return (
    <LabelWithContent
      label="Only Include Specified Mappings"
      content={
        <OnlyIncludeSpecifiedMappings
          mode={mode}
          fieldName={fieldName}
          includeMappingsTypeValue={includeMappingsTypeValue}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      }
    />
  );
}
