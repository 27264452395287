import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import SystemAdminUserGrid from "./SystemAdminUserGrid";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useEffect } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { getSystemUsersForTenantAdminAsync } from "store/actions/SystemAdminActions";

export default function SystemAdminUsers() {
  const dispatch = useAppDispatch();

  const systemUsers = useSelector(
    (state: RootState) => state.systemAdminData.systemUsers
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(getSystemUsersForTenantAdminAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  const pageTitleDivId = "system-user-page-header";
  const excludedContainerIds: string[] = [pageTitleDivId];
  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });
  return (
    <>
      <div className="mb-4 title-section" id={pageTitleDivId}>
        <PageHeaderTitle title="Users" />
      </div>
      <div>
        <SystemAdminUserGrid height={gridHeight} systemUsers={systemUsers} />
      </div>
    </>
  );
}
