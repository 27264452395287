import PromineoPopover from "components/common/controls/PromineoPopover";
import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { Offset, Position } from "devextreme-react/popup";
import { useState } from "react";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";
import { getShowSuccessToastStateFromLocalStorage, setShowSuccessToastStateInLocalStorage } from "shared/utilities/ToastUtility";

interface Props {
  target: string;
  visible: boolean;
  onHiding: () => void;
}
export default function SettingPopOver(props: Props) {
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(
    getShowSuccessToastStateFromLocalStorage()
  );

  const onSuccessToastValueChange = ()=>{
    const currentShowSuccessToastState = getShowSuccessToastStateFromLocalStorage();
    setShowSuccessToastStateInLocalStorage(!currentShowSuccessToastState);
    setShowSuccessToast(!currentShowSuccessToastState);
  }

  const Content = () => {
    return (
      <div className="py-4 px-6">
        <div className="flex">
          <PromineoSwitchButton
            switchType={SwitchButtonType.Default}
            onChange={onSuccessToastValueChange}
            checked={showSuccessToast}
            buttonLeftText="Show Success notification: "
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <PromineoPopover width={"auto"} height={"auto"} {...props}>
        <Position my={{ x: "right", y: "top" }}>
          <Offset x={20} y={10} />
        </Position>
        <Content />
      </PromineoPopover>
    </>
  );
}
