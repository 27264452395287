import React from "react";
import NavigationBar from "components/header/navigation-bar/NavigationBar";
import { useIsAuthenticated } from "@azure/msal-react";
import useCurrentUser from "hooks/CurrentUserHook";
import useSignalR from "hooks/RealtimeNotificationHook";
import { useLocation, useNavigate } from "react-router-dom";
import { LANDING } from "shared/constants/RoutePathConstants";
import { REDIRECT_URL_PROP } from "shared/utilities/RouteUtility";

interface Props {
  children: React.ReactElement;
}

export default function PrivateRouteGuard(props: Props) {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();

  // This will load current user information if not loaded already.
  useCurrentUser();

  // INFO: SignalR connection is used to receive in-app push notifications
  useSignalR();

  if (!isAuthenticated) {
    // After successfull sign-in we will redirect user to the user's expected pathname.
    navigate(LANDING, { state: { [REDIRECT_URL_PROP]: location.pathname } });
    return null;
  }

  return (
    <div className="bg-backgroundGray min-h-screen">
      <NavigationBar />
      <div className="p-6">{props.children}</div>
    </div>
  );
}
