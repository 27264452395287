import EditTemplateHeader from "./EditTemplateHeader";
import EditTemplateGrid from "./EditTemplateGrid";
import { useCallback, useEffect, useState } from "react";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import LabelResponse from "interfaces/response/LabelResponse";
import TemplateHeaderData from "interfaces/component-data/TemplateHeaderData";
import EditTemplateGridData from "interfaces/component-data/EditTemplateGridData";
import React from "react";
import TemplateRequest from "interfaces/request/TemplateRequest";
import TemplateFieldWithValueCountResponse from "interfaces/response/TemplateFieldWithValueCountResponse";
import FieldResponse from "interfaces/response/FieldResponse";
import { GetNewId } from "shared/utilities/CommonUtility";
import DetailedTemplateResponse from "interfaces/response/DetailedTemplateResponse";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useAppDispatch } from "store/hooks";
import { removeTemplateByIdAsync } from "store/actions/TemplateActions";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import { useNavigate } from "react-router-dom";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import TemplateDeleteValidationDialog from "./TemplateDeleteValidationDialog";
import { ValidationErrorType } from "shared/enums/ValidationErrorType";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
const MemorizedEditTemplateGrid = React.memo(EditTemplateGrid);

interface Props {
  template: DetailedTemplateResponse;
  onSave: (templateRequest: TemplateRequest) => void;
  onCancel: () => void;
  copyOrNew?: boolean;
}

export default function TemplateEditor(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isModified, setIsModified] = useState(false);

  const [
    isUnsavedChangeConfirmationVisible,
    setIsUnsavedChangeConfirmationVisible,
  ] = useState(false);

  const [validationMessageForDelete, setValidationMessageForDelete] = useState<
    string[]
  >([]);

  const getTemplateHeaderDataFromProps = () => {
    var headerData: TemplateHeaderData = {
      id: props.template.id,
      title: props.template.title,
      description: props.template.description,
      ownerTenant: props.template.ownerTenant,
      labels: props.template.labels,
      fieldCount: props.template.fieldCount,
      ownConfigsCount: props.template.ownConfigsCount,
      shareCount: props.template.shareCount,
      tenantId: props.template.tenantId,
      createdBy: props.template.creatorName,
      createdOn: props.template.created
    };
    return headerData;
  };

  const getEditTemplateGridDataFromProps = () => {
    var gridData: EditTemplateGridData = {
      id: props.template.id,
      fields: props.template.fields,
    };
    return gridData;
  };

  const [templateHeaderData, setTemplateHeaderData] =
    useState<TemplateHeaderData>(getTemplateHeaderDataFromProps());
  const [editTemplateGridData, setEditTemplateGridData] =
    useState<EditTemplateGridData>(getEditTemplateGridDataFromProps());
  const [disableSaveChangesButton, setDisableSaveChangesButton] =
    useState(false);

  const [
    isDeleteTemplateConfirmationDialogVisible,
    setIsDeleteTemplateConfirmationDialogVisible,
  ] = useState<boolean>(false);

  useEffect(() => {
    let isDisabled = true;

    if (templateHeaderData && templateHeaderData.title) {
      isDisabled = false;
    }

    setDisableSaveChangesButton(isDisabled);
  }, [templateHeaderData]);

  useEffect(() => {
    if (props.template) {
      setTemplateHeaderData(getTemplateHeaderDataFromProps());
      setEditTemplateGridData(getEditTemplateGridDataFromProps());
    }
  }, [props.template]);

  const handleDeleteLabel = (label: LabelResponse) => {
    setTemplateHeaderData((prev) => {
      let filteredLabels = prev!.labels.filter(
        (l) => l.id !== label.id || l._key_ !== label._key_
      );
      return { ...prev!, labels: filteredLabels };
    });
  };

  const handleTitleChange = (data: any) => {
    setTemplateHeaderData((prev) => {
      return { ...prev!, title: data.value };
    });
    setIsModified(true);
  };

  const handleDescriptionChange = (data: any) => {
    setTemplateHeaderData((prev) => {
      return { ...prev!, description: data.value };
    });
    setIsModified(true);
  };

  const handleFieldDeletion = useCallback(
    ({ data }: { data: TemplateFieldWithValueCountResponse }) => {
      setEditTemplateGridData((prev) => {
        var filteredFields =
          prev!.fields?.filter(
            (f) => f.id !== data.id || f._key_ !== data._key_
          ) || [];
        return { ...prev!, fields: filteredFields };
      });
      setIsModified(true);
    },
    [editTemplateGridData]
  );

  const handleDeleteTemplateClick = () => {
    if (props.template) {
      setIsDeleteTemplateConfirmationDialogVisible(true);
    }
  };

  const handleTemplateDeleteErrorResponse = (error: any) => {
    if (
      error.statusCode !== 400 ||
      !error.errorsWithType ||
      error.errorsWithType.length === 0
    ) {
      toastError(error.message);
    } else {
      var businessRuleValidationErrors: string[] = [];
      var otherErrors: string[] = [];
      error.errorsWithType.forEach(
        (e: { type: ValidationErrorType; message: string }) => {
          if (e.type === ValidationErrorType.BusinessRuleValidationError) {
            businessRuleValidationErrors.push(e.message);
          } else {
            otherErrors.push(e.message);
          }
        }
      );
      if (otherErrors.length !== 0) {
        toastError(otherErrors.join(","));
      }
      if (businessRuleValidationErrors.length !== 0) {
        setValidationMessageForDelete(businessRuleValidationErrors);
      }
    }
  };

  const handleDeleteTemplateConfirmClick = () => {
    if (props.template) {
      displayLoadingPanel();
      dispatch(removeTemplateByIdAsync(props.template.id))
        .unwrap()
        .then(() => {
          navigate(TEMPLATES);
          toastSuccess("Template deleted successfully.");
        })
        .catch((error: any) => {
          handleTemplateDeleteErrorResponse(error);
        })
        .finally(hideLoadingPanel);
    }
    setIsDeleteTemplateConfirmationDialogVisible(false);
  };

  const handleAddNewField = useCallback((field: FieldResponse) => {
    setEditTemplateGridData((prev) => {
      let newField: TemplateFieldWithValueCountResponse = {
        tenantId: props.template.tenantId ? props.template.tenantId : 0,
        allowBlank: true,
        allowContentControl: false,
        code: field.code,
        dataType: field.dataType,
        dataTypeText: field.dataTypeText,
        description: field.description,
        fieldId: field.id,
        name: field.name,
        planningObjectType: field.planningObjectTypes,
        planningObjectTypeText: field.planningObjectTypesText,
        templateId: prev!.id,
        templateName: props.template.title,
        uri: field.uri,
        valueCount: 0,
        _key_: GetNewId(),
        id: 0,
        controlledOn: field.controlledOn,
        controlledOnText: field.controlledOnText,
        contentControlLevel: field.contentControl,
        contentControlLevelText: field.contentControlText,
        ilapId: field.ilapId,
      };
      return {
        ...prev!,
        fields: [...(prev!.fields ?? []), newField],
      };
    });
    setIsModified(true);
  }, []);

  const handleNewLabelCreationToAddInTemplate = useCallback(
    (label: LabelResponse) => {
      setTemplateHeaderData((prev) => {
        return { ...prev!, labels: [...prev!.labels, label] };
      });
      setIsModified(true);
    },
    [templateHeaderData]
  );

  const handleSaveChangesClick = () => {
    if (templateHeaderData && editTemplateGridData) {
      const templateUpdateRequest: TemplateRequest = {
        title: templateHeaderData.title,
        description: templateHeaderData.description,
        labelIdentifiers: templateHeaderData.labels.map((l) => l.id),
        fieldIdentifiers:
          editTemplateGridData.fields?.map((f) => f.fieldId) || [],
      };

      props.onSave(templateUpdateRequest);
    }
  };

  const headerDivId: string = "edit-template-header";
  const actionButtonsDivId: string = "edit-template-action-buttons";
  const excludedContainerIds: string[] = [headerDivId, actionButtonsDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 100,
  });

  const handleCancelClicked = () => {
    if (isModified) {
      setIsUnsavedChangeConfirmationVisible(true);
    } else {
      props.onCancel();
    }
  };

  const handleConfirmCancel = () => {
    setIsUnsavedChangeConfirmationVisible(false);
    props.onCancel();
  };

  const handleCancelConfirmationDialog = () => {
    setIsUnsavedChangeConfirmationVisible(false);
  };

  return (
    <div>
      <div id={headerDivId}>
        <EditTemplateHeader
          copyOrNew={props.copyOrNew}
          data={templateHeaderData}
          onTitleChanged={handleTitleChange}
          onDescriptionChanged={handleDescriptionChange}
          onDeleteLabel={handleDeleteLabel}
          onAddNewLabel={handleNewLabelCreationToAddInTemplate}
        />
      </div>

      <MemorizedEditTemplateGrid
        data={editTemplateGridData}
        onAddNewField={handleAddNewField}
        onDeleteRow={handleFieldDeletion}
        height={gridHeight}
      />

      <div className="flex justify-between mt-6" id={actionButtonsDivId}>
        <div className="flex gap-6">
          <PromineoButton
            variant={PromineoButtonType.Secondary}
            text="Cancel"
            onClick={handleCancelClicked}
          />
          {props.template.id !== 0 && (
            <PromineoButton
              variant={PromineoButtonType.BorderlessDanger}
              text="Delete template"
              onClick={handleDeleteTemplateClick}
            />
          )}
        </div>

        <PromineoButton
          disabled={disableSaveChangesButton}
          variant={PromineoButtonType.Primary}
          text="Save Changes"
          onClick={handleSaveChangesClick}
        />
      </div>

      {validationMessageForDelete.length !== 0 && props.template && (
        <TemplateDeleteValidationDialog
          validationMessagForDelete={validationMessageForDelete}
          onClose={() => {
            setValidationMessageForDelete([]);
          }}
        />
      )}

      {isDeleteTemplateConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleDeleteTemplateConfirmClick}
          onCancel={() => {
            setIsDeleteTemplateConfirmationDialogVisible(false);
          }}
          content={`Are you sure you want to delete the template "${props.template.title}"`}
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
        />
      )}

      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmCancel}
          onCancel={handleCancelConfirmationDialog}
        ></PromineoCancelEditingConfirmationDialog>
      ) : null}
    </div>
  );
}
