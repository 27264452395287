import { ScrollView } from "devextreme-react";
import "./styles/ErrorDisplayModal.css";
import PromineoModal from "components/modal/PromineoModal";
import PromineoModalHeader from "components/modal/PromineoModalHeader";

export interface ErrorDetails {
  lineNumber?: number,
  inputValue?: string,
  errorText: string,
}

interface Props {
  errors: string[];
  count?: number; // if count is not provided, number of errors are counted
  onHideDialog: () => void;
  asWarnings?: boolean;
}

export default function ErrorDisplayModal(props: Props) {
  const errorCount = props.count ? props.count : props.errors.length;
  const headerText = props.asWarnings ? `${errorCount} warning(s)!` : `${errorCount} error(s) found!`;

  const css = props.asWarnings ? "warning-display-modal" : "error-display-modal";

  return (
    <>
      <PromineoModal
        displayDefaultActions={false}
        isVisible={true}
        width={500}
        height="457"
        className={css}
      >
        <PromineoModalHeader
          onCancel={props.onHideDialog}
          text={headerText}
          showCloseIcon={true}
        />
        <ScrollView showScrollbar="always" height={350}>
          {props.errors.map((error, index) => <div key={index} className="mb-1 text-xs">{error}</div>)}
        </ScrollView>
      </PromineoModal>
    </>
  );
}