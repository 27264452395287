import { createSlice } from "@reduxjs/toolkit";

export interface ErrorState {
  noTenantFoundErrorMessage: string;
}

const initialState: ErrorState = {
  noTenantFoundErrorMessage: "",
};

export const errorSlice = createSlice({
  name: "errorHandlerSlice",
  initialState,
  reducers: {
    handleError(state, action) {
      state.noTenantFoundErrorMessage = action.payload;
    },
    resetNoTenantErrorMessage(state) {
      state.noTenantFoundErrorMessage = "";
    },
  },
  extraReducers: (builder) => {},
});

export const { handleError, resetNoTenantErrorMessage } = errorSlice.actions;

export default errorSlice.reducer;
