import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import LabelWithContent from "components/common/LabelWithContent";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoModal from "components/modal/PromineoModal";
import { EditorActionButtonProp } from "components/modal/PromineoModalActionFooter";
import SystemLevelOwnerStatusChangeRequest from "interfaces/request/SystemLevelOwnerStatusChangeRequest";
import { useEffect, useMemo, useState } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { toastSuccess } from "shared/utilities/ToastUtility";
import {
  loadTenantByIdAsync,
  modifyTenantOwnerStatusAtSystemLevelAsync,
  removeTenantByIdAsync,
} from "store/actions/TenantActions";
import { loadLoggedInUserInfo } from "store/actions/UserActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ContactInformationComponent from "./ContactInformationComponent";
import TenantDetailHeader from "./TenantDetailHeader";
import TenantOwnerStatusDropdown from "./TenantOwnerStatusDropdown";

interface Props {
  onClose: () => void;
  isEdit: boolean;
  onEditIconClick: () => void;
  tenantId: number;
}

export default function TenantDetailDialog(props: Props) {
  const dispatch = useAppDispatch();
  const { onClose, isEdit, onEditIconClick, tenantId } = props;
  const [isModified, setIsModified] = useState(false);
  const [
    isUnsavedChangeConfirmationVisible,
    setIsUnsavedChangeConfirmationVisible,
  ] = useState(false);

  const [
    showTenantDeleteConfirmationDialog,
    setShowTenantDeleteConfirmationDialog,
  ] = useState<boolean>(false);

  const [isTenantModified, setIsTenantModified] = useState<boolean>(false);
  const [canTenantBeOwner, setCanTenantBeOwner] = useState<boolean>(false);

  const tenant = useAppSelector((store) => store.tenantData.tenant);
  const currentUser = useAppSelector((store) => store.userData.mySelfResponse);

  useEffect(() => {
    if (tenantId) {
      displayLoadingPanel();
      dispatch(loadTenantByIdAsync(tenantId)).finally(hideLoadingPanel);
    }
  }, [tenantId]);

  useEffect(() => {
    if (tenant) {
      setCanTenantBeOwner(tenant.canBeOwner);
    }
  }, [tenant]);

  const labelClassName =
    "ml-2 w-44 font-inter font-medium text-[12px] leading-[18px] mb-6";

  const onTenantDeleteClick = () => {
    setShowTenantDeleteConfirmationDialog(true);
  };

  const handleCancelforDeletePrompt = () => {
    setShowTenantDeleteConfirmationDialog(false);
  };

  const handleConfirmDelete = () => {
    if (tenant) {
      displayLoadingPanel();
      dispatch(removeTenantByIdAsync(tenant.id))
        .unwrap()
        .then(() => {
          setShowTenantDeleteConfirmationDialog(false);
          onClose();
        })
        .finally(hideLoadingPanel);
    }
  };

  const onTenantSaveChangesClick = () => {
    if (tenant) {
      var tenantOwnerStatusChangeRequest: SystemLevelOwnerStatusChangeRequest =
        {
          canBeOwner: canTenantBeOwner,
        };
      displayLoadingPanel();
      dispatch(
        modifyTenantOwnerStatusAtSystemLevelAsync({
          tenantId: tenant.id,
          tenantOwnerStatusChangeRequest,
        })
      )
        .unwrap()
        .then(() => {
          if (currentUser?.tenant && tenant.id === currentUser.tenant.id) {
            displayLoadingPanel();
            dispatch(loadLoggedInUserInfo()).finally(hideLoadingPanel);
          }
          toastSuccess("Tenant owner status successfully changed");
          setShowTenantDeleteConfirmationDialog(false);
          onClose();
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleOwnerStatusChange = (changedStatus: boolean) => {
    setCanTenantBeOwner(changedStatus);
    if (tenant) {
      setIsTenantModified(tenant.canBeOwner !== changedStatus);
    }
    setIsModified(true);
  };

  const handleConfirmCancel = () => {
    setIsUnsavedChangeConfirmationVisible(false);
    onClose();
  };

  const handleCancelConfirmationDialog = () => {
    setIsUnsavedChangeConfirmationVisible(false);
  };

  const handleCloseClick = () => {
    if (isModified) {
      setIsUnsavedChangeConfirmationVisible(true);
    } else {
      onClose();
    }
  };

  const editorActionButtonProps = useMemo<EditorActionButtonProp>(() => {
    return {
      mode: PromineoModalMode.Modify,
      cancelButtonCaption: "Cancel",
      deleteButtonCaption: "Delete",
      saveChangesButtonCaption: "Save",
      onDelete: onTenantDeleteClick,
      onCancel: handleCloseClick,
      onSaveChanges: onTenantSaveChangesClick,
      disableConfirmButton: !isTenantModified,
    };
  }, [isTenantModified]);

  return (
    <>
      <PromineoModal
        isVisible={true}
        displayDefaultActions={isEdit}
        coloredBg={true}
        width={720}
        minHeight={480}
        height="auto"
        editorActionButtonProps={editorActionButtonProps}
      >
        {tenant && (
          <>
            <TenantDetailHeader
              onClose={onClose}
              isEdit={isEdit}
              tenant={tenant}
              onEditIconClick={onEditIconClick}
              status={tenant.tenantStatus}
            />
            <div className="grid grid-cols-2 gap-2 mt-4 border-b border-lightGray">
              <div className="col-start-1">
                <LabelWithContent
                  label={"Company registration number"}
                  content={
                    <div className={labelClassName}>
                      {tenant.companyRegistrationNumber}
                    </div>
                  }
                />
                <LabelWithContent
                  label={"Tenant registrator"}
                  content={
                    <div className={labelClassName}>{tenant.registrator}</div>
                  }
                />
                <LabelWithContent
                  label={"Can be owner"}
                  content={
                    <div className={labelClassName}>
                      {isEdit ? (
                        <TenantOwnerStatusDropdown
                          onChange={handleOwnerStatusChange}
                          selectedOwnerStatus={canTenantBeOwner}
                        />
                      ) : canTenantBeOwner ? (
                        "Yes"
                      ) : (
                        "No"
                      )}
                    </div>
                  }
                />
                <LabelWithContent
                  label={"Identity provider type"}
                  content={<div className={labelClassName}>{"N/A"}</div>}
                />
                <LabelWithContent
                  label={"Identity provider"}
                  content={<div className={labelClassName}>{"N/A"}</div>}
                />
                <LabelWithContent
                  label={"Identity provider ID"}
                  content={
                    <div className={labelClassName}>
                      {tenant.identityProviderId}
                    </div>
                  }
                />
              </div>
              <div className="col-start-2">
                <ContactInformationComponent
                  showMainContact={true}
                  contactInformation={tenant.contactInformation}
                  labelClassName={labelClassName}
                />
                <ContactInformationComponent
                  showMainContact={false}
                  contactInformation={tenant.contactInformation}
                  labelClassName={labelClassName}
                />
              </div>
            </div>
          </>
        )}
      </PromineoModal>

      {showTenantDeleteConfirmationDialog && tenant && (
        <PromineoConfirmationDialog
          content={`Are you sure you want to delete ${tenant.name}?`}
          confirmButtonText="Confirm"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelforDeletePrompt}
        />
      )}

      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmCancel}
          onCancel={handleCancelConfirmationDialog}
        ></PromineoCancelEditingConfirmationDialog>
      ) : null}
    </>
  );
}
