interface Props {
  fillColor?: "white" | "black";
}
export default function UpIcon(props: Props) {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0.333496L0 4.3335L0.94 5.2735L4 2.22016L7.06 5.2735L8 4.3335L4 0.333496Z"
        fill="#727272"
      />
    </svg>
  );
}
