import IlapTermHeaderData from "interfaces/component-data/IlapTermHeaderData";
import IlapTermHeader from "./ilap-term-header/IlapTermHeader";
import { FieldContentControlLevel } from "shared/enums/feature/FieldContentControlLevel";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";
import ViewFieldValueGrid from "features/common/view-field-value-grid/ViewFieldValueGrid";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { useNavigate, useParams } from "react-router-dom";
import { getFieldForSystemAdminAsync } from "store/actions/SystemAdminActions";
import Breadcrumb, { BreadcrumbItem } from "components/common/breadcrumb/Breadcrumb";
import { SYS_ADMIN_ILAP_TERMS } from "shared/constants/RoutePathConstants";
import { TypeCode } from "shared/enums/TypeCode";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function ViewIlapTerm() {
  const dispatch = useAppDispatch();
  const param = useParams();
  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadcrumbItem[]>([]);
  const navigate = useNavigate();

  const [headerData, setHeaderData] = useState<IlapTermHeaderData>({
    name: "",
    description: "",
    plannigObjectType: PlanningObjectTypes.None,
    plannigObjectTypeText: "",
    uri: "",
    contentControl: FieldContentControlLevel.System,
    contentControlText: "System",
    dataType: TypeCode.Empty,
    dataTypeText: "Empty",
    ilapId: "",
  });

  useEffect(() => {
    if (param.fieldId) {
      var fieldId = Number(param.fieldId);
      displayLoadingPanel();
      dispatch(getFieldForSystemAdminAsync(fieldId)).finally(hideLoadingPanel);
    }
  }, []);

  const detailedField = useAppSelector((state) => state.systemAdminData.field);

  useEffect(() => {
    if (detailedField) {
      const items: BreadcrumbItem[] = [
        {
          key: "ilap-terms",
          text: "ILAP terms",
          data: { url: SYS_ADMIN_ILAP_TERMS },
        },
        {
          key: "ilap-term-name",
          text: detailedField.name,
          data: { url: `${SYS_ADMIN_ILAP_TERMS}/${detailedField.id}` },
        },
      ];

      setBreadCrumbItems(items);
    }
  }, [detailedField]);

  useEffect(() => {
    if (detailedField) {
      setHeaderData({
        id: detailedField.id,
        name: detailedField.name,
        description: detailedField.description,
        plannigObjectType: detailedField.planningObjectType,
        plannigObjectTypeText: detailedField.planningObjectTypeText,
        uri: detailedField.uri,
        allowBlank: detailedField.allowBlank ?? undefined,
        contentControl: detailedField.controlledOn,
        contentControlText: detailedField.controlledOnText ?? "",
        dataType: detailedField.dataType,
        dataTypeText: detailedField.dataTypeText,
        ilapId: detailedField.ilapId,
      });
    }
  }, [detailedField]);

  const handleBreadCrumbSelectionChange = (ind: number, item: BreadcrumbItem) => {
    if (item.data?.url) {
      navigate(item.data.url);
    }
  };

  const headerDivId: string = "view-ilap-term-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  if (!detailedField) {
    return <></>;
  }

  return (
    <>
      <div id={headerDivId}>
        <div className="mb-1">
          <Breadcrumb items={breadCrumbItems} onSelectionChange={handleBreadCrumbSelectionChange} />
        </div>
        <IlapTermHeader isHeaderEditable={false} headerData={headerData} />
      </div>

      <div className="w-560px h-fit ml-auto mr-auto">
        <ViewFieldValueGrid dataSource={detailedField?.values} height={gridHeight} />
      </div>
    </>
  );
}
