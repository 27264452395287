import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { FormulaPickerAccordionDataSource } from "./FormulaPickerAccordion";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";
import { FieldMappingFormulaType } from "shared/enums/FieldMappingFormulaType";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  toggleShowFavoriteCustomFormulaOnly,
  toggleShowFavoriteSystemFormulaOnly,
} from "store/slices/FieldMappingFormulaPickerUISlice";

interface Props {
  dataSource: FormulaPickerAccordionDataSource;
}

export default function FormulaPickerAccordionTitle(props: Props) {
  const { dataSource } = props;

  const dispatch = useAppDispatch();

  const showFavoriteCustomFormulaOnly = useAppSelector(
    (store) =>
      store.fieldMappingFormulaPickerUIData.showFavoriteCustomFormulaOnly
  );

  const showFavoriteSystemFormulaOnly = useAppSelector(
    (store) =>
      store.fieldMappingFormulaPickerUIData.showFavoriteSystemFormulaOnly
  );

  const onSwitchClick = () => {
    if (dataSource.formulaType === FieldMappingFormulaType.System) {
      dispatch(toggleShowFavoriteSystemFormulaOnly());
    }

    if (dataSource.formulaType === FieldMappingFormulaType.Custom) {
      dispatch(toggleShowFavoriteCustomFormulaOnly());
    }
  };

  return (
    <div className="flex justify-between w-full">
      <div>{dataSource.title}</div>
      {dataSource.formulaType !== FieldMappingFormulaType.CoreField && (
        <div>
          <PromineoSwitchButton
            buttonRightText="View starred only"
            onChange={onSwitchClick}
            checked={
              (dataSource.formulaType === FieldMappingFormulaType.System &&
                showFavoriteSystemFormulaOnly) ||
              (dataSource.formulaType === FieldMappingFormulaType.Custom &&
                showFavoriteCustomFormulaOnly)
            }
            switchType={SwitchButtonType.Default}
          />
        </div>
      )}
    </div>
  );
}
