import { ReactNode } from "react";
import PromineoTextBox from "../controls/PromineoTextBox";

interface Props {
  title: string;
  placeHolder?: string;
  isEdit?: boolean;
  onTitleChanged?: (value: any) => void;
  widget?: ReactNode;
}

export default function PageHeaderTitle(props: Props) {
  return (
    <div className="inline-block h-9 max-w-[80%]">
      {props.isEdit ? (
        <PromineoTextBox
          className="text-darkBlue"
          width={"700"}
          placeholder={props.placeHolder}
          defaultValue={props.title}
          onValueChanged={props.onTitleChanged}
          size="large"
        />
      ) : (
        <div className="flex space-x-3">
          <div className="font-poppins text-24px leading-10 font-bold text-darkBlue truncate">
            {props.title}
          </div>
          {props.widget && <>{props.widget}</>}
        </div>
      )}
    </div>
  );
}
