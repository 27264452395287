import PromineoPopover from "components/common/controls/PromineoPopover";
import { Offset, Position } from "devextreme-react/popover";
import NavItemOption from "interfaces/NavItemOption";
import { useLocation } from "react-router-dom";
import { checkIfNavItemIsActive } from "shared/utilities/RouteUtility";
import { getNavItemId } from "./NavigationItems";
import NavigationItem from "./NavigationItem";

interface Props {
  navItems: NavItemOption[];
  target: string;
  visible: boolean;
  depth?: number;
  onHiding: () => void;
  onNavigation: (routeToNavigate: string | undefined) => void;
}

export default function SubNavMenu(props: Props) {
  const location = useLocation();

  const positionX = !props.depth ? "right" : "left";
  const offsetY = !props.depth ? -25 : -42;

  return (
    <>
      <PromineoPopover
        width={"auto"}
        minWidth={160}
        height={"auto"}
        visible={props.visible}
        target={props.target}
        onHiding={props.onHiding}
      >
        <Position my={{ x: positionX, y: "top" }}>
          <Offset x={80} y={offsetY} />
        </Position>
        <div className="py-2">
          {props.navItems.map((it) => {

            const hasChildren = it.children?.length;

            // INFO: If the submenu item has children, we put the padding on the parent 'NavigationItem', so that the entire area is clickable.
            // Padding added here are not clickable if we render a 'NavigationItem' component inside, which defies the expectation of the user.
            const paddingCss = hasChildren ? "" : "py-2.5 px-4";

            return (
              <div
                key={getNavItemId(it.itemId)}
                className={`font-poppins font-15px leading-22px hover:bg-lighterBlue ${paddingCss} cursor-pointer ${checkIfNavItemIsActive(location.pathname, it)
                    ? "font-bold"
                    : "font-medium"
                  }`}
                onClick={(e) => {
                  props.onNavigation(it.route);
                  e.stopPropagation();
                }}
              >
                {!hasChildren
                  ? it.text
                  : <NavigationItem
                    itemId={it.itemId}
                    text={it.text}
                    children={it.children}
                    onHidingSubmenu={props.onHiding}
                    isSubNavItem={true}
                  />}
              </div>
            );
          })}
        </div>
      </PromineoPopover>
    </>
  );
}
