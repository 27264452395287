import FieldResponse from "interfaces/response/FieldResponse";
import { getDataAsync } from "./API";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";

export const getFieldsAsync = async (
  excludeFieldWithUndefinedContentControl: boolean = true
) => {
  let apiUrl = `Fields?excludeFieldWithUndefinedContentControl=${excludeFieldWithUndefinedContentControl}`;
  let response = await getDataAsync<FieldResponse[]>(apiUrl);
  return response;
};

export const getFieldDetailsWithContentControlValuesByTemplateIdAsync = async (
  templateId: number
) => {
  let apiUrl = `Fields/Details/Templates/${templateId}`;
  let response = await getDataAsync<FieldDetailsResponse[]>(apiUrl);
  return response;
};
