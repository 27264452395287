import LabelWithContent from "components/common/LabelWithContent";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoCheckbox from "components/common/controls/PromineoCheckbox";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import PromineoModal from "components/modal/PromineoModal";
import PromineoModalHeader from "components/modal/PromineoModalHeader";
import ClientCreateRequest from "interfaces/request/autonomous-components/ClientCreateRequest";
import { useMemo, useState } from "react";
import { ClientTypeEnum } from "shared/enums/ClientTypeEnum";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import {
  addNewAutonomousComponentAsync,
  modifyAutonomousComponentsAsync,
} from "store/actions/AutonomousComponentActions";
import { addNewIlapAnalyticsClientAsync, modifyIlapAnalyticsClientAsync } from "store/actions/IlapAnalyticsActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  onClose: () => void;
  clientCreateRequest: ClientCreateRequest;
  clientType: ClientTypeEnum
}

export default function AutonomousComponentEditorDialog(props: Props) {
  const dispatch = useAppDispatch();
  const isEdit = props.clientCreateRequest.id ? true : false;
  const [client, setClient] = useState(
    props.clientCreateRequest
  );

  const handleChange = (fieldName: string, evt: any) => {
    setClient((prev) => {
      return { ...prev, [fieldName]: evt.value };
    });
  };

  const handleSaveChanges = () => {
    if (client.id) {
      if (client.name) {
        displayLoadingPanel();

        const promise = props.clientType === ClientTypeEnum.AutonomousComponent
          ? dispatch(modifyAutonomousComponentsAsync({
            componentId: client.id,
            data: client,
          }))
          : dispatch(modifyIlapAnalyticsClientAsync({
            clientId: client.id,
            data: client
          }));

        const updateSuccessMessage = props.clientType === ClientTypeEnum.AutonomousComponent 
          ? "Component updated successfully." 
          : "Client updated Successfully.";

        promise
          .unwrap()
          .then(() => {
            props.onClose();
            toastSuccess(updateSuccessMessage);
          })
          .finally(hideLoadingPanel);
      } else {
        displayNameValidationFailureToast();
      }
    }
  };

  const handleCreateNew = () => {
    if (client.name) {
      displayLoadingPanel();

      const promise = props.clientType === ClientTypeEnum.AutonomousComponent
        ? dispatch(addNewAutonomousComponentAsync(client))
        : dispatch(addNewIlapAnalyticsClientAsync(client));

      const createSuccessMessage = props.clientType === ClientTypeEnum.AutonomousComponent 
        ? "Component created successfully." 
        : "Client created Successfully.";

      promise
        .unwrap()
        .then(() => {
          props.onClose();
          toastSuccess(createSuccessMessage);
        })
        .finally(hideLoadingPanel);
    } else {
      displayNameValidationFailureToast();
    }
  };

  const displayNameValidationFailureToast = () => {
    toastError("Name is required.");
  };

  const headerText = useMemo(() => {
    return props.clientType === ClientTypeEnum.AutonomousComponent
    ? "Autonomous Component"
    : "ILAP Analytics";
  }, [props.clientType]);

  return (
    <PromineoModal
      isVisible={true}
      width={400}
      height="auto"
      editorActionButtonProps={{
        mode: isEdit ? PromineoModalMode.Modify : PromineoModalMode.Create,
        cancelButtonCaption: "Cancel",
        createButtonCaption: "Create",
        doneButtonCaption: "Cancel",
        saveChangesButtonCaption: "Save",
        onDone: props.onClose,
        onCancel: props.onClose,
        onCreate: handleCreateNew,
        onSaveChanges: handleSaveChanges,
      }}
    >
      <PromineoModalHeader
        onCancel={props.onClose}
        text={headerText}
        showCloseIcon={false}
      />
      <div className="space-y-2 pb-4">
        <LabelWithContent
          label="Name"
          content={
            <PromineoTextBox
              onValueChanged={(evt: any) => handleChange("name", evt)}
              width={"100%"}
              defaultValue={client.name}
            />
          }
        />
        <LabelWithContent
          label="Description"
          content={
            <PromineoMultilineTextEdit
              onValueChanged={(evt: any) => handleChange("description", evt)}
              width={"100%"}
              defaultValue={client.description}
            />
          }
        />
        <LabelWithContent
          label="Is Active"
          content={
            <PromineoCheckbox
              onValueChanged={(evt: any) => handleChange("isActive", evt)}
              defaultValue={client.isActive}
            />
          }
        />
      </div>
    </PromineoModal>
  );
}
