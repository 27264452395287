import PromineoTextBox from "components/common/controls/PromineoTextBox";
import { EditorMode } from "shared/enums/EditorMode";
import TextViewerComponent from "./safran/TextViewerComponent";

interface Props {
  mode: EditorMode;
  textBoxValue: string;
  fieldName: string;
  textBoxWidth?: number;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function HostSystemConfigurationTextEditor(props: Props) {
  const {
    mode,
    textBoxValue,
    fieldName,
    textBoxWidth,
    handleConfigurationValueChanged,
  } = props;

  return (
    <>
      {mode === EditorMode.Edit && (
        <PromineoTextBox
          width={textBoxWidth ?? 320}
          name={fieldName}
          value={textBoxValue}
          placeholder="Type..."
          onChange={({ event }: any) => {
            if (handleConfigurationValueChanged) {
              handleConfigurationValueChanged(
                event?.currentTarget?.name,
                event?.currentTarget?.value
              );
            }
          }}
        />
      )}

      {mode === EditorMode.View && (
        <TextViewerComponent text={textBoxValue ? textBoxValue : "-"} />
      )}
    </>
  );
}
