import DialogHeader from "features/common/DialogHeader";

export default function BulkFieldValueInsertDialogHeader() {
  const instructions = [
    "Each line contains a value and a description",
    "Value and description are separated by a semicolon",
    "Values and descriptions must not contain internal semicolons",
  ];
  return <DialogHeader title={"Upload Values"} instructions={instructions} />;
}
