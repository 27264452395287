import FieldValue from "interfaces/common/FieldValue";
import { ValidationResult } from "interfaces/common/ValidationResult";

export const validateFieldValues = (fieldValues: FieldValue[]): ValidationResult => {
  const seenInputs: Set<string> = new Set();
  const errors: string[] = [];

  for (let i = 0; i < fieldValues.length; i++) {
    const { valueCode } = fieldValues[i];
    const trimmedCode = valueCode.trim();
    const lowercaseCode = trimmedCode.toLowerCase();

    if (!trimmedCode) {
      errors.push(`Line ${i + 1}: Value code can not be empty.`);
      continue;
    }

    if (seenInputs.has(lowercaseCode)) {
      errors.push(`Line ${i + 1}: Duplicate code "${trimmedCode}" is not allowed.`);
      continue;
    }

    seenInputs.add(lowercaseCode); // inserting lowercase code to have case-insensitive duplicate check
  }

  return {
    isValidationSuccessful: errors.length === 0,
    errors: errors
  };
}
