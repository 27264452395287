import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loadLoggedInUserInfo } from "store/actions/UserActions";
import { useAppDispatch } from "store/hooks";

export default function useCurrentUser() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const [previousIsAuthenticated, setPreviousIsAuthenticated] =
    useState(isAuthenticated);

  useEffect(() => {
    // Workflow of loading current user:
    // When signing in from the landing page, we load the current user information after a successful sign-in(In landing page).
    // This component allows us to handle scenarios where the page is reloaded (by hitting refresh or opening a new tab) and still have access to the current user's information.
    // If the user is already authenticated and the page is reloaded, we use the msal-react to attempt retrieving the user information locally.
    // During this process, the initial value of the "isAuthenticated" flag is set to false, and it becomes true once the retrieval is complete.
    // At that point, we load the current user information again.
    // However, if the user is not authenticated or the token has expired, and they attempt to reload the page, they will be redirected back to the landing page.
    if (isAuthenticated && !previousIsAuthenticated) {
      dispatch(loadLoggedInUserInfo());
      // This wil prevent further unnecessary reloading of user information.
      setPreviousIsAuthenticated(true);
    }
  }, [previousIsAuthenticated, isAuthenticated]);
}
