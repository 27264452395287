import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";

interface Props {
  data: { data: FieldMappingResponse };
  onSelectionChagne: (isChecked: boolean) => void;
}

export default function UseValueTransformationToggleButton(props: Props) {
  const handleChange = () => {
    const useValueTransformationPreviousValue =
      !props.data.data.hasDirectMapping;
    const useValueTransformationToggleValue =
      !useValueTransformationPreviousValue;
    props.onSelectionChagne(useValueTransformationToggleValue);
  };

  return (
    <PromineoSwitchButton
      checked={!props.data.data.hasDirectMapping}
      switchType={SwitchButtonType.Default}
      onChange={handleChange}
    />
  );
}
