import { ReactNode } from "react";
import LabelWithAsteriskSymbol from "components/common/LabelWithAsteriskSymbol";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";

interface Props {
  label: string;
  content: ReactNode | string;
  addAsterisksymbol?: boolean;
  mode: PromineoModalMode;
  maxWidth?: string;
}

export default function ConnectorLabelWithContent(props: Props) {
  const { label, content, addAsterisksymbol, mode } = props;

  var maxWidth = props.maxWidth ?? "max-w-[235px]";

  return (
    <div className="py-1">
      <LabelWithAsteriskSymbol
        label={label}
        addAsterisksymbol={
          mode !== PromineoModalMode.View ? addAsterisksymbol : false
        }
      />
      <div
        className={`mt-1 font-medium ${
          mode === PromineoModalMode.View ? `ml-2 ${maxWidth} truncate` : ""
        }`}
      >
        {content}
      </div>
    </div>
  );
}
