import { ReactNode } from "react";

interface Props {
  cssClasses?: string;
  text: string;
  LeftIcon?: ReactNode;
}

export default function GridRowStatusLabel(props: Props) {
  const { cssClasses, text, LeftIcon } = props;
  let classNames = `h-6 flex justify-center font-normal items-center rounded-[8px] px-2 text-[14px] leading-[18px] font-inter ${
    cssClasses ? cssClasses : ""
  }`;
  return (
    <div className={`${classNames}`}>
      {LeftIcon && <div>{LeftIcon}</div>}
      {text}
    </div>
  );
}
