import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import AnalyticsHostSystemParameters from "features/setup/configs/host-system/analytics/AnalyticsHostSystemParameters";
import MicrosoftProjectReceivingParameters from "features/setup/configs/host-system/microsoftProject/MicrosoftProjectReceivingParameters";
import PrimaveraReceivingParameters from "features/setup/configs/host-system/primavera/PrimaveraReceivingParameters";
import SafranReceivingParameters from "features/setup/configs/host-system/safran/SafranReceivingParameters";
import SapReceivingParameters from "features/setup/configs/host-system/sap/SapReceivingParameters";
import AnalyticsBaseConfig from "interfaces/host-system-config/analytics/AnalyticsBaseConfig";
import PrimaveraImportConfig from "interfaces/host-system-config/primavera/PrimaveraImportConfig";
import SafranImportConfig from "interfaces/host-system-config/safran/SafranImportConfig";
import { SapImportConfig } from "interfaces/host-system-config/sap/SapImportConfig";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { forwardRef, useEffect, useState } from "react";
import { EditorMode } from "shared/enums/EditorMode";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import { loadConnectorHostFieldAsync } from "store/actions/ConnectorActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  mode: EditorMode;
  hostSystem?: HostSystem;
  hostParameters?: SupportedHostSystemConfigurationType;
  selectedCodeSet?: number | null;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  height: number;
  receiverConnectorId?: number;
  onStructureGridModified?: () => void;
}

const ReceiverHostParameterOverrides = forwardRef(
  (props: Props, forwardedRef) => {
    const dispatch = useAppDispatch();
    const {
      mode,
      height,
      hostSystem,
      hostParameters,
      selectedCodeSet,
      receiverConnectorId,
      handleConfigurationValueChanged,
    } = props;

    const [connectorHostFields, setConnectorHostFields] = useState<
      HostFieldResponse[]
    >([]);

    useEffect(() => {
      if (receiverConnectorId && selectedCodeSet && mode === EditorMode.Edit) {
        displayLoadingPanel();
        dispatch(
          loadConnectorHostFieldAsync({
            connectorId: receiverConnectorId,
            includeAll: false,
            userFieldId: selectedCodeSet,
          })
        )
          .unwrap()
          .then((response: HostFieldResponse[]) => {
            setConnectorHostFields(response);
          })
          .finally(hideLoadingPanel);
      }
    }, [receiverConnectorId, selectedCodeSet]);

    if (!hostSystem) {
      return <></>;
    }

    return (
      <>
        {hostSystem === HostSystem.Safran && hostParameters && (
          <SafranReceivingParameters
            mode={mode}
            height={height}
            selectedCodeSet={selectedCodeSet ?? null}
            importConfig={hostParameters as SafranImportConfig}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
            ref={forwardedRef}
            isOverride={true}
            hostFields={connectorHostFields}
            onStructureGridModified={props.onStructureGridModified}
          />
        )}
        {hostSystem === HostSystem.IlapAnalytics && hostParameters && (
          <AnalyticsHostSystemParameters
            mode={mode}
            height={height}
            config={hostParameters as AnalyticsBaseConfig}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
            isOverride={true}
          />
        )}
        {hostSystem === HostSystem.SAP && hostParameters && (
          <SapReceivingParameters
            height={height}
            mode={mode}
            config={hostParameters as SapImportConfig}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
            ref={forwardedRef}
            isOverride={true}
          />
        )}
        {hostSystem === HostSystem.PrimaveraP6 && hostParameters && (
          <PrimaveraReceivingParameters
            mode={mode}
            height={height}
            importConfig={hostParameters as PrimaveraImportConfig}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
            isOverride={true}
          />
        )}
        {hostSystem === HostSystem.MicrosoftProject && (
          <MicrosoftProjectReceivingParameters
            height={height}
            isOverride={true}
          />
        )}
      </>
    );
  }
);

export default ReceiverHostParameterOverrides;
