import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getConnectorsForCurrentUserAsync,
  getConnectorDetailsAsync,
  createConnectorAsync,
  updateConnectorAsync,
  getSimplifiedConnectorsAsync,
  deleteConnectorAsync,
  generateConnectorEventAsync,
  getConnectorEventStatusAsync,
  getConnectorHostFieldsAsync,
} from "apis/ConnectorApi";
import ConnectorEventCreateRequest from "interfaces/request/ConnectorEventCreateRequest";
import ConnectorModifyRequest from "interfaces/request/ConnectorModifyRequest";
import ConnectorWriteBaseRequest from "interfaces/request/ConnectorWriteBaseRequest";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";

export const loadConnectorsForCurrentUserAsync = createAsyncThunk(
  "Connecotrs/loadConnectorsForCurrentUser",
  async () => {
    return await getConnectorsForCurrentUserAsync();
  }
);

export const loadConnectorDetailsAsync = createAsyncThunk(
  "Connecotrs/loadConnectorDetails",
  async (connectorId: number) => {
    return await getConnectorDetailsAsync(connectorId);
  }
);

export const createConnector = createAsyncThunk(
  "Connecotrs/createConnector",
  async (connectorRequest: ConnectorWriteBaseRequest) => {
    return await createConnectorAsync(connectorRequest);
  }
);

export const updateConnector = createAsyncThunk<
  ConnectorDetailsResponse,
  { connectorId: number; connectorRequest: ConnectorModifyRequest }
>("Connecotrs/updateConnector", async (request) => {
  return await updateConnectorAsync(
    request.connectorId,
    request.connectorRequest
  );
});

export const loadSimplifiedConnectorsAsync = createAsyncThunk(
  "Connecotrs/loadSimplifiedConnectors",
  async () => {
    return await getSimplifiedConnectorsAsync();
  }
);

export const removeConnectorAsync = createAsyncThunk(
  "Connecotrs/removeConnector",
  async (connectorId: number, { rejectWithValue }) => {
    try {
      return await deleteConnectorAsync(connectorId);
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const generateConnectorPersistEventAsync = createAsyncThunk(
  "Connecotrs/generateConnectorEventAsync",
  async (connectorEventCreateRequest: ConnectorEventCreateRequest) => {
    return await generateConnectorEventAsync(connectorEventCreateRequest);
  }
);

export const loadConnectorEventStatusAsync = createAsyncThunk(
  "Connecotrs/getConnectorEventStatusAsync",
  async (connectorId: number) => {
    return await getConnectorEventStatusAsync(connectorId);
  }
);

export const loadConnectorHostFieldAsync = createAsyncThunk<
  HostFieldResponse[],
  { connectorId: number; includeAll: boolean; userFieldId: number }
>("Connecotrs/loadConnectorHostFieldAsync", async (request) => {
  return await getConnectorHostFieldsAsync(
    request.connectorId,
    request.includeAll,
    request.userFieldId
  );
});