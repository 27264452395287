import ClientCreateRequest from "interfaces/request/autonomous-components/ClientCreateRequest";
import {
  deleteDataAsync,
  getDataAsync,
  postDataAsync,
  putDataAsync,
} from "./API";
import ClientResponse from "interfaces/response/autonomous-components/ClientResponse";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import ServiceTokenCreateRequest from "interfaces/request/autonomous-components/ServiceTokenCreateRequest";
import ServiceTokenUpdateRequest from "interfaces/request/autonomous-components/ServiceTokenUpdateRequest";

export const getIlapAnalyticsClientsAsync = async () => {
  let apiUrl = "IlapAnalytics";
  let response = await getDataAsync<ClientResponse[]>(apiUrl);
  return response;
};

export const createIlapAnalyticsClientAsync = async (
  data: ClientCreateRequest
) => {
  let apiUrl = "IlapAnalytics";
  let response = await postDataAsync<
    ClientCreateRequest,
    ClientResponse
  >(apiUrl, data);
  return response;
};

export const updateIlapAnalyticsClientAsync = async (
  clientId: number,
  data: ClientCreateRequest
) => {
  let apiUrl = `IlapAnalytics/${clientId}`;
  let response = await putDataAsync<
    ClientCreateRequest,
    ClientResponse
  >(apiUrl, data);
  return response;
};

export const deleteIlapAnalyticsClientAsync = async (clientId: number) => {
  let apiUrl = `IlapAnalytics/${clientId}`;
  let response = await deleteDataAsync(apiUrl);
  return response;
};

export const getServiceTokensAsync = async (clientId: number) => {
  let apiUrl = `IlapAnalytics/${clientId}/ServiceTokens`;
  let response = await getDataAsync<ServiceTokenResponse[]>(apiUrl);
  return response;
};

export const createServiceTokenAsync = async (
  clientId: number,
  data: any
) => {
  let apiUrl = `IlapAnalytics/${clientId}/ServiceTokens`;
  let response = await postDataAsync<
    ServiceTokenCreateRequest,
    ServiceTokenResponse
  >(apiUrl, data);
  return response;
};

export const updateServiceTokenAsync = async (
  serviceTokenId: number,
  data: ServiceTokenUpdateRequest
) => {
  let apiUrl = `IlapAnalytics/ServiceTokens/${serviceTokenId}`;
  let response = await putDataAsync<
    ServiceTokenUpdateRequest,
    ServiceTokenResponse
  >(apiUrl, data);
  return response;
};

export const deleteServiceTokenAsync = async (serviceTokenId: number) => {
  let apiUrl = `IlapAnalytics/ServiceTokens/${serviceTokenId}`;
  let response = await deleteDataAsync(apiUrl);
  return response;
};
