import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import { EditConfigHeaderData } from "interfaces/component-data/EditConfigHeaderData";
import LabelResponse from "interfaces/response/LabelResponse";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { CONFIGS } from "shared/constants/RoutePathConstants";
import ConfigDirectionHolder from "./ConfigDirectionHolder";
import ConfigConnectorDropDown from "./ConfigConnectorDropDown";
import ConfigTemplateDropDown from "./ConfigTemplateDropDown";
import ConfigLabelHolder from "./ConfigLabelHolder";
import { getLocalFormattedDateTimeFromUtcDateObject } from "shared/utilities/DateTimeUtility";

interface Props {
  data: EditConfigHeaderData;
  onDeleteLabel: (label: LabelResponse) => void;
  onTitleChanged: (value: any) => void;
  onAddNewLabel: (label: LabelResponse) => void;
  onConnectorSelectionChange: (connectorId: number, connectorName: string) => void;
  onTemplateSelectionChange: (templateId: number, title: string) => void;
  onDirectionChange: (direction: ConfigDirection) => void;
  readonly: boolean;
  isNew?: boolean;
}

export default function EditConfigHeader(props: Props) {
  let navigate = useNavigate();

  const Separator = () => {
    return <div>|</div>;
  };

  const headerComponentInnerElementClass = "flex gap-x-2 items-center";

  const breadCrumbsItems: BreadcrumbItem[] = [
    {
      key: "configs",
      text: "Configs",
    },
    {
      key: "config-name",
      text: `${props.data.title}`,
    },
  ];

  return (
    <div>
      <div className="mb-1">
        <Breadcrumb
          items={breadCrumbsItems}
          onSelectionChange={(index, selectedItem) => {
            if (selectedItem.key === "configs") {
              navigate(CONFIGS);
            }
          }}
        />
      </div>
      <div className="flex space-x-3 items-center">
        <PageHeaderTitle
          placeHolder={"Type config title..."}
          title={props.data.title}
          onTitleChanged={props.onTitleChanged}
          isEdit={!props.readonly}
        />
      </div>
      <div className="mt-3 flex items-center">
        <div className="flex flex-wrap items-center text-10px font-inter font-medium text-darkBlue space-x-4 gap-y-2">
          <div>ID: {props.data.id}</div>
          <Separator />
          <ConfigTemplateDropDown
            className={headerComponentInnerElementClass}
            onSelectionChange={props.onTemplateSelectionChange}
            selectedTemplateId={props.data.templateId}
            templateTitle={props.data.templateTitle}
            readonly={props.readonly}
            isConfigPublishedPreviously={props.data.isPublishedPreviously}
          />
          <Separator />
          <ConfigConnectorDropDown
            className={headerComponentInnerElementClass}
            onSelectionChanged={props.onConnectorSelectionChange}
            selectedConnectorId={props.data.connectorId}
            configDirection={props.data.direction}
            connectorName={props.data.connectorName}
            readonly={props.readonly}
            isConfigPublishedPreviously={props.data.isPublishedPreviously}
          />
          <Separator />
          <ConfigDirectionHolder
            className={headerComponentInnerElementClass}
            onDirectionChange={props.onDirectionChange}
            selectedConnectorId={props.data.connectorId}
            selectedDirection={props.data.direction}
            selectedDirectionText={props.data.directionText}
            readonly={props.readonly}
            isConfigPublishedPreviously={props.data.isPublishedPreviously}
          />
          <Separator />
          <ConfigLabelHolder
            className={headerComponentInnerElementClass}
            labels={props.data.labels}
            readonly={props.readonly}
            onAddLabel={props.onAddNewLabel}
            onDelete={props.onDeleteLabel}
          />
          <Separator />
          <div># of schedules: {props.data.scheduleCount}</div>
          {!props.isNew && (
            <>
              <Separator />
              <div>Created by: {props.data.createdBy}</div>
              <Separator />
              <div>Created on:{" "}{getLocalFormattedDateTimeFromUtcDateObject(props.data.createdOn)}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
