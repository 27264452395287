import { List } from "devextreme-react";
import { IListOptions } from "devextreme-react/list";
import React from "react";
import "./styles/PromineoList.css";

interface Props {
  reference?: React.LegacyRef<List<any, any>>;
}

function PromineoList(props: Props & IListOptions) {
  return (
    <List
      {...props}
      className={`promineo-list ${props.className}`}
      ref={props.reference}
    />
  );
}

export default React.forwardRef(
  (props: Props & IListOptions, reference: React.LegacyRef<List<any, any>>) => {
    return <PromineoList {...props} reference={reference} />;
  }
);
