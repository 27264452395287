import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { HostSystem } from "shared/enums/feature/HostSystem";
import SafranSendingParameters from "./safran/SafranSendingParameters";
import SafranExportConfig from "interfaces/host-system-config/safran/SafranExportConfig";
import { EditorMode } from "shared/enums/EditorMode";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import SafranReceivingParameters from "./safran/SafranReceivingParameters";
import SafranImportConfig from "interfaces/host-system-config/safran/SafranImportConfig";
import AnalyticsHostSystemParameters from "./analytics/AnalyticsHostSystemParameters";
import AnalyticsBaseConfig from "interfaces/host-system-config/analytics/AnalyticsBaseConfig";
import SapReceivingParameters from "./sap/SapReceivingParameters";
import { SapImportConfig } from "interfaces/host-system-config/sap/SapImportConfig";
import { forwardRef } from "react";
import PrimaveraReceivingParameters from "./primavera/PrimaveraReceivingParameters";
import PrimaveraImportConfig from "interfaces/host-system-config/primavera/PrimaveraImportConfig";
import SapSendingParameters from "./sap/SapSendingParameters";
import MicrosoftProjectSendingParameters from "./microsoftProject/MicrosoftProjectSendingParameters";
import MicrosoftProjectReceivingParameters from "./microsoftProject/MicrosoftProjectReceivingParameters";
import MicrosoftProjectExportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectExportConfig";
import { useAppSelector } from "store/hooks";
import PrimaveraExportConfig from "interfaces/host-system-config/primavera/PrimaveraExportConfig";
import PrimaveraSendingParameters from "./primavera/PrimaveraSendingParameter";

interface Props {
  mode: EditorMode;
  direction: ConfigDirection;
  hostSystem: HostSystem;
  hostSystemConfiguration?: SupportedHostSystemConfigurationType;
  height: number;
  selectedConnectorId: number;
  selectedCodeSet: number | null;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

const HostSystemParameters = forwardRef((props: Props, forwardedRef) => {
  const {
    mode,
    hostSystem,
    direction,
    hostSystemConfiguration,
    height,
    selectedConnectorId,
    selectedCodeSet,
    handleConfigurationValueChanged,
  } = props;

  const hostFields = useAppSelector(
    (root) => root.connectorData.selectedConnector?.hostFields
  );

  return (
    <>
      {direction === ConfigDirection.Sending &&
        hostSystem === HostSystem.Safran &&
        hostSystemConfiguration && (
          <SafranSendingParameters
            mode={mode}
            height={height}
            exportConfig={hostSystemConfiguration as SafranExportConfig}
            selectedConnectorId={selectedConnectorId}
            selectedCodeSet={selectedCodeSet}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        )}

      {direction === ConfigDirection.Receiving &&
        hostSystem === HostSystem.Safran &&
        hostSystemConfiguration && (
          <SafranReceivingParameters
            mode={mode}
            height={height}
            selectedCodeSet={selectedCodeSet}
            importConfig={hostSystemConfiguration as SafranImportConfig}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
            ref={forwardedRef}
            hostFields={hostFields || []}
          />
        )}

      {hostSystem === HostSystem.IlapAnalytics && hostSystemConfiguration && (
        <AnalyticsHostSystemParameters
          mode={mode}
          height={height}
          config={hostSystemConfiguration as AnalyticsBaseConfig}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      )}

      {hostSystem === HostSystem.SAP &&
        direction === ConfigDirection.Receiving &&
        hostSystemConfiguration && (
          <SapReceivingParameters
            height={height}
            mode={mode}
            config={hostSystemConfiguration as SapImportConfig}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
            ref={forwardedRef}
          />
        )}

      {direction === ConfigDirection.Sending &&
        hostSystem === HostSystem.SAP && (
          <SapSendingParameters height={height} />
        )}

      {direction === ConfigDirection.Receiving &&
        hostSystem === HostSystem.PrimaveraP6 &&
        hostSystemConfiguration && (
          <PrimaveraReceivingParameters
            mode={mode}
            height={height}
            importConfig={hostSystemConfiguration as PrimaveraImportConfig}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        )}

      {direction === ConfigDirection.Sending &&
        hostSystem === HostSystem.PrimaveraP6 && (
          <PrimaveraSendingParameters
            height={height}
            mode={mode}
            exportConfig={hostSystemConfiguration as PrimaveraExportConfig}
            selectedConnectorId={selectedConnectorId}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        )}

      {direction === ConfigDirection.Sending &&
        hostSystem === HostSystem.MicrosoftProject && (
          <MicrosoftProjectSendingParameters
            height={height}
            mode={mode}
            exportConfig={
              hostSystemConfiguration as MicrosoftProjectExportConfig
            }
            selectedConnectorId={selectedConnectorId}
            selectedCodeSet={selectedCodeSet}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        )}

      {direction === ConfigDirection.Receiving &&
        hostSystem === HostSystem.MicrosoftProject && (
          <MicrosoftProjectReceivingParameters height={height} />
        )}
    </>
  );
});

export default HostSystemParameters;
