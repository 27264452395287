import TemplateFieldWithValueCountResponse from "interfaces/response/TemplateFieldWithValueCountResponse";
import { getContentConrolInformation } from "shared/utilities/ContentControlUtility";

export default function ContentControlInformationCellComponent(props: {
  data: { data: TemplateFieldWithValueCountResponse };
}) {
  const cellData = props.data.data;

  const displayText = getContentConrolInformation({
    contentControlText: cellData.contentControlLevelText,
    controlledOnText: cellData.controlledOnText,
    allowBlank: cellData.allowBlank,
    allowContentControl: cellData.allowContentControl,
  });

  return <div>{displayText}</div>;
}
