import PromineoModal from "components/modal/PromineoModal";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import DialogHeader from "features/common/DialogHeader";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import SapActivityFilter from "./SapActivityFilter";

interface Props {
  isEdit: boolean;
  orderFilterValue: string;
  operationFilterValue: string;
  frequencyType: FrequencyType;
  isPredecessorIEASelected: boolean;
  onCancel: () => void;
  onSapFilterSave: () => void;
  handleOrderFilterValueChange: (
    selectedFilter: string,
    parentName: string,
    valueChangedFromDropdown: boolean
  ) => void;
  handleOperationFilterValueChange: (
    newOperationFilter: string,
    valueChangedFromDropdown: boolean
  ) => void;
  handlePredecessorIEASelectionChange: (selectedValue: boolean) => void;
}

export default function SapActivityFilterDialog(props: Props) {
  const {
    isEdit,
    orderFilterValue,
    operationFilterValue,
    frequencyType,
    isPredecessorIEASelected,
    onCancel,
    onSapFilterSave,
    handleOrderFilterValueChange,
    handleOperationFilterValueChange,
    handlePredecessorIEASelectionChange,
  } = props;

  return (
    <PromineoModal
      isVisible={true}
      displayDefaultActions={true}
      coloredBg={true}
      width={720}
      height={isEdit ? 680 : 630}
      editorActionButtonProps={{
        mode: isEdit ? PromineoModalMode.Create : PromineoModalMode.View,
        cancelButtonCaption: "Cancel",
        createButtonCaption: "Save",
        doneButtonCaption: "Cancel",
        onDone: onCancel,
        onCancel: onCancel,
        onCreate: onSapFilterSave,
      }}
    >
      <div className="space-y-4">
        <DialogHeader title={"Activity filter"} />
        <div className="border-b border-lightGray"></div>

        <SapActivityFilter
          frequencyType={frequencyType}
          isEdit={isEdit}
          isPredecessorIEASelected={isPredecessorIEASelected}
          orderFilterValue={orderFilterValue}
          operationFilterValue={operationFilterValue}
          onOperationFilterValueChange={handleOperationFilterValueChange}
          onOrderFilterValueChange={handleOrderFilterValueChange}
          onPredecessorIEASelectionChange={handlePredecessorIEASelectionChange}
        />
        <div className="border-b border-lightGray"></div>
      </div>
    </PromineoModal>
  );
}
