import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import { useEffect, useRef } from "react";
import { loadTenantStatusesValuesAsync } from "store/actions/DropdownValueActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

export default function useLoadTenantStatusesHook(): DropdownValuesResponse[] {
  const dispatch = useAppDispatch();
  const isTenantStatusLoaded = useRef(false);

  const tenantStatuses = useAppSelector(
    (store) => store.dropdownData.tenantStatuses
  );

  useEffect(() => {
    if (!tenantStatuses.length && !isTenantStatusLoaded.current) {
      displayLoadingPanel();
      dispatch(loadTenantStatusesValuesAsync()).finally(() => {
        hideLoadingPanel();
        isTenantStatusLoaded.current = true;
      });
    }
  }, [tenantStatuses]);

  return tenantStatuses;
}
