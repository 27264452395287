import { useLocation } from "react-router-dom";
import { Offset, Position } from "devextreme-react/popup";
import { checkIfNavItemIsActive } from "shared/utilities/RouteUtility";
import NavItemOption from "interfaces/NavItemOption";
import PromineoPopover from "components/common/controls/PromineoPopover";
import NavigationItem from "./NavigationItem";

interface Props {
  navigationItems: NavItemOption[];
  visible: boolean;
  onHiding: () => void;
  target: string;
}

export default function MobileNavigation(props: Props) {
  const { navigationItems } = props;
  const location = useLocation();
  const height = navigationItems.length * 35;

  return (
    <PromineoPopover width={"auto"} height={height} {...props}>
      <Position my={{ x: "right", y: "top" }}>
        <Offset x={10} y={-5} />
      </Position>
      <div className="mt-2 space-y-4">
        {navigationItems.map((navItem) => (
          <NavigationItem
            text={navItem.text}
            route={navItem.route}
            itemId={navItem.itemId}
            children={navItem.children ?? []}
            isActive={checkIfNavItemIsActive(location.pathname, navItem)}
            key={navItem.itemId}
            image={navItem.image}
            onHidingSubmenu={props.onHiding}
          />
        ))}
      </div>
    </PromineoPopover>
  );
}
