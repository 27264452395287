import ShareTemplateButton from "./ShareTemplateButton";
import TenantSimplifiedResponse from "interfaces/response/TenantSimplifiedResponse";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { useCallback } from "react";

interface Props {
  dataSource: TenantSimplifiedResponse[];
  tenantIdToShareWith: number;
  setTenantIdToShareWith: (value: number) => void;
  onShare: () => void;
}

export default function ShareTemplateDialogFooter(props: Props) {
  const { dataSource, tenantIdToShareWith, setTenantIdToShareWith, onShare } =
    props;

  const RenderTenantSelectionComponent = useCallback(() => {
    return (
      <>
        <PromineoSelectBox
          placeholder="Select or search"
          defaultValue={tenantIdToShareWith}
          items={dataSource}
          valueExpr="id"
          displayExpr="name"
          searchEnabled={true}
          searchMode="contains"
          onValueChange={(value: number) => {
            setTenantIdToShareWith(value);
          }}
        />
      </>
    );
  }, [props.dataSource, tenantIdToShareWith]);
  return (
    <div className="flex gap-2">
      <div className="flex items-center ">
        <div className="whitespace-nowrap w-auto px-2 border border-lightGray h-6 text-10px rounded-l-lg flex items-center">
          Add tenant
        </div>
        <RenderTenantSelectionComponent />
      </div>

      <ShareTemplateButton onClick={onShare} />
    </div>
  );
}
