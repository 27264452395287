import { IButtonOptions } from "devextreme-react/button";
import { useState, ReactNode } from "react";
import PromineoButtonWithIcon from "./PromineoButtonWithIcon";
import { PromineoButtonType } from "./PromineoButton";
import PromineoPopoverWithMenu from "components/common/controls/popover-with-menu/PromineoPopoverWithMenu";
import PopoverMenuItem from "components/common/controls/popover-with-menu/PopoverMenuItem";

interface Props {
  id: string;
  text?: string;
  Icon?: ReactNode;
  RightIcon?: ReactNode;
  variant?: PromineoButtonType;
  menuItems?: PopoverMenuItem[];
}

export default function PromineoButtonWithPopover(
  props: Props & IButtonOptions
) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleButtonClick = () => {
    setIsPopoverVisible((prevState) => !prevState);
  };

  const handlePopoverOnHiding = () => {
    setIsPopoverVisible(false);
  };

  return (
    <div>
      <PromineoButtonWithIcon
        {...props}
        variant={props.variant ? props.variant : PromineoButtonType.Primary}
        text={props.text || "Button with popover"}
        Icon={props.Icon}
        RightIcon={props.RightIcon}
        id={props.id}
        onClick={handleButtonClick}
      />
      <PromineoPopoverWithMenu
        menuItems={props.menuItems ? props.menuItems : []}
        visible={isPopoverVisible}
        onHiding={handlePopoverOnHiding}
        target={`#${props.id}`}
      />
    </div>
  );
}
