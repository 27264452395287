import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { TENANT_ADMIN_FIELDS } from "shared/constants/RoutePathConstants";
import { useAppDispatch, useAppSelector } from "store/hooks";
import "components/common/grid/styles/PromineoUIGrid.css";
import ViewFieldValueGrid from "features/common/view-field-value-grid/ViewFieldValueGrid";
import { getFieldForTenantAdminAsync } from "store/actions/TenantAdminActions";
import ContentControlFieldHeader from "./ContentControlFieldHeader";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function ContentControlFieldViewer() {
  const param = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadcrumbItem[]>([]);

  useEffect(() => {
    if (param.fieldId) {
      var fieldId = Number(param.fieldId);
      displayLoadingPanel();
      dispatch(getFieldForTenantAdminAsync(fieldId)).finally(hideLoadingPanel);
    }
  }, []);

  const fieldWithValue = useAppSelector((root) => root.tenantAdminData.field);

  useEffect(() => {
    if (fieldWithValue) {
      const items: BreadcrumbItem[] = [
        {
          key: "Content control",
          text: "Content control",
          data: { url: TENANT_ADMIN_FIELDS },
        },
        {
          key: "field-name",
          text: fieldWithValue.name,
        },
      ];

      setBreadCrumbItems(items);
    }
  }, [fieldWithValue]);

  const handleBreadCrumbSelectionChange = (
    ind: number,
    item: BreadcrumbItem
  ) => {
    if (item.data?.url) {
      navigate(item.data.url);
    }
  };

  const headerDivId: string = "content-control-field-viewer-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  if (!fieldWithValue) {
    return <></>;
  }

  return (
    <>
      <div id={headerDivId}>
        <div className="mb-1">
          <Breadcrumb
            items={breadCrumbItems}
            onSelectionChange={handleBreadCrumbSelectionChange}
          />
        </div>
        <ContentControlFieldHeader
          field={fieldWithValue}
          isNotAllowedToEdit={false} 
        />
      </div>

      <div className="flex justify-center">
        <ViewFieldValueGrid
          dataSource={fieldWithValue.values}
          height={gridHeight}
        />
      </div>
    </>
  );
}
