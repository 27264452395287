import LabelWithContent from "components/common/LabelWithContent";
import TenantUsersDropdown from "../TenantUsersDropdown";
import RepresentativeDropdown from "../RepresentativeDropdown";
import SaveButton from "components/common/controls/buttons/SaveButton";
import EditButton from "components/common/controls/buttons/EditButton";
import { useEffect, useRef, useState } from "react";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { loadUsersByTenantAsync } from "store/actions/UserActions";

const labelClassName =
  "ml-2 w-44 font-inter font-medium text-10px leading-15px";

interface RepresentativeCreateConfig {
  ownerRepresentatives: number[];
  onValueChange: (value: number[]) => void;
}

interface RepresentativeEditConfig {
  isOwner?: boolean;
  ownerRepresentativeIds: number[];
  onOwnerRepresentativeChange: (representativeIds: number[]) => void;
  selectedRepresentatives: UserSimplifiedResponse[];
  onSaveOwnerRepresentative: () => void;
  allowEdit?: boolean;
}

interface Props {
  editStatus: "create" | "edit";
  createConfig?: RepresentativeCreateConfig;
  editConfig?: RepresentativeEditConfig;
  ownerTenantId: number;
}

const AddRepresentativeField = (
  props: RepresentativeCreateConfig & {
    tenantUsers: UserSimplifiedResponse[];
  }
) => {
  return (
    <LabelWithContent
      label="Owner representatives"
      content={
        <div className="space-y-2 py-2">
          <TenantUsersDropdown
            users={props.ownerRepresentatives}
            maximumNumberOfUsers={4}
            minimumNumberOfUsers={0}
            userList={props.tenantUsers}
            onValueChange={(users: number[]) => {
              props.onValueChange(users);
            }}
          />
        </div>
      }
    />
  );
};

const EditRepresentativeField = (
  props: RepresentativeEditConfig & {
    onLoadTenantUser: () => void;
    tenantUsers: UserSimplifiedResponse[];
  }
) => {
  const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(false);
  const [isOwnerRepresentativeEditable, setIsOwnerRepresentativeEditable] =
    useState(false);

  const handleSaveOwnerRepresentativeClick = () => {
    setIsOwnerRepresentativeEditable(false);
    props.onSaveOwnerRepresentative();
  };

  const updateDisableButtonStatus = (status: boolean) => {
    setIsSaveButtonDisable(status);
  };

  const handleEditOwnerRepresentativeClick = () => {
    props.onLoadTenantUser();
    setIsOwnerRepresentativeEditable(true);
  };

  return (
    <LabelWithContent
      label={
        <div className="flex space-x-2">
          <div>Owner representatives</div>
          <div className="-mt-1">
            {props.allowEdit ? (
              isOwnerRepresentativeEditable ? (
                <SaveButton
                  classNames="w-6 h-6"
                  isDisabled={isSaveButtonDisable}
                  onSaveClick={handleSaveOwnerRepresentativeClick}
                />
              ) : (
                <EditButton
                  classNames="w-6 h-6"
                  onEditClick={handleEditOwnerRepresentativeClick}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      }
      content={
        <RepresentativeDropdown
          representatives={props.selectedRepresentatives}
          isEditable={props.allowEdit === true && isOwnerRepresentativeEditable}
          labelClassName={labelClassName}
          representativeIds={props.ownerRepresentativeIds}
          usersByTenant={props.tenantUsers}
          handleRepresentativeChange={props.onOwnerRepresentativeChange}
          updateDisableButtonStatus={updateDisableButtonStatus}
        />
      }
    />
  );
};

export default function OwnerRepresentativeField(props: Props) {
  const [shouldLoadTenantUsers, setShouldLoadTeantUsers] = useState(false);
  const dispatch = useAppDispatch();
  const areUsersLoaded = useRef(false);
  const [usersByTenant, setUsersByTenant] = useState<UserSimplifiedResponse[]>(
    []
  );

  useEffect(() => {
    if (
      props.createConfig ||
      shouldLoadTenantUsers ||
      (props.editConfig && props.editConfig.allowEdit)
    ) {
      if (props.ownerTenantId && !areUsersLoaded.current) {
        areUsersLoaded.current = true;
        displayLoadingPanel();
        dispatch(loadUsersByTenantAsync({ tenantId: props.ownerTenantId }))
          .unwrap()
          .then((userList: UserSimplifiedResponse[]) => {
            setUsersByTenant(userList);
          })
          .finally(hideLoadingPanel);
      }
    }
  }, [props.createConfig, shouldLoadTenantUsers]);

  return props.editStatus === "create" &&
    props.editConfig?.allowEdit &&
    props.createConfig ? (
    <AddRepresentativeField
      {...props.createConfig}
      tenantUsers={usersByTenant}
    />
  ) : props.editConfig ? (
    <EditRepresentativeField
      {...props.editConfig}
      tenantUsers={usersByTenant}
      onLoadTenantUser={() => setShouldLoadTeantUsers(true)}
    />
  ) : (
    <></>
  );
}
