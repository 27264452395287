import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import { ClientTypeEnum } from "shared/enums/ClientTypeEnum";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { removeAutonomousComponentServiceTokenAsync } from "store/actions/AutonomousComponentActions";
import { removeIlapAnalyticsServiceTokenAsync } from "store/actions/IlapAnalyticsActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  serviceToken?: ServiceTokenResponse;
  onClose: () => void;
  clientType: ClientTypeEnum;
}

export default function ServiceTokenDeleteConfirmationDialog(props: Props) {
  const dispatch = useAppDispatch();

  const handleDeleteConfirm = () => {
    if (props.serviceToken) {
      displayLoadingPanel();
      
      const promise = props.clientType === ClientTypeEnum.AutonomousComponent
        ? dispatch(removeAutonomousComponentServiceTokenAsync(props.serviceToken.id))
        : dispatch(removeIlapAnalyticsServiceTokenAsync(props.serviceToken.id))
        
      promise
        .unwrap()
        .then(() => {
          toastSuccess("Service token deleted successfully.");
          props.onClose();
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleCancel = () => {
    props.onClose();
  };

  return props.serviceToken ? (
    <PromineoConfirmationDialog
      onConfirm={handleDeleteConfirm}
      onCancel={handleCancel}
      content={`Are you sure, you want to delete this service token?`}
      cancelButtonText="No"
      confirmButtonText="Yes"
    />
  ) : null;
}
