import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";

interface Props {
  dataSource: DropdownValuesResponse[];
  defaultValue: number;
  onValueChange: (value: any) => void;
}

export default function SapParameterDropdown(props: Props) {
  return (
    <PromineoSelectBox
      dataSource={props.dataSource}
      valueExpr={"value"}
      widthClass="w-auto"
      displayExpr={"name"}
      defaultValue={props.defaultValue}
      onValueChange={props.onValueChange}
    />
  );
}
