import LabelResponse from "interfaces/response/LabelResponse";
import { addNewLabelsAsync, loadLabelsAsync } from "store/actions/LabelActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import AddLabelButton from "./controls/buttons/AddLabelButton";
import Label from "./Label";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import LabelRequest from "interfaces/request/LabelRequest";
import { useEffect, useRef } from "react";
import { displayLoadingPanel, hideLoadingPanel } from "./LoadingPanel";

interface Props {
  labels: LabelResponse[];
  labelType: SupportedLabelEntity;
  allowDelete?: boolean;
  allowAdd?: boolean;
  onDelete?: (label: LabelResponse) => void;
  onAddLabel?: (label: LabelResponse) => void;
}

export default function LabelHolder(props: Props) {
  const dispatch = useAppDispatch();
  const allLabels = useAppSelector((store) => store.labelData.allLabels);
  const isLabelDataLoaded = useRef(false);

  useEffect(() => {
    if(props.allowAdd === true && isLabelDataLoaded.current === false)
    {
      displayLoadingPanel();
      dispatch(loadLabelsAsync(props.labelType)).finally(hideLoadingPanel);
    }
    isLabelDataLoaded.current = true;
  }, []);

  const handleAddNewLabel = (label: string) => {
    const labelRequest: LabelRequest = {
      name: label,
      supportedEntities: props.labelType,
    };

    dispatch(addNewLabelsAsync(labelRequest))
      .unwrap()
      .then((label: LabelResponse) => {
        props.onAddLabel?.(label);
      });
  };

  const handleLabelSelection = (value: any) => {
    if (value && value.length && value[0]) {
      props.onAddLabel?.({ id: value[0].id, name: value[0].name });
    }
  };

  return (
    <div className="flex gap-x-2 gap-y-1 h-6 flex-wrap">
      {props.labels.map((label) => {
        return (
          <Label
            text={label.name}
            allowDelete={props.allowDelete}
            onDelete={() => props.onDelete?.(label)}
            key={label.id}
          />
        );
      })}
      {props.allowAdd && (
        <AddLabelButton
          allLabels={allLabels}
          onSelectionChanged={handleLabelSelection}
          onAddNew={handleAddNewLabel}
          alreadyAddedLabels={props.labels}
          labelType={props.labelType}
        />
      )}
    </div>
  );
}
