export const displayTextFieldName = "DisplayText";

export const getValueFromJsonString = (jsonString: string, key: string) => {
  let obj = safelyParseToJson(jsonString);
  let val = undefined;
  if (obj) {
    val = obj[key];
  }

  return val;
};

export const getJsonStringWithKeyValueChange = (
  jsonString: string,
  keyValueArray: { key: string; value: any }[]
) => {
  let obj = safelyParseToJson(jsonString);
  let newJsonString = jsonString;
  if (obj) {
    for (const item of keyValueArray) {
      obj[item.key] = item.value;
    }
    newJsonString = JSON.stringify(obj);
  }

  return newJsonString;
};

export const getFormatJsonString = (jsonString: any) => {
  let formattedString = jsonString
    .replace(/,/g, `,\r\n`)
    .replace(/{/g, `{\r\n`)
    .replace(/}/g, `\r\n}`);

  return formattedString;
};

export const getJsonStringAfterRemovingNewLine = (jsonString: any) => {
  let formattedString = jsonString.replace(/(\r\n|\n|\r)/g, "");

  return formattedString;
};

export const safelyParseToJson = (jsonString: string) => {
  let obj = null;
  try {
    obj = JSON.parse(jsonString);
  } catch (error) {}

  return obj;
};

export const isValidJsonString = (jsonString: string): boolean => {
  let isValid = true;
  let parsedJson = safelyParseToJson(jsonString);
  if (!parsedJson) isValid = false;

  return isValid;
};
