import FieldMappingFormulaCreateRequest from "interfaces/request/FieldMappingFormulaCreateRequest";
import FieldMappingFormulaFavoriteStatusChangeRequest from "interfaces/request/FieldMappingFormulaFavoriteStatusChangeRequest";
import FieldMappingFormulaUpdateRequest from "interfaces/request/FieldMappingFormulaUpdateRequest";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import {
  deleteDataAsync,
  getDataAsync,
  postDataAsync,
  putDataAsync,
} from "./API";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";

export const getCurrentUserFieldMappingFormulaAsync = async () => {
  // For now, we are only getting the MappingFormulas for
  // Activity, Schedule and ResourceAssignments.
  let apiUrl = `FieldMappingFormula?PlanningObjectTypes=${PlanningObjectTypes.Activity},${PlanningObjectTypes.Schedule},${PlanningObjectTypes.ResourceUsage}`;
  let response = await getDataAsync<FieldMappingFormulaResponse[]>(apiUrl);
  return response;
};

export const createFieldMappingFormulaAsync = async (
  requestDto: FieldMappingFormulaCreateRequest
) => {
  let apiUrl = "FieldMappingFormula";
  let response = await postDataAsync<
    FieldMappingFormulaCreateRequest,
    FieldMappingFormulaResponse
  >(apiUrl, requestDto);
  return response;
};

export const updateFieldMappingFormulaAsync = async (
  fieldMappingFormulaId: number,
  requestDto: FieldMappingFormulaUpdateRequest
) => {
  let apiUrl = `FieldMappingFormula/${fieldMappingFormulaId}`;
  let response = await putDataAsync<
    FieldMappingFormulaUpdateRequest,
    FieldMappingFormulaResponse
  >(apiUrl, requestDto);
  return response;
};

export const deleteFieldMappingFormulaAsync = async (
  fieldMappingFormulaId: number
) => {
  let apiUrl = `FieldMappingFormula/${fieldMappingFormulaId}`;
  let response = await deleteDataAsync(apiUrl);
  return response;
};

export const changeFieldMappingFormulaFavoriteStatusAsync = async (
  fieldMappingFormulaId: number,
  requestDto: FieldMappingFormulaFavoriteStatusChangeRequest
) => {
  let apiUrl = `FieldMappingFormula/Favorite/${fieldMappingFormulaId}`;
  let response = await putDataAsync<
    FieldMappingFormulaFavoriteStatusChangeRequest,
    FieldMappingFormulaResponse
  >(apiUrl, requestDto);
  return response;
};
