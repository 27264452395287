import { DropdownOptions } from "components/common/dropdown-box/PromineoDropdownBox";
import FieldResponse from "interfaces/response/FieldResponse";
import { useEffect, useRef, useState } from "react";
import FieldSelectionDropdown from "./field-selection-dropdown/FieldSelectionDropdown";
import { IDropDownBoxOptions } from "devextreme-react/drop-down-box";

interface Props {
  dataSource: FieldResponse[];
  onAddField: (data: FieldResponse) => void;
  alreadyAddedFieldIds: number[];
}

export default function FieldSelector(props: Props) {
  const [dataSource, setDataSource] = useState(props.dataSource);

  useEffect(() => {
    setDataSource(props.dataSource);
  }, [props.dataSource]);

  const dropdownOptions = useRef<DropdownOptions & IDropDownBoxOptions>({
    valueExpr: "id",
    roundedDirection: "none",
  });

  return (
    <div className="flex items-center w-full">
      <div className="whitespace-nowrap w-auto px-2 border border-lightGray h-6 text-10px rounded-l-lg flex items-center">
        Add fields
      </div>
      <FieldSelectionDropdown
        dataSource={dataSource}
        alreadyAddedFieldIds={props.alreadyAddedFieldIds}
        dropdownOptions={dropdownOptions.current}
        onAdd={props.onAddField}
      />
    </div>
  );
}
