import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import { useState } from "react";
import { getContentConrolInformation } from "shared/utilities/ContentControlUtility";
import FieldValueModal from "./FieldValueModal";

interface Props {
  fieldMapping: FieldMappingResponse;
  isPreviewChange?: boolean;
}

export default function ContentControlCellComponent(props: Props) {
  const [isInfoPopupVisible, setIsInfoPopupVisible] = useState(false);
  const fieldMapping = props.fieldMapping;

  const handleContentControlClicked = () => {
    if (fieldMapping.allowContentControl) {
      setIsInfoPopupVisible(true);
    }
  };

  const displayText = getContentConrolInformation({
    contentControlText: fieldMapping.contentControlLevelText,
    controlledOnText: fieldMapping.contentControlLevelText,
    allowBlank: fieldMapping.allowBlank,
    allowContentControl: fieldMapping.allowContentControl,
  });

  return (
    <>
      {displayText && fieldMapping.isConfigTemplateFieldMapping ? (
        <div
          className={`${
            fieldMapping.allowContentControl ? "underline cursor-pointer" : ""
          }`}
          onClick={handleContentControlClicked}
        >
          {displayText}
        </div>
      ) : (
        <div>-</div>
      )}
      {isInfoPopupVisible && (
        <FieldValueModal
          fieldName={fieldMapping.name}
          allowBlank={fieldMapping.allowBlank}
          values={fieldMapping.values}
          onClose={() => {
            setIsInfoPopupVisible(false);
          }}
        />
      )}
    </>
  );
}
