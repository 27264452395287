import { IDropDownBoxOptions } from "devextreme-react/drop-down-box";
import { ITreeViewOptions } from "devextreme-react/tree-view";
import { useCallback, useEffect, useRef, useState } from "react";
import PromineoDropdownBox, {
  DropdownOptions,
} from "../dropdown-box/PromineoDropdownBox";
import PromineoTreeView, {
  TreeViewOptions,
} from "../tree-view/PromineoTreeView";
import { isArray, isEqual } from "lodash";
import "./styles/PromineoTreeViewDropdown.css";

interface Props {
  dataSource: any[];
  dropdownOptions: DropdownOptions & IDropDownBoxOptions;
  popupWidth?: number;
  treeViewOptions: TreeViewOptions & ITreeViewOptions;
  onSelectionChanged: ([]) => void;
  shouldClosePopupOnSelect?: boolean;
  checkIfIsSelectable?: (data: any) => boolean;
  preventTogggleSelection?: boolean;
}

export default function PromineoTreeViewDropdown(props: Props) {
  const selections = useRef<any>();
  const {
    dataSource,
    dropdownOptions,
    treeViewOptions,
    preventTogggleSelection,
  } = props;
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  useEffect(() => {
    selections.current = dropdownOptions.value;

    const applyInitialSelectionAndExpansion = () => {
      const selectAndExpandItem = (item: any) => {
        item.expanded = true;
        item.selected = true;
      };

      dataSource.forEach((src) => {
        if (
          treeViewOptions.keyExpr &&
          typeof treeViewOptions.keyExpr === "string" // Currently, we only have string keyExpr.
        ) {
          const dataSourceKeyValue = src[treeViewOptions.keyExpr];
          if (isArray(selections.current)) {
            if (selections.current.indexOf(dataSourceKeyValue) >= 0) {
              selectAndExpandItem(src);
            }
          } else if (dataSourceKeyValue === selections.current) {
            selectAndExpandItem(src);
          }
        }
      });
    };

    if (selections.current && dataSource) {
      applyInitialSelectionAndExpansion();
    }
  }, [dropdownOptions.value, dataSource]);

  const handleItemSelection = (e: any) => {
    if (props.checkIfIsSelectable && !props.checkIfIsSelectable(e.itemData)) {
      return;
    }

    if (
      !props.checkIfIsSelectable ||
      (props.checkIfIsSelectable && props.checkIfIsSelectable(e.itemData))
    ) {
      var selectedNodes = e.component.getSelectedNodeKeys();

      // This check is to prevent toggle selection.
      // Previously, if we selected an already selected item, the selection would get cleared.
      // That is the default behavior of the DevExtreme TreeView.
      if (preventTogggleSelection === true) {
        if (
          !selectedNodes.length ||
          isEqual(selections.current, selectedNodes)
        ) {
          return;
        }
      }

      props.onSelectionChanged(selectedNodes);
      selections.current = selectedNodes;
      if (props.shouldClosePopupOnSelect) {
        setIsDropdownOpened(false);
      }
    }
  };

  const getTreeview = useCallback(() => {
    return (
      <PromineoTreeView
        {...treeViewOptions}
        dataSource={dataSource}
        className="p-2"
        onItemSelectionChanged={handleItemSelection}
      />
    );
  }, [props.dataSource]);

  const treeBoxOpend = useCallback((evt: any) => {
    if (evt.name === "opened") {
      setIsDropdownOpened(evt.value);
    }
  }, []);

  const dropdownDefaultWidthOption = useRef({
    width: props.popupWidth,
  });

  return (
    <>
      <PromineoDropdownBox
        {...dropdownOptions}
        dropDownOptions={dropdownDefaultWidthOption.current}
        opened={isDropdownOpened}
        dataSource={dataSource}
        className={`promineo-tree-view-dropdown`}
        contentRender={getTreeview}
        onOptionChanged={treeBoxOpend}
      />
    </>
  );
}
