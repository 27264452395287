import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ValueMap from "interfaces/common/ValueMap";

interface Props {
  valueMaps: ValueMap[];
  height: number;
}

export default function BulkInsertValueMapPreviewGrid(props: Props) {
  return (
    <div>
      <PromineoViewGrid dataSource={props.valueMaps} height={props.height}>
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column dataField={"templateValue"} caption="Template value" />
        <Column dataField={"hostValue"} caption="Host value" />
        <Column dataField={"hostValueDescription"} caption="Comment" />
      </PromineoViewGrid>
    </div>
  );
}
