import { useEffect } from "react";
import ContentControlGrid from "./ContentControlGrid";
import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getContentControlFieldsForTenantAdminAsync } from "store/actions/TenantAdminActions";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { loadContentControlValuesAsync } from "store/actions/DropdownValueActions";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function ContentControl() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(getContentControlFieldsForTenantAdminAsync()).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    // content control field options required to display dropdowns inside grid.
    displayLoadingPanel();
    dispatch(loadContentControlValuesAsync()).finally(hideLoadingPanel);
  }, []);

  const contentControlFields = useAppSelector((store) => store.tenantAdminData.fields);

  const headerDivId: string = "content-control-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  return (
    <div>
      <div className="flex mb-4" id={headerDivId}>
        <PageHeaderTitle title={"Content control"} />
      </div>
      {contentControlFields ? (
        <ContentControlGrid fields={contentControlFields} height={gridHeight} />
      ) : (
        <></>
      )}
    </div>
  );
}
