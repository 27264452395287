import React from "react";

interface Props {
  children: React.ReactElement;
}

// Only for unauthorized user.
export default function PublicOnlyRouteGuard(props: Props) {
  return <div className="text-center">{props.children}</div>;
}
