import { ActivityFilter } from "interfaces/common/ActivityFilter";

export const msProjectActivityFilterList: ActivityFilter[] = [
  {
    title: `Filter on a Flag field (Flag1 in this case)`,
    example: `(Activity["MSP_Act_Flag1"]=True)`,
  },
  {
    title: `Filter on a Text field (Text1 in this case)`,
    example: `(Activity["MSP_Act_Text1"]="X")`,
  },
  {
    title: `Filter on a OutlineCode field (OutlineCode1 in this case)`,
    example: `(Activity["MSP_Act_OutlineCode1"]="X")`,
  },
  {
    title: `Filter on a Start field (Start1 in this case)`,
    example: `(Activity["MSP_Act_Start1"]>[2024-01-30])`,
  },
  {
    title: `Filter on a Finish field (Finish1 in this case)`,
    example: `(Activity["MSP_Act_Finish1"]>DateTime.Today)`,
  },
  {
    title: `Filter on a Number field (Number1 in this case)`,
    example: `(Activity["MSP_Act_Number1"]>10)`,
  },
  {
    title: `Filter on a Duration field (Duration1 in this case)`,
    example: `(Activity["MSP_Act_Duration1"]>10)`,
  },
  {
    title: `Filter on a Milestone field`,
    example: `(Activity["MSP_Act_Milestone"]=True)`,
  },
  {
    title: `Filter on a RemainingWork field`,
    example: `(Activity["MSP_Act_RemainingWork"]>10)`,
  },
];
