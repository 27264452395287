import BulkValueInsertDialog from "features/common/bulk-value-insert-dialog/BulkValueInsertDialog";
import BulkInsertValueMapPreviewGrid from "features/common/bulk-value-insert-dialog/bulk-editor-value-preview/BulkInsertValueMapPreviewGrid";
import ValueMap from "interfaces/common/ValueMap";
import { useAppDispatch } from "store/hooks";
import { deepCopyObject } from "shared/utilities/CommonUtility";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { FieldValueBulkEditorInputHeight } from "shared/constants/GridConstants";
import { validateAndPrepareBulkValueMaps } from "store/actions/BulkInsertUIAction";
import { resetBulkInsertUIState, setBulkEditorMode, setUserInput } from "store/slices/BulkInsertUISlice";
import BulkValueMapInsertDialogHeader from "features/common/bulk-value-insert-dialog/bulk-value-insert-dialog-header/BulkValueMapInsertDialogHeader";
import { useEffect } from "react";

interface Props {
  onCancel: () => void;
  onSubmit: (newValueMapsToInsert: ValueMap[]) => void;
}

export default function BulkValueMapInsertDialog(props: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetBulkInsertUIState());
  }, []);

  const editorMode = useSelector((store: RootState) => store.bulkInsertUIData.bulkEditorMode);

  const userInput = useSelector((store: RootState) => store.bulkInsertUIData.userInputText);

  const validValueMaps = useSelector((store: RootState) => store.bulkInsertUIData.validValueMaps);

  const validationErrors = useSelector(
    (store: RootState) => store.bulkInsertUIData.validationErrors
  );

  const handleEditMode = () => {
    dispatch(setBulkEditorMode("edit"));
  };

  const handlePreviewMode = () => {
    dispatch(setBulkEditorMode("preview"));
  };

  const handleSubmit = () => {
    dispatch(validateAndPrepareBulkValueMaps())
      .unwrap()
      .then(({ errors, valueMaps }) => {
        errors.length === 0
          ? props.onSubmit(deepCopyObject(valueMaps))
          : dispatch(setBulkEditorMode("preview"));
      });
  };

  const onUserInputChange = (value: string) => {
    dispatch(setUserInput(value));
    dispatch(validateAndPrepareBulkValueMaps());
  };

  const GridPreviewComponent = () => {
    return (
      <BulkInsertValueMapPreviewGrid
        valueMaps={validValueMaps}
        height={FieldValueBulkEditorInputHeight}
      />
    );
  };

  return (
    <BulkValueInsertDialog
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      onEditModeSet={handleEditMode}
      onPreviewModeSet={handlePreviewMode}
      userInput={userInput}
      onUserInputChange={onUserInputChange}
      editorMode={editorMode}
      validationErrors={validationErrors}
      headerComponent={<BulkValueMapInsertDialogHeader />}
      gridPreviewComponent={<GridPreviewComponent />}
    />
  );
}
