import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import TemplateRequest from "interfaces/request/TemplateRequest";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import {
  loadTemplateByIdAsync,
  modifyTemplateAsync,
} from "store/actions/TemplateActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import TemplateEditor from "./TemplateEditor";
import { toastSuccess } from "shared/utilities/ToastUtility";
import TemplateResponse from "interfaces/response/TemplateResponse";

export default function EditTemplate() {
  const param = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadTemplateByIdAsync(Number(param.id))).finally(hideLoadingPanel);
  }, []);

  const template = useAppSelector(
    (state) => state.templateData.currentTemplate
  );

  const handleSaveChangesClick = (templateRequest: TemplateRequest) => {
    if (templateRequest && template) {
      displayLoadingPanel();
      dispatch(
        modifyTemplateAsync({
          templateId: template.id,
          template: templateRequest,
        })
      )
        .unwrap()
        .then((response: TemplateResponse) => {
          toastSuccess("Template updated successfully.");
          navigate(`${TEMPLATES}/${response.id}`);
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  return template ? (
    <TemplateEditor
      template={template}
      onSave={handleSaveChangesClick}
      onCancel={handleCancelClick}
    />
  ) : (
    <></>
  );
}
