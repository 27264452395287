import BulkValueInsertDialog from "features/common/bulk-value-insert-dialog/BulkValueInsertDialog";
import BulkInsertFieldValuePreviewGrid from "features/common/bulk-value-insert-dialog/bulk-editor-value-preview/BulkInsertFieldValuePreviewGrid";
import BulkFieldValueInsertDialogHeader from "features/common/bulk-value-insert-dialog/bulk-value-insert-dialog-header/BulkFieldValueInsertDialogHeader";
import FieldValue from "interfaces/common/FieldValue";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FieldValueBulkEditorInputHeight } from "shared/constants/GridConstants";
import { deepCopyObject } from "shared/utilities/CommonUtility";
import { validateAndPrepareBulkFieldValues } from "store/actions/BulkInsertUIAction";
import { useAppDispatch } from "store/hooks";
import {
  resetBulkInsertUIState,
  setBulkEditorMode,
  setUserInput,
} from "store/slices/BulkInsertUISlice";
import { RootState } from "store/store";

interface Props {
  onCancel: () => void;
  onSubmit: (newValueMapsToInsert: FieldValue[]) => void;
}

export default function BulkFieldValueInsertDialog(props: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetBulkInsertUIState());
  }, []);

  const editorMode = useSelector(
    (store: RootState) => store.bulkInsertUIData.bulkEditorMode
  );

  const userInput = useSelector(
    (store: RootState) => store.bulkInsertUIData.userInputText
  );

  const validFieldValues = useSelector(
    (store: RootState) => store.bulkInsertUIData.validFieldValues
  );

  const validationErrors = useSelector(
    (store: RootState) => store.bulkInsertUIData.validationErrors
  );

  const handleEditMode = () => {
    dispatch(setBulkEditorMode("edit"));
  };

  const handlePreviewMode = () => {
    dispatch(setBulkEditorMode("preview"));
  };

  const handleSubmit = () => {
    dispatch(validateAndPrepareBulkFieldValues())
      .unwrap()
      .then(({ errors, fieldValues }) => {
        errors.length === 0
          ? props.onSubmit(deepCopyObject(fieldValues))
          : dispatch(setBulkEditorMode("preview"));
      });
  };

  const onUserInputChange = (value: string) => {
    dispatch(setUserInput(value));
    dispatch(validateAndPrepareBulkFieldValues());
  };

  const GridPreviewComponent = () => {
    return (
      <BulkInsertFieldValuePreviewGrid
        fieldValues={validFieldValues}
        height={FieldValueBulkEditorInputHeight}
      />
    );
  };

  return (
    <BulkValueInsertDialog
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      onEditModeSet={handleEditMode}
      onPreviewModeSet={handlePreviewMode}
      userInput={userInput}
      onUserInputChange={onUserInputChange}
      editorMode={editorMode}
      validationErrors={validationErrors}
      headerComponent={<BulkFieldValueInsertDialogHeader />}
      gridPreviewComponent={<GridPreviewComponent />}
    />
  );
}
