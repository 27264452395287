import TenantResponse from "interfaces/response/TenantResponse";
import {
  deleteDataAsync,
  getDataAsync,
  postDataAsync,
  putDataAsync,
} from "./API";
import TenantSimplifiedResponse from "interfaces/response/TenantSimplifiedResponse";
import TenantDetailResponse from "interfaces/common/TenantDetailResponse";
import SystemLevelOwnerStatusChangeRequest from "interfaces/request/SystemLevelOwnerStatusChangeRequest";

export const getSimplifiedTenantsAsync = async (excludeOwn: boolean) => {
  let apiUrl = "Tenants/Simplified";

  if (excludeOwn) {
    apiUrl = apiUrl + "?excludeOwn=true";
  }

  return await getDataAsync<TenantSimplifiedResponse[]>(apiUrl);
};

export const GetAllTenantsAsync = async () => {
  let apiUrl = "Tenants";

  return await getDataAsync<TenantResponse[]>(apiUrl);
};

export const GetTenantByIdAsync = async (tenantId: number) => {
  let apiUrl = `Tenants/${tenantId}`;

  return await getDataAsync<TenantDetailResponse>(apiUrl);
};

export const DeleteTenantByIdAsync = async (tenantId: number) => {
  let apiUrl = `Tenants/${tenantId}`;

  await deleteDataAsync(apiUrl);
  return tenantId;
};

export const UpdateTenantOwnerStatusAtSystemLevelAsync = async (
  tenantId: number,
  tenantOwnerStatusChangeRequest: SystemLevelOwnerStatusChangeRequest
) => {
  let apiUrl = `Tenants/${tenantId}/SystemLevel`;
  let response = await putDataAsync<
    SystemLevelOwnerStatusChangeRequest,
    TenantResponse
  >(apiUrl, tenantOwnerStatusChangeRequest);

  return response;
};

export const createTenantRequestAsync = async () => {
  let apiUrl = "Tenants/Request";

  return await postDataAsync(apiUrl, null, false);
};

export const createAcceptTenantRequestAsync = async (tenantId: number) => {
  let apiUrl = `Tenants/Request/${tenantId}/Accept`;

  return await postDataAsync(apiUrl, null);
};

export const createRejectTenantRequestAsync = async (tenantId: number) => {
  let apiUrl = `Tenants/Request/${tenantId}/Reject`;

  return await postDataAsync(apiUrl, null);
};
