import LabelWithContent from "components/common/LabelWithContent";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";
import HostSystemConfigurationDropdown from "features/setup/configs/host-system/HostSystemConfigurationDropdown";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";

interface Props extends BaseHostSystemComponentProps {
  dropdownOptions: DropdownValuesResponse[];
  selectedValue: number;
}

export default function ExportScopeField(props: Props) {
  const {
    mode,
    dropdownOptions,
    fieldName,
    selectedValue,
    handleConfigurationValueChanged,
  } = props;

  return (
    <LabelWithContent
      label="Export Scope"
      content={
        <HostSystemConfigurationDropdown
          mode={mode}
          dropdownOptions={dropdownOptions}
          fieldName={fieldName}
          selectedValue={selectedValue}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      }
    />
  );
}
