import PromineoTextBox from "components/common/controls/PromineoTextBox";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import MicrosoftProjectConnectorConfiguration from "interfaces/response/connector/MicrosoftProjectConnectorConfiguration";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";

interface Props {
  configuration: MicrosoftProjectConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function MicrosoftProjectConnectorInformation(props: Props) {
  const { configuration, handleConfigurationValueChanged } = props;

  const isEdit =
    props.mode === PromineoModalMode.Modify ||
    props.mode === PromineoModalMode.Create;

  const handleValueChanged = (evt: any) => {
    handleConfigurationValueChanged?.(
      evt.event.currentTarget.name,
      evt.event.currentTarget.value
    );
  };

  const handleAllFilesHaveSameUserFieldSwitchValueChange = () => {
    handleConfigurationValueChanged?.(
      "allFilesHaveSameUserFields",
      !configuration.allFilesHaveSameUserFields
    );
  };

  return (
    <div>
      <div
        className={
          props.mode === PromineoModalMode.View ||
          props.mode === PromineoModalMode.Modify
            ? "grid grid-cols-2 gap-x-60"
            : ""
        }
      >
        <ConnectorLabelWithContent
          mode={props.mode}
          addAsterisksymbol={true}
          label="Sharepoint URL"
          content={
            isEdit ? (
              <PromineoTextBox
                width={props.mode === PromineoModalMode.Create ? 330 : 188}
                name="sharePointUrl"
                defaultValue={configuration.sharePointUrl}
                onValueChanged={handleValueChanged}
              />
            ) : (
              <>{configuration.sharePointUrl ?? "-"}</>
            )
          }
        />

        <ConnectorLabelWithContent
          mode={props.mode}
          addAsterisksymbol={true}
          label="SharePoint ClientId"
          content={
            isEdit ? (
              <PromineoTextBox
                width={props.mode === PromineoModalMode.Create ? 330 : 188}
                name="sharePointClientId"
                defaultValue={configuration.sharePointClientId}
                onValueChanged={handleValueChanged}
              />
            ) : (
              <>
                {configuration.sharePointClientId
                  ? configuration.sharePointClientId
                  : "-"}
              </>
            )
          }
        />

        <ConnectorLabelWithContent
          mode={props.mode}
          addAsterisksymbol={true}
          label="SharePoint TenantId"
          content={
            isEdit ? (
              <PromineoTextBox
                width={props.mode === PromineoModalMode.Create ? 330 : 188}
                name="sharePointTenantId"
                defaultValue={configuration.sharePointTenantId}
                onValueChanged={handleValueChanged}
              />
            ) : (
              <>{configuration.sharePointTenantId ?? "-"}</>
            )
          }
        />

        {/* No use case for now */}
        {/* <ConnectorLabelWithContent
          mode={props.mode}
          label="All files have same user fields"
          content={
            isEdit ? (
              <PromineoSwitchButton
                switchType={SwitchButtonType.Default}
                checked={configuration.allFilesHaveSameUserFields}
                onChange={handleAllFilesHaveSameUserFieldSwitchValueChange}
              />
            ) : (
              <>{configuration.allFilesHaveSameUserFields ? "Yes" : "No"}</>
            )
          }
        /> */}
      </div>
    </div>
  );
}
