import LabelWithContent from "components/common/LabelWithContent";
import { ScrollView } from "devextreme-react";
import MicrosoftProjectExportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectExportConfig";
import { useDispatch } from "react-redux";
import { EditorMode } from "shared/enums/EditorMode";
import { AppDispatch } from "store/store";
import HostSystemConfigurationSwitchButton from "../HostSystemConfigurationSwitchButton";
import WbsComponent from "../safran/WbsComponent";
import { useCallback, useEffect, useState } from "react";
import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadWbsStructureCandidatesAsync } from "store/actions/HostSystemActions";

interface Props {
  mode: EditorMode;
  exportConfig: MicrosoftProjectExportConfig;
  height: number;
  selectedConnectorId: number;
  selectedCodeSet: number | null;
  isOverride?: boolean;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

const includeSummaryTaskPropertyName = "includeSummaryTask";
const wbsStructureExportFieldNamePropertyName = "wbsStructureExportFieldName";

export default function MicrosoftProjectSendingParameters(props: Props) {
  const {
    mode,
    isOverride,
    exportConfig,
    height,
    selectedConnectorId,
    selectedCodeSet,
    handleConfigurationValueChanged,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const [wbsStructureCandidates, setWbsStructureCandidates] = useState<
    HostFieldDropdownValueResponse[]
  >([]);

  useEffect(() => {
    if (exportConfig) {
      if (selectedCodeSet) {
        displayLoadingPanel();
        dispatch(
          loadWbsStructureCandidatesAsync({
            connectorId: selectedConnectorId,
            codeSetId: selectedCodeSet,
          })
        )
          .unwrap()
          .then((wbsCandidateList: HostFieldDropdownValueResponse[]) => {
            setWbsStructureCandidates(wbsCandidateList);
          })
          .finally(hideLoadingPanel);
      }
    }
  }, [dispatch, exportConfig, selectedConnectorId, selectedCodeSet]);

  const Fields = useCallback(() => {
    return (
      <>
        <LabelWithContent
          label="Include summary task(s)"
          content={
            <HostSystemConfigurationSwitchButton
              mode={mode}
              fieldName={includeSummaryTaskPropertyName}
              switchButtonValue={exportConfig.includeSummaryTask}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
            />
          }
        />
        <LabelWithContent
          label="WBS"
          content={
            <WbsComponent
              mode={mode}
              fieldName={wbsStructureExportFieldNamePropertyName}
              wbsCandidates={wbsStructureCandidates}
              wbsValue={exportConfig.wbsStructureExportFieldName}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
            />
          }
        />
      </>
    );
  }, [
    exportConfig.includeSummaryTask,
    exportConfig.wbsStructureExportFieldName,
    mode,
  ]);

  if (!exportConfig) {
    return <></>;
  }

  return !isOverride ? (
    <ScrollView showScrollbar="always" height={height}>
      <div className="flex space-x-6">
        <div className="w-60 font-semibold text-base text-textGray">
          MSProject Sending Parameters
        </div>
        <div className="space-y-6">
          <Fields />
        </div>
      </div>
    </ScrollView>
  ) : (
    <div className="grid grid-cols-3 gap-x-4 gap-y-8">
      <Fields />
    </div>
  );
}
