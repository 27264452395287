import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import TemplateRequest from "interfaces/request/TemplateRequest";
import DetailedTemplateResponse from "interfaces/response/DetailedTemplateResponse";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import { toastSuccess } from "shared/utilities/ToastUtility";
import {
  addNewTemplateAsync,
  loadTemplateByIdAsync,
} from "store/actions/TemplateActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import TemplateEditor from "./TemplateEditor";

export default function CopyTemplate() {
  const param = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const templateToCopy = useAppSelector((state) => {
    const currentTemplate = state.templateData.currentTemplate;
    if (currentTemplate != null) {
      const copiedTemplateData = {
        fieldCount: currentTemplate.fieldCount,
        fields: currentTemplate.fields.map((f) => ({
          ...f,
          id: 0,
          valueCount: 0,
        })),
        labels: currentTemplate.labels,
        ownConfigsCount: 0,
        shareCount: 0,
        title: `Copy of ${currentTemplate.title}`,
        description: currentTemplate.description,
        id: 0,
        // We don't display the tenant and we don't know the tenant name here. The owner tenant should be current user's tenant
        ownerTenant: "",
      };

      return copiedTemplateData;
    }

    return null;
  });

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadTemplateByIdAsync(Number(param.id))).finally(hideLoadingPanel);
  }, []);

  const handleSaveChangesClick = (templateRequest: TemplateRequest) => {
    if (templateToCopy && templateRequest) {
      displayLoadingPanel();
      dispatch(addNewTemplateAsync(templateRequest))
        .unwrap()
        .then((newTemplate: DetailedTemplateResponse) => {
          toastSuccess("Template copied successfully.");
          navigate(`${TEMPLATES}/${newTemplate.id}`);
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  return templateToCopy ? (
    <TemplateEditor
      copyOrNew={true}
      template={templateToCopy}
      onSave={handleSaveChangesClick}
      onCancel={handleCancelClick}
    />
  ) : (
    <></>
  );
}
