import { useEffect, useState } from "react";
import TemplateUpdatePreviewModal from "../template-update/TemplateUpdatePreviewModal";
import ConfigUpdatePreviewDueToFieldUpdateModal from "../template-update/ConfigUpdatePreviewDueToFieldUpdateModal";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import { useAppDispatch, useAppSelector } from "store/hooks";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadTemplateChangesPreviewAsync } from "store/actions/ConfigActions";
import { GetNewId, deepCopyObject } from "shared/utilities/CommonUtility";
import { resetTemplateChangesPreview } from "store/slices/ConfigSlice";
import FieldValueMapResponse from "interfaces/response/FieldValueMapResponse";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import FieldMappingWithSummaryResponse from "interfaces/response/FieldMappingWithSummaryResponse";
import { loadConnectorDetailsAsync } from "store/actions/ConnectorActions";
import { resetSelectedConnector } from "store/slices/ConnectorSlice";

interface Props {
  onCancel: () => void;
  config: ConfigDetailResponse;
  selectedCodeSet: number | null;
}

export default function TemplateChangeViewer(props: Props) {
  const [displayConfigFieldUpdatePreview, setDisplayConfgFieldUpdatePreview] =
    useState(false);

  const dispatch = useAppDispatch();
  const [addedFieldsMappings, setAddedFieldsMappings] = useState<
    FieldMappingResponse[]
  >([]);

  const [updatedFieldMappings, setUpdatedFieldMappings] = useState<
    FieldMappingResponse[]
  >([]);

  const templateChanges = useAppSelector(
    (store) => store.configData.templateChangesPreview
  );

  useEffect(() => {
    if (props.config && props.config.id) {
      displayLoadingPanel();
      dispatch(loadTemplateChangesPreviewAsync(props.config.id)).finally(hideLoadingPanel);
      displayLoadingPanel();
      dispatch(loadConnectorDetailsAsync(props.config.connectorId)).finally(hideLoadingPanel);
    }
    return () => {
      dispatch(resetTemplateChangesPreview());
      dispatch(resetSelectedConnector());
    };
  }, []);

  const getFieldMappingResponseFromFieldDetailResponse = (
    fieldChangeResponse: FieldDetailsResponse
  ): FieldMappingResponse => {
    let fieldMappingResponse: FieldMappingResponse = {
      id: 0,
      _key_: GetNewId(),
      name: fieldChangeResponse.name,
      description: fieldChangeResponse.description,
      dataType: fieldChangeResponse.dataType,
      planningObjectType: fieldChangeResponse.planningObjectType,
      dataTypeText: fieldChangeResponse.dataTypeText,
      configId: props.config.id,
      hasDirectMapping: true,
      allowContentControl: fieldChangeResponse.allowContentControl ?? false,
      allowBlank: fieldChangeResponse.allowBlank ?? false,
      planningObjectTypeText: fieldChangeResponse.planningObjectTypeText,
      mappedConnectorHostFieldId: null,
      mappedConnectorHostFieldName: "",
      formula: "",
      fieldId: fieldChangeResponse.id,
      contentControlLevel: fieldChangeResponse.contentControlLevel,
      isConfigTemplateFieldMapping: true,
      isCoreFieldMapping: false,
      contentControlLevelText: fieldChangeResponse.contentControlLevelText ?? "",
      valueCount: fieldChangeResponse.valueCount,
      valueMaps: [],
      values: deepCopyObject(fieldChangeResponse.values),
    };

    return fieldMappingResponse;
  };

  const getFieldMappingResponseFromFieldMappingWithSummeryResponse =(mappingSummeryResponse: FieldMappingWithSummaryResponse)=>{
    let fieldMappingResponse: FieldMappingResponse = {
      id: mappingSummeryResponse.id,
      _key_: GetNewId(),
      name: mappingSummeryResponse.name,
      description: mappingSummeryResponse.description,
      dataType: mappingSummeryResponse.dataType,
      planningObjectType: mappingSummeryResponse.planningObjectType,
      dataTypeText: mappingSummeryResponse.dataTypeText,
      configId: mappingSummeryResponse.configId,
      hasDirectMapping: mappingSummeryResponse.hasDirectMapping,
      allowContentControl: mappingSummeryResponse.allowContentControl,
      allowBlank: mappingSummeryResponse.allowBlank,
      planningObjectTypeText: mappingSummeryResponse.planningObjectTypeText,
      mappedConnectorHostFieldId: mappingSummeryResponse.mappedConnectorHostFieldId,
      mappedConnectorHostFieldName: mappingSummeryResponse.mappedConnectorHostFieldName,
      formula: mappingSummeryResponse.formula,
      fieldId: mappingSummeryResponse.fieldId,
      contentControlLevel: mappingSummeryResponse.contentControlLevel,
      isConfigTemplateFieldMapping: mappingSummeryResponse.isConfigTemplateFieldMapping,
      isCoreFieldMapping: mappingSummeryResponse.isCoreFieldMapping,
      contentControlLevelText: mappingSummeryResponse.contentControlLevelText,
      valueCount: mappingSummeryResponse.valueCount,
      valueMaps: deepCopyObject(mappingSummeryResponse.valueMaps),
      values: deepCopyObject(mappingSummeryResponse.values),
    };

    if (!fieldMappingResponse.hasDirectMapping) {

      var usedValueIdList = fieldMappingResponse.valueMaps
        .filter((vm) => !!vm.fieldValueId)
        .map((vm) => vm.fieldValueId as number);

        fieldMappingResponse.values.forEach((v) => {
        var found = usedValueIdList.find((id) => id === v.id);

        if (!found) {
          let newValueMap = {
            fieldValueId: v.id,
            fieldValueText: v.code,
            fieldValueDescription: v.description,
          } as FieldValueMapResponse;
          fieldMappingResponse.valueMaps.push(newValueMap);
        }
      });
    }

    return fieldMappingResponse;
  }

  useEffect(() => {
    const addedFields = (templateChanges?.addedFields || []).map(
      (fieldChangeResponse) => {
        const addedField = getFieldMappingResponseFromFieldDetailResponse(fieldChangeResponse);
        return addedField;
      }
    );

    const updatedFields = (templateChanges?.updatedFields || []).map(
      (fieldChangeResponse) => {
        var fielMappingResponse = getFieldMappingResponseFromFieldMappingWithSummeryResponse(fieldChangeResponse);
        return fielMappingResponse;
      }
    );

    setAddedFieldsMappings(addedFields);
    setUpdatedFieldMappings(updatedFields);
  }, [templateChanges]);

  const handleViewConfigFieldUpdatePreviewClick = () => {
    setDisplayConfgFieldUpdatePreview(true);
  };

  const handleHideConfigFieldUpdatePreviewClick = (
    isUpdateConfigSuccess?: boolean
  ) => {
    setDisplayConfgFieldUpdatePreview(false);
    if (isUpdateConfigSuccess) {
      props.onCancel();
    }
  };

  return (
    <>
      <TemplateUpdatePreviewModal
        onCancel={props.onCancel}
        onProceed={handleViewConfigFieldUpdatePreviewClick}
        configId={props.config.id}
        templateChanges={templateChanges}
      />
      {displayConfigFieldUpdatePreview && (
        <ConfigUpdatePreviewDueToFieldUpdateModal
          config={props.config}
          configDirection={props.config.direction}
          selectedCodeSet={props.selectedCodeSet}
          onClose={handleHideConfigFieldUpdatePreviewClick}
          addedFieldMappings={addedFieldsMappings}
          updatedFieldMappings={updatedFieldMappings}
        />
      )}
    </>
  );
}
