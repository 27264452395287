import LabelHolder from "components/common/LabelHolder";
import PageHeader, {
  PageOption,
} from "components/common/page-header/PageHeader";
import ExchangeAgreementHeaderData from "interfaces/response/ExchangeAgreementHeaderData";
import LabelResponse from "interfaces/response/LabelResponse";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import {
  activityFilterFieldName,
  exportFilterTypeFieldName,
  operationFilterFieldName,
  orderFilterFieldName,
} from "shared/constants/IEAFieldNameConstants";
import {
  FunctionsParentName,
  OperatorsParentName,
} from "shared/constants/SapOrderFilter";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { SapExportFilterType } from "shared/enums/feature/sap-host-system/SapExportFilterType";
import {
  getJsonStringWithKeyValueChange,
  getValueFromJsonString,
} from "shared/utilities/JsonUtility";
import ActivityFilterLabel from "./ActivityFilterLabel";
import IEAActivityFilterDialog from "./iea-activity-filter-dialog/IEAActivityFilterDialog";
import SapActivityFilterDialog from "./iea-activity-filter-dialog/sap/SapActivityFilterDialog";

interface Props {
  senderHostSystem: HostSystem | undefined;
  isOwner: boolean;
  isPartner: boolean;
  isActivityFilterEditable: boolean;
  titleWidget?: ReactElement;
  headerData?: ExchangeAgreementHeaderData | null;
  ieaFrequencyType: FrequencyType;
  onDeleteLabel: (label: LabelResponse) => void;
  onAddNewLabel: (label: LabelResponse) => void;
  onSaveActivityFilterClick: (newFilter: string) => void;
  pageOptions?: PageOption;
  onTitleChange?: (title: string) => void;
  allowEdit?: boolean;
}

export default function IEAHeader(props: Props) {
  const {
    isOwner,
    senderHostSystem,
    titleWidget,
    headerData,
    ieaFrequencyType,
    isActivityFilterEditable,
    allowEdit,
    onAddNewLabel,
    onDeleteLabel,
    onSaveActivityFilterClick,
    onTitleChange,
  } = props;

  const [isLabelEditable, setIsLabelEditable] = useState<boolean>(
    props.allowEdit ?? false
  );
  const [showActivityFilterDialog, setShowActivityFilterDialog] =
    useState<boolean>(false);
  const [activityFilterValue, setActivityFilterValue] = useState<string>("");

  const [orderFilterValue, setOrderFilterValue] = useState<string>("");
  const [operationFilterValue, setOperationFilterValue] = useState<string>("");
  const [isPredecessorIEAFilterSelected, setIsPredecessorIEAFilterSelected] =
    useState<boolean>(false);
  const [showSapFilterDialog, setShowSapFilterDialog] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLabelEditable(allowEdit ?? false);
  }, [allowEdit]);

  const setSapFilterValuesFromConfig = () => {
    if (headerData && headerData.senderConfigSettings) {
      var orderFilter = getValueFromJsonString(
        headerData.senderConfigSettings,
        orderFilterFieldName
      );
      var operationFilter = getValueFromJsonString(
        headerData.senderConfigSettings,
        operationFilterFieldName
      );
      var exportFilterType = getValueFromJsonString(
        headerData.senderConfigSettings,
        exportFilterTypeFieldName
      );

      setOrderFilterValue(orderFilter);
      setOperationFilterValue(operationFilter);
      setIsPredecessorIEAFilterSelected(
        exportFilterType === SapExportFilterType.OrderFilter ? false : true
      );
    }
  };

  useEffect(() => {
    if (headerData && headerData.senderConfigSettings) {
      if (senderHostSystem === HostSystem.SAP) {
        setSapFilterValuesFromConfig();
      } else {
        var filterValue = getValueFromJsonString(
          headerData.senderConfigSettings,
          activityFilterFieldName
        );
        if (filterValue !== undefined) {
          setActivityFilterValue(filterValue);
        }
      }
    }
  }, [headerData?.senderConfigSettings, headerData]);

  // The activity filter for sap is different from the activity filters of other host systems
  // We have conditionally shown the different activity filers based on the host system in the iea header

  const initialWidgets = useMemo(() => {
    return [
      <>ID: {headerData?.id}</>,
      <div className="flex space-x-1">
        <div>Template: </div>
        <div className="max-w-[235px] truncate">
          {headerData?.templateTitle}
        </div>
      </div>,
      <>Sender: {headerData?.sender}</>,
      <>Receiver: {headerData?.receiver}</>,
      <>
        {senderHostSystem && (
          <ActivityFilterLabel
            isEditable={isActivityFilterEditable}
            isOn={
              senderHostSystem !== HostSystem.SAP
                ? !!activityFilterValue
                : orderFilterValue?.trim().length !== 0 ||
                  operationFilterValue?.trim().length !== 0 ||
                  isPredecessorIEAFilterSelected
            }
            onViewOrEdit={() =>
              senderHostSystem === HostSystem.SAP
                ? setShowSapFilterDialog(true)
                : setShowActivityFilterDialog(true)
            }
          />
        )}
      </>,

      <div className="flex gap-x-2 items-center">
        {isLabelEditable || headerData?.labels.length !== 0 ? (
          <>Labels: </>
        ) : null}
        <LabelHolder
          labels={headerData?.labels ?? []}
          labelType={SupportedLabelEntity.ExchangeAgreement}
          allowAdd={isLabelEditable}
          allowDelete={isLabelEditable}
          onAddLabel={onAddNewLabel}
          onDelete={onDeleteLabel}
        />
      </div>,
    ];
  }, [
    headerData,
    activityFilterValue,
    isLabelEditable,
    onAddNewLabel,
    onDeleteLabel,
    isOwner,
    orderFilterValue,
    operationFilterValue,
    isPredecessorIEAFilterSelected,
  ]);

  const [widgets, setWidgets] = useState(initialWidgets);

  useEffect(() => {
    var newWidgets = [...initialWidgets].filter((el) => el.props.children);
    setWidgets(() => {
      return newWidgets;
    });
  }, [
    headerData,
    isLabelEditable,
    initialWidgets,
    activityFilterValue,
    orderFilterValue,
    operationFilterValue,
    isPredecessorIEAFilterSelected,
  ]);

  const onActivityFilterSave = (newFilter: string) => {
    if (isActivityFilterEditable) {
      onSaveActivityFilterClick(newFilter);
    }

    setShowActivityFilterDialog(false);
  };

  const onSapFilterSave = () => {
    if (isActivityFilterEditable && headerData) {
      var exportFilterTypeValue = isPredecessorIEAFilterSelected
        ? SapExportFilterType.PredecessorIEA
        : SapExportFilterType.OrderFilter;

      var updatedSenderConfig = getJsonStringWithKeyValueChange(
        headerData.senderConfigSettings,
        [
          { key: orderFilterFieldName, value: orderFilterValue },
          { key: operationFilterFieldName, value: operationFilterValue },
          { key: exportFilterTypeFieldName, value: exportFilterTypeValue },
        ]
      );

      onSaveActivityFilterClick(updatedSenderConfig);
    }

    setShowSapFilterDialog(false);
  };

  const handlePredecessorIEASelectionChange = (selectedValue: boolean) => {
    setIsPredecessorIEAFilterSelected(selectedValue);
  };

  const handleOrderFilterValueChange = (
    selectedFilter: string,
    parentName: string,
    valueChangedFromDropdown: boolean
  ) => {
    setOrderFilterValue((previousFilter) => {
      var newFilterValue: string = "";

      if (!valueChangedFromDropdown) {
        newFilterValue = selectedFilter;
      } else {
        if (!previousFilter) {
          newFilterValue = selectedFilter;
        } else {
          var filterJoiner =
            parentName === FunctionsParentName ||
            parentName === OperatorsParentName
              ? " "
              : " and ";
          newFilterValue = `${previousFilter}${filterJoiner}${selectedFilter}`;
        }
      }

      return newFilterValue;
    });
  };

  const handleOperationFilterValueChange = (
    selectedFilter: string,
    valueChangedFromDropdown: boolean
  ) => {
    setOperationFilterValue((previousFilter) => {
      var newFilter = "";

      if (!valueChangedFromDropdown) {
        newFilter = selectedFilter;
      } else {
        if (!previousFilter) {
          newFilter = selectedFilter;
        } else {
          newFilter = `${previousFilter} & ${selectedFilter}`;
        }
      }

      return newFilter;
    });
  };

  return (
    <div className="flex">
      <PageHeader
        title={headerData?.title ?? "IEA title"}
        titleWidget={titleWidget}
        headerWidgets={widgets}
        hideBottomBorder={true}
        pageOptions={props.pageOptions}
        onTitleChanged={onTitleChange}
        isEdit={allowEdit}
      />

      {showActivityFilterDialog && headerData && (
        <IEAActivityFilterDialog
          senderHostSystem={senderHostSystem}
          filterValue={activityFilterValue}
          isEdit={isActivityFilterEditable}
          onCancel={() => setShowActivityFilterDialog(false)}
          onSave={onActivityFilterSave}
        />
      )}

      {showSapFilterDialog && (
        <SapActivityFilterDialog
          isEdit={isActivityFilterEditable}
          operationFilterValue={operationFilterValue}
          orderFilterValue={orderFilterValue}
          isPredecessorIEASelected={isPredecessorIEAFilterSelected}
          frequencyType={ieaFrequencyType}
          onCancel={() => {
            setSapFilterValuesFromConfig();
            setShowSapFilterDialog(false);
          }}
          onSapFilterSave={onSapFilterSave}
          handleOrderFilterValueChange={handleOrderFilterValueChange}
          handleOperationFilterValueChange={handleOperationFilterValueChange}
          handlePredecessorIEASelectionChange={
            handlePredecessorIEASelectionChange
          }
        />
      )}
    </div>
  );
}
