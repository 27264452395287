export default function TickIcon() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8344_26080)">
        <g clipPath="url(#clip1_8344_26080)">
          <g clipPath="url(#clip2_8344_26080)">
            <path
              d="M4.69844 8.1L2.59844 6L1.89844 6.7L4.69844 9.5L10.6984 3.5L9.99844 2.8L4.69844 8.1Z"
              fill="#434343"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8344_26080">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.199219)"
          />
        </clipPath>
        <clipPath id="clip1_8344_26080">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.199219)"
          />
        </clipPath>
        <clipPath id="clip2_8344_26080">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.199219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
