import TenantRequestDialog from "./TenantRequestDialog";

interface Props {
  onClose: () => void;
  message: string;
}

export default function TenantRequestPendingDialog(props: Props) {
  return (
    <TenantRequestDialog
      title={"A request to register your domain as a tenant is pending."}
      contentText={props.message}
      confirmButtonText={"Close"}
      onConfirm={props.onClose}
      hideCancelButton={true}
    />
  );
}
