import { Button } from "devextreme-react";
import { IButtonOptions } from "devextreme-react/button";
import "./styles/LandingButton.css";

export enum LandingButtonType {
  SignIn = "signIn",
  ReadMore = "readMore",
}

interface Props {
  variant?: LandingButtonType;
}

export default function LandingButton(props: Props & IButtonOptions) {
  const { className, variant, ...rest } = props;
  let classNames = `landing-button${className ? ` ${className}` : ""}`;

  if (!variant || variant === LandingButtonType.SignIn) {
    classNames = `${classNames} signIn-button`;
  } else if (variant === LandingButtonType.ReadMore) {
    classNames = `${classNames} readMore-button`;
  }

  return <Button {...rest} className={classNames} />;
}
