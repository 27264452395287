import { getDataAsync, postDataAsync } from "./API";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import TemplateFieldDetailsResponse from "interfaces/response/TemplateFieldDetailsResponse";
import TemplateFieldWithValueResponse from "interfaces/response/TemplateFieldWithValueResponse";

export const getTemplateFieldByTemplateFieldIdAsync = async (
  templateFieldId: number
) => {
  let apiUrl = `TemplateFields/${templateFieldId}`;
  let response = await getDataAsync<TemplateFieldDetailsResponse>(apiUrl);
  return response;
};

export const getTemplateFieldByTemplateIdAndFieldIdAsync = async (
  templateId: number,
  fieldId: number
) => {
  let apiUrl = `Templates/${templateId}/Fields/${fieldId}`;
  let response = await getDataAsync<TemplateFieldWithValueResponse>(apiUrl);
  return response;
};

export const manageTemplateFieldAsync = async (
  templateFieldId: number,
  manageRequest: FieldManageRequest
) => {
  let apiUrl = `TemplateFields/${templateFieldId}/Manage`;
  let response = await postDataAsync<
    FieldManageRequest,
    TemplateFieldWithValueResponse
  >(apiUrl, manageRequest);
  return response;
};
