interface Props {
  text: string;
  color?: string;
  showBorder?: boolean;
  isBold?: boolean;
  isItalic?: boolean;
  onClick?: () => void;
}
export default function LabelWithBorder(props: Props) {
  const { text, color, showBorder, isBold, isItalic, onClick } = props;
  const labelClass = `font-inter text-[12px] leading-15px text-cente
  ${showBorder ? "border-2 rounded px-2" : ""} 
  ${color ? `text-${color} border-${color}` : ""}
  ${isBold ? "font-bold" : ""}
  ${isItalic ? "italic" : ""}
  ${onClick ? "cursor-pointer hover:drop-shadow-2xl hover:drop-shadow black" : ""}`;

  return (
    <div className={labelClass} onClick={onClick}>
      {text}
    </div>
  );
}
