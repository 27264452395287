import { ReactElement } from "react";

interface Props {
  value: number | string;
  label: string;
  isSelected: boolean;
  iconComponent?: ReactElement;
  onSummaryButtonClick: () => void;
}

export default function SummaryComponent(props: Props) {
  const { value, label, isSelected, onSummaryButtonClick } = props;

  return (
    <div
      className={`select-none cursor-pointer rounded-full h-12 px-6 flex items-center justify-center hover:bg-lighterBlue ${
        isSelected
          ? "bg-lighterBlue border border-lightBlue"
          : "border border-lightGray bg-white"
      }`}
      onClick={onSummaryButtonClick}
    >
      <div className="flex items-center text-sm leading-[18px] font-inter font-normal space-x-2">
        {props.iconComponent ? <div>{props.iconComponent}</div> : null}
        <div className="font-normal">{label}</div>
        <div className="font-bold">{value}</div>
      </div>
    </div>
  );
}
