import { createSlice } from "@reduxjs/toolkit";
import FieldValue from "interfaces/common/FieldValue";
import ValueMap from "interfaces/common/ValueMap";
import {
  validateAndPrepareBulkFieldValues,
  validateAndPrepareBulkValueMaps,
} from "store/actions/BulkInsertUIAction";

interface BulkInsertUISlice {
  userInputText: string;
  validationErrors: string[];
  validValueMaps: ValueMap[];
  validFieldValues: FieldValue[];
  bulkEditorMode: "edit" | "preview";
}

const initialState: BulkInsertUISlice = {
  userInputText: "",
  validationErrors: [],
  validValueMaps: [],
  validFieldValues: [],
  bulkEditorMode: "edit",
};

export const bulkInsertUISlice = createSlice({
  name: "bulkInsertUISlice",
  initialState,
  reducers: {
    resetBulkInsertUIState: () => initialState,
    setUserInput: (state, { payload }: { payload: string }) => {
      state.userInputText = payload;
    },
    setBulkEditorMode: (
      state,
      { payload }: { payload: "edit" | "preview" }
    ) => {
      state.bulkEditorMode = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      validateAndPrepareBulkValueMaps.fulfilled,
      (state, action) => {
        state.validValueMaps = action.payload.valueMaps;
        state.validationErrors = action.payload.errors;
      }
    );
    builder.addCase(
      validateAndPrepareBulkFieldValues.fulfilled,
      (state, action) => {
        state.validFieldValues = action.payload.fieldValues;
        state.validationErrors = action.payload.errors;
      }
    );
  },
});

export default bulkInsertUISlice.reducer;
export const { resetBulkInsertUIState, setUserInput, setBulkEditorMode } =
  bulkInsertUISlice.actions;
