import PromineoTextBox from "components/common/controls/PromineoTextBox";
import { useCallback } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import { getHiddenPasswordPlaceHolder } from "shared/utilities/CommonUtility";

interface Props {
  userName?: string;
  password?: string;
  mode: PromineoModalMode;
  classNames?: string;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  validateOnFocusOut?:(event: any) => void;
}

export default function SafranConnectorBasicAuthenticationContent(props: Props) {
  const { 
    userName,
    password,
    mode,
    classNames,
    handleConfigurationValueChanged,
    validateOnFocusOut
  } = props;

  const hiddenPassword = getHiddenPasswordPlaceHolder(password);

  const RenderUserNameComponent = useCallback(() => {
    return(
      <>
        {(mode === PromineoModalMode.Create || mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="username"
            fieldName="User Name"
            required={true}
            defaultValue={userName}
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && (
          userName
        )}
      </>
    );
  }, [mode, userName]);

  const RenderPasswordComponent = useCallback(() => {
    return(
      <>
        {(mode === PromineoModalMode.Create || mode === PromineoModalMode.Modify) && (
          <PromineoTextBox
            width={mode === PromineoModalMode.Create ? 330 : 188}
            name="password"
            fieldName="Password"
            required={true}
            defaultValue={password}
            mode="password"
            placeholder="Type..."
            onChange={({ event }: any) => {
              if (handleConfigurationValueChanged) {
                handleConfigurationValueChanged(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && (
          hiddenPassword
        )}
      </>
    );
  }, [mode, password]);

  return(
    <div className={classNames}>
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Username"
        content={<RenderUserNameComponent />}
      />
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Password"
        content={<RenderPasswordComponent />}
      />
    </div>
  );
}