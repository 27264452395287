interface Props {
  fillColor?: string;
}

export default function EditIcon(props: Props) {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 12.1667L8 9.5H13.3333V12.1667H5.33333ZM1.33333 10.8333H2.26667L8.01667 5.08333L7.08333 4.15L1.33333 9.9V10.8333ZM10.8667 4.11667L8.03333 1.31667L8.96667 0.383333C9.22222 0.127778 9.53622 0 9.90867 0C10.2807 0 10.5944 0.127778 10.85 0.383333L11.7833 1.31667C12.0389 1.57222 12.1722 1.88067 12.1833 2.242C12.1944 2.60289 12.0722 2.91111 11.8167 3.16667L10.8667 4.11667ZM9.9 5.1L2.83333 12.1667H0V9.33333L7.06667 2.26667L9.9 5.1Z"
        fill={`${props.fillColor ?? "#727272"}`}
      />
    </svg>
  );
}
