import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import { useEffect, useState } from "react";
import { ConfigStatus } from "shared/enums/feature/ConfigStatus";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetCurrentConfig } from "store/slices/ConfigSlice";
import ConfigEditor from "./config-edit/ConfigEditor";
import { HostSystem } from "shared/enums/feature/HostSystem";

export default function NewConfig() {
  const dispatch = useAppDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  const [initialConfig] = useState<ConfigDetailResponse>({
    id: 0,
    connectorId: 0,
    connectorName: "",
    direction: 0,
    directionText: "",
    labels: [],
    mappings: [],
    scheduleCount: 0,
    schedules: [],
    status: ConfigStatus.UnderConstruction,
    statusText: "",
    templateId: 0,
    templateTitle: "",
    tenantId: 0,
    title: "",
    hostSystemParameters: "",
    createdBy: "",
    connectorHostSystem: HostSystem.None
  });

  useEffect(() => {
    dispatch(resetCurrentConfig());
    setIsInitialized(true);
  }, []);

  const createdNewConfig = useAppSelector(
    (state) => state.configData.currentConfig
  );

  return (
    <div>
      {isInitialized && (
        <ConfigEditor isNew={true} config={createdNewConfig ?? initialConfig} />
      )}
    </div>
  );
}
