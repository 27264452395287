import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PageHeader from "components/common/page-header/PageHeader";
import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import ShareIcon from "components/icons/ShareIcon";
import EditIcon from "components/icons/EditIcon";
import TemplateHeaderData from "interfaces/component-data/TemplateHeaderData";
import LabelHolder from "components/common/LabelHolder";
import ShareTemplateWithTenantDialog from "../share-template/ShareTemplateWithTenantDialog";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { useAppSelector } from "store/hooks";
import { getLocalFormattedDateTimeFromUtcDateObject } from "shared/utilities/DateTimeUtility";

interface Props {
  templateHeaderData?: TemplateHeaderData | null;
}

export default function ViewTemplateHeader(props: Props) {
  let navigate = useNavigate();

  const currentUser = useAppSelector((state) => state.userData.mySelfResponse);

  const initialWidgets = [
    <>ID: {props.templateHeaderData?.id}</>,
    <LabelHolder
      labels={props.templateHeaderData?.labels ?? []}
      labelType={SupportedLabelEntity.Template}
    />,
    <># of fields: {props.templateHeaderData?.fieldCount}</>,
    <># of configs: {props.templateHeaderData?.ownConfigsCount}</>,
    <>#Shared with: {props.templateHeaderData?.shareCount}</>,
    <>Created by: {props.templateHeaderData?.createdBy}</>,
    <>Created on: {getLocalFormattedDateTimeFromUtcDateObject(props.templateHeaderData?.createdOn)}</>,
  ];

  const [widgets, setWidgets] = useState(initialWidgets);
  const [displayShareTemplateDialog, setDisplayShareTemplateDialog] =
    useState(false);

  useEffect(() => {
    setWidgets(() => {
      return [...initialWidgets];
    });
  }, [props.templateHeaderData]);

  const onEditClick = () => {
    navigate(`${TEMPLATES}/${props.templateHeaderData?.id}/edit`);
  };

  const onShareClick = () => {
    setDisplayShareTemplateDialog(true);
  };

  const breadCrumbsItems: BreadcrumbItem[] = [
    {
      key: "Templates",
      text: "Templates",
    },
    {
      key: "metadata-create-view-update",
      text: `${
        props.templateHeaderData ? props.templateHeaderData.title : " "
      }`,
    },
  ];

  return (
    <div>
      <Breadcrumb
        items={breadCrumbsItems}
        onSelectionChange={(index, selectedItem) => {
          if (selectedItem.key === "Templates") navigate(TEMPLATES);
        }}
      />
      <div className="flex mt-1">
        <PageHeader
          title={props.templateHeaderData?.title ?? "Template title"}
          description={props.templateHeaderData?.description}
          displayDescriptionField={true}
          titleWidget={
            currentUser?.tenant?.id === props.templateHeaderData?.tenantId ? (
              <div className="flex gap-2">
                <div onClick={onEditClick} className="cursor-pointer">
                  <EditIcon />
                </div>
                <div onClick={onShareClick} className="cursor-pointer">
                  <ShareIcon />
                </div>
              </div>
            ) : (
              <></>
            )
          }
          headerWidgets={widgets}
        />
      </div>
      {displayShareTemplateDialog && props.templateHeaderData && (
        <ShareTemplateWithTenantDialog
          onCancel={() => {
            setDisplayShareTemplateDialog(false);
          }}
          templateToShare={{
            templateId: props.templateHeaderData.id,
            templateTitle: props.templateHeaderData.title,
          }}
        />
      )}
    </div>
  );
}
