import LabelWithBorder from "components/common/LabelWithBorder";
import EditButton from "components/common/controls/buttons/EditButton";
import ViewButton from "components/common/controls/buttons/ViewButton";

export default function ActivityFilterLabel(props: {
  isOn: boolean;
  isEditable: boolean;
  onViewOrEdit: () => void;
}) {
  return (
    <div className="flex gap-2">
      <div className="flex gap-2">
        Activity Filter:{" "}
        <LabelWithBorder
          text={`${props.isOn ? "On" : "Off"}`}
          color="black"
          isBold={true}
        />
      </div>
      {props.isEditable ? (
        <EditButton onEditClick={props.onViewOrEdit} />
      ) : (
        <ViewButton onEditClick={props.onViewOrEdit} />
      )}
    </div>
  );
}
