import TenantUsersDropdown from "./TenantUsersDropdown";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";

interface Props {
  representatives: UserSimplifiedResponse[];
  isEditable: boolean;
  labelClassName: string;
  representativeIds: number[];
  usersByTenant: UserSimplifiedResponse[];
  handleRepresentativeChange: (representativeIds: number[]) => void;
  updateDisableButtonStatus?: (value: boolean) => void;
}

export default function RepresentativeDropdown(props: Props) {
  const {
    representatives,
    isEditable,
    labelClassName,
    representativeIds,
    usersByTenant,
    handleRepresentativeChange,
    updateDisableButtonStatus
  } = props;

  return (
    <>
      {isEditable ? (
        <TenantUsersDropdown
          users={representativeIds}
          maximumNumberOfUsers={4}
          minimumNumberOfUsers={1}
          userList={usersByTenant}
          onValueChange={handleRepresentativeChange}
          updateDisableButtonStatus={updateDisableButtonStatus}
        />
      ) : (
        <div className="space-y-1">
          {representatives.map((user, index) => (
            <div className={labelClassName} key={`${index}_${user.id}`}>
              {user.fullName}
            </div>
          ))}
        </div>
      )}
    </>
  );
}