import PromineoViewGrid, {
  PromineoGridToolbarConfig,
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ClientResponse from "interfaces/response/autonomous-components/ClientResponse";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import AutonomousComponentLastRequestReceiveStatusComponent from "./AutonomousComponentLastRequestReceiveStatusComponent";

interface Props {
  clients: ClientResponse[];
  onAddNewClick: () => void;
  onEditClick: (component: ClientResponse) => void;
  onDeleteClick: (component: ClientResponse) => void;
  height: number;
  baseUrl: string;
  noDataText?: string;
}

export default function AutonomousComponentGrid(props: Props) {
  let navigate = useNavigate();

  const toolbarConfig = useRef<PromineoGridToolbarConfig>({
    dislplaySearchPanel: true,
    addNewButtonOptions: {
      isVisible: true,
      onClick: props.onAddNewClick,
      text: "Add New Component",
    },
  });

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    displayDefault: false,
    items: [
      {
        text: "Edit",
        visible: true,
        onClick: (data: any) => {
          props.onEditClick(data.data);
        },
      },
      {
        text: "Service Tokens",
        visible: true,
        onClick: (data: any) => {
          const componentId = data.data.id;
          let route = `${props.baseUrl}/${componentId}/service-tokens`;
          navigate(route);
        },
      },
      {
        text: "Delete",
        visible: true,
        onClick: (data: any) => {
          props.onDeleteClick(data.data);
        },
      },
    ],
  });

  return (
    <PromineoViewGrid
      dataSource={props.clients}
      className="promineo-ui-grid"
      toolbarConfig={toolbarConfig.current}
      rowOperationConfig={rowOperationConfig.current}
      height={props.height}
      noDataText={props.noDataText}
    >
      <Scrolling rowRenderingMode={"virtual"} mode={"virtual"} />
      <Column caption={"ID"} dataField="id" width={150} alignment={"left"} />
      <Column caption={"Name"} dataField="name" />
      <Column caption={"Description"} dataField="description" />
      <Column
        caption={"Is Active"}
        dataField="isActive"
        trueText={"Yes"}
        falseText={"No"}
        showEditorAlways={false}
        width={150}
      />
      <Column
        caption={"Last Authenticated Request Status"}
        width={230}
        alignment={"center"}
        cellRender={AutonomousComponentLastRequestReceiveStatusComponent}
      />
    </PromineoViewGrid>
  );
}
