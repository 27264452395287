import PromineoPopover from "components/common/controls/PromineoPopover";
import { Offset, Position } from "devextreme-react/popover";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import CoreFieldResponse from "interfaces/response/CoreFieldResponse";
import { useCallback } from "react";

interface Props {
  dataSource: CoreFieldResponse[];
  target: string;
  visible: boolean;
  onHiding: () => void;
  setCoreFieldToAdd: (data: CoreFieldResponse) => void;
  offsetX?: number;
  offsetY?: number;
  minWidth?: number;
}

export default function AddIlapCoreFieldPopOverGrid(props: Props) {
  const handleRowClick = useCallback((evt: { data: CoreFieldResponse }) => {
    props.setCoreFieldToAdd(evt.data);
    props.onHiding();
  }, []);

  return (
    <div>
      <PromineoPopover
        width={"auto"}
        minWidth={props.minWidth ? props.minWidth : 109}
        height={"auto"}
        visible={props.visible}
        target={props.target}
        onHiding={props.onHiding}
      >
        <Position my={{ x: "right", y: "top" }}>
          <Offset
            x={props.offsetX ? props.offsetX : 45}
            y={props.offsetY ? props.offsetY : -5}
          />
        </Position>
        <PromineoViewGrid
          dataSource={props.dataSource}
          height={288}
          width={594}
          showColumnHeaders={false}
          onRowClick={handleRowClick}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
          <Column
            dataField={"planningObjectTypeText"}
            caption="Example formulPlanning object typr"
            width={150}
          />
          <Column dataField={"name"} caption="Name" />
          <Column dataField={"dataTypeText"} caption="Data type" width={100} />
        </PromineoViewGrid>
      </PromineoPopover>
    </div>
  );
}
