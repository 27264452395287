import { ReactNode } from "react";
import LabelWithAsteriskSymbol from "./LabelWithAsteriskSymbol";

interface Props {
  label: ReactNode | string;
  labelIcon?: ReactNode;
  content: ReactNode | string;
  addAsterisksymbol?: boolean;
  isInline?: boolean;
}

export const TextContent = (props: { text: string | number | undefined }) => {
  return (
    <span className="ml-2 w-44 font-inter font-medium text-[12px] leading-[18px]">
      {props.text}
    </span>
  );
};

export default function LabelWithContent(props: Props) {
  const { label, content, addAsterisksymbol, isInline, labelIcon } = props;

  return (
    <div className={`w-auto ${isInline ? "flex justify-center items-center gap-2" : "space-y-1"}`}>
      <div className="flex gap-2">
        <LabelWithAsteriskSymbol label={label} addAsterisksymbol={addAsterisksymbol} />
        {labelIcon}
      </div>
      <div>{content}</div>
    </div>
  );
}
