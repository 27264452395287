export default function FilledFavoriteIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.39665 13.6736C8.46021 13.6353 8.53979 13.6353 8.60335 13.6736L13.5017 16.6301C13.6532 16.7215 13.84 16.5857 13.7998 16.4134L12.4999 10.8413C12.4831 10.769 12.5076 10.6933 12.5637 10.6447L16.8917 6.89538C17.0254 6.77954 16.954 6.55988 16.7777 6.54493L11.0789 6.06145C11.005 6.05517 10.9406 6.00851 10.9117 5.94021L8.68414 0.684474C8.61523 0.521868 8.38477 0.521868 8.31586 0.684474L6.08831 5.94021C6.05937 6.00851 5.99499 6.05517 5.92108 6.06145L0.222326 6.54493C0.0460403 6.55988 -0.0254407 6.77954 0.108279 6.89538L4.43626 10.6447C4.49237 10.6933 4.51694 10.769 4.50007 10.8413L3.20018 16.4134C3.15999 16.5857 3.34684 16.7215 3.4983 16.6301L8.39665 13.6736Z"
        fill="#FF9A3A"
      />
    </svg>
  );
}
