import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { useEffect, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { useAppSelector } from "store/hooks";

interface Props {
  onChange: (direction: ConfigDirection) => void;
  selectedDirection?: ConfigDirection;
  selectedConnectorId?: number;
}

export default function ConfigDirectionDropdown(props: Props) {
  const simplifiedConnectors = useAppSelector(
    (store) => store.connectorData.simplifiedConnectors
  );
  const [directionDataSource, setDirectionDataSource] = useState<{ id: ConfigDirection; text: string }[]>([]);

  useEffect(() => {
    let isConnctorHostSystemMsProject = false;
    if(props.selectedConnectorId && simplifiedConnectors)
    {
        let simplifiedConnectorDetail = simplifiedConnectors?.find((c) => c.id === props.selectedConnectorId)
        isConnctorHostSystemMsProject = simplifiedConnectorDetail?.hostSystem === HostSystem.MicrosoftProject;
    }
    if (isConnctorHostSystemMsProject) {
      setDirectionDataSource([{ id: ConfigDirection.Sending, text: "Sending" }]);
    } else {
      setDirectionDataSource([
        { id: ConfigDirection.Sending, text: "Sending" },
        { id: ConfigDirection.Receiving, text: "Receiving"},
      ]);
    }
  }, [simplifiedConnectors, props.selectedConnectorId]);

  const handleSelectionChanged = (evt: any) => {
    props.onChange(evt.value);
  };

  return (
    <PromineoSelectBox
      placeholder="Select"
      dataSource={directionDataSource}
      valueExpr="id"
      displayExpr={"text"}
      onValueChanged={handleSelectionChanged}
      value={props.selectedDirection}
    />
  );
}
