import SapOperationFilterComponent from "./SapOperationFilterComponent";
import SapOrderFilterComponent from "./SapOrderFilterComponent";
import SapOrderOperationFilterInstructionComponent from "./SapOrderOperationFilterInstructionComponent";

interface Props {
  isEdit: boolean;
  isNew: boolean;
  orderFilterValue: string;
  operationFilterValue: string;
  handleOrderFilterValueChange: (
    selectedFilter: string,
    parentName: string,
    valueChangedFromDropdown: boolean
  ) => void;
  handleOperationFilterValueChange: (
    newOperationFilter: string,
    valueChangedFromDropdown: boolean
  ) => void;
}

export default function SapOrderOperationFilterComponent(props: Props) {
  const heightForNewIea = 120;
  const heightForExistingIea = 170;
  const {
    isEdit,
    orderFilterValue,
    operationFilterValue,
    handleOrderFilterValueChange,
    handleOperationFilterValueChange,
  } = props;

  return (
    <div className="space-y-4">
      {isEdit && <SapOrderOperationFilterInstructionComponent />}
      <SapOrderFilterComponent
        isEdit={isEdit}
        orderFilterValue={orderFilterValue}
        handleOrderFilterValueChange={handleOrderFilterValueChange}
        height={props.isNew ? heightForNewIea : heightForExistingIea}
      />
      <SapOperationFilterComponent
        isEdit={isEdit}
        operationFilterValue={operationFilterValue}
        handleOperationFilterValueChange={handleOperationFilterValueChange}
        height={props.isNew ? heightForNewIea : heightForExistingIea}
      />
    </div>
  );
}
