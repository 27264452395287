import CrossIcon from "components/icons/CrossIcon";
import PromineoModal from "components/modal/PromineoModal";
import { useState } from "react";

interface Props {
  name: string;
}

export default function ConnectorInfoDisplay(props: Props) {
  const [isInfoPopupVisible, setIsInfoPopupVisible] = useState(false);
  const handleNameClicked = () => {
    // setIsInfoPopupVisible(true);
  };
  return (
    <>
      <div className="max-w-[235px] truncate" onClick={handleNameClicked}>
        {props.name}
      </div>

      {isInfoPopupVisible && (
        <PromineoModal
          isVisible={isInfoPopupVisible}
          width={600}
          height={400}
        >
          <div className="flex justify-between mb-4">
            <div className="font-bold font-poppins">
              Connector core field info
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setIsInfoPopupVisible(false);
              }}
            >
              <CrossIcon />
            </div>
          </div>
          <div className="font-medium font-inter">
          </div>
        </PromineoModal>
      )}
    </>
  );
}
