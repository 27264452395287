import LabelWithAsteriskSymbol from "components/common/LabelWithAsteriskSymbol";
import LabelWithContent from "components/common/LabelWithContent";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import IlapTermHeaderData from "interfaces/component-data/IlapTermHeaderData";

interface Props {
  isEdit?: boolean;
  isNew?: boolean;
  headerData: IlapTermHeaderData;
  onChange: (event: any) => void;
}

export default function IlapIdLabel(props: Props) {
  const { isEdit, isNew, onChange, headerData } = props;
  return isEdit ? (
    <LabelWithContent
      label="Ilap ID: "
      isInline={true}
      content={
        <PromineoTextBox
          width={110}
          name="ilapId"
          defaultValue={headerData?.ilapId}
          placeholder="Type..."
          onChange={onChange}
        />
      }
    />
  ) : (
    <LabelWithAsteriskSymbol label={`Ilap ID: ${headerData?.ilapId}`} />
  );
}
