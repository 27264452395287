import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "store/store";
import Provider from "react-redux/es/components/Provider";

import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "authentication/msalConfig";
import { injectMsalInstance } from "authentication/AuthenticationManager";

const msalInstance = new PublicClientApplication(msalConfig);
injectMsalInstance(msalInstance);
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const container = document.getElementById("root");

if (!container) {
  throw new Error("Root container missing in index.html");
}

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App msalInstance={msalInstance} />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
