import { ScrollView } from "devextreme-react";
import HostSystemNoParameterInfoComponent from "../HostSystemNoParameterInfoComponent";
import NoParamtersRequiredText from "../NoParamtersRequiredText";

interface Props {
  height: number;
  isOverride?: boolean;
}

const Info = () => (
  <HostSystemNoParameterInfoComponent headerText="MSProject Receiving parameters" />
);

export default function MicrosoftProjectReceivingParameters(props: Props) {
  return !props.isOverride ? (
    <ScrollView showScrollbar="always" height={props.height}>
      <Info />
    </ScrollView>
  ) : (
    <NoParamtersRequiredText/>
  );
}
