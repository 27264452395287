import PromineoTextBox from "components/common/controls/PromineoTextBox";
import TenantInfoLabelTextContent from "./TenantInfoLabelTextContent";
import OwnTenantResponse from "interfaces/response/OwnTenantResponse";

interface Props {
  tenantDetail: OwnTenantResponse;
  isEdit: boolean;
  onChange: (updatedInfo: OwnTenantResponse) => void;
}

export default function CompanyRegistrationFieldViewOrEdit(props: Props) {
  const handleValueChange = (evt: any) => {
    props.onChange({
      ...props.tenantDetail,
      companyRegistrationNumber: evt.value,
    });
  };

  if (props.isEdit) {
    return (
      <PromineoTextBox
        width={188}
        defaultValue={props.tenantDetail.companyRegistrationNumber}
        onValueChanged={handleValueChange}
      />
    );
  }

  return (
    <TenantInfoLabelTextContent
      text={props.tenantDetail.companyRegistrationNumber}
    />
  );
}
