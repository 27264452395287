interface Props {
  title: string;
}

export default function IEAInfoTitle(props: Props) {
  return (
    <div className="font-poppins font-bold text-10px leading-15px text-textGray px-2">
      {props.title}
    </div>
  )
}