import { useSelector } from "react-redux";
import { RootState, store } from "store/store";
import PromineoModal from "components/modal/PromineoModal";
import LoadingIcon from "components/icons/LoadingIcon";
import "./styles/LoadingPanel.css";
import {
  makeLoadingPanelVisible,
  makeLoadingPanelHide,
  makeLoadingPanelHideForce,
} from "store/slices/LoadingPanelSlice";

export default function LoadingPanel() {
  const isLoading = useSelector(
    (state: RootState) => state.loadingPanelData.isLoading
  );

  return (
    <>
      {isLoading && (
        <PromineoModal
          isVisible={isLoading}
          displayDefaultActions={false}
          width="150"
          height="150"
          className="promineo-loading-panel-modal"
          animation={{
            show: { duration: 0 },
            hide: { duration: 0 },
          }}
        >
          <div className="flex justify-center items-center h-full">
            <LoadingIcon />
          </div>
        </PromineoModal>
      )}
    </>
  );
}

export const hideLoadingPanelForce = () => {
  store.dispatch(makeLoadingPanelHideForce());
};

export const displayLoadingPanel = () => {
  store.dispatch(makeLoadingPanelVisible());
};

export const hideLoadingPanel = () => {
  store.dispatch(makeLoadingPanelHide());
};
