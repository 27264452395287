import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import AnalyticsServiceAccountTokenField from "./AnalyticsServiceAccountTokenField";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
interface Props {
  mode: PromineoModalMode;
  configuration: AnalyticsConnectorConfiguration;
  validateOnFocusOut?: (evt: any) => void;
  onValueChange?: (fieldName: string, value: string) => void;
}

export default function AnalyticsServiceAccountTokenAuthentication(
  props: Props
) {
  return (
    <AnalyticsServiceAccountTokenField
      configuration={props.configuration}
      mode={props.mode}
      onValueChange={props.onValueChange}
      validateOnFocusOut={props.validateOnFocusOut}
    ></AnalyticsServiceAccountTokenField>
  );
}
