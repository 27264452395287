import { createAsyncThunk } from "@reduxjs/toolkit";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import { RootState } from "store/store";

export const updateSystemFormulaInFormulaPickerDataSource =
  createAsyncThunk(
    "fieldMappingFormulaPickerUI/updateSystemFormulaInFormulaPickerDataSource",
    (mappingFormula: FieldMappingFormulaResponse, thunkApi) => {
      const state = thunkApi.getState() as RootState;
      let systemFormulas = state.fieldMappingFormulaPickerUIData.systemFormulas;
      let newsystemFormulaList = systemFormulas.map((f) => {
        if (f.id === mappingFormula.id) {
          return mappingFormula;
        } else {
          return f;
        }
      });
      return newsystemFormulaList;
    }
  );


export const addOrUpdateCustomFormulaInFormulaPickerDataSource =
  createAsyncThunk(
    "fieldMappingFormulaPickerUI/addOrUpdateCustomFormulaInFormulaPickerDataSource",
    (mappingFormula: FieldMappingFormulaResponse, thunkApi) => {
      const state = thunkApi.getState() as RootState;
      
      let newCustomFormulaList: FieldMappingFormulaResponse[] = (state.fieldMappingFormulaPickerUIData.customFormulas) ;
      let isExistingFormula = newCustomFormulaList.find(f=> f.id === mappingFormula.id); 

      if(isExistingFormula){
        newCustomFormulaList = newCustomFormulaList.map(f=> f.id === mappingFormula.id ? mappingFormula : f);
      }
      else {
        newCustomFormulaList = [...newCustomFormulaList, mappingFormula];        
      }

      return newCustomFormulaList;
    }
  );

export const removeCustomFormulaFromFormulaPickerDataSource = createAsyncThunk(
  "fieldMappingFormulaPickerUI/removeCustomFormulaFromFormulaPickerDataSource",
  (formulaId: number, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    let customFormulas = state.fieldMappingFormulaPickerUIData.customFormulas;
    customFormulas = customFormulas.filter((f) => f.id !== formulaId);

    return customFormulas;
  }
);
