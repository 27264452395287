import TreeView, { ITreeViewOptions } from "devextreme-react/tree-view";
import React from "react";
import "./styles/PromineoTreeView.css";

export interface TreeViewOptions {
  reference?: React.LegacyRef<TreeView<any>>;
  className?: string;
}

function PromineoTreeView(props: TreeViewOptions & ITreeViewOptions) {
  let classNames = "promineo-tree-view";

  if (props.className) {
    classNames = `${classNames} ${props.className}`;
  }

  return <TreeView {...props} ref={props.reference} className={classNames} />;
}

export default React.forwardRef(
  (
    props: TreeViewOptions & ITreeViewOptions,
    reference: React.LegacyRef<TreeView<any>>
  ) => {
    return <PromineoTreeView {...props} reference={reference} />;
  }
);
