import { useCallback, useState } from "react";
import PromineoDropdownBox from "components/common/dropdown-box/PromineoDropdownBox";
import FormulaPickerAccordion from "./FormulaPickerAccordion";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setFormulaSearchText } from "store/slices/FieldMappingFormulaPickerUISlice";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";

interface Props {
  onFormulaSelectionChange: (formula: FieldMappingFormulaResponse) => void;
  mappingPlanningObjectType: PlanningObjectTypes;
}

export default function ExampleFormulaPickerDropdown(props: Props) {
  const { onFormulaSelectionChange, mappingPlanningObjectType } = props;
  const dispatch = useAppDispatch();

  const formulaSearchText = useAppSelector(
    (store) => store.fieldMappingFormulaPickerUIData.formulaSearchText
  );

  const [isOpened, setIsOpened] = useState(false);

  const handleOpenedChanged = (value: boolean) => {
    setIsOpened(value);
  };

  const onFormulaSelection = useCallback(
    (formula: FieldMappingFormulaResponse) => {
      onFormulaSelectionChange(formula);
      handleOpenedChanged(false);
    },
    []
  );

  const allFormulaAccordionComponent = useCallback(() => {
    return (
      <div>
        <FormulaPickerAccordion onFormulaSelection={onFormulaSelection} mappingPlanningObjectType={mappingPlanningObjectType}/>
      </div>
    );
  }, [onFormulaSelection, mappingPlanningObjectType]);

  const onInput = (data: any)=> {
    let text = data.component.option("text");
    dispatch(setFormulaSearchText(text));
  }

  return (
    <>
      <PromineoDropdownBox
        placeholder="Select"
        width={395}
        dropDownOptions={{
          width: 700,
          maxHeight: 470,
          height: "auto",
        }}
        dataSource={[]}
        className={`promineo-tree-view-dropdown w-full`}
        contentComponent={allFormulaAccordionComponent}
        onOpenedChange={handleOpenedChanged}
        opened={isOpened}
        acceptCustomValue={true}
        onInput={onInput}
        showClearButton={true}
        openOnFieldClick={false}
        value={formulaSearchText}
      />
    </>
  );
}
