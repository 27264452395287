import CrossIcon from "components/icons/CrossIcon";
import "./styles/CrossButton.css";

interface Props {
  onDeleteClick: () => void;
  classNames?: string;
}

export default function CrossButton(props: Props) {
  const { onDeleteClick, classNames } = props;

  return (
    <div
      className={`cursor-pointer cross-button ${classNames}`}
      onClick={onDeleteClick}
    >
      <CrossIcon />
    </div>
  );
}
