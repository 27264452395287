import LabelWithContent from "components/common/LabelWithContent";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";
import HostSystemConfigurationSwitchButton from "features/setup/configs/host-system/HostSystemConfigurationSwitchButton";

interface Props extends BaseHostSystemComponentProps {
  switchButtonValue: boolean;
}

export default function AllowUpdateStructureDescriptionField(props: Props) {
  const {
    mode,
    fieldName,
    switchButtonValue,
    handleConfigurationValueChanged,
  } = props;

  return (
    <LabelWithContent
      label="Allow Update Of Structure Description"
      content={
        <HostSystemConfigurationSwitchButton
          mode={mode}
          fieldName={fieldName}
          switchButtonValue={switchButtonValue}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      }
    />
  );
}
