import BorderComponent from "components/common/BorderComponent";
import CrossIcon from "components/icons/CrossIcon";
import { ReactNode } from "react";

interface Props {
  onCancel: () => void;
  text: string;
  showCloseIcon: boolean;
  children?: ReactNode;
}

export default function PromineoModalHeader(props: Props) {
  return (
    <div>
      <div className="flex justify-between w-full">
        <div className="promineo-modal-header font-Poppins font-bold leading-4 text-[12px]">
          {props.text}
        </div>
        {props.showCloseIcon && (
          <div onClick={props.onCancel} className="cursor-pointer">
            <CrossIcon />
          </div>
        )}
      </div>
      {props.children}
      <BorderComponent customClass="my-4"/>
    </div>
  );
}
