import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { EditorMode } from "shared/enums/EditorMode";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";
import TextViewerComponent from "./safran/TextViewerComponent";

interface Props {
  mode: EditorMode;
  switchButtonValue: boolean;
  fieldName: string;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function HostSystemConfigurationSwitchButton(props: Props) {
  const {
    mode,
    switchButtonValue,
    fieldName,
    handleConfigurationValueChanged,
  } = props;
  const displayText = switchButtonValue ? "On" : "Off";

  return (
    <>
      {mode === EditorMode.Edit && (
        <PromineoSwitchButton
          checked={switchButtonValue}
          switchType={SwitchButtonType.Default}
          onChange={() => {
            if (handleConfigurationValueChanged) {
              handleConfigurationValueChanged(fieldName, !switchButtonValue);
            }
          }}
        />
      )}

      {mode === EditorMode.View && <TextViewerComponent text={displayText} />}
    </>
  );
}
