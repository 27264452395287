export default function WrenchIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3034 17.989L18.0056 17.2894L10.0059 9.31928C10.3235 8.93618 10.5703 8.50311 10.7462 8.02008C10.9214 7.53704 11.009 7.02902 11.009 6.49601C11.009 5.24678 10.5703 4.1891 9.69293 3.32296C8.81489 2.45683 7.74893 2.02376 6.49506 2.02376H6.24428L9.3288 5.09687L5.06564 9.31928L1.98112 6.24617V6.521C1.98112 7.77023 2.41981 8.82792 3.29718 9.69405C4.17523 10.5602 5.24118 10.9933 6.49506 10.9933C7.03004 10.9933 7.53594 10.9056 8.01274 10.7304C8.48888 10.5559 8.91921 10.3103 9.30373 9.99387L17.3034 17.989ZM15.8991 19.4131L8.97772 12.5173C8.5932 12.6672 8.19631 12.7838 7.78705 12.8671C7.37711 12.9504 6.95481 12.992 6.52013 12.992C4.69784 12.992 3.15574 12.3631 1.89385 11.1052C0.631283 9.84795 0 8.31157 0 6.49601C0 5.79644 0.104656 5.12186 0.313969 4.47226C0.522614 3.82265 0.827555 3.22302 1.22879 2.67336L5.06564 6.49601L6.49506 5.09687L2.65821 1.24923C3.22663 0.832822 3.83685 0.520347 4.48886 0.311809C5.14087 0.103936 5.80961 0 6.49506 0C8.31735 0 9.85978 0.628614 11.1223 1.88584C12.3842 3.14374 13.0152 4.68046 13.0152 6.49601C13.0152 6.92908 12.9734 7.35782 12.8898 7.78222C12.8062 8.2073 12.6808 8.61138 12.5136 8.99448L19.4099 15.8653C19.7945 16.2484 19.9911 16.7187 19.9998 17.2764C20.0078 17.8347 19.8195 18.3054 19.435 18.6885L18.7579 19.3881C18.3734 19.7879 17.9009 19.9917 17.3405 19.9997C16.7808 20.0084 16.3003 19.8128 15.8991 19.4131Z"
        fill="#BD9DE7"
      />
    </svg>
  );
}
