import ScrollView from "devextreme-react/scroll-view";
import { ControlSize } from "shared/enums/ControlSizeEnum";
export const getSizeSpecificClasses = (size?: ControlSize) => {
  let className = "h-10";
  if (size && size === ControlSize.Large) {
    return "h-53px";
  }

  if (size && size === ControlSize.Small) {
    return "h-9";
  }

  return className;
};

export const getControlRadius = (radiusSize?: ControlSize) => {
  let className = "rounded-sm";
  if (radiusSize && radiusSize === ControlSize.Midium) {
    return "rounded";
  }

  if (radiusSize && radiusSize === ControlSize.Large) {
    return "rounded-lg";
  }

  return className;
};

export const updateScrollViewToDisplayScrollbar = (
  scrollViewRef: React.RefObject<ScrollView>
) => {
  scrollViewRef.current?.instance.update();
};

export const scrollViewScrollToBottom = (
  scrollViewRef: React.RefObject<ScrollView>
) => {
  const scrollHeight = scrollViewRef?.current?.instance.scrollHeight();
  scrollViewRef?.current?.instance?.scrollTo(scrollHeight);
};
