import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import IEAStatusCellComponent from "./IEAStatusCellComponent";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import { useCallback, useState } from "react";
import DownloadConflictsDialog from "../conflicts/DownloadConflictsDialog";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { getLocalDateFromUtc } from "shared/utilities/DateTimeUtility";

export default function DownloadStatusCellComponent(e: {
  data: { data: ActiveExchangeAgreementListingResponse };
}) {
  const exchangeAgreement = e.data.data;
  const [isConflictPopupVisible, setIsConfigPopupVisible] = useState(false);

  const handleViewConflictsClick = () => {
    setIsConfigPopupVisible(true);
  };

  const handleCloseConflictViewer = () => {
    setIsConfigPopupVisible(false);
  };

  const getTooltipText = useCallback(() => {
    const exchangeAgreement = e.data.data;
    switch (exchangeAgreement.lastDownloadStatus) {
      case DataTransferStatus.Success:
        return `Download successful on ${getLocalDateFromUtc(exchangeAgreement.lastDownloadTime)}`;
      case DataTransferStatus.Fail:
        return `Download failed on ${getLocalDateFromUtc(exchangeAgreement.lastDownloadTime)}`;
      case DataTransferStatus.Overdue:
        if (exchangeAgreement.lastTransferConflictResolveOption > 0){
          return `Download overdue due to conflict resolution (occurred on ${getLocalDateFromUtc(exchangeAgreement.lastDownloadTime)})`;
        }
        return `Download overdue. Expected download date was ${getLocalDateFromUtc(exchangeAgreement.lastExpectedTransferTime)}`;
      case DataTransferStatus.Pending:
        if (exchangeAgreement.lastTransferConflictResolveOption > 0){
          return `Download pending due to conflict resolution (occurred on ${getLocalDateFromUtc(exchangeAgreement.lastDownloadTime)})`;
        }
        return '';
      default:
        return '';
    }
  }, [e.data.data.lastDownloadStatus]);

  return (
    <>
      <div className="flex gap-x-1 items-center">
        <div
          className={`${!exchangeAgreement.isLastDownloadFailedDueToConflicts ? "pt-1" : ""
            }`}
        >
          <IEAStatusCellComponent
            status={exchangeAgreement.lastDownloadStatus}
            tooltipText={getTooltipText()}
          />
        </div>
        {exchangeAgreement.isLastDownloadFailedDueToConflicts && (
          <PromineoButton
            height={20}
            text="View conflicts"
            variant={PromineoButtonType.Ternary}
            onClick={handleViewConflictsClick}
          />
        )}
      </div>
      {isConflictPopupVisible && (
        <DownloadConflictsDialog
          iea={e.data.data}
          onClose={handleCloseConflictViewer}
        />
      )}
    </>
  );
}
