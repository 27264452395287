import config from "react-global-configuration";
import beta from "./beta";
import dev from "./dev";
import local from "./local";
import prod from "./prod";
import azurerelease from "./azurerelease";

if (window.location.hostname === "localhost") {
  config.set(prod, { freeze: false });
  config.set(local, { assign: true });
} else if (window.location.hostname.indexOf("-beta") > 0) {
  config.set(prod, { freeze: false });
  config.set(beta, { assign: true });
} else if (window.location.hostname.indexOf("-dev") > 0) {
  config.set(prod, { freeze: false });
  config.set(dev, { assign: true });
} else if (window.location.hostname.indexOf("azurewebsites.net") > 0) {
  config.set(prod, { freeze: false });
  config.set(azurerelease, { assign: true });
} else {
  config.set(prod);
}
