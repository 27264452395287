import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import TenantAdminFieldResponse from "interfaces/response/TenantAdminFieldResponse";
import { ContentControl } from "shared/enums/feature/ContentControl";
import { updateFieldsContentControlForTenantAdminAsync } from "store/actions/TenantAdminActions";
import { useAppDispatch } from "store/hooks";
import TenantAdminContentControlDropdown from "features/tenant-admin/content-control/TenantAdminContentControlDropdown";
import { toastSuccess } from "shared/utilities/ToastUtility";

interface Props {
  field: TenantAdminFieldResponse;
  isDisabled?: boolean;
}

export default function TenantAdminContentControlLabelComponent(props: Props) {
  const dispatch = useAppDispatch();
  const field = props.field;

  const handleContentControlValueChange = (value: number) => {
    displayLoadingPanel();
    dispatch(
      updateFieldsContentControlForTenantAdminAsync({
        fieldId: field.id,
        request: { contentControl: value },
      })
    )
      .unwrap()
      .then(() => {
        toastSuccess("Content control updated successfully.");
      })
      .finally(hideLoadingPanel);
  };

  if (props.field.contentControl === ContentControl.System) {
    return <div className="mt-1 ml-1">{field.contentControlText}</div>;
  }

  return (
    <TenantAdminContentControlDropdown
      isDisabled={props.isDisabled}
      contentControl={props.field.contentControl}
      onValueChange={handleContentControlValueChange}
    />
  );
}
