import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";
import { useEffect, useState } from "react";
import {
  loadExportScopeValuesAsync,
  loadRFieldExportOptionValuesAsync,
} from "store/actions/DropdownValueActions";
import {
  loadStructureToExportCandidatesAsync,
  loadWbsStructureCandidatesAsync,
} from "store/actions/HostSystemActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";

export interface SafranSendingParameterDataLoaderProps {
  selectedCodeSet: number | null;
  selectedConnectorId: number;
}

export default function useSafranSendingParameterDataLoader(
  props: SafranSendingParameterDataLoaderProps
) {
  const dispatch = useAppDispatch();
  const { selectedCodeSet, selectedConnectorId } = props;

  const [wbsStructureCandidates, setWbsStructureCandidates] = useState<
    HostFieldDropdownValueResponse[]
  >([]);

  const [structuresToExportCandidates, setStructuresToExportCandidates] =
    useState<HostFieldDropdownValueResponse[]>([]);

  const exportScopes = useAppSelector(
    (state: RootState) => state.dropdownData.exportScopes
  );

  const rFieldExportOptions = useAppSelector(
    (state: RootState) => state.dropdownData.rFieldExportOptions
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadExportScopeValuesAsync()).finally(hideLoadingPanel);

    displayLoadingPanel();
    dispatch(loadRFieldExportOptionValuesAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  useEffect(() => {
    if (selectedCodeSet && selectedConnectorId) {
      displayLoadingPanel();
      dispatch(
        loadWbsStructureCandidatesAsync({
          connectorId: selectedConnectorId,
          codeSetId: selectedCodeSet,
        })
      )
        .unwrap()
        .then((wbsCandidateList: HostFieldDropdownValueResponse[]) => {
          setWbsStructureCandidates(wbsCandidateList);
        })
        .finally(hideLoadingPanel);

      displayLoadingPanel();
      dispatch(
        loadStructureToExportCandidatesAsync({
          connectorId: selectedConnectorId,
          codeSetId: selectedCodeSet,
        })
      )
        .unwrap()
        .then((candidateList: HostFieldDropdownValueResponse[]) => {
          setStructuresToExportCandidates(candidateList);
        })
        .finally(hideLoadingPanel);
    }
  }, [dispatch, selectedConnectorId, selectedCodeSet]);

  return {
    wbsStructureCandidates,
    structuresToExportCandidates,
    exportScopes,
    rFieldExportOptions,
  };
}
