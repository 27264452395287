import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";

export default function UploadWarningCountComponent(e: {
  data: { data: ActiveExchangeAgreementListingResponse };
}) {
  return e.data.data.uploadWarningCount ? (
    <>{e.data.data.uploadWarningCount}</>
  ) : (
    <>{"-"}</>
  );
}
