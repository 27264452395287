import { ConnectorHealthCheckStatus } from "shared/enums/feature/ConnectorHealthCheckStatus";
import ConnectorHealthCheckStatusCircle from "components/common/ConnectorHealthCheckStatusCircle";

interface Props {
  healthCheckStatus: ConnectorHealthCheckStatus;
  healthCheckedAt?: string;
  isHealthCheckRequestAllowed: boolean;
}

export default function LastHealthCheckComponent(props: Props) {
  const { healthCheckStatus, healthCheckedAt } =
    props;

  return (
    <div className="flex h-3">
      <ConnectorHealthCheckStatusCircle
        healthCheckStatus={healthCheckStatus}
        healthCheckedAt={healthCheckedAt}
      />
    </div>
  );
}
