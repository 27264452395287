import { useAppSelector } from "store/hooks";
import SapParameterDropdown from "./SapParameterDropdown";
import { applyGridChangesToDataSource } from "shared/utilities/PromineoGridUtility";
import { SapHostParameterGridKey } from "shared/utilities/HostSystemConfigurationUtility";
import { EditorMode } from "shared/enums/EditorMode";

export default function FunctionTypeDropdownCellEdit(props: any) {
  const data = props.data.data;
  const functionTypes = useAppSelector(
    (store) => store.dropdownData.sapFunctionTypes
  );

  const handleValueChange = (value: any) => {
    props.data.component.cellValue(
      props.data.row.rowIndex,
      "functionType",
      value
    );

    applyGridChangesToDataSource(SapHostParameterGridKey, props.data.component);
  };

  if (props.mode === EditorMode.View) {
    const functionTypeDisplay =
      (functionTypes || []).find((o) => o.value === data.functionType)?.name ??
      "-";

    return <>{functionTypeDisplay}</>;
  }
  
  return (
    <SapParameterDropdown
      dataSource={functionTypes}
      defaultValue={data.functionType}
      onValueChange={handleValueChange}
    />
  );
}
