import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import { getDisplayExpressionForScheduleSelectorDropdown } from "shared/utilities/IEAUtility";

export default function ScheduleSelectionDropdownItemRenderComponent(
  connectorSchedule: ConnectorScheduleSimplifiedResponse
) {
  return (
    <div
      className="truncate"
      title={getDisplayExpressionForScheduleSelectorDropdown(connectorSchedule)}
    >
      {getDisplayExpressionForScheduleSelectorDropdown(connectorSchedule)}
    </div>
  );
}
