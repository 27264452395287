import { SapOrderFilter } from "interfaces/common/SapOrderFilter";

export const ExamplesParentName = "Examples";
export const OperatorsParentName = "Operators";
export const FunctionsParentName = "Functions";

export const SapOrderFilterList: SapOrderFilter[] = [
  {
    title: ExamplesParentName,
    example: ExamplesParentName
  },
  {
    title: OperatorsParentName,
    example: OperatorsParentName
  },
  {
    title: FunctionsParentName,
    example: FunctionsParentName
  },
  {
    parent: ExamplesParentName,
    title: "Order.OrderType eq 'X'",
    example: "OrderType eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.Orderid eq 'X'",
    example: "Orderid eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.Planplant eq 'X'",
    example: "Planplant eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.Plant eq 'X'",
    example: "Plant eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.MnWkCtr eq 'X'",
    example: "MnWkCtr eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.Plangroup eq 'X'",
    example: "Plangroup eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.FunctLoc eq 'X'",
    example: "FunctLoc eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.Maintplant eq 'X'",
    example: "Maintplant eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.Location eq 'X'",
    example: "Location eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.Plsectn eq 'X'",
    example: "Plsectn eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.SuperiorNetwork eq 'X'",
    example: "SuperiorNetwork eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.SuperiorActivity eq 'X'",
    example: "SuperiorActivity eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.WbsElem eq 'X'",
    example: "WbsElem eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.LocWbsElem eq 'X'",
    example: "LocWbsElem eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.ScheduledStartTime ge datetimeoffset'2017-12-31T23:00:00Z'",
    example: "ScheduledStartTime ge datetimeoffset'2017-12-31T23:00:00Z'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.ScheduledFinishTime le datetimeoffset'2018-12-31T23:00:00Z'",
    example: "ScheduledFinishTime le datetimeoffset'2018-12-31T23:00:00Z'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.LocWkCtr eq 'X'",
    example: "LocWkCtr eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.RevisionNumber eq 'X'",
    example: "RevisionNumber eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.SysStatus eq 'X'",
    example: "SysStatus eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.Userstatus eq 'X'",
    example: "Userstatus eq 'X'"
  },
  {
    parent: ExamplesParentName,
    title: "Order.MaintenanceProcessingPhaseCode eq 'X'",
    example: "MaintenanceProcessingPhaseCode eq 'X'"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Equal': eq",
    example: "eq"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Not equal': ne",
    example: "ne"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Greater than': gt",
    example: "gt"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Greater than or equal': ge",
    example: "ge"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Less than': lt",
    example: "lt"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Less than or equal': le",
    example: "le"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Logical and': and",
    example: "and"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Logical or': or",
    example: "or"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Logical negation': not",
    example: "not"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Addition': add",
    example: "add"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Subtraction': sub",
    example: "sub"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Multiplication': mul",
    example: "mul"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Division': div",
    example: "div"
  },
  {
    parent: OperatorsParentName,
    title: "Operator 'Modulo': mod",
    example: "mod"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'substringof()': substringof(string po, string p1)",
    example: "substringof(string po, string p1)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'endswith()': endswith(string p0, string p1)",
    example: "endswith(string p0, string p1)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'startswith()': startswith(string p0, string p1)",
    example: "startswith(string p0, string p1)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'length()': length(string p0)",
    example: "length(string p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'indexof()': indexof(string p0, string p1)",
    example: "indexof(string p0, string p1)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'replace()': replace(string p0, string find, string replace)",
    example: "replace(string p0, string find, string replace)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'substring()': substring(string p0, int pos)",
    example: "substring(string p0, int pos)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'substring()': substring(string p0, int pos, int length)",
    example: "substring(string p0, int pos, int length)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'tolower()': tolower(string p0)",
    example: "tolower(string p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'toupper()': toupper(string p0)",
    example: "toupper(string p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'trim()': trim(string p0)",
    example: "trim(string p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'concat()': concat(string p0, string p1)",
    example: "concat(string p0, string p1)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'day()': day(DateTime p0)",
    example: "day(DateTime p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'hour()': hour(DateTime p0)",
    example: "hour(DateTime p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'minute()': minute(DateTime p0)",
    example: "minute(DateTime p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'month()': month(DateTime p0)",
    example: "month(DateTime p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'second()': second(DateTime p0)",
    example: "second(DateTime p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'year()': year(DateTime p0)",
    example: "year(DateTime p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'round()': round(double p0)",
    example: "round(double p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'round()': round(decimal p0)",
    example: "round(decimal p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'floor()': floor(double p0)",
    example: "floor(double p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'floor()': floor(decimal p0)",
    example: "floor(decimal p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'ceiling()': ceiling(double p0)",
    example: "ceiling(double p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'ceiling()': ceiling(decimal p0)",
    example: "ceiling(decimal p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'IsOf()': IsOf(type p0)",
    example: "IsOf(type p0)"
  },
  {
    parent: FunctionsParentName,
    title: "Function 'IsOf()': IsOf(expression p0, type p1)",
    example: "IsOf(expression p0, type p1)"
  },
];
