import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import ReceivedTemplates from "./ReceivedTemplates";
import TenantTemplates from "./TenantTemplates";
import { Item } from "devextreme-react/tab-panel";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function Templates() {
  const headerDivId: string = "templates-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 150,
  });

  return (
    <>
      <div className="mb-4" id={headerDivId}>
        <PageHeaderTitle title="Templates" />
      </div>
      <PromineoTabPanel>
        <Item title={"Tenant Templates"}>
          <div className="p-4">
            <TenantTemplates height={gridHeight} />
          </div>
        </Item>
        <Item title={"Received Templates"}>
          <div className="p-4">
            <ReceivedTemplates height={gridHeight} />
          </div>
        </Item>
      </PromineoTabPanel>
    </>
  );
}
