import DateBox, { IDateBoxOptions } from "devextreme-react/date-box";
import ExpandIcon from "components/icons/ExpandIcon";
import "./styles/PromineoDatepicker.css";

interface Props {
  className?: string;
}

export default function PromineoDatepicker(
  props: Props & IDateBoxOptions
) {
  const {
    className: propClassNames,
    label,
    id,
    ...rest
  } = props;

  const getClassNames = () => {
    const classNames = `h-6 w-188px`;
    return propClassNames ? `${classNames} ${propClassNames}` : classNames;
  };

  return (
    <div className="promineo-datepicker">
      <DateBox
        acceptCustomValue={false}
        openOnFieldClick={true}
        dropDownButtonRender={() => (
          <ExpandIcon/>
        )}
        {...rest}
        className={getClassNames()}
      />
    </div>
  );
}
