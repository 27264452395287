import { createSlice } from "@reduxjs/toolkit";
import DetailedTemplateResponse from "interfaces/response/DetailedTemplateResponse";
import OwnTemplateResponse from "interfaces/response/OwnTemplateResponse";
import ReceivedTemplateResponse from "interfaces/response/ReceivedTemplateResponse";
import ResponseBase from "interfaces/response/ResponseBase";
import TemplateDropdownResponse from "interfaces/response/TemplateDropdownResponse";
import TemplateShareResponse from "interfaces/response/TemplateShareResponse";
import {
  addNewTemplateAsync,
  loadOwnTemplatesAsync,
  loadReceivedTemplatesAsync,
  loadSimplifiedTemplatesAsync,
  loadTemplateByIdAsync,
  modifyTemplateAsync,
  loadTemplateSharedDataListAsync,
  shareTemplateWithTenantAsync,
  copyTemplateByIdAsync,
  removeTemplateByIdAsync,
} from "store/actions/TemplateActions";

export interface TemplateState {
  currentTemplate: DetailedTemplateResponse | null;
  ownTemplates: OwnTemplateResponse[];
  receivedTemplates: ReceivedTemplateResponse[];
  simplifiedTemplates: TemplateDropdownResponse | null;
  currentTemplateShareData: TemplateShareResponse[];
  newTemplateShare: TemplateShareResponse | null;
  copiedTemplate: ResponseBase | null;
}

const initialState: TemplateState = {
  currentTemplate: null,
  ownTemplates: [],
  receivedTemplates: [],
  simplifiedTemplates: null,
  currentTemplateShareData: [],
  newTemplateShare: null,
  copiedTemplate: null,
};

const updateTemplateShareCountInState = (
  currentTemplateId: number,
  state: TemplateState
) => {
  if (state.currentTemplate && state.currentTemplate.id === currentTemplateId) {
    state.currentTemplate.shareCount++;
  }

  const existingTemplateIndex = state.ownTemplates.findIndex(
    (template: OwnTemplateResponse) => template.id === currentTemplateId
  );

  if (existingTemplateIndex > -1) {
    state.ownTemplates[existingTemplateIndex].shareCount++;
  }
};

export const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    resetCurrentTemplate: (state) => {
      state.currentTemplate = null;
    },
    resetCurrentTemplateShareData: (state) => {
      state.currentTemplateShareData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTemplateByIdAsync.pending, (state, action) => {
      state.currentTemplate = null;
    });
    builder.addCase(loadTemplateByIdAsync.fulfilled, (state, action) => {
      state.currentTemplate = action.payload;
    });
    
    builder.addCase(modifyTemplateAsync.fulfilled, (state, action) => {
      state.currentTemplate = action.payload;
    });

    builder.addCase(addNewTemplateAsync.fulfilled, (state, action) => {
      state.currentTemplate = action.payload;
    });

    builder.addCase(loadOwnTemplatesAsync.pending, (state, action) => {
      state.ownTemplates = [];
    });
    builder.addCase(loadOwnTemplatesAsync.fulfilled, (state, action) => {
      state.ownTemplates = action.payload;
    });

    builder.addCase(loadReceivedTemplatesAsync.pending, (state, action) => {
      state.receivedTemplates = [];
    });
    builder.addCase(loadReceivedTemplatesAsync.fulfilled, (state, action) => {
      state.receivedTemplates = action.payload;
    });

    builder.addCase(loadSimplifiedTemplatesAsync.pending, (state, action) => {
      state.simplifiedTemplates = null;
    });
    builder.addCase(loadSimplifiedTemplatesAsync.fulfilled, (state, action) => {
      state.simplifiedTemplates = action.payload;
    });

    builder.addCase(
      loadTemplateSharedDataListAsync.pending,
      (state, action) => {
        state.currentTemplateShareData = [];
      }
    );
    builder.addCase(
      loadTemplateSharedDataListAsync.fulfilled,
      (state, action) => {
        state.currentTemplateShareData = action.payload;
      }
    );

    builder.addCase(shareTemplateWithTenantAsync.pending, (state, action) => {
      state.newTemplateShare = null;
    });
    builder.addCase(shareTemplateWithTenantAsync.fulfilled, (state, action) => {
      state.newTemplateShare = action.payload;
      updateTemplateShareCountInState(action.meta.arg.templateId, state);
    });
    builder.addCase(copyTemplateByIdAsync.pending, (state, action) => {
      state.copiedTemplate = null;
    });
    builder.addCase(copyTemplateByIdAsync.fulfilled, (state, action) => {
      state.copiedTemplate = action.payload;
    });
    builder.addCase(removeTemplateByIdAsync.fulfilled, (state, action) => {
      // Nothing if successfully deleted 
      // Template list is not cached, so do not need to remove deleted template from cache
    });
  },
});

export const { resetCurrentTemplate, resetCurrentTemplateShareData } =
  templateSlice.actions;
export default templateSlice.reducer;
