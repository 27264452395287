import DataTransferConflictResponse from "interfaces/response/DataTransferConflictResponse";
import { getDataAsync, postDataAsync } from "./API";
import DataTransferConflictResolveRequest from "interfaces/request/DataTransferConflictResolveRequest";

export const getDownloadConflictsAsync = async (dataTransferId: number) => {
  const url = `DataTransfers/${dataTransferId}/Conflicts`;
  return await getDataAsync<DataTransferConflictResponse[]>(url);
};

export const setConflictResolveApproach = async (
  dataTransferId: number,
  conflictResolveRequest: DataTransferConflictResolveRequest
) => {
  const url = `DataTransfers/${dataTransferId}/Conflicts/ResolveApproach`;
  return await postDataAsync(url, conflictResolveRequest);
};
