import PromineoButton from "components/common/controls/buttons/PromineoButton";
import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";
interface Props {
  onClick: () => void;
}

export default function ShareTemplateButton(props: Props) {
  return (
    <PromineoButton
      text="Share"
      variant={PromineoButtonType.Primary}
      onClick={props.onClick}
    />
  );
}
