import LabelWithCircle from "./LabelWithCircle";
import { ConnectorHealthCheckStatus } from "shared/enums/feature/ConnectorHealthCheckStatus";
import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import { getLocalFormattedDateTime } from "shared/utilities/DateTimeUtility";

interface Props {
  healthCheckStatus: ConnectorHealthCheckStatus;
  healthCheckedAt?: string;
}

export default function ConnectorHealthCheckStatusCircle(props: Props) {
  let circleColor = PromineoColorVariant.Red;

  if (props.healthCheckStatus === ConnectorHealthCheckStatus.RecentlyHealthy) {
    circleColor = PromineoColorVariant.IlapGreen;
  } else if (
    props.healthCheckStatus === ConnectorHealthCheckStatus.HealthyButNotCheckedRecently
  ) {
    circleColor = PromineoColorVariant.Orange;
  }

  let label = props.healthCheckedAt
    ? getLocalFormattedDateTime(props.healthCheckedAt)
    : "";

  return <LabelWithCircle color={circleColor} text={label} />;
}
