import DropdownValuesWithExecutionComponentResponse from "interfaces/response/DropdownValuesWithExecutionComponentResponse";
import { getDataAsync } from "./API";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";

export const getDropdownValuesAsync = async (dropdownTableName: string) => {
  const routeUrl = `Fields/Options/${dropdownTableName}`;

  return await getDataAsync<DropdownValuesResponse[]>(routeUrl);
};

export const getDropdownValuesWithExecutionComponentAsync = async (
  dropdownTableName: string
) => {
  const routeUrl = `Fields/Options/ExecutionComponent/${dropdownTableName}`;

  return await getDataAsync<DropdownValuesWithExecutionComponentResponse[]>(
    routeUrl
  );
};
