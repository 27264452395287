import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import {
  loadTemplateFieldByTemplateFieldIdAsync,
  updateTemplateFieldAsync,
} from "store/actions/FieldActions";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import FieldValueWriteRequest from "interfaces/request/FieldValueWriteRequest";
import FieldHeader from "./FieldHeader";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import EditFieldValueGrid from "features/common/edit-field-value-grid/EditFieldValueGrid";
import FieldValue from "interfaces/common/FieldValue";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
import { validateFieldValues } from "shared/utilities/FieldValueUtility";
import ErrorDisplayModal from "features/common/error-display-modal/ErrorDisplayModal";

export default function EditField() {
  const param = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [
    isUnsavedChangeConfirmationVisible,
    setIsUnsavedChangeConfirmationVisible,
  ] = useState(false);

  const [allowContentControl, setAllowContentControl] = useState<
    boolean | null | undefined
  >(false);
  const [allowBlanks, setAllowBlanks] = useState<boolean | null | undefined>(
    false
  );
  const [fieldValueDataSource, setFieldValueDataSource] = useState<
    FieldValue[]
  >([]);
  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadcrumbItem[]>([]);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

  const templateField = useAppSelector(
    (state) => state.fieldData.currentTemplateField
  );

  useEffect(() => {
    if (templateField) {
      const items: BreadcrumbItem[] = [
        {
          key: "templates",
          text: "Templates",
          data: { url: TEMPLATES },
        },
        {
          key: "templates-name",
          text: templateField.templateName,
          data: { url: `${TEMPLATES}/${templateField.templateId}` },
        },
        {
          key: "field-name",
          text: templateField.name,
        },
      ];

      setBreadCrumbItems(items);
    }
  }, [templateField]);

  useEffect(() => {
    if (param.templateFieldId) {
      var templateFieldId = Number(param.templateFieldId);
      displayLoadingPanel();
      dispatch(
        loadTemplateFieldByTemplateFieldIdAsync(templateFieldId)
      )
      .finally(hideLoadingPanel);
    }
  }, []);

  useEffect(() => {
    if (templateField) {
      const fetchedTemplateFieldValue: FieldValue[] = templateField.values.map(
        (fd) => {
          return {
            valueCode: fd.code,
            description: fd.description,
            id: fd.id,
            __KEY__: fd._key_,
          } as FieldValue;
        }
      );

      setFieldValueDataSource(fetchedTemplateFieldValue);
      setAllowContentControl(templateField.allowContentControl);
      setAllowBlanks(templateField.allowBlank);
    }
  }, [templateField]);

  const handleBreadCrumbSelectionChange = (
    ind: number,
    item: BreadcrumbItem
  ) => {
    if (item.data?.url) {
      navigate(item.data.url);
    }
  };

  const handleSaveChangesClick = () => {
    const result = validateFieldValues(fieldValueDataSource);

    if (!result.isValidationSuccessful){
      setValidationErrors(result.errors)
      showErrorModal();
      return;
    }

    const templateFieldValues: FieldValueWriteRequest[] =
      fieldValueDataSource.map((fd) => {
        return {
          id: fd.id,
          code: fd.valueCode,
          description: fd.description,
        } as FieldValueWriteRequest;
      });

    const templateFieldUpdateRequest: FieldManageRequest = {
      allowContentControl: allowContentControl ?? false,
      allowBlank: allowBlanks ?? false,
      values: allowContentControl ? templateFieldValues : [],
    };

    if (templateField != null) {
      displayLoadingPanel();
      dispatch(
        updateTemplateFieldAsync({
          templateFieldId: templateField.id,
          manageRequest: templateFieldUpdateRequest,
        })
      )
        .unwrap()
        .then(() => {
          toastSuccess("Save changed successfully.");
          navigate(`${TEMPLATES}/${templateField.templateId}`);
        })
        .finally(hideLoadingPanel);
    }
  };

  const headerDivId: string = "template-edit-field-header";
  const footerDivId: string = "template-edit-field-footer";
  const excludedContainerIds: string[] = [headerDivId, footerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 140,
  });

  const handleFieldValueDatasourceChange = (value: any) => {
    setFieldValueDataSource(value);
    setIsModified(true);
  };

  const handleAllowContentControlChange = (value: boolean) => {
    setAllowContentControl(value);
    setIsModified(true);
  };

  const handleAllowBlankChange = (value: boolean) => {
    setAllowBlanks(value);
    setIsModified(true);
  };

  const handleRowUpdated = () => {
    setIsModified(true);
  };

  const navigateForCancellation = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    if (isModified) {
      setIsUnsavedChangeConfirmationVisible(true);
    } else {
      navigateForCancellation();
    }
  };

  const handleConfirmCancel = () => {
    setIsUnsavedChangeConfirmationVisible(false);
    navigateForCancellation();
  };

  const handleCancelConfirmationDialog = () => {
    setIsUnsavedChangeConfirmationVisible(false);
  };

  const showErrorModal = () => setIsErrorModalVisible(true);
  const hideErrorModal = () => setIsErrorModalVisible(false);

  if (!templateField) {
    return <></>;
  }

  return (
    <div>
      <div id={headerDivId}>
        <div className="mb-1">
          <Breadcrumb
            items={breadCrumbItems}
            onSelectionChange={handleBreadCrumbSelectionChange}
          />
        </div>
        <FieldHeader template={templateField} />
      </div>

      <div className="w-560px h-fit ml-auto mr-auto">
        <EditFieldValueGrid
          fieldValueDataSource={fieldValueDataSource}
          setFieldValueDataSource={handleFieldValueDatasourceChange}
          allowContentControl={allowContentControl ?? false}
          setAllowContentControl={handleAllowContentControlChange}
          allowBlanks={allowBlanks ?? false}
          setAllowBlanks={handleAllowBlankChange}
          height={gridHeight}
          onRowUpdated={handleRowUpdated}
        />
      </div>
      <div className="flex justify-between mt-6" id={footerDivId}>
        <PromineoButton
          variant={PromineoButtonType.Secondary}
          text="Cancel"
          onClick={handleCancelClick}
        />
        <PromineoButton
          variant={PromineoButtonType.Success}
          text="Save Changes"
          onClick={handleSaveChangesClick}
        />
      </div>
      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmCancel}
          onCancel={handleCancelConfirmationDialog}
        ></PromineoCancelEditingConfirmationDialog>
      ) : null}
      {isErrorModalVisible && <ErrorDisplayModal errors={validationErrors} onHideDialog={hideErrorModal} />}
    </div>
  );
}
