import { ReactElement } from "react";
import PageHeaderTitle from "./PageHeaderTitle";
import WidgetsWithSeparator from "./WidgetsWithSeparator";
import PromineoMultilineTextEdit from "../controls/PromineoMultilineTextEdit";
import PromineoDropdownButton from "../dropdown-button/PromineoDropdownButton";

export interface PageOptionItem {
  id: number;
  text: string;
  onSelectionChange: () => void;
}

export interface PageOption {
  isVisible: boolean;
  items: PageOptionItem[];
  dropdownPopupWidth: string | number;
}

interface Props {
  isEdit?: boolean;
  title: string;
  description?: string;
  placeholder?: string;
  titleWidget?: ReactElement;
  headerWidgets?: ReactElement[];
  hideBottomBorder?: boolean;
  onTitleChanged?: (value: any) => void;
  onDescriptionChanged?: (value: any) => void;
  displayDescriptionField?: boolean;
  pageOptions?: PageOption;
}
export default function PageHeader(props: Props) {
  const handlePageOptionSelectionChange = (selection: any) => {
    var selectedItem = selection.itemData;
    selectedItem?.onSelectionChange();
  };

  return (
    <div
      className={`mb-4 border-lightGray text-darkBlue w-full ${
        !props.hideBottomBorder ? "border-b pb-4" : ""
      }`}
    >
      <div className="flex justify-between">
        <div className={`w-full space-y-2`}>
          <div className="flex items-center justify-between">
            <div className="w-full flex items-center space-x-3">
              <PageHeaderTitle
                placeHolder={props.placeholder}
                title={props.title}
                onTitleChanged={props.onTitleChanged}
                isEdit={props.isEdit}
              />
              <div>{props.titleWidget ?? <></>}</div>
            </div>
            <div>
              {props.pageOptions?.isVisible && (
                <PromineoDropdownButton
                  text="Options"
                  items={props.pageOptions?.items}
                  displayExpr={"text"}
                  onItemClick={handlePageOptionSelectionChange}
                  dropDownOptions={{
                    width: props.pageOptions.dropdownPopupWidth,
                  }}
                />
              )}
            </div>
          </div>

          {props.displayDescriptionField && (
            <>
              {props.isEdit ? (
                <PromineoMultilineTextEdit
                  borderRound="large"
                  placeholder="Type description..."
                  onValueChanged={props.onDescriptionChanged}
                  defaultValue={props.description}
                />
              ) : (
                <div className="text-sm line-clamp-2" title={props.description}>
                  {props.description}
                </div>
              )}
            </>
          )}

          {props.headerWidgets && (
            <div className="h-auto">
              <WidgetsWithSeparator widgets={props.headerWidgets} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
