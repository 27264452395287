import { useEffect, useState } from "react";
import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";
import { EditorMode } from "shared/enums/EditorMode";
import PrimaveraExportConfig from "interfaces/host-system-config/primavera/PrimaveraExportConfig";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import {
  loadStructureToExportCandidatesAsync,
  loadWbsStructureCandidatesAsync,
} from "store/actions/HostSystemActions";
import { useAppDispatch } from "store/hooks";
import {
  otherStructuresPropertyName,
  wbsStructureExportFieldNamePropertyName,
} from "shared/constants/PrimaveraHostParameterPropertyName";
import OtherStructureField from "features/common/host-system-parameter-fields/safran/OtherStructureField";
import WbsField from "features/common/host-system-parameter-fields/safran/WbsField";

interface Props {
  mode: EditorMode;
  height: number;
  selectedConnectorId: number;
  exportConfig: PrimaveraExportConfig;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
}

export default function PrimaveraSendingParameterFields(props: Props) {
  const {
    mode,
    selectedConnectorId,
    exportConfig,
    handleConfigurationValueChanged,
    isOverride
  } = props;

  const classNames = isOverride
    ? "grid grid-cols-3 gap-x-16 gap-y-8"
    : "space-y-6";

  const [wbsStructureCandidates, setWbsStructureCandidates] = useState<
    HostFieldDropdownValueResponse[]
  >([]);

  const [structuresToExportCandidates, setStructuresToExportCandidates] =
    useState<HostFieldDropdownValueResponse[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedConnectorId) {
      displayLoadingPanel();
      dispatch(
        loadWbsStructureCandidatesAsync({
          connectorId: selectedConnectorId,
          codeSetId: 0, // For Primavera, codeSetId is always null. For this reason, we are sending 0 here.
        })
      )
        .unwrap()
        .then((wbsCandidateList: HostFieldDropdownValueResponse[]) => {
          setWbsStructureCandidates(wbsCandidateList);
        })
        .finally(hideLoadingPanel);

      displayLoadingPanel();
      dispatch(
        loadStructureToExportCandidatesAsync({
          connectorId: selectedConnectorId,
          codeSetId: 0,
        })
      )
        .unwrap()
        .then((candidateList: HostFieldDropdownValueResponse[]) => {
          setStructuresToExportCandidates(candidateList);
        })
        .finally(hideLoadingPanel);
    }
  }, [dispatch, selectedConnectorId]);

  return (
    <div className={classNames}>
      <WbsField
        mode={mode}
        fieldName={wbsStructureExportFieldNamePropertyName}
        wbsCandidates={wbsStructureCandidates}
        wbsValue={exportConfig.wbsStructureExportFieldName}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <OtherStructureField
        mode={mode}
        structuresToExportCandidates={structuresToExportCandidates}
        fieldName={otherStructuresPropertyName}
        selectedValues={exportConfig.otherStructures}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
    </div>
  );
}
