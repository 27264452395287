import NoParamtersRequiredText from "./NoParamtersRequiredText";

interface Props {
  headerText: string;
}

export default function HostSystemNoParameterInfoComponent(props: Props) {
  const { headerText } = props;

  return (
    <div className="flex space-x-32 text-textGray">
      <div className="w-60 font-semibold text-base">{headerText}</div>

      <NoParamtersRequiredText />
    </div>
  );
}
