import AnalyticsConnectorAuthorityField from "./AnalyticsConnectorAuthorityField";
import AnalyticsClientIdField from "./AnalyticsConnectorClientIdField";
import AnalyticsScopesField from "./AnalyticsConnectorScopesField";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";

interface Props {
  configuration: AnalyticsConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
}

export default function AnalyticsAzureAdAuthentication(props: Props) {
  return (
    <div
      className={
        props.mode === PromineoModalMode.View ||
        props.mode === PromineoModalMode.Modify
          ? "grid grid-cols-2 gap-x-60"
          : ""
      }
    >
      <AnalyticsConnectorAuthorityField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />

      <AnalyticsClientIdField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />

      <AnalyticsScopesField
        configuration={props.configuration}
        mode={props.mode}
        onValueChange={props.handleConfigurationValueChanged}
        validateOnFocusOut={props.validateOnFocusOut}
      />
    </div>
  );
}
