import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDownloadConflictsAsync,
  setConflictResolveApproach,
} from "apis/ConflictApi";
import DataTransferConflictResolveRequest from "interfaces/request/DataTransferConflictResolveRequest";

export const loadDownloadConflictsAsync = createAsyncThunk(
  "conflicts/loadDownloadConflicts",
  async (dataTransferId: number) => {
    return await getDownloadConflictsAsync(dataTransferId);
  }
);

export const saveConflictResolveApproachAsync = createAsyncThunk(
  "conflicts/saveConflictResolveApproach",
  async (args: { dataTransferId: number; conflictResolveRequest: DataTransferConflictResolveRequest }) => {
    return await setConflictResolveApproach(
      args.dataTransferId,
      args.conflictResolveRequest
    );
  }
);
