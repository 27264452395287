interface Props {
  fillColor?: "white" | "black";
}
export default function LandingIllustration(props: Props) {
  return (
    <svg
      height="100%"
      viewBox="0 0 258 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="75.5"
        cy="484.5"
        r="8.5"
        transform="rotate(90 75.5 484.5)"
        fill="white"
      />
      <circle
        cx="108.5"
        cy="235.5"
        r="8.5"
        transform="rotate(90 108.5 235.5)"
        fill="white"
      />
      <circle
        cx="150.5"
        cy="277.5"
        r="16.5"
        transform="rotate(90 150.5 277.5)"
        fill="white"
      />
      <circle
        cx="152.5"
        cy="405.5"
        r="8.5"
        transform="rotate(90 152.5 405.5)"
        fill="white"
      />
      <circle
        cx="249.5"
        cy="178.5"
        r="8.5"
        transform="rotate(90 249.5 178.5)"
        fill="white"
      />
      <path d="M147.5 274L109 235.5L109 -1" stroke="white" />
      <path
        d="M152.5 277.5L152.5 405.5L74 484L74.0001 739.5L1.00006 812.5"
        stroke="white"
      />
      <path d="M152 277L250.5 178.5L250.5 -1.00001" stroke="white" />
      <circle
        cx="107.5"
        cy="449.5"
        r="8.5"
        transform="rotate(90 107.5 449.5)"
        fill="white"
      />
      <circle
        cx="75.5"
        cy="736.5"
        r="8.5"
        transform="rotate(90 75.5 736.5)"
        fill="white"
      />
      <circle
        cx="107.5"
        cy="319.5"
        r="8.5"
        transform="rotate(90 107.5 319.5)"
        fill="white"
      />
      <line
        x1="146.354"
        y1="280.354"
        x2="106.354"
        y2="320.354"
        stroke="white"
      />
      <line x1="106.5" y1="320" x2="106.5" y2="824" stroke="white" />
      <line x1="152.5" y1="313" x2="152.5" y2="-2" stroke="white" />
    </svg>
  );
}
