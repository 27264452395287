import { ActivityFilter } from "interfaces/common/ActivityFilter";

export const commonActivityFilterList: ActivityFilter[] = [
  {
    title: `Activity Code (Id) starts with "A1"`,
    example: `(Activity.Code.StartsWith("A1")=True)`,
  },
  {
    title: `Activity Code (Id) ends with "A1"`,
    example: `(Activity.Code.EndsWith("A1")=True)`,
  },
  {
    title: `Activity Code (Id) contains "A1"`,
    example: `(Activity.Code.Contains("A1")=True)`,
  },
  {
    title: `The first 3 characters of activity description is "aBc" (case sensitive)`,
    example: `(Activity.Description.Substring(0,3)="aBc")`,
  },
  {
    title: `The first 3 characters of activity description is "ABC" (case insensitive)`,
    example: `(Activity.Description.Substring(0,3).ToUpper()="ABC")`,
  },
  {
    title: `Activity is not cancelled`,
    example: `(Activity.IsCancelled=False)`,
  },
  {
    title: `Activity scope hours is between 100 and 200`,
    example: `(Activity.ActivityHoursOfWork>=100) & (Activity.ActivityHoursOfWork<=200)`,
  },
  {
    title: `Activity is a start milestone`,
    example: `(Activity.ActivityType.ToString()="MilestoneStart")`,
  },
  {
    title: `Activity is a finish milestone`,
    example: `(Activity.ActivityType.ToString()="MilestoneFinish")`,
  },
  {
    title: `Activity is a milestone (start or finish)`,
    example: `(Activity.ActivityType.ToString().StartsWith("Milestone")=True)`,
  },
  {
    title: `Activity is not a milestone`,
    example: `(Activity.ActivityType.ToString()="RegularActivity")`,
  },
  {
    title: `Activity Code's last 2 characters are "A1"`,
    example: `(Sys.Right(Activity.Code, 2)="A1")`,
  },
  {
    title: `The Left part of Activity Code split on "." is "B1"`,
    example: `(Sys.GetPart(Activity.Code, ".", "L")="B1")`,
  },
  {
    title: `The Right part of Activity Code split on "." is "B1"`,
    example: `(Sys.GetPart(Activity.Code, ".", "R")="B1")`,
  },
  {
    title: `The second part of Activity Code split on "." is "B1" (for multiple ".")`,
    example: `(Sys.GetPartIndex(Activity.Code, ".", 1)="B1")`,
  },
  {
    title: `Activity progress is greater than 10%`,
    example: `(Activity.CurrentProgress > 10.0)`,
  },
  {
    title: `Multiple conditions using AND (&) operator`,
    example: `(Activity.CurrentProgress > 10.0) & (Activity.Code.StartsWith("A")=True)`,
  },
  {
    title: `Multiple conditions using  OR (|) operator`,
    example: `(Activity.CurrentProgress > 10.0) | (Activity.Code.StartsWith("A")=True)`,
  },
  {
    title: `EarlyFinish date is before or equal to today's date`,
    example: `(Activity.EarlyFinish <= DateTime.Today)`,
  },
  {
    title: `Actual Start is not blank (null)`,
    example: `(General.IsNull(Activity.ActualStart)=False)`,
  },
  {
    title: `Activity Code (Id) is one of the items in the list ("AA", "BB", "CC")`,
    example: `(Array.IndexOf({"AA", "BB", "CC"}, Activity.Code)>(-1))`,
  },
  {
    title: `ADVANCED: Activity Code starts with A, B, or C and is followed by one or more digits`,
    example: `(Regex.IsMatch(Activity.Code, "[A-C]\\d+"))`,
  },
];
