import NewIEAWizardInformation from "./NewIEAWizardInformation";
import OwnerConfigurationForNewIEA, {
  OwnerConfigurationProps,
} from "../OwnerConfigurationForNewIEA";

export interface StepTwoNewIeaProps {
  ownerConfigurationProps: OwnerConfigurationProps;
}

export default function StepTwoNewIEA(props: StepTwoNewIeaProps) {
  return (
    <div>
      <NewIEAWizardInformation
        text={`Complete your configuration by selecting the connector and the schedule identifier. 
        These fields are required (*). 
        Optionally, you can also add more owner representatives.`}
      ></NewIEAWizardInformation>
      <div className="mt-3">
        <OwnerConfigurationForNewIEA {...props.ownerConfigurationProps} />
      </div>
    </div>
  );
}
