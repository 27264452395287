import { useEffect, useState } from "react";
import PromineoOptionDropdown from "../controls/PromineoOptionDropdown";

interface RowOperationOption {
  text: string;
  onClick: (data?: any) => void;
  textFn?: (data: any) => string;
  visible?: boolean;
  visibleFn?: (data: any) => boolean;
}

export interface RowOperationDefaultOption {
  onOpen?: () => void;
  onModify?: () => void;
  onCopy?: () => void;
  hideOpen?: boolean;
  hideModify?: boolean;
  hideCopy?: boolean;
}

export interface PromineoGridRowOptionConfig {
  items?: RowOperationOption[];
  displayDefault?: boolean;
  defaultOptionConfig?: RowOperationDefaultOption;
}

export default function PromineoGridRowOptionColumnTemplate(props: PromineoGridRowOptionConfig) {
  const { displayDefault, defaultOptionConfig, items } = props;
  const [options, setOptions] = useState<RowOperationOption[]>(items ?? []);
  const [displayOptionsDropdown, setDisplayOptionsDropdown] = useState<boolean>(true);

  useEffect(() => {
    if (displayDefault === true) {
      var defaultOptions = [];

      if (defaultOptionConfig?.hideOpen !== true) {
        defaultOptions.push({
          text: "Open",
          onClick: defaultOptionConfig?.onOpen
            ? defaultOptionConfig?.onOpen
            : () => {},
        });
      }

      if (defaultOptionConfig?.hideModify !== true) {
        defaultOptions.push({
          text: "Modify",
          onClick: defaultOptionConfig?.onModify
            ? defaultOptionConfig?.onModify
            : () => {},
        });
      }

      if (defaultOptionConfig?.hideCopy !== true) {
        defaultOptions.push({
          text: "Copy",
          onClick: defaultOptionConfig?.onCopy
            ? defaultOptionConfig?.onCopy
            : () => {},
        });
      }
      
      setOptions([...defaultOptions, ...options]);
    }

    if (items) {
      let isVisible = false;

      for (let index = 0; index < items.length; index++) {
        if (items[index].visible) {
          isVisible = true;
        }
      }

      setDisplayOptionsDropdown(isVisible);
    }


  }, [displayDefault]);

  return (
    <>
      {displayOptionsDropdown && (
        <div className="cursor-pointer inline-block">
          <PromineoOptionDropdown
            items={options}
            keyExpr={"text"}
            displayExpr={"text"}
          />
        </div>
      )}
    </>
  );
}
