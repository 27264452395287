import axios from "axios";
import {
  WithInterceptors,
  WithAuthenticationInterceptors,
} from "./ApiConnectorInterceptor";
import { getApiUrl } from "../utilities/ConfigUtility";

export const ProtectedApiConnector = WithAuthenticationInterceptors(
  axios.create({
    baseURL: getApiUrl(),
  })
);

export const PublicApiConnector = WithInterceptors(
  axios.create({ baseURL: getApiUrl() })
);
