interface Props {
  fillColor?: "white" | "black";
}
export default function ShareIcon(props: Props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.53614 8.48193C9.07831 8.48193 8.66867 8.66265 8.35542 8.94578L4.06024 6.44578C4.09036 6.30723 4.11446 6.16867 4.11446 6.0241C4.11446 5.87952 4.09036 5.74096 4.06024 5.60241L8.30723 3.12651C8.63253 3.42771 9.06024 3.61446 9.53614 3.61446C10.5361 3.61446 11.3434 2.80723 11.3434 1.80723C11.3434 0.807229 10.5361 0 9.53614 0C8.53614 0 7.72892 0.807229 7.72892 1.80723C7.72892 1.95181 7.75301 2.09036 7.78313 2.22892L3.53614 4.70482C3.21084 4.40361 2.78313 4.21687 2.30723 4.21687C1.30723 4.21687 0.5 5.0241 0.5 6.0241C0.5 7.0241 1.30723 7.83133 2.30723 7.83133C2.78313 7.83133 3.21084 7.64458 3.53614 7.34337L7.8253 9.8494C7.79518 9.9759 7.77711 10.1084 7.77711 10.241C7.77711 11.2108 8.56626 12 9.53614 12C10.506 12 11.2952 11.2108 11.2952 10.241C11.2952 9.27108 10.506 8.48193 9.53614 8.48193Z"
        fill="#434343"
      />
    </svg>
  );
}
