import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createIlapAnalyticsClientAsync,
  createServiceTokenAsync,
  deleteIlapAnalyticsClientAsync,
  deleteServiceTokenAsync,
  getIlapAnalyticsClientsAsync,
  getServiceTokensAsync,
  updateIlapAnalyticsClientAsync,
  updateServiceTokenAsync,
} from "apis/IlapAnalyticsApi";
import ClientCreateRequest from "interfaces/request/autonomous-components/ClientCreateRequest";
import ServiceTokenCreateRequest from "interfaces/request/autonomous-components/ServiceTokenCreateRequest";
import ServiceTokenUpdateRequest from "interfaces/request/autonomous-components/ServiceTokenUpdateRequest";

export const loadIlapAnalyticsClientsAsync = createAsyncThunk(
  "ilapAnalytics/loadIlapAnalyticsClients",
  async () => {
    return await getIlapAnalyticsClientsAsync();
  }
);

export const addNewIlapAnalyticsClientAsync = createAsyncThunk(
  "ilapAnalytics/addNewIlapAnalyticsClient",
  async (data: ClientCreateRequest) => {
    return await createIlapAnalyticsClientAsync(data);
  }
);

export const removeIlapAnalyticsClientAsync = createAsyncThunk(
  "ilapAnalytics/removeIlapAnalyticsClient",
  async (clientId: number) => {
    return await deleteIlapAnalyticsClientAsync(clientId);
  }
);

export const modifyIlapAnalyticsClientAsync = createAsyncThunk(
  "ilapAnalytics/modifyIlapAnalyticsClient",
  async ({
    clientId,
    data,
  }: {
    clientId: number;
    data: ClientCreateRequest;
  }) => {
    return await updateIlapAnalyticsClientAsync(clientId, data);
  }
);

export const loadIlapAnalyticsServiceTokensAsync = createAsyncThunk(
  "ilapAnalytics/loadServiceTokens",
  async (clientId: number) => {
    return await getServiceTokensAsync(clientId);
  }
);

export const addNewIlapAnalyticsServiceTokenAsync = createAsyncThunk(
  "ilapAnalytics/addNewServiceToken",
  async ({
    clientId,
    data,
  }: {
    clientId: number;
    data: ServiceTokenCreateRequest;
  }) => {
    return await createServiceTokenAsync(clientId, data);
  }
);

export const removeIlapAnalyticsServiceTokenAsync = createAsyncThunk(
  "ilapAnalytics/removeServiceToken",
  async (serviceTokenId: number) => {
    return await deleteServiceTokenAsync(serviceTokenId);
  }
);

export const modifyIlapAnalyticsServiceTokenAsync = createAsyncThunk(
  "ilapAnalytics/modifyServiceToken",
  async ({
    serviceTokenId,
    data,
  }: {
    serviceTokenId: number;
    data: ServiceTokenUpdateRequest;
  }) => {
    return await updateServiceTokenAsync(serviceTokenId, data);
  }
);
