import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import { useEffect } from "react";
import { ApplicationRole } from "shared/enums/feature/ApplicationRole";
import { loadApplicationRoleValuesAsync } from "store/actions/DropdownValueActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface Props {
  selectedRole: ApplicationRole;
  onValueChanged: (data: any) => void;
  allowedRoles?: ApplicationRole[];
}

export default function TenantAdminApplicationRoleDropdown(props: Props) {
  const dispatch = useAppDispatch();
  const applicationRoles = useAppSelector(
    (store) => store.dropdownData.applicationRoles
  );

  const allowedRoles = props.allowedRoles
    ? applicationRoles.filter(
        (role) =>
          !!(
            props.allowedRoles &&
            props.allowedRoles.find((ar) => Number(ar) === role.value)
          )
      )
    : applicationRoles;

  useEffect(() => {
    if (!applicationRoles.length) {
      displayLoadingPanel();
      dispatch(loadApplicationRoleValuesAsync()).finally(hideLoadingPanel);
    }
  }, [applicationRoles]);

  return (
    <PromineoSelectBox
      displayExpr={"name"}
      valueExpr={"value"}
      defaultValue={props.selectedRole}
      items={allowedRoles}
      onValueChanged={props.onValueChanged}
    />
  );
}
