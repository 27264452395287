import TenantResponse from "interfaces/response/TenantResponse";

interface Props {
  data: { data: TenantResponse };
}

export default function OwnerStateCellComponent(props: Props) {
  const tenant = props.data.data;
  return tenant.canBeOwner ? <>Yes</> : <>No</>;
}
