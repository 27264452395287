import { createSlice } from "@reduxjs/toolkit";
import DataTransferConflictResponse from "interfaces/response/DataTransferConflictResponse";
import { loadDownloadConflictsAsync } from "store/actions/ConflictActoins";

export interface ConflictState {
  downloadConflicts: DataTransferConflictResponse[];
}

const initialState: ConflictState = {
  downloadConflicts: [],
};

export const conflictSlice = createSlice({
  name: "conflicts",
  initialState,
  reducers: {
    resetDownloadConflicts: (state) => {
      state.downloadConflicts = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDownloadConflictsAsync.fulfilled, (state, action) => {
      state.downloadConflicts = action.payload;
    });
  },
});

export const { resetDownloadConflicts } = conflictSlice.actions;

export default conflictSlice.reducer;
