import { useAppDispatch, useAppSelector } from "store/hooks";
import ConfigTemplateSelectorDropdown from "./ConfigTemplateSelectorDropdown";
import { loadSimplifiedTemplatesAsync } from "store/actions/TemplateActions";
import { useEffect } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";

interface Props {
  onSelectionChange: (templateId: number, title: string) => void;
  selectedTemplateId?: number;
  templateTitle: string;
  readonly: boolean;
  className: string;
  isConfigPublishedPreviously?: boolean;
}

export default function ConfigTemplateDropDown(props: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadSimplifiedTemplatesAsync()).finally(hideLoadingPanel);
  }, []);

  const simplifiedTemplates = useAppSelector(
    (store) => store.templateData.simplifiedTemplates
  );

  return (
    <div className={props.className ? props.className : ""}>
      <div>Template:</div>
      {!props.readonly && !props.isConfigPublishedPreviously ? (
        <ConfigTemplateSelectorDropdown
          simplifiedTemplates={simplifiedTemplates}
          selectedTemplateId={props.selectedTemplateId}
          onSelectionChange={props.onSelectionChange}
        />
      ) : (
        <div className="max-w-[235px] truncate">{props.templateTitle}</div>
      )}
    </div>
  );
}
