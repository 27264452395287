import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import ConnectorDropdown from "./ConnectorDropdown";
import ConnectorInfoDisplay from "../../config-common/ConnectorInfoDisplay";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { loadSimplifiedConnectorsAsync } from "store/actions/ConnectorActions";
import { useEffect } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";

interface Props {
  onSelectionChanged: (connectorId: number, connectorName: string) => void;
  selectedConnectorId?: number;
  configDirection?: ConfigDirection;
  connectorName: string;
  readonly: boolean;
  className: string;
  isConfigPublishedPreviously?: boolean;
}

export default function ConfigConnectorDropDown(props: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadSimplifiedConnectorsAsync()).finally(hideLoadingPanel);
  }, []);

  const simplifiedConnectors = useAppSelector(
    (store) => store.connectorData.simplifiedConnectors
  );

  return (
    <div className={props.className ? props.className : ""}>
      <div>Connector:</div>
      {!props.readonly && !props.isConfigPublishedPreviously ? (
        <ConnectorDropdown
          simplifiedConnectors={simplifiedConnectors}
          selectedConnectorId={props.selectedConnectorId}
          onSelectionChanged={props.onSelectionChanged}
          configDirection={props.configDirection}
        />
      ) : (
        <ConnectorInfoDisplay name={props.connectorName} />
      )}
    </div>
  );
}
