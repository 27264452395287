import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorLabelWithContent from "./ConnectorLabelWithContent";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import {
  loadExecutionComponentValuesAsync,
  loadHostSystemValuesAsync,
} from "store/actions/DropdownValueActions";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { checkIfHasFlag } from "shared/utilities/CommonUtility";

interface Props {
  connector: ConnectorResponse;
  mode: PromineoModalMode;
  classNames?: string;
  handleValueChanged?: (fieldName: string, value: any) => void;
  handleHostSystemValueChange?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
}

export default function ConnectorBasicInformation(props: Props) {
  const {
    connector,
    mode,
    classNames,
    handleValueChanged,
    handleHostSystemValueChange,
    validateOnFocusOut,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const executionComponents = useSelector(
    (state: RootState) => state.dropdownData.executionComponent
  );

  const hostSystems = useSelector(
    (state: RootState) => state.dropdownData.hostSystem
  );

  useEffect(() => {
    if (
      mode === PromineoModalMode.Create ||
      mode === PromineoModalMode.Modify
    ) {
      displayLoadingPanel();
      dispatch(loadExecutionComponentValuesAsync()).finally(hideLoadingPanel);

      displayLoadingPanel();
      dispatch(loadHostSystemValuesAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, mode]);

  const RenderHostSystemComponent = useCallback(() => {
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoSelectBox
            placeholder="Select"
            width={mode === PromineoModalMode.Create ? 330 : 188}
            fieldName="Host System"
            required={true}
            defaultValue={connector.hostSystem}
            items={hostSystems}
            valueExpr={"value"}
            displayExpr={"name"}
            onValueChange={(value: number) => {
              if (handleHostSystemValueChange) {
                handleHostSystemValueChange(value);
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && connector.hostSystemName}
      </>
    );
  }, [mode, hostSystems, connector.hostSystem, connector.hostSystemName]);

  const RenderExecutionComponent = useCallback(() => {
    const selectedHostSystemFieldOption = hostSystems.find(
      (h) => h.value === connector.hostSystem
    );
    return (
      <>
        {(mode === PromineoModalMode.Create ||
          mode === PromineoModalMode.Modify) && (
          <PromineoSelectBox
            placeholder="Select"
            width={mode === PromineoModalMode.Create ? 330 : 188}
            fieldName="Execution component"
            required={true}
            defaultValue={connector.executionComponent}
            items={executionComponents.filter((ex) =>
              selectedHostSystemFieldOption
                ? checkIfHasFlag(selectedHostSystemFieldOption.allowedExecutionComponents, ex.value)
                : false
            )}
            valueExpr={"value"}
            displayExpr={"name"}
            onValueChange={(value: number) => {
              if (handleValueChanged) {
                handleValueChanged("executionComponent", value);
              }
            }}
            onFocusOut={(event: any) => {
              if (validateOnFocusOut) {
                validateOnFocusOut(event);
              }
            }}
          />
        )}

        {mode === PromineoModalMode.View && connector.executionComponentText}
      </>
    );
  }, [
    mode,
    hostSystems,
    executionComponents,
    connector.executionComponent,
    connector.executionComponentText,
    connector.hostSystem,
  ]);

  const RenderTitleComponent = useCallback(() => {
    return (
      <>
        {mode === PromineoModalMode.Create && (
          <ConnectorLabelWithContent
            mode={mode}
            addAsterisksymbol={true}
            label="Title"
            content={
              <PromineoTextBox
                name="name"
                fieldName="Title"
                required={true}
                placeholder="Type..."
                defaultValue={connector.name}
                onChange={({ event }: any) => {
                  if (handleValueChanged) {
                    handleValueChanged(
                      event.currentTarget.name,
                      event.currentTarget.value
                    );
                  }
                }}
                onFocusOut={(event: any) => {
                  if (validateOnFocusOut) {
                    validateOnFocusOut(event);
                  }
                }}
              />
            }
          />
        )}
      </>
    );
  }, [mode]);

  return (
    <div className={classNames}>
      <ConnectorLabelWithContent
        mode={mode}
        label="Id"
        content={
          mode === PromineoModalMode.Create
            ? "[Generated automatically]"
            : connector.id
        }
      />
      <RenderTitleComponent />
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Host system"
        content={<RenderHostSystemComponent />}
      />
      <ConnectorLabelWithContent
        mode={mode}
        addAsterisksymbol={true}
        label="Execution component"
        content={<RenderExecutionComponent />}
      />
    </div>
  );
}
