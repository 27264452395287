import HostSystemDefinitionResponse from "interfaces/response/HostSystemDefinitionResponse";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { getDataAsync } from "./API";
import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";

export const getHostSystemConfigurationDefinitionAsync = async (
  hostSystem: HostSystem,
  exchangeRole: ExchangeRoleEnum
) => {
  const apiUrl = `HostSystems/${hostSystem}/Role/${exchangeRole}/ParameterDefinition`;

  return await getDataAsync<HostSystemDefinitionResponse[]>(apiUrl);
};

export const getWbsStructureCandidatesAsync = async (
  connectorId: number,
  userFieldSetId: number
) => {
  const apiUrl = `Connectors/${connectorId}/WbsStructureCandidates?userFieldSetId=${userFieldSetId}`;

  return await getDataAsync<HostFieldDropdownValueResponse[]>(apiUrl);
};

export const getStructureToExportCandidatesAsync = async (
  connectorId: number,
  userFieldSetId: number
) => {
  const apiUrl = `Connectors/${connectorId}/StructureExportCandidates?userFieldSetId=${userFieldSetId}`;

  return await getDataAsync<HostFieldDropdownValueResponse[]>(apiUrl);
};
