import { FieldValueBulkEditorInputHeight } from "shared/constants/GridConstants";

interface Props {
  validationErrors: string[];
}

export default function BulkEditorErrorPreview(props: Props) {
  return (
    <div
      className={`min-h-[${FieldValueBulkEditorInputHeight}px] text-10px rounded-lg bg-gray2 w-full pl-6 pt-2 font-Inter border border-lightGray leading-3 text-textGray`}
    >
      <span className="font-bold ">Validation failed:</span>
      <ul className="list-disc pb-4">
        {props.validationErrors.map((error: string, index: number) => {
          return (
            <li className="pt-2" key={index}>
              {error}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
