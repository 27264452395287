import IEAScheduling, { IeaScheduleProps } from "../IEASchedulings";
import NewIEAWizardInformation from "./NewIEAWizardInformation";

export interface StepThreeNewIeaProps {
  scheduleProps: IeaScheduleProps;
}

export default function StepThreeNewIEA(props: StepThreeNewIeaProps) {
  return (
    <div>
      <NewIEAWizardInformation
        text={`Define from when and until when you want the IEA to be active and with what 
        frequency you want to exchange data. 
        These fields are required (*). 
        Optionally, you can add labels and comments.`}
      ></NewIEAWizardInformation>
      <div className="mt-3">
        <IEAScheduling {...props.scheduleProps} />
      </div>
    </div>
  );
}
