import React from "react";
import PromineoButton from "components/common/controls/buttons/PromineoButton";
import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";

export interface EditorActionButtonProp {
  footerComponent?: React.ReactElement;
  cancelButtonCaption?: string;
  createButtonCaption?: string;
  saveChangesButtonCaption?: string;
  doneButtonCaption?: string;
  deleteButtonCaption?: string;
  onSaveChanges?: Function;
  onCancel?: Function;
  onCreate?: Function;
  onDone?: Function;
  onDelete?: Function;
  useSubmitBehaviorOnApply?: boolean;
  mode?: PromineoModalMode;
  disableConfirmButton?: boolean;
}

export default function PromineoModalActionFooter(
  props: EditorActionButtonProp
) {
  const {
    onSaveChanges,
    onCancel,
    onCreate,
    onDone,
    onDelete,
    deleteButtonCaption,
    saveChangesButtonCaption,
    cancelButtonCaption,
    createButtonCaption,
    doneButtonCaption,
    useSubmitBehaviorOnApply,
    footerComponent,
    mode,
    disableConfirmButton,
  } = props;

  return footerComponent ? (
    <>{footerComponent}</>
  ) : mode === PromineoModalMode.Create ? (
    <div className="flex justify-between items-center">
      {cancelButtonCaption && (
        <PromineoButton
          variant={PromineoButtonType.Secondary}
          text={cancelButtonCaption}
          onClick={() => onCancel?.()}
        />
      )}

      {createButtonCaption && (
        <PromineoButton
          disabled={disableConfirmButton}
          variant={PromineoButtonType.Primary}
          text={createButtonCaption}
          onClick={() => onCreate?.()}
        />
      )}
    </div>
  ) : mode === PromineoModalMode.View ? (
    <div className="flex justify-end">
      {doneButtonCaption && (
        <PromineoButton
          variant={PromineoButtonType.Primary}
          text={doneButtonCaption}
          onClick={() => onDone?.()}
        />
      )}
    </div>
  ) : mode === PromineoModalMode.Modify ? (
    <div className="flex justify-between items-center">
      {cancelButtonCaption && (
        <PromineoButton
          text={cancelButtonCaption}
          variant={PromineoButtonType.Secondary}
          onClick={() => onCancel?.()}
        />
      )}

      <div className="flex gap-9">
        {deleteButtonCaption && (
          <PromineoButton
            text={deleteButtonCaption}
            variant={PromineoButtonType.BorderlessDanger}
            onClick={() => onDelete?.()}
          />
        )}

        {saveChangesButtonCaption && (
          <PromineoButton
            disabled={disableConfirmButton}
            text={saveChangesButtonCaption}
            useSubmitBehavior={useSubmitBehaviorOnApply}
            onClick={() => onSaveChanges?.()}
          />
        )}
      </div>
    </div>
  ) : mode === PromineoModalMode.ConfirmationDialog ? (
    <div className="flex justify-between px-6">
      {cancelButtonCaption && (
        <PromineoButton
          variant={PromineoButtonType.Secondary}
          text={cancelButtonCaption}
          onClick={() => onCancel?.()}
        />
      )}

      {doneButtonCaption && (
        <PromineoButton
          variant={PromineoButtonType.Primary}
          text={doneButtonCaption}
          onClick={() => onDone?.()}
        />
      )}
    </div>
  ) : mode === PromineoModalMode.Delete ? (
    <div className="flex justify-between px-6">
      {cancelButtonCaption && (
        <PromineoButton
          variant={PromineoButtonType.Secondary}
          text={cancelButtonCaption}
          onClick={() => onCancel?.()}
        />
      )}

      {deleteButtonCaption && (
        <PromineoButton
          variant={PromineoButtonType.BorderlessDanger}
          text={deleteButtonCaption}
          onClick={() => onDelete?.()}
        />
      )}
    </div>
  ) : (
    <></>
  );
}
