import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import IEAStatusCellComponent from "./IEAStatusCellComponent";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { getLocalDateFromUtc } from "shared/utilities/DateTimeUtility";
import { useCallback } from "react";

export default function UploadStatusCellComponent(e: {
  data: { data: ActiveExchangeAgreementListingResponse };
}) {
  const getTooltipText = useCallback(() => {
    const exchangeAgreement = e.data.data;
    switch (exchangeAgreement.lastUploadStatus) {
      case DataTransferStatus.Success:
        return `Upload successful on ${getLocalDateFromUtc(exchangeAgreement.lastUploadTime)}`;
      case DataTransferStatus.Fail:
        return `Upload failed on ${getLocalDateFromUtc(exchangeAgreement.lastUploadTime)}`;
      case DataTransferStatus.Overdue:
        if (exchangeAgreement.lastTransferConflictResolveOption > 0){
          return `Upload overdue due to conflict resolution (occurred on ${getLocalDateFromUtc(exchangeAgreement.lastDownloadTime)})`;
        }
        return `Upload overdue. Expected upload date was ${getLocalDateFromUtc(exchangeAgreement.lastExpectedTransferTime)}`;
      case DataTransferStatus.Pending:
        if (exchangeAgreement.lastTransferConflictResolveOption > 0){
          return `Upload pending due to conflict resolution (occurred on ${getLocalDateFromUtc(exchangeAgreement.lastDownloadTime)})`;
        }
        return '';
      default:
        return '';
    }
  }, [e.data.data.lastUploadStatus]);

  return <IEAStatusCellComponent status={e.data.data.lastUploadStatus} tooltipText={getTooltipText()} />;
}
