import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as routePaths from "shared/constants/RoutePathConstants";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import LandingIllustration from "components/icons/LandingIllustration";
import LandingIlapLogo from "components/icons/LandingIlapLogo";

import LandingButton, { LandingButtonType } from "./LandingButton";
import { signIn } from "authentication/AuthenticationManager";
import { useIsAuthenticated } from "@azure/msal-react";
import { toastError } from "shared/utilities/ToastUtility";
import { loadLoggedInUserInfo } from "store/actions/UserActions";
import { useAppDispatch } from "store/hooks";
import { REDIRECT_URL_PROP } from "shared/utilities/RouteUtility";

export default function LandingPage() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const [loadUserInfo, setLoadUserInfo] = useState<boolean>(true);

  const navigateToHomePage = () => {
    navigate(routePaths.ACTIVE_IEA);
  };

  useEffect(() => {
    if (isAuthenticated && loadUserInfo) {
      // When making the first request to the api from the landing page, we need to wait untill it is completed.
      // After that we can make parallel request to the api. The reason for this is that, if the user has never logged in to our system before,
      // then we perform auto provisioning when the user logs in for the first time. So, if we do not wait untill the first request is done, then our system will create multiple users in the database due to
      // auto provisioning multiple request parallelly. For this reason, we need to wait untill the first request is done.
      dispatch(loadLoggedInUserInfo())
        .unwrap()
        .then(() => {
          if (location && location.state && location.state[REDIRECT_URL_PROP]) {
            // After successfull sign-in we are redirecting user to the user's expected route.
            navigate(location.state[REDIRECT_URL_PROP]);
          } else {
            navigateToHomePage();
          }
        })
        .catch(() => {
          setLoadUserInfo(false);
        });
    }
  }, [isAuthenticated, loadUserInfo]);

  const handleSignInClick = () => {
    displayLoadingPanel();
    signIn()
      .then(() => {
        setLoadUserInfo(true);
      })
      .catch((error) => {
        toastError(error.errorMessage);
      })
      .finally(hideLoadingPanel);
  };

  return (
    <div className="bg-darkBlue h-screen flex">
      <div className="w-[30%]">
        <LandingIllustration />
      </div>

      <div className="w-[40%] flex">
        <div className="text-white w-[531px] font-poppins text-left my-auto">
          <div className="text-4xl">
            Efficient data exchange across planning systems
          </div>
          <div className="md:flex md:gap-2 mt-10">
            <LandingButton
              onClick={handleSignInClick}
              className="w-64 md:w-56 h-11 mb-5 md:mb-0"
              text="Sign in"
              variant={LandingButtonType.SignIn}
            />
            <LandingButton
              onClick={() => {}}
              className="w-64 md:w-56 h-11"
              text="Read more"
              variant={LandingButtonType.ReadMore}
            />
          </div>
        </div>
      </div>

      <div className="w-[30%]">
        <div className="flex justify-end mt-14 mr-16">
          <LandingIlapLogo />
        </div>
      </div>
    </div>
  );
}
