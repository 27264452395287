import CheckIcon from "components/icons/CheckIcon";

interface Props {
  isDisabled?: boolean;
  onSaveClick: () => void;
  classNames?: string;
}

export default function SaveButton(props: Props) {
  return(
    <div
      id="save-btn"
      className={`rounded-lg cursor-pointer flex items-center justify-center bg-transparent hover:bg-backgroundGray ${props.isDisabled ? 'pointer-events-none' : ''} ${props.classNames}`}
      onClick={props.onSaveClick}
    >
      <CheckIcon />
    </div>
  );
}