import { createSlice } from "@reduxjs/toolkit";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import {
  createNewFieldMappingFormulaAsync,
  loadCurrentUserFieldMappingFormulaAsync,
  modifyFieldMappingFormulaAsync,
  modifyFieldMappingFormulaFavoriteStatusAsync,
  removeFieldMappingFormulaAsync,
} from "store/actions/FieldMappingFormulaActions";

export interface FieldMappingFormulaState {
  fieldMappingFormulas: FieldMappingFormulaResponse[];
  fieldMappingFormula: FieldMappingFormulaResponse | null;
}

const initialState: FieldMappingFormulaState = {
  fieldMappingFormulas: [],
  fieldMappingFormula: null,
};

export const fieldMappingFormulaSlice = createSlice({
  name: "fieldMappingFormula",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadCurrentUserFieldMappingFormulaAsync.fulfilled,
      (state, action) => {
        state.fieldMappingFormulas = action.payload;
      }
    );
    builder.addCase(
      createNewFieldMappingFormulaAsync.fulfilled,
      (state, action) => {
        state.fieldMappingFormulas.push(action.payload);
      }
    );

    builder.addCase(
      modifyFieldMappingFormulaAsync.fulfilled,
      (state, action) => {
        let modifiedList = state.fieldMappingFormulas.map((m) => {
          if (m.id === action.payload.id) {
            return action.payload;
          } else {
            return m;
          }
        });
        state.fieldMappingFormulas = modifiedList;
      }
    );

    builder.addCase(
      modifyFieldMappingFormulaFavoriteStatusAsync.fulfilled,
      (state, action) => {
        let modifiedList = state.fieldMappingFormulas.map((m) => {
          if (m.id === action.payload.id) {
            return action.payload;
          } else {
            return m;
          }
        });
        state.fieldMappingFormulas = modifiedList;
      }
    );
    builder.addCase(
      removeFieldMappingFormulaAsync.fulfilled,
      (state, action) => {
        let modifiedList = state.fieldMappingFormulas.filter(
          (m) => m.id !== action.meta.arg
        );
        state.fieldMappingFormulas = modifiedList;
      }
    );
  },
});

export default fieldMappingFormulaSlice.reducer;
