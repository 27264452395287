import GridRowStatusLabel from "components/common/GridRowStatusLabel";
import ConfigResponse from "interfaces/response/ConfigResponse";

interface Props {
  data: { data: ConfigResponse };
}

export default function TitleCellComponent(props: Props) {
  let configResponse = props.data.data;
  // change label bg and text based on config status
  let cssClassForBg = "bg-lightGreen";
  let text = "Update available";

  return (
    <div className="flex justify-between gap-x-2 items-center">
      <div className="flex-shrink min-w-0 overflow-hidden">
        {configResponse.title}
      </div>
      <div className="min-w-fit ">
        {configResponse.updateRequired && (
          <GridRowStatusLabel text={text} cssClasses={cssClassForBg} />
        )}
      </div>
    </div>
  );
}
