import PromineoConfirmationDialog from "./PromineoConfirmationDialog";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
}

export default function PromineoCancelEditingConfirmationDialog(props: Props) {
  const SubContent = () => {
    return (
      <div className="font-medium">
        <div>Are you sure you want to close without saving?</div>
        <div>Your changes will be lost.</div>
      </div>
    );
  };

  return (
    <PromineoConfirmationDialog
      minWidth="450"
      width="auto"
      actionOptions={{
        mode: PromineoModalMode.Modify,
      }}
      content={`You have unsaved changes.`}
      subContent={<SubContent />}
      confirmButtonText="Close without saving"
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
    ></PromineoConfirmationDialog>
  );
}
