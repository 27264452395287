import { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { useAppSelector } from 'store/hooks';
import { toastWarning } from 'shared/utilities/ToastUtility';
import { getSignalRHubBaseUrl } from 'shared/utilities/ConfigUtility';
import { getTokenAsync } from 'authentication/AuthenticationManager';
import { NotificationMessage } from 'interfaces/common/NotificationMessage';

const enum SignalRConstants {
  PushNotificationReceived = "PushNotificationReceived",
  JoinSpecificGroup = "JoinSpecificGroupAsync"
}

export default function useSignalR() {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const mySelfData = useAppSelector((store) => store.userData.mySelfResponse);

  useEffect(() => {
    const hubBaseUrl = getSignalRHubBaseUrl();

    if (!hubBaseUrl) {
      return;
    }

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${hubBaseUrl}/Notification`, {
        accessTokenFactory: async () => {
          var token = await getTokenAsync();
          return typeof token === 'string' ? token : '';
        },
        withCredentials: false
      })
      .configureLogging(LogLevel.Warning)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);

    return () => {
      newConnection.stop();
    };
  }, []);

  useEffect(() => {
    if (!connection || !mySelfData?.id) {
      return;
    }

    if (connection.state === HubConnectionState.Disconnected) {
      connection.on(SignalRConstants.PushNotificationReceived, (data: NotificationMessage) => {
        toastWarning(data.message);
      });

      connection.start()
        .then(() => {
          const clientGroup = mySelfData.id.toString();
          connection.invoke(SignalRConstants.JoinSpecificGroup, clientGroup);
        });
    }

    return () => {
      connection.off(SignalRConstants.PushNotificationReceived);
    };
  }, [connection, mySelfData?.id]);

  return connection;
};