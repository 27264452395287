import LabelWithContent from "components/common/LabelWithContent";
import { EditorMode } from "shared/enums/EditorMode";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadRevisionTypeOptionValuesAsync } from "store/actions/DropdownValueActions";
import AnalyticsBaseConfig from "interfaces/host-system-config/analytics/AnalyticsBaseConfig";
import HostSystemConfigurationDropdown from "../HostSystemConfigurationDropdown";
import { ScrollView } from "devextreme-react";

interface Props {
  mode: EditorMode;
  height: number;
  config: AnalyticsBaseConfig;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
}

const revisionTypePropertyName = "revisionType";

export default function AnalyticsHostSystemParameters(props: Props) {
  const { mode, isOverride, config, height, handleConfigurationValueChanged } =
    props;
  const dispatch = useAppDispatch();

  const revisionTypeOptions = useAppSelector(
    (store) => store.dropdownData.revisionTypeOptions
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadRevisionTypeOptionValuesAsync()).finally(hideLoadingPanel);
  }, []);

  const Fields = useCallback(() => {
    return (
      <div className="space-y-6">
        <LabelWithContent
          label="Revision Type"
          content={
            <HostSystemConfigurationDropdown
              mode={mode}
              dropdownOptions={revisionTypeOptions}
              fieldName={revisionTypePropertyName}
              selectedValue={config.revisionType}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
            />
          }
        />
      </div>
    );
  }, [revisionTypeOptions, config.revisionType, mode]);

  return !isOverride ? (
    <ScrollView showScrollbar="always" height={height}>
      <div className="flex space-x-6">
        <div className="w-60 font-semibold text-base text-textGray">
          Revision Type
        </div>
        <Fields />
      </div>
    </ScrollView>
  ) : (
    <Fields />
  );
}
