import HostParametersOverride, {
  HostParameterOverrideProps,
} from "features/ieas/common/HostParametersOverride";
import NewIEAWizardInformation from "./NewIEAWizardInformation";

export interface StepFourNewIeaProps {
  hostParameterOverrideProps: HostParameterOverrideProps;
}

export default function StepFourNewIEA(props: StepFourNewIeaProps) {
  return (
    <div>
      <NewIEAWizardInformation
        text={`Adjust the host system parameters if required.`}
      ></NewIEAWizardInformation>
      <div className="mt-3">
        <HostParametersOverride {...props.hostParameterOverrideProps} />
      </div>
    </div>
  );
}
