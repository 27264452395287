import LabelWithAsteriskSymbol from "components/common/LabelWithAsteriskSymbol";
import IlapTermHeaderData from "interfaces/component-data/IlapTermHeaderData";

interface Props {
  headerData: IlapTermHeaderData;
}

export default function AllowBlanksLabel(props: Props) {
  const { headerData } = props;

  return (
    <LabelWithAsteriskSymbol label={`Allow blanks: ${headerData?.allowBlank ? "Yes" : "No"}`} />
  );
}
