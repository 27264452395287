import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import { copyToClipboard } from "shared/utilities/CommonUtility";
import { toastSuccess } from "shared/utilities/ToastUtility";

interface Props {
  serviceToken: ServiceTokenResponse;
  onClose: () => void;
}

export default function TokenViewer(props: Props) {
  const handleCopyClick = () => {
    copyToClipboard(props.serviceToken.token ?? "");
    toastSuccess("Copied to clipboard.");
  };

  return (
    <div className="pt-8 px-8 pb-6">
      <div className="font-bold text-24px mb-4">Token created</div>
      <div className="fond-bold font-poppins">
        This key will be displayed once!
      </div>
      Make sure to note down the token in a secure location.
      <div className="mt-4 p-4 border rounded-lg flex justify-between">
        <div className="font-bold">Token:</div>
        <div className="mx-4 truncate">{props.serviceToken.token}</div>
        <div
          className="italic border-b text-darkBlue hover:text-ilapBlue cursor-pointer"
          onClick={handleCopyClick}
        >
          copy
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <PromineoButton
          variant={PromineoButtonType.Secondary}
          text="OK"
          onClick={props.onClose}
        />
      </div>
    </div>
  );
}
