export const LANDING = "/";
export const HOME = "/home";
export const ACTIVE_IEA = "/active-ieas";
export const NEW_IEAS = "/new-ieas";
export const ARCHIVE_IEAS = "/archive-ieas";
export const ARCHIVE_IEA_VIEW = "/archive-ieas/:id";
export const ARCHIVE_IEA_EDIT = "/archive-ieas/:id/edit";
export const ACTIVE_IEA_VIEW = "/active-ieas/:id";
export const ACTIVE_IEA_EDIT = "/active-ieas/:id/edit";
export const NEW_IEA_VIEW = "/new-ieas/:id";
export const NEW_IEA_EDIT = "/new-ieas/:id/edit";
export const TENANT_ADMIN = "/tenant-admin";
export const SYSTEM_ADMIN = "/system-admin";
export const CONNECTORS = "/setup/connectors";
export const CONNECTOR_VIEW = "/setup/connectors/:id";
export const CONNECTOR_EDIT = "/setup/connectors/:id/edit";
export const TEMPLATES = "/setup/templates";
export const TEMPLATE_EDIT = "/setup/templates/:id/edit";
export const TEMPLATE_VIEW = "/setup/templates/:id";
export const NEW_TEMPLATE = "/setup/templates/new";
export const COPY_TEMPLATE = "/setup/templates/:id/copy";
export const TEMPLATE_FIELDS = "/setup/templates/fields";
export const TEMPLATE_FIELD_VIEW = "/setup/templates/fields/:templateFieldId";
export const CONFIGS = "/setup/configs";
//export const CONFIGS = "/setup/templates/1/edit";
//export const CONFIGS = "/templates/1/fields/8/edit";
export const CONFIG_NEW = "/setup/configs/new";
export const CONFIG_EDIT = "/setup/configs/:configId/edit";
export const CONFIG_VIEW = "/setup/configs/:configId";
export const SIGN_IN = "/sign-in";
export const LOG_OUT = "/logout";
export const TEST = "/test";
export const TEMPLATE_FIELD_EDIT = "/setup/templates/fields/:templateFieldId/edit";
//export const TEMPLATE_VIEWER = "/templates/:templateId";
export const TENANT_ADMIN_TENANT = "/tenant-admin/tenant";
export const TENANT_ADMIN_USERS = "/tenant-admin/users";
export const TENANT_ADMIN_FIELDS = "/tenant-admin/fields";
export const TENANT_ADMIN_FIELD_VIEW = "/tenant-admin/fields/:fieldId";
export const TENANT_ADMIN_FIELD_EDIT = "/tenant-admin/fields/:fieldId/edit";

export const SYS_ADMIN_TENANTS = "/system-admin/tenant";
export const SYS_ADMIN_USERS = "/system-admin/users";
export const SYS_ADMIN_ILAP_TERMS = "/system-admin/ilap-terms";
export const SYS_ADMIN_ILAP_TERM_NEW = "/system-admin/ilap-terms/new";
export const SYS_ADMIN_ILAP_TERM_EDIT =
  "/system-admin/ilap-terms/:fieldId/edit";
export const SYS_ADMIN_ILAP_TERM_VIEW = "/system-admin/ilap-terms/:fieldId";

export const AUTONOMOUS_COMPONENTS = "/autonomous-components"
export const AUTONOMOUS_COMPONENTS_VIEW = "/autonomous-components/:id";

export const AUTONOMOUS_COMPONENTS_SERVICE_TOKENS = "/autonomous-components/:clientId/service-tokens";

export const ILAP_ANALYTICS = "/ilap-analytics"
export const ILAP_ANALYTICS_VIEW = "/ilap-analytics/:id";

export const ILAP_ANALYTICS_SERVICE_TOKENS = "/ilap-analytics/:clientId/service-tokens";
