import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";
import ConnectorEventStatusCircle from "components/common/ConnectorEventStatusCircle";
import PromineoButtonWithIcon from "components/common/controls/buttons/PromineoButtonWithIcon";
import LoadingIconSmall from "components/icons/LoadingIconSmall";
import { generateConnectorPersistEventAsync, loadConnectorEventStatusAsync } from "store/actions/ConnectorActions";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { AppDispatch } from "store/store";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { ConnectorEventType } from "shared/enums/feature/ConnectorEventType";
import ConnectorEventStatusResponse from "interfaces/response/connector/ConnectorEventStatusResponse";
import { ConnectorEventStatus } from "shared/enums/feature/ConnectorEventStatus";

interface Props {  
  connectorId: number; 
  showPersistButton?: boolean; 
}

export default function LastPersistComponent(props: Props) {
  const {connectorId, showPersistButton} = props;    
    
  const [connectorEventStatusResponse, setConnectorEventStatusResponse] = useState<ConnectorEventStatusResponse>();    

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    displayLoadingPanel();      
    dispatch(
      loadConnectorEventStatusAsync(connectorId)
    )      
    .unwrap()
    .then((response: ConnectorEventStatusResponse) => {
        setConnectorEventStatusResponse(response);
    })
    .finally(hideLoadingPanel);
  }, [dispatch, connectorId]);

  const handlePersistClick = () => {
    displayLoadingPanel();
    setConnectorEventStatusResponse({
      ...connectorEventStatusResponse,
      hasRequested: true
    })
    dispatch(
      generateConnectorPersistEventAsync({eventType: ConnectorEventType.Persist, connectorId: connectorId})
    )      
    .finally(hideLoadingPanel);
  };   
  var persistWithin = process.env.PERSIST_WITHIN_MINUTES || 3;
  return (
    <div className="flex">
      { connectorEventStatusResponse && (
        <div className="flex">
          <ConnectorEventStatusCircle
            status={connectorEventStatusResponse?.status}
            lastExecutedAt={connectorEventStatusResponse.lastExecutedAt}
          />
          {showPersistButton && (
            <PromineoButtonWithIcon
              height={24}  
              className="ml-3 pt-[1px]"
              disabled={connectorEventStatusResponse.hasRequested}
              Icon={connectorEventStatusResponse.hasRequested ? <LoadingIconSmall /> : <></>}
              variant={PromineoButtonType.Ternary}
              text={connectorEventStatusResponse.hasRequested ? "Persist has been requested - refresh to see status." : `Persist (within ${persistWithin} minutes)`}
              onClick={handlePersistClick}
            />
          )}                
        </div>
      )}
    </div>
  );
}
