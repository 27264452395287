import TextArea, { ITextAreaOptions } from "devextreme-react/text-area";
import "./styles/PromineoMultilineTextEdit.css";

interface Props {
  borderRound?: "medium" | "large";
}

export default function PromineoMultilineTextEdit(
  props: Props & ITextAreaOptions
) {
  const { className, borderRound, ...rest } = props;
  const borderRoundClass =
    borderRound === "large" ? "large-round" : "medium-round";
  return (
    <TextArea
      className={`promineo-multiline-text-edit ${
        className ?? ""
      } ${borderRoundClass}`}
      {...rest}
    />
  );
}
