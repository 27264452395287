import GridRowStatusLabel from "components/common/GridRowStatusLabel";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

interface Props {
  data: { data: ActiveExchangeAgreementListingResponse };
}

export default function TitleCellComponent(props: Props) {
  let activeIeaResponse = props.data.data;
  // change label bg and text based on iea status
  let cssClassForButton = "bg-ilapBlue text-white";
  let updateAvailableText = "Update available";
  let updatePerformedText = "Update performed";

  const loggedInUser = useSelector(
    (state: RootState) => state.userData.mySelfResponse
  );

  let hasPartnerRole = loggedInUser
    ? activeIeaResponse.partnerRepresentatives.some(
        (partnerRep) => partnerRep.id === loggedInUser.id
      )
    : false;

  return (
    <div className="flex justify-between gap-x-2 items-center">
      <div
        className="flex-shrink min-w-0 overflow-hidden"
        title={activeIeaResponse.title}
      >
        {activeIeaResponse.title}
      </div>
      {activeIeaResponse.updateRequired && (
        <div className="min-w-fit">
          <GridRowStatusLabel
            text={updateAvailableText}
            cssClasses={cssClassForButton}
          />
        </div>
      )}
      {hasPartnerRole && activeIeaResponse.isUpdatePerformedByOwner && (
        <div className="min-w-fit">
          <GridRowStatusLabel
            text={updatePerformedText}
            cssClasses={cssClassForButton}
          />
        </div>
      )}
    </div>
  );
}
