import PromineoButtonWithPopover from "components/common/controls/buttons/PromineoButtonWithPopOver";
import PopoverMenuItem from "components/common/controls/popover-with-menu/PopoverMenuItem";
import AddIcon from "components/icons/AddIcon";
import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";
import { IButtonOptions } from "devextreme-react/button";

interface Props {
  id: string;
  menuItems?: PopoverMenuItem[];
}

export default function NewFieldValueAddButton(props: Props & IButtonOptions) {
  return (
    <PromineoButtonWithPopover
      {...props}
      id={props.id}
      text={"Add new"}
      Icon={<AddIcon />}
      variant={PromineoButtonType.Primary}
      menuItems={props.menuItems ? props.menuItems : []}
    ></PromineoButtonWithPopover>
  );
}
