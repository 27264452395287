import DoneIcon from "components/icons/DoneIcon";

interface Props {
  stepNumber: number;
  isCurrent: boolean;
  isComplete: boolean;
  onClick: (step: number) => void;
  allowClick: boolean;
}

export default function StepCircle(props: Props) {
  const handleClick = () => {
    props.onClick(props.stepNumber);
  };

  let classes =
    "w-[21px] h-[21px] text-center text-sm text-white font-inter leading-[21px] rounded-full";

  let cursorClass = props.allowClick ? "cursor-pointer" : "cursor-default";

  const getClassesForCurrent = () => {
    return `${classes} ${cursorClass} bg-ilapBlue`;
  };

  const getClassesForComplete = () => {
    return `${classes} ${cursorClass} bg-green500 flex justify-center items-center`;
  };

  const getClassesForIncomplete = () => {
    return `${classes} ${cursorClass} bg-gray`;
  };

  return props.isCurrent ? (
    <div className={getClassesForCurrent()} onClick={handleClick}>
      {props.stepNumber}
    </div>
  ) : props.isComplete ? (
    <div className={getClassesForComplete()} onClick={handleClick}>
      <DoneIcon />
    </div>
  ) : (
    <div className={getClassesForIncomplete()} onClick={handleClick}>
      {props.stepNumber}
    </div>
  );
}
