import { createSlice } from "@reduxjs/toolkit";
import { 
  loadUsersAsync,
  loadUsersByTenantAsync,
  loadLoggedInUserInfo
} from "store/actions/UserActions";
import UserResponse from "interfaces/response/UserResponse";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import MySelfResponse from "interfaces/response/MySelfResponse";

export interface UserListByTenant {
  tenantId: number;
  userList: UserSimplifiedResponse[];
}

export interface UserState {
  users: UserResponse[];
  usersByTenant: UserListByTenant[];
  mySelfResponse: MySelfResponse | null;
}

const initialState: UserState = {
  users: [],
  usersByTenant: [],
  mySelfResponse: null
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadUsersAsync.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(loadUsersByTenantAsync.fulfilled, (state, action) => {
      const userList: UserListByTenant = {
        tenantId: action.meta.arg.tenantId,
        userList: action.payload
      };

      state.usersByTenant.push(userList);
    });

    builder.addCase(loadLoggedInUserInfo.pending, (state) => {
      state.mySelfResponse = null;
    });
    builder.addCase(loadLoggedInUserInfo.fulfilled, (state, action) => {
      state.mySelfResponse = action.payload;
    });
  },
});

export default userSlice.reducer;
