import { EditorMode } from "shared/enums/EditorMode";
import PrimaveraImportConfig from "interfaces/host-system-config/primavera/PrimaveraImportConfig";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadActivityLinkRebuildOptionValuesAsync } from "store/actions/DropdownValueActions";
import { ScrollView } from "devextreme-react";
import PrimaveraReceivingFields from "./PrimaveraReceivingFields";

interface Props {
  mode: EditorMode;
  height: number;
  importConfig: PrimaveraImportConfig;
  isOverride?: boolean;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function PrimaveraReceivingParameters(props: Props) {
  const {
    mode,
    isOverride,
    importConfig,
    height,
    handleConfigurationValueChanged,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const activityLinkRebuildOptions = useSelector(
    (state: RootState) => state.dropdownData.activityLinkRebuildOption
  );

  useEffect(() => {
    if (importConfig) {
      displayLoadingPanel();
      dispatch(loadActivityLinkRebuildOptionValuesAsync()).finally(
        hideLoadingPanel
      );
    }
  }, [dispatch, mode, importConfig]);

  if (!importConfig) {
    return <></>;
  }

  return !isOverride ? (
    <ScrollView showScrollbar="always" height={height}>
      <div className="flex space-x-6">
        <div className="w-60 font-semibold text-base text-textGray">
          Primavera P6 Receiving parameters
        </div>
        <div className="space-y-6">
          <PrimaveraReceivingFields
            activityLinkRebuildOptions={activityLinkRebuildOptions}
            height={height}
            importConfig={importConfig}
            mode={mode}
            handleConfigurationValueChanged={handleConfigurationValueChanged}
          />
        </div>
      </div>
    </ScrollView>
  ) : (
    <div className="grid grid-cols-4 gap-x-4 gap-y-8">
      <PrimaveraReceivingFields
        activityLinkRebuildOptions={activityLinkRebuildOptions}
        height={height}
        importConfig={importConfig}
        mode={mode}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
    </div>
  );
}
