import EditIcon from "components/icons/EditIcon";

interface Props {
  isDisabled?: boolean;
  onEditClick: () => void;
  classNames?: string;
}

export default function EditButton(props: Props) {
  return(
    <div
      id="edit-btn"
      className={`rounded-lg cursor-pointer flex items-center justify-center bg-transparent hover:bg-backgroundGray ${props.isDisabled ? 'pointer-events-none' : ''} ${props.classNames}`}
      onClick={props.onEditClick}
    >
      <EditIcon />
    </div>
  );
}