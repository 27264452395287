import { HostSystem } from "shared/enums/feature/HostSystem";
import NewIEAWizardInformation from "./NewIEAWizardInformation";
import IEAActivityFilter from "features/ieas/iea-activity-filter-dialog/IEAActivityFilter";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import { useState } from "react";
import LabelWithContent from "components/common/LabelWithContent";
import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";
import SapActivityFilter, {
  SapActivityFilterProps,
} from "features/ieas/iea-activity-filter-dialog/sap/SapActivityFilter";

export interface StepFiveNewIeaProps {
  hostSystem: HostSystem;
  hostParameter?: SupportedHostSystemConfigurationType;
  generalActivityFilter?: {
    filterValue: string;
    onCurrentFilterChange: (value: string) => void;
  };
  sapActivityFilter?: SapActivityFilterProps;
}

export default function StepFiveNewIEA(props: StepFiveNewIeaProps) {
  const { generalActivityFilter, sapActivityFilter } = props;
  const [displayActivityFilter, setDisplayActivityFilter] = useState(false);
  const handleActivityFilterToggle = () => {
    setDisplayActivityFilter((prev) => !prev);
  };

  return (
    <div>
      <NewIEAWizardInformation
        text={`If desired, you can add an activity filter now.`}
      ></NewIEAWizardInformation>
      <div className="mt-3">
        {props.hostSystem === HostSystem.SAP ? (
          sapActivityFilter && (
            <div>
              <SapActivityFilter {...sapActivityFilter} />
            </div>
          )
        ) : (
          <div>
            <LabelWithContent
              label="Activity Filter"
              content={
                <PromineoSwitchButton
                  switchType={SwitchButtonType.Default}
                  defaultChecked={displayActivityFilter}
                  onChange={handleActivityFilterToggle}
                />
              }
            />

            {displayActivityFilter && generalActivityFilter && (
              <>
                <div className="border-b border-lightGray py-2"></div>
                <IEAActivityFilter
                  isEdit={true}
                  isForNewIea={true}
                  filterValue={generalActivityFilter.filterValue}
                  senderHostSystem={props.hostSystem}
                  onCurrentFilterValueChange={
                    generalActivityFilter.onCurrentFilterChange
                  }
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
