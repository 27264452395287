import { useEffect, useState } from "react";
import StepCircle from "./StepCircle";

interface Props {
  totalSteps: number;
  currentStep?: number;
  defaultCurrentStep?: number;
  onChange?: (currentSelection: number) => void;
}

export default function StepProgressBar(props: Props) {
  const [currentSelection, setCurrentSelection] = useState(
    props.defaultCurrentStep ?? 0
  );

  useEffect(() => {
    if (props.currentStep) {
      setCurrentSelection(props.currentStep);
    }
  }, [props.currentStep]);

  const handleClick = (step: number) => {
    if (props.onChange) {
      setCurrentSelection(step);
      props.onChange?.(step);
    }
  };

  return (
    <div className="flex gap-x-2 items-center justify-center select-none">
      {[...Array(props.totalSteps)].map((_, step) => {
        const current = step + 1;
        return (
          <div className="flex items-center" key={current}>
            <StepCircle
              stepNumber={current}
              isCurrent={current === currentSelection}
              isComplete={current < currentSelection}
              onClick={handleClick}
              allowClick={!!props.onChange}
            />
            {current < props.totalSteps ? (
              <div className="w-6 border-b border-lighterGray ml-2"></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
