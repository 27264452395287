import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import PromineoModal from "components/modal/PromineoModal";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorBasicInformation from "./ConnectorBasicInformation";
import { HostSystem } from "shared/enums/feature/HostSystem";
import SafranConnectorInformation from "./host-system/safran/SafranConnectorInformation";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import ConnectorWriteBaseRequest from "interfaces/request/ConnectorWriteBaseRequest";
import { createConnector } from "store/actions/ConnectorActions";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";
import { CONNECTORS } from "shared/constants/RoutePathConstants";
import { toastError } from "shared/utilities/ToastUtility";
import Validator from "devextreme/ui/validator";
import {
  getAnalyticsConnectorDefaultConfiguration,
  getConnectorConfigurationBasedOnHostSystem,
  getMicrosoftProjectConnectorDefaultConfiguration,
  getPrimaveraConnectorDefaultConfiguration,
  getSAPConnectorDefaultConfiguration,
  getSafranConnectorDefaultConfiguration,
  isConnectorInputValid,
} from "shared/utilities/ConnectorUtility";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import AnalyticsConnectorInformation from "./host-system/analytics/AnalyticsConnectorInformation";
import SAPConnectorInformation from "./host-system/sap/SAPConnectorInformation";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import { ScrollView } from "devextreme-react";
import PrimaveraConnectorConfiguration from "interfaces/response/connector/PrimaveraConnectorConfiguration";
import PrimaveraConnectorInformation from "./host-system/primavera/PrimaveraConnectorInformation";
import MicrosoftProjectConnectorConfiguration from "interfaces/response/connector/MicrosoftProjectConnectorConfiguration";
import MicrosoftProjectConnectorInformation from "./host-system/microsoftProject/MicrosoftProjectConnectorInformation";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import { AnalyticsConnectorAuthenticationMode } from "shared/enums/feature/AnalyticsConnectorAuthenticationMode";
import { SafranApiConnectorAuthenticationMode } from "shared/enums/feature/safran-host-system/SafranApiConnectorAuthenticationMode";
import { PrimaveraConnectorAuthenticationMode } from "shared/enums/feature/PrimaveraConnectorAuthenticationMode";
import { SapConnectorAuthenticationMode } from "shared/enums/feature/sap-host-system/SapConnectorAuthenticationMode";

interface Props {
  connector: ConnectorResponse;
  connectorConfiguration:
    | SafranApiConnectorConfigurationResponse
    | AnalyticsConnectorConfiguration
    | SAPConnectorConfiguration
    | PrimaveraConnectorConfiguration
    | MicrosoftProjectConnectorConfiguration;
  modalMode: PromineoModalMode;
  handleCancelClick: () => void;
}

export default function ConnectorCreateOrCopyDialog(props: Props) {
  const { connector, modalMode } = props;
  const [
    isUnsavedChangeConfirmationVisible,
    setIsUnsavedChangeConfirmationVisible,
  ] = useState(false);

  const [isModified, setIsModified] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [connectorInput, setConnectorInput] =
    useState<ConnectorResponse>(connector);
  const [connectorConfiguration, setConnectorConfiguration] = useState<
    | SafranApiConnectorConfigurationResponse
    | AnalyticsConnectorConfiguration
    | SAPConnectorConfiguration
    | PrimaveraConnectorConfiguration
    | MicrosoftProjectConnectorConfiguration
  >(props.connectorConfiguration);
  const [selectedHostSystem, setSelectedHostSystem] = useState<HostSystem>(
    connector.hostSystem
  );

  const shouldResetAuthenticationDependentValues = useRef(false);
  const shouldResetHostSystemDependentValues = useRef(false);
  const [isInputValid, setIsInputValid] = useState<boolean>(false);

  const resetConnectorAuthenticationMode = () => {
    shouldResetAuthenticationDependentValues.current = true;
    let authenticationMode = -1;
    if (connectorInput.hostSystem === HostSystem.Safran) {
      authenticationMode =
        authenticationMode as SafranApiConnectorAuthenticationMode;
    } else if (connectorInput.hostSystem === HostSystem.IlapAnalytics) {
      authenticationMode =
        authenticationMode as AnalyticsConnectorAuthenticationMode;
    } else if (connectorInput.hostSystem === HostSystem.PrimaveraP6) {
      authenticationMode =
        authenticationMode as PrimaveraConnectorAuthenticationMode;
    } else if (connectorInput.hostSystem === HostSystem.SAP) {
      authenticationMode = authenticationMode as SapConnectorAuthenticationMode;
    }
    setConnectorConfiguration((prev) => ({
      ...prev,
      authenticationMode: authenticationMode,
    }));
  };

  const handleConnectorValueChanged = (fieldName: string, value: any) => {
    if (fieldName === "executionComponent") {
      resetConnectorAuthenticationMode();
    }
    setConnectorInput((previousConnector) => ({
      ...previousConnector,
      [fieldName]: value,
    }));
    setIsModified(true);
  };

  const handleHostSystemValueChange = (value: number) => {
    setConnectorInput((previousConnector) => ({
      ...previousConnector,
      hostSystem: value,
      executionComponent: -1 as ExecutionComponent,
    }));

    shouldResetHostSystemDependentValues.current = true;
    setSelectedHostSystem(value);
    setIsModified(true);
  };

  const handleConnectorConfigurationValueChanged = (
    fieldName: string,
    value: any
  ) => {
    setConnectorConfiguration((previousConfiguration) => ({
      ...previousConfiguration,
      [fieldName]: value,
    }));
    setIsModified(true);
  };

  const handleAuthenticationModeValueChanged = (value: any) => {
    setConnectorConfiguration((previousConfiguration) => ({
      ...previousConfiguration,
      authenticationMode: value,
    }));
    setIsModified(true);

    shouldResetAuthenticationDependentValues.current = true;
  };

  const validateOnFocusOut = (event: any) => {
    let instance = Validator.getInstance(event.element) as Validator;
    instance.validate();
  };

  useEffect(() => {
    if (shouldResetAuthenticationDependentValues.current) {
      // When authentication mode is changed, we are resetting all authentication dependened values.
      if (selectedHostSystem === HostSystem.Safran) {
        resetSafranAuthenticationRelatedValues();
      } else if (selectedHostSystem === HostSystem.IlapAnalytics) {
        resetAnalyticsAuthenticationRelatedValues();
      } else if (selectedHostSystem === HostSystem.SAP) {
        resetSAPAuthenticationRelatedValues();
      } else if (selectedHostSystem === HostSystem.PrimaveraP6) {
        resetPrimaveraAuthenticationRelatedValues();
      }
    }
  }, [connectorConfiguration.authenticationMode]);

  useEffect(() => {
    if (shouldResetHostSystemDependentValues.current) {
      // When host system is changed, we are resetting all host system dependened values.
      resetHostSystemRelatedValuesOnHostSystemSelectionChange();
    }
  }, [connectorInput.hostSystem]);

  useEffect(() => {
    setIsInputValid(
      isConnectorInputValid(connectorInput, connectorConfiguration)
    );
  }, [connectorInput, connectorConfiguration]);

  const resetSafranAuthenticationRelatedValues = () => {
    const currentConfiguration = {
      ...connectorConfiguration,
    } as SafranApiConnectorConfigurationResponse;

    currentConfiguration.username = "";
    currentConfiguration.password = "";
    currentConfiguration.adTenantId = "";
    currentConfiguration.clientApplicationId = "";
    currentConfiguration.redirectUri = "";

    setConnectorConfiguration(currentConfiguration);
  };

  const resetAnalyticsAuthenticationRelatedValues = () => {
    const currentConfiguration = {
      ...connectorConfiguration,
    } as AnalyticsConnectorConfiguration;

    currentConfiguration.azureAdAuthority = "";
    currentConfiguration.azureAdClientId = "";
    currentConfiguration.azureAdScopes = "";
    currentConfiguration.ilapAnalyticsToken = "";

    setConnectorConfiguration(currentConfiguration);
  };

  const resetSAPAuthenticationRelatedValues = () => {
    let currentConfiguration = {
      ...getSAPConnectorDefaultConfiguration(),
      baseUrl: connectorConfiguration.baseUrl,
      authenticationMode: connectorConfiguration.authenticationMode,
    } as SAPConnectorConfiguration;

    setConnectorConfiguration(currentConfiguration);
  };

  const resetPrimaveraAuthenticationRelatedValues = () => {
    const currentConfiguration = {
      ...connectorConfiguration,
    } as PrimaveraConnectorConfiguration;

    currentConfiguration.username = "";
    currentConfiguration.password = "";

    setConnectorConfiguration(currentConfiguration);
  };

  const resetHostSystemRelatedValuesOnHostSystemSelectionChange = () => {
    var defaultConfiguration = undefined;

    if (selectedHostSystem === HostSystem.IlapAnalytics) {
      defaultConfiguration = getAnalyticsConnectorDefaultConfiguration();
    } else if (selectedHostSystem === HostSystem.Safran) {
      defaultConfiguration = getSafranConnectorDefaultConfiguration();
    } else if (selectedHostSystem === HostSystem.SAP) {
      defaultConfiguration = getSAPConnectorDefaultConfiguration();
    } else if (selectedHostSystem === HostSystem.PrimaveraP6) {
      defaultConfiguration = getPrimaveraConnectorDefaultConfiguration();
    } else if (selectedHostSystem === HostSystem.MicrosoftProject) {
      defaultConfiguration = getMicrosoftProjectConnectorDefaultConfiguration();
    }

    if (defaultConfiguration) {
      setConnectorConfiguration(defaultConfiguration);
    }
  };

  const getConnectorModelFromInput = (): ConnectorWriteBaseRequest => {
    const configuration = getConnectorConfigurationBasedOnHostSystem(
      connectorConfiguration,
      selectedHostSystem
    );

    const connectorRequest: ConnectorWriteBaseRequest = {
      name: connectorInput.name,
      hostSystem: connectorInput.hostSystem,
      executionComponent: connectorInput.executionComponent,
      definition: JSON.stringify(configuration),
    };

    return connectorRequest;
  };

  const handleCreateClick = () => {
    const connectorRequest = getConnectorModelFromInput();

    displayLoadingPanel();
    dispatch(createConnector(connectorRequest))
      .then(unwrapResult)
      .then((createdConnector: ConnectorDetailsResponse) => {
        navigate(`${CONNECTORS}/${createdConnector.id}`);
      })
      .catch(() => {
        toastError("Failed to create new connector.");
      })
      .finally(hideLoadingPanel);
  };

  const handleCancelClick = () => {
    if (isModified) {
      setIsUnsavedChangeConfirmationVisible(true);
    } else {
      props.handleCancelClick();
    }
  };

  const handleConfirmCancel = () => {
    setIsUnsavedChangeConfirmationVisible(false);
    props.handleCancelClick();
  };

  const handleCancelConfirmationDialog = () => {
    setIsUnsavedChangeConfirmationVisible(false);
  };

  return (
    <>
      <PromineoModal
        displayDefaultActions={true}
        isVisible={true}
        width="400"
        height="auto"
        title="New connector"
        editorActionButtonProps={{
          onCreate: handleCreateClick,
          onCancel: handleCancelClick,
          disableConfirmButton: !isInputValid,
          createButtonCaption: "Create",
          cancelButtonCaption: "Cancel",
          mode: PromineoModalMode.Create,
        }}
      >
        <div>
          <div className="border-t border-lightGray mb-1"></div>

          <div className="text-textGray text-10px leading-4">
            <ScrollView height={510}>
              <div>
                <ConnectorBasicInformation
                  connector={connectorInput}
                  mode={modalMode}
                  handleValueChanged={handleConnectorValueChanged}
                  handleHostSystemValueChange={handleHostSystemValueChange}
                  validateOnFocusOut={validateOnFocusOut}
                />

                {selectedHostSystem === HostSystem.Safran && (
                  <SafranConnectorInformation
                    executionComponent={connectorInput.executionComponent}
                    configuration={
                      connectorConfiguration as SafranApiConnectorConfigurationResponse
                    }
                    mode={modalMode}
                    handleConfigurationValueChanged={
                      handleConnectorConfigurationValueChanged
                    }
                    handleSafranAuthenticationModeValueChanged={
                      handleAuthenticationModeValueChanged
                    }
                    validateOnFocusOut={validateOnFocusOut}
                  />
                )}
                {selectedHostSystem === HostSystem.IlapAnalytics && (
                  <AnalyticsConnectorInformation
                    executionComponent={connectorInput.executionComponent}
                    configuration={
                      connectorConfiguration as AnalyticsConnectorConfiguration
                    }
                    mode={modalMode}
                    handleConfigurationValueChanged={
                      handleConnectorConfigurationValueChanged
                    }
                    handleAuthenticationModeValueChanged={
                      handleAuthenticationModeValueChanged
                    }
                    validateOnFocusOut={validateOnFocusOut}
                  />
                )}
                {selectedHostSystem === HostSystem.SAP && (
                  <SAPConnectorInformation
                    configuration={
                      connectorConfiguration as SAPConnectorConfiguration
                    }
                    executionComponent={connectorInput.executionComponent}
                    mode={modalMode}
                    handleConfigurationValueChanged={
                      handleConnectorConfigurationValueChanged
                    }
                    handleAuthenticationModeValueChanged={
                      handleAuthenticationModeValueChanged
                    }
                    validateOnFocusOut={validateOnFocusOut}
                  />
                )}
                {selectedHostSystem === HostSystem.PrimaveraP6 && (
                  <PrimaveraConnectorInformation
                    configuration={
                      connectorConfiguration as PrimaveraConnectorConfiguration
                    }
                    mode={modalMode}
                    handleConfigurationValueChanged={
                      handleConnectorConfigurationValueChanged
                    }
                    handleAuthenticationModeValueChanged={
                      handleAuthenticationModeValueChanged
                    }
                    validateOnFocusOut={validateOnFocusOut}
                  />
                )}
                {selectedHostSystem === HostSystem.MicrosoftProject && (
                  <MicrosoftProjectConnectorInformation
                    configuration={
                      connectorConfiguration as MicrosoftProjectConnectorConfiguration
                    }
                    mode={modalMode}
                    handleConfigurationValueChanged={
                      handleConnectorConfigurationValueChanged
                    }
                  />
                )}
              </div>
            </ScrollView>
          </div>

          <div className="border-t border-lightGray mt-6"></div>
        </div>
      </PromineoModal>
      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmCancel}
          onCancel={handleCancelConfirmationDialog}
        ></PromineoCancelEditingConfirmationDialog>
      ) : null}
    </>
  );
}
