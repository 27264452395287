import { useEffect, useState } from "react";

interface Props {
  isCoreInfoRequestValid: boolean;
  isOwnerConfigRequestValid: boolean;
  isScheduleRequestValid: boolean;
  isHostParameterRequestValid: boolean;
  isHostParametersWithActivityFilterRequestValid: boolean;
  currentStep: number;
}

const useStepValidation = (props: Props): boolean => {
  const [areAllStepDataValid, setAreAllStepDataValid] = useState(false);
  const {
    isCoreInfoRequestValid,
    isOwnerConfigRequestValid,
    isScheduleRequestValid,
    isHostParameterRequestValid,
    isHostParametersWithActivityFilterRequestValid,
    currentStep,
  } = props;

  useEffect(() => {
    let isValid = true;
    if (currentStep >= 1) {
      isValid &&= isCoreInfoRequestValid;
    }
    if (currentStep >= 2) {
      isValid &&= isOwnerConfigRequestValid;
    }
    if (currentStep >= 3) {
      isValid &&= isScheduleRequestValid;
    }
    if (currentStep >= 4) {
      isValid &&= isHostParameterRequestValid;
    }
    if (currentStep === 5) {
      isValid &&= isHostParametersWithActivityFilterRequestValid;
    }

    setAreAllStepDataValid(isValid);
  }, [
    isCoreInfoRequestValid,
    isOwnerConfigRequestValid,
    isScheduleRequestValid,
    isHostParameterRequestValid,
    isHostParametersWithActivityFilterRequestValid,
    currentStep,
  ]);

  return areAllStepDataValid;
};

export default useStepValidation;
