import {
  forwardRef,
  LegacyRef,
  ReactNode,
  useMemo,
  useRef,
} from "react";
import { IPopupOptions, Popup } from "devextreme-react/popup";
import { EditorActionButtonProp } from "./PromineoModalActionFooter";
import PromineoModalFooter from "./PromineoModalFooter";
import "./styles/PromineoModal.css";

export interface Props extends IPopupOptions {
  isVisible: boolean;
  className?: string;
  innerClassName?: string;
  children?: ReactNode;
  displayDefaultActions?: boolean;
  editorActionButtonProps?: EditorActionButtonProp;
  coloredBg?: boolean;
}

export default function PromineoModal(props: Props) {
  const {
    title,
    isVisible,
    children,
    displayDefaultActions,
    editorActionButtonProps,
    onShown,
    ...rest
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const bodyContentRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const modalWrapperAttr = useMemo(() => {
    return {
      class: `promineo-popup ${props.className ?? ""} ${
        props.coloredBg ? "colored-bg" : ""
      }`,
    };
  }, [props.coloredBg, props.className]);

  return isVisible ? (
    <div>
      <Popup
        wrapperAttr={modalWrapperAttr}
        visible={isVisible}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={false}
        container="html"
        minWidth={380}
        position={"center"}
        showTitle={false}
        {...rest}
      >
        <div
          className="flex flex-col justify-between h-full"
          ref={containerRef}
        >
          <div className="font-poppins text-textGray mx-6 mt-6">
            {title && <Title title={title} ref={titleRef} />}
            {children && (
              <BodyContent content={children} ref={bodyContentRef} />
            )}
          </div>

          {displayDefaultActions !== false && editorActionButtonProps && (
            <div className="flex flex-col justify-end">
              <PromineoModalFooter
                editorActionButtonProps={editorActionButtonProps}
                ref={footerRef}
              />
            </div>
          )}
        </div>
      </Popup>
    </div>
  ) : (
    <></>
  );
}

const Title = forwardRef(
  ({ title }: { title: string }, ref: LegacyRef<HTMLDivElement>) => {
    return (
      <div className="text-xs leading-[18px] font-bold mb-2 text-lightTextGray" ref={ref}>
        {title}
      </div>
    );
  }
);

const BodyContent = forwardRef(
  (
    {
      height,
      content,
    }: {
      height?: number;
      content: any;
    },
    ref: LegacyRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        style={{ height: height ? `${height}px` : "auto" }}
        className="overflow-hidden"
      >
        {content}
      </div>
    );
  }
);
