import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import {
  getDropdownValuesWithExecutionComponentAsync,
  getDropdownValuesAsync,
} from "apis/DropdownValuesApi";
import {
  AnalyticsApiConnectorAuthenticationModeTableName,
  ApplicationRoleTableName,
  ContentControlTableName,
  ExecutionComponentTableName,
  FieldContentControlLevelTableName,
  HostSystemTableName,
  PlanningObjectTypesTableName,
  PrimaveraConnectorAuthenticationModeTableName,
  RFieldExportOptionsEnumTableName,
  RevisionTypeEnumName,
  RevisionTypeTableName,
  SAPConnectorAuthenticationModeTableName,
  SafranApiConnectorAuthenticationModeTableName,
  ScopeEnumTableName,
  StorageAtHomeOptionTableName,
  TenantContentControlLavelTableName,
  ActivityLinkRebuildOptionEnumTableName,
  SapObjectTypeEnumName,
  SapFunctionContextEnumName,
  SapFunctionTypeEnumName,
  SapFunctionSequenceTypeEnumName,
  ConflictResolveOptionEnumName,
  TenantStatusOptionEnumName,
} from "shared/constants/DropdownTableNameConstants";

export const loadExecutionComponentValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/executionComponentValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const executionComponentValues = state.dropdownData.executionComponent;

  if (executionComponentValues.length === 0) {
    return await getDropdownValuesAsync(ExecutionComponentTableName);
  } else {
    return executionComponentValues;
  }
});

export const loadHostSystemValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/hostSystemValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const hostSystems = state.dropdownData.hostSystem;

  if (hostSystems.length === 0) {
    return await getDropdownValuesWithExecutionComponentAsync(
      HostSystemTableName
    );
  } else {
    return hostSystems;
  }
});

export const loadSafranApiConnectorAuthenticationModeValuesAsync =
  createAsyncThunk<any, void, { state: RootState }>(
    "dropdownValues/safranApiConnectorAuthenticationModeValues",
    async (_, thunkApi) => {
      const state = thunkApi.getState();
      const safranApiConnectorAuthenticationModes =
        state.dropdownData.safranApiConnectorAuthenticationMode;

      if (safranApiConnectorAuthenticationModes.length === 0) {
        return await getDropdownValuesWithExecutionComponentAsync(
          SafranApiConnectorAuthenticationModeTableName
        );
      } else {
        return safranApiConnectorAuthenticationModes;
      }
    }
  );

export const loadAnalyticsConnectorAuthenticationModeValuesAsync =
  createAsyncThunk<any, void, { state: RootState }>(
    "dropdownValues/analyticsConnectorAuthenticationModeValues",
    async (_, thunkApi) => {
      const state = thunkApi.getState();
      const analyticsConnectorAuthenticationModes =
        state.dropdownData.analyticsConnectorAuthenticationMode;

      if (analyticsConnectorAuthenticationModes.length === 0) {
        return await getDropdownValuesWithExecutionComponentAsync(
          AnalyticsApiConnectorAuthenticationModeTableName
        );
      } else {
        return analyticsConnectorAuthenticationModes;
      }
    }
  );

export const loadSAPConnectorAuthenticationModeValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>(
  "dropdownValues/sapConnectorAuthenticationModeValues",
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const sapConnectorAuthenticationModes =
      state.dropdownData.sapConnectorAuthenticationMode;

    if (sapConnectorAuthenticationModes.length === 0) {
      return await getDropdownValuesWithExecutionComponentAsync(
        SAPConnectorAuthenticationModeTableName
      );
    } else {
      return sapConnectorAuthenticationModes;
    }
  }
);

export const loadPrimaveraConnectorAuthenticationModeValuesAsync =
  createAsyncThunk<any, void, { state: RootState }>(
    "dropdownValues/primaveraConnectorAuthenticationModeValues",
    async (_, thunkApi) => {
      const state = thunkApi.getState();
      const primaveraConnectorAuthenticationModes =
        state.dropdownData.primaveraConnectorAuthenticationMode;

      if (primaveraConnectorAuthenticationModes.length === 0) {
        return await getDropdownValuesWithExecutionComponentAsync(
          PrimaveraConnectorAuthenticationModeTableName
        );
      } else {
        return primaveraConnectorAuthenticationModes;
      }
    }
  );

export const loadAnalyticsRevisionTypeValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/AnalyticsRevisionTypeValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const analyticsRevisionTypes = state.dropdownData.analyticsRevisionTypes;

  if (analyticsRevisionTypes.length === 0) {
    return await getDropdownValuesAsync(RevisionTypeTableName);
  } else {
    return analyticsRevisionTypes;
  }
});

export const loadContentControlValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/contentControlValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const contentControls = state.dropdownData.contentControls;

  if (contentControls.length === 0) {
    return await getDropdownValuesAsync(ContentControlTableName);
  } else {
    return contentControls;
  }
});

export const loadPlanningObjectTypeValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/planningObjectTypeValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const planningObjectTypes = state.dropdownData.planningObjectTypes;

  if (planningObjectTypes.length === 0) {
    return await getDropdownValuesAsync(PlanningObjectTypesTableName);
  } else {
    return planningObjectTypes;
  }
});

export const loadDataTypeValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/dataTypeValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const dataTypes = state.dropdownData.dataTypes;

  if (dataTypes.length === 0) {
    return await getDropdownValuesAsync("TypeCode");
  } else {
    return dataTypes;
  }
});

export const loadFieldContentControlLevelAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/fieldContentControlLevels", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const fieldContentControlLevels =
    state.dropdownData.fieldContentControlLevels;

  if (fieldContentControlLevels.length === 0) {
    return await getDropdownValuesAsync(FieldContentControlLevelTableName);
  } else {
    return fieldContentControlLevels;
  }
});

export const loadTenantContentControlLavelValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadTenantContentControlLavelValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const contentControls = state.dropdownData.contentControls;

  if (contentControls.length === 0) {
    return await getDropdownValuesAsync(TenantContentControlLavelTableName);
  } else {
    return contentControls;
  }
});

export const loadApplicationRoleValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadApplicationRoleValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const applicationRoles = state.dropdownData.applicationRoles;

  if (applicationRoles.length === 0) {
    return await getDropdownValuesAsync(ApplicationRoleTableName);
  } else {
    return applicationRoles;
  }
});

export const loadStorageAtHomeOptionValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadStorageAtHomeOptionValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const storageAtHomeOptions = state.dropdownData.storageAtHomeOptions;

  if (storageAtHomeOptions.length === 0) {
    return await getDropdownValuesAsync(StorageAtHomeOptionTableName);
  } else {
    return storageAtHomeOptions;
  }
});

export const loadExportScopeValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadExportScopeValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const exportScopes = state.dropdownData.exportScopes;

  if (exportScopes.length === 0) {
    return await getDropdownValuesAsync(ScopeEnumTableName);
  } else {
    return exportScopes;
  }
});

export const loadRFieldExportOptionValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadRFieldExportOptionValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const rFieldExportOptionValues = state.dropdownData.rFieldExportOptions;

  if (rFieldExportOptionValues.length === 0) {
    return await getDropdownValuesAsync(RFieldExportOptionsEnumTableName);
  } else {
    return rFieldExportOptionValues;
  }
});

export const loadActivityLinkRebuildOptionValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadActivityLinkRebuildOptionValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const activityLinkRebuildOptionValues =
    state.dropdownData.activityLinkRebuildOption;

  if (activityLinkRebuildOptionValues.length === 0) {
    return await getDropdownValuesAsync(ActivityLinkRebuildOptionEnumTableName);
  } else {
    return activityLinkRebuildOptionValues;
  }
});

export const loadRevisionTypeOptionValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadRevisionTypeOptionValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const revisionTypes = state.dropdownData.revisionTypeOptions;

  if (revisionTypes.length === 0) {
    return await getDropdownValuesAsync(RevisionTypeEnumName);
  } else {
    return revisionTypes;
  }
});

export const loadSapObjectTypeOptionValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadSapObjectTypeOptionValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const objectTypes = state.dropdownData.sapObjectTypes;

  if (objectTypes.length === 0) {
    return await getDropdownValuesAsync(SapObjectTypeEnumName);
  } else {
    return objectTypes;
  }
});

export const loadSapFunctionTypeOptionValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadSapFunctionTypeOptionValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const optionValues = state.dropdownData.sapFunctionTypes;

  if (optionValues.length === 0) {
    return await getDropdownValuesAsync(SapFunctionTypeEnumName);
  } else {
    return optionValues;
  }
});

export const loadSapFunctionContextOptionValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadSapFunctionContextOptionValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const optionValues = state.dropdownData.sapContextTypes;

  if (optionValues.length === 0) {
    return await getDropdownValuesAsync(SapFunctionContextEnumName);
  } else {
    return optionValues;
  }
});

export const loadSapSequenceTypeOptionValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadSapSequenceTypeOptionValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const optionValues = state.dropdownData.sapSequenceTypes;

  if (optionValues.length === 0) {
    return await getDropdownValuesAsync(SapFunctionSequenceTypeEnumName);
  } else {
    return optionValues;
  }
});

export const loadConflictResolveApproachValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadConflictResolveApproachValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const optionValues = state.dropdownData.conflictResolveApproaches;

  if (optionValues.length === 0) {
    return await getDropdownValuesAsync(ConflictResolveOptionEnumName);
  } else {
    return optionValues;
  }
});

export const loadTenantStatusesValuesAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("dropdownValues/loadTenantStatusesValues", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const optionValues = state.dropdownData.tenantStatuses;

  if (optionValues.length === 0) {
    return await getDropdownValuesAsync(TenantStatusOptionEnumName);
  } else {
    return optionValues;
  }
});
