import { FrequencyType } from "shared/enums/feature/FrequencyType";
import SapPredecessorIEAFilterComponent from "./SapPredecessorIEAFilterComponent";
import SapOrderOperationFilterComponent from "./SapOrderOperationFilterComponent";

export interface SapActivityFilterProps {
  isEdit?: boolean;
  isNew?: boolean;
  frequencyType: FrequencyType;
  isPredecessorIEASelected: boolean;
  orderFilterValue: string;
  operationFilterValue: string;
  onPredecessorIEASelectionChange: (selectedValue: boolean) => void;
  onOrderFilterValueChange: (
    selectedFilter: string,
    parentName: string,
    valueChangedFromDropdown: boolean
  ) => void;
  onOperationFilterValueChange: (
    newOperationFilter: string,
    valueChangedFromDropdown: boolean
  ) => void;
}

export default function SapActivityFilter(props: SapActivityFilterProps) {
  const {
    isEdit,
    isNew,
    isPredecessorIEASelected,
    frequencyType,
    orderFilterValue,
    operationFilterValue,
    onPredecessorIEASelectionChange,
    onOperationFilterValueChange,
    onOrderFilterValueChange,
  } = props;
  return (
    <>
      <SapPredecessorIEAFilterComponent
        isEdit={isEdit ?? false}
        frequencyType={frequencyType}
        isPredecessorIEASelected={isPredecessorIEASelected}
        handlePredecessorIEASelectionChange={onPredecessorIEASelectionChange}
      />

      {!isPredecessorIEASelected && (
        <>
          <div className="border-b border-lightGray my-2"></div>
          <SapOrderOperationFilterComponent
            isEdit={isEdit ?? false}
            isNew={isNew ?? false}
            orderFilterValue={orderFilterValue}
            operationFilterValue={operationFilterValue}
            handleOrderFilterValueChange={onOrderFilterValueChange}
            handleOperationFilterValueChange={onOperationFilterValueChange}
          />
        </>
      )}
    </>
  );
}
