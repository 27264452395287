import { createSlice } from "@reduxjs/toolkit";
import ClientResponse from "interfaces/response/autonomous-components/ClientResponse";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import {
  addNewIlapAnalyticsClientAsync,
  addNewIlapAnalyticsServiceTokenAsync,
  loadIlapAnalyticsClientsAsync,
  loadIlapAnalyticsServiceTokensAsync,
  modifyIlapAnalyticsClientAsync,
  modifyIlapAnalyticsServiceTokenAsync,
  removeIlapAnalyticsClientAsync,
  removeIlapAnalyticsServiceTokenAsync,
} from "store/actions/IlapAnalyticsActions";

export interface IlapAnalyticsState {
  clients: ClientResponse[];
  serviceTokens: ServiceTokenResponse[];
}

const initialState: IlapAnalyticsState = {
  clients: [],
  serviceTokens: [],
};

export const ilapAnalyticsSlice = createSlice({
  name: "ilapAnalytics",
  initialState,
  reducers: {
    resetIlapAnalyticsServiceTokens: (action) => {
      action.serviceTokens = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadIlapAnalyticsClientsAsync.fulfilled,
      (state, action) => {
        state.clients = action.payload;
      }
    );

    builder.addCase(
      addNewIlapAnalyticsClientAsync.fulfilled,
      (state, action) => {
        state.clients.push(action.payload);
      }
    );

    builder.addCase(
      modifyIlapAnalyticsClientAsync.fulfilled,
      (state, action) => {
        state.clients = state.clients.map(
          (component) => {
            if (component.id === action.payload.id) {
              return action.payload;
            }
            return component;
          }
        );
      }
    );

    builder.addCase(
      removeIlapAnalyticsClientAsync.fulfilled,
      (state, action) => {
        state.clients = state.clients.filter(
          (component) => {
            return component.id !== action.meta.arg;
          }
        );
      }
    );

    builder.addCase(loadIlapAnalyticsServiceTokensAsync.fulfilled, (state, action) => {
      state.serviceTokens = action.payload;
    });

    builder.addCase(addNewIlapAnalyticsServiceTokenAsync.fulfilled, (state, action) => {
      state.serviceTokens.push(action.payload);
    });

    builder.addCase(modifyIlapAnalyticsServiceTokenAsync.fulfilled, (state, action) => {
      state.serviceTokens = state.serviceTokens.map((serviceToken) => {
        if (serviceToken.id === action.payload.id) {
          return action.payload;
        }
        return serviceToken;
      });
    });

    builder.addCase(removeIlapAnalyticsServiceTokenAsync.fulfilled, (state, action) => {
      state.serviceTokens = state.serviceTokens.filter((serviceToken) => {
        return serviceToken.id !== action.meta.arg;
      });
    });
  },
});

export const { resetIlapAnalyticsServiceTokens } = ilapAnalyticsSlice.actions;
export default ilapAnalyticsSlice.reducer;
