import { ScrollView } from "devextreme-react";
import DataTransferEventLogResponse from "interfaces/response/DataTransferEventLogResponse";
import DetailsLog from "./DetailsLog";
import CopyLogButton from "./CopyLogButton";

interface Props {
  logDetails: DataTransferEventLogResponse[];
}

export default function LogListView(props: Props) {
  return (
    <div className="w-672px h-296px border border-lightGray rounded-lg bg-gray2 flex">
      <div className="w-full">
        <ScrollView direction={"both"} showScrollbar="always">
          <div className="space-y-3">
            {props.logDetails.map((log, ind) => (
              <DetailsLog
                key={ind}
                logLevel={log.logLevel}
                logMessage={log.message}
                logTime={log.time}
              />
            ))}
          </div>
        </ScrollView>
      </div>
      <div className="w-5 mt-2.5 mr-3.5">
        <CopyLogButton logDetails={props.logDetails} />
      </div>
    </div>
  );
}
