import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import CrossButton from "components/common/controls/buttons/CrossButton";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

interface UserDropdownDataSource extends UserSimplifiedResponse {
  visible: boolean;
}

interface Props {
  userList: UserSimplifiedResponse[];
  users: number[];
  maximumNumberOfUsers: number;
  minimumNumberOfUsers: number;
  onValueChange: (value: number[]) => void;
  updateDisableButtonStatus?: (value: boolean) => void;
  width?: number;
}

export default function TenantUsersDropdown(props: Props) {
  const {
    userList,
    users,
    maximumNumberOfUsers,
    minimumNumberOfUsers,
    onValueChange,
    updateDisableButtonStatus,
  } = props;

  const currentUser = useAppSelector((state) => state.userData.mySelfResponse);

  const [userDataSource, setUserDataSource] = useState<
    UserDropdownDataSource[]
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>(users);

  const checkIfMaximumNumberOfUsersAreAdded = () => {
    return selectedUsers.length >= maximumNumberOfUsers;
  };

  const checkIfMinimumNumberOfUsersExists = () => {
    return selectedUsers.length > minimumNumberOfUsers;
  };

  const [disableAddNewUserButton, setDisableAddNewUserButton] =
    useState<boolean>(checkIfMaximumNumberOfUsersAreAdded());
  const [disableDeleteUserButton, setDisableDeleteUserButton] =
    useState<boolean>(checkIfMinimumNumberOfUsersExists());

  useEffect(() => {
    let temporaryDataSource: UserDropdownDataSource[] = [];

    for (let index = 0; index < userList.length; index++) {
      // Creating the new data source with visibility property.
      // If the user exists in userList then we are hidding it in DataSource.
      let userExists = users.some((id) => id === userList[index].id);

      temporaryDataSource.push({
        id: userList[index].id,
        firstName: userList[index].firstName,
        middleName: userList[index].middleName,
        lastName: userList[index].lastName,
        fullName: userList[index].fullName,
        visible: !userExists,
      });
    }

    setUserDataSource(temporaryDataSource);
  }, [userList]);

  const handleAddUserClick = () => {
    setSelectedUsers([...selectedUsers, -1]);
  };

  useEffect(() => {
    onValueChange(selectedUsers);

    setDisableAddNewUserButton(checkIfMaximumNumberOfUsersAreAdded());
    setDisableDeleteUserButton(!checkIfMinimumNumberOfUsersExists());

    if (updateDisableButtonStatus) {
      let filteredPartnerRepresentatives = selectedUsers.filter(
        (userId: number) => userId !== -1
      );

      updateDisableButtonStatus(filteredPartnerRepresentatives.length === 0);
    }
  }, [selectedUsers]);

  const AddButton = () => {
    return (
      <div
        className={`select-none w-[165px] cursor-pointer flex items-center text-ilapBlue hover:text-blue600 ${
          disableAddNewUserButton ? "pointer-events-none text-gray" : ""
        }`}
        onClick={handleAddUserClick}
      >
        <span className="text-2xl pb-1">+</span>
        <span className="ml-1 font-poppins text-sm font-medium">Add representative</span>
      </div>
    );
  };

  const handleDeleteUserClick = (
    indexToDelete: number,
    valueToDelete: number
  ) => {
    const newItems = [...selectedUsers];
    newItems.splice(indexToDelete, 1);
    setSelectedUsers(newItems);

    updateDataSourceVisibility(valueToDelete, true);
  };

  const updateDataSourceVisibility = (value: number, visibility: boolean) => {
    if (value !== -1) {
      let newDataSource = [...userDataSource];
      let indexToUpdate = newDataSource.findIndex(
        (user: UserDropdownDataSource) => user.id === value
      );

      if (indexToUpdate !== -1) {
        newDataSource[indexToUpdate].visible = visibility;
        setUserDataSource(newDataSource);
      }
    }
  };

  const isCurrentUser = (userId: number) => {
    return currentUser?.id == userId;
  };

  return (
    <div className="space-y-2">
      {selectedUsers.map((userId, index) => (
        <div
          className="flex space-x-2"
          key={`${index}_${userId}`}
          id={`${index}`}
        >
          <PromineoSelectBox
            width={props.width ?? "auto"}
            placeholder="Select"
            items={userDataSource}
            value={userId}
            disabled={isCurrentUser(userId)}
            valueExpr={"id"}
            displayExpr={"fullName"}
            onValueChanged={(e: any) => {
              // Firstly, we are updating the selectedItem list.
              // Then we are making the visibility of currently selected item to False so that it will
              // not appear in other dropdowns. And finally, we are making the visibility of previously
              // selected item to True.

              let newSelectedUsers = [...selectedUsers];
              newSelectedUsers[index] = e.value;
              setSelectedUsers(newSelectedUsers);

              updateDataSourceVisibility(e.value, false);

              if (e.previousValue) {
                updateDataSourceVisibility(e.previousValue, true);
              }
            }}
          />

          {!isCurrentUser(userId) && (
            <div>
              <CrossButton
                classNames={`mt-1 ${
                  disableDeleteUserButton ? "pointer-events-none" : ""
                }`}
                onDeleteClick={() => {
                  handleDeleteUserClick(index, userId);
                }}
              />
            </div>
          )}
        </div>
      ))}

      <AddButton />
    </div>
  );
}
