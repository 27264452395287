import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import { ClientTypeEnum } from "shared/enums/ClientTypeEnum";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { modifyAutonomousComponentServiceTokenAsync } from "store/actions/AutonomousComponentActions";
import { modifyIlapAnalyticsServiceTokenAsync } from "store/actions/IlapAnalyticsActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  onClose: () => void;
  serviceToken: ServiceTokenResponse | undefined;
  clientType: ClientTypeEnum;
}

export default function ServiceTokenStatusChanger(props: Props) {
  const dispatch = useAppDispatch();
  const handleCancelClick = () => {
    props.onClose();
  };

  const handleConfirmClick = () => {
    if (props.serviceToken) {
      displayLoadingPanel();

      const promise = props.clientType === ClientTypeEnum.AutonomousComponent
        ? dispatch(
          modifyAutonomousComponentServiceTokenAsync({
            serviceTokenId: props.serviceToken.id,
            data: {
              isActive: !props.serviceToken.isActive,
            },
          })
        )
        : dispatch(
          modifyIlapAnalyticsServiceTokenAsync({
            serviceTokenId: props.serviceToken.id,
            data: {
              isActive: !props.serviceToken.isActive,
            },
          })
        )
        
      promise
        .unwrap()
        .then(() => {
          props.onClose();
          toastSuccess("Service token updated successfully.");
        })
        .finally(hideLoadingPanel);
    }
  };

  const confirmationMessage = props.serviceToken
    ? `Are you sure, you want to
  ${props.serviceToken.isActive ? "inactivate" : "activate"} this service token`
    : "";

  return props.serviceToken ? (
    <PromineoConfirmationDialog
      onCancel={handleCancelClick}
      onConfirm={handleConfirmClick}
      content={confirmationMessage}
      confirmButtonText="Confirm"
    ></PromineoConfirmationDialog>
  ) : null;
}
