import PromineoToggleButton from "components/common/controls/buttons/PromineoToggleButton";
interface Props {
  editorMode: "edit" | "preview";
  onEditModeSet: () => void;
  onPreviewModeSet: () => void;
}

export default function BulkInsertToggleButton(props: Props) {
  const { editorMode, onEditModeSet, onPreviewModeSet } = props;
  return (
    <PromineoToggleButton
      value={editorMode === "edit" ? "left" : "right"}
      toggleLeftText="Edit"
      toggleRightText="Preview"
      onToggleLeft={onEditModeSet}
      onToggleRight={onPreviewModeSet}
      onToggleLeftBg={"bg-ilapGreen"}
      onToggleRightBg={"bg-ilapBlue"}
    />
  );
}
