import { applyGridChangesToDataSource } from "shared/utilities/PromineoGridUtility";
import { EditorMode } from "shared/enums/EditorMode";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { GridRowKeyProperty } from "shared/constants/GridConstants";

interface Props {
  rFields: HostFieldResponse[];
  data: any;
  mode: EditorMode;
  defaultValue?: string;
  onValueChange?: (value:any) => void;
}

export default function RFieldDropdownCellEdit(props: Props) {
  const data = props.data;

  const handleValueChange = (value: any) => {
    data.component.cellValue(data.row.rowIndex, "rFieldName", value);

    applyGridChangesToDataSource(GridRowKeyProperty, data.component);
    props.onValueChange?.(value);
  };

  if (props.mode === EditorMode.View) {
    return <>{data.data.rFieldName}</>;
  }

  return (
    <PromineoSelectBox
      width={"auto"}
      dataSource={props.rFields}
      valueExpr="name"
      displayExpr="displayText"
      defaultValue={data.data.rFieldName}
      onValueChange={handleValueChange}
    />
  );
}
