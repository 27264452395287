import LabelWithContent from "components/common/LabelWithContent";
import { getLocalFormattedDate } from "shared/utilities/DateTimeUtility";
import DateEditor from "./DateEditor";

export function StartDateViewer(props: { startDate: string }) {
  const labelClassName =
    "ml-2 w-full pr-6 font-inter font-medium text-[12px] leading-[18px]";

  return (
    <LabelWithContent
      label="Start date"
      content={
        <div className={labelClassName}>
          {getLocalFormattedDate(props.startDate)}
        </div>
      }
    />
  );
}

interface Props {
  startDate?: string;
  onValueChange?: (value: string) => void;
  isEdit?: boolean;
}

export default function StartDateField(props: Props) {
  return props.isEdit ? (
    <DateEditor
      onValueChange={props.onValueChange!}
      defaultValue={props.startDate}
      title="Start date"
    />
  ) : (
    <StartDateViewer startDate={props.startDate!} />
  );
}
