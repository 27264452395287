import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import { useCallback, useEffect, useRef, useState } from "react";
import { loadActiveSimplifiedExchangeAgreementsAsync } from "store/actions/ExchangeAgreementActions";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import PromineoNumberBox from "components/common/controls/PromineoNumberBox";
import {
  Frequency,
  Time,
  FrequencyResponse,
  TimeResponse,
  getFrequencyDropdownData,
  getTimeDropdownData,
  WeekDayResponse,
  getWeeklyDaysDropdownData,
  MonthlyDayResponse,
  getMonthlyDayDropdownData,
  getLagDayDropdownData,
  MonthlyDay,
  WeekDay,
  LagDayResponse,
} from "shared/utilities/FrequencyUtility";
import { useAppDispatch } from "store/hooks";
import ExchangeAgreementSimplifiedResponse from "interfaces/response/ExchangeAgreementSimplifiedResponse";
import PredecessorIeaSelectionDropdown from "./active-ieas/PredecessorIeaSelectionDropdown";

interface Props {
  ownerExchangeRole: ExchangeRoleEnum;
  predecessorAgreementId?: number;
  defaultValue?: string;
  lagInMinutes?: number;
  onPredecessorAgreementIdChange?: (value: any) => void;
  onLagMinutesChange?: (value: any) => void;
  onFrequencyTypeChange?: (value: FrequencyType) => void;
  onCronExpressionChange: (value: string) => void;
  frequencyType?: FrequencyType;
  selectedExchangeAgreementId?: number;
}

export default function FrequencyComponent(props: Props) {
  const executionCronExpressionFieldName = "executionCronExpression";
  const initialCronExpression: string[] = ["*", "*", "?", "*", "*"];
  const isInitialized = useRef(!!!props.defaultValue);

  const { ownerExchangeRole } = props;
  const dispatch = useAppDispatch();

  const [frequencyData, setFrequencyData] = useState<FrequencyResponse[]>([]);
  const [timeData, setTimeData] = useState<TimeResponse[]>([]);
  const [weekDayData, setWeekDayData] = useState<WeekDayResponse[]>([]);
  const [monthlyDayData, setMonthlyDayData] = useState<MonthlyDayResponse[]>(
    []
  );
  const [selectedPredecessor, setSelectedPredecessor] =
    useState<ExchangeAgreementSimplifiedResponse>();
  const [selectedFrequency, setSelectedFrequency] = useState<Frequency>();
  const [lagDayData, setLagDayData] = useState<LagDayResponse[]>([]);
  const [displayPredecessorIEA, setDisplayPredecessorIEA] = useState<boolean>();

  const exchangeAgreements = useSelector(
    (state: RootState) =>
      state.exchangeAgreementData.activeExchangeAgreementsSimplified
  );

  const [minutes, setMinutes] = useState<number>();
  const [hours, setHours] = useState<number>();
  const [weekDay, setWeekDay] = useState<WeekDay>();
  const [monthDays, setMonthDays] = useState<MonthlyDay>();
  const [lagInMinutes, setLagInMinutes] = useState<number | undefined>(
    props.lagInMinutes
  );

  const resetFrequencyValues = () => {
    setMinutes(undefined);
    setHours(undefined);
    setWeekDay(undefined);
    setMonthDays(undefined);
    setLagInMinutes(undefined);
    setSelectedPredecessor(undefined);
  };

  const handleCronExpressionChange = (value: any) => {
    props.onCronExpressionChange(value);
  };

  useEffect(() => {
    const notifyCronExpressionChange = (cronExpression: string) => {
      if (isInitialized.current) {
        handleCronExpressionChange(cronExpression);
      } else {
        isInitialized.current = true;
      }
    };

    if (!selectedFrequency) {
      return;
    }

    if (
      selectedFrequency === Frequency.Custom ||
      selectedFrequency === Frequency.PredecessorIEA
    ) {
      notifyCronExpressionChange("");
      return;
    }

    if (
      selectedFrequency === Frequency.Daily &&
      (minutes === undefined || hours === undefined)
    ) {
      notifyCronExpressionChange("");
      return;
    }

    if (
      selectedFrequency === Frequency.Weekly &&
      (minutes === undefined || hours === undefined || weekDay === undefined)
    ) {
      notifyCronExpressionChange("");
      return;
    }

    if (
      selectedFrequency === Frequency.Monthly &&
      (minutes === undefined || hours === undefined || monthDays === undefined)
    ) {
      notifyCronExpressionChange("");
      return;
    }

    let cronExpression = initialCronExpression;
    // Cron Expression Structure:
    // MINUTE HOUR DAY_OF_MONTH MONTH DAY_OF_WEEK
    let minuteString = "*";
    let hourString = "*";
    let weekDayString = "*";

    if (minutes !== undefined) {
      minuteString = minutes.toString();
    }

    if (hours !== undefined) {
      hourString = hours.toString();
    }

    if (weekDay !== undefined) {
      weekDayString = weekDay.toString();
    }

    if (selectedFrequency === Frequency.Monthly) {
      if (monthDays !== undefined) {
        if (monthDays === MonthlyDay.LastDay) {
          let value = "L";
          cronExpression[2] = value;
          weekDayString = "?";
        } else if (monthDays === MonthlyDay.LastSunday) {
          weekDayString = "0L";
        }
      }
    }

    cronExpression[0] = minuteString;
    cronExpression[1] = hourString;
    cronExpression[4] = weekDayString;

    let cronExpressionString =
      getCronExpressionFromCronExpressionArray(cronExpression);

    if (
      selectedFrequency === Frequency.Daily ||
      selectedFrequency === Frequency.Monthly ||
      selectedFrequency === Frequency.Weekly
    ) {
      notifyCronExpressionChange(cronExpressionString);
    }
  }, [minutes, hours, weekDay, monthDays, selectedFrequency]);

  useEffect(() => {
    if (props.frequencyType === FrequencyType.Predecessor) {
      setSelectedFrequency(Frequency.PredecessorIEA);
    }
    else if (props.frequencyType === FrequencyType.Manual){
      setSelectedFrequency(Frequency.Manual);
    }
    else if (props.defaultValue && monthlyDayData && monthlyDayData.length) {
      var values = props.defaultValue.split(" ");
      var hour = Number(values[1]);
      setHours(hour);
      var mins = Number(values[0]);
      setMinutes(mins);

      if (values[4] === "?" || values[4] === "0L") {
        setSelectedFrequency(Frequency.Monthly);
        const day = values[2] === "L" ? monthlyDayData[0] : monthlyDayData[1];
        setMonthDays(day.monthlyDay);
      } else if (values[4] !== "*") {
        setSelectedFrequency(Frequency.Weekly);
        const day = Number(values[4]);
        setWeekDay(day as WeekDay);
      } else if (values[2] === "?" && values[3] === "*" && values[4] === "*") {
        setSelectedFrequency(Frequency.Daily);
      } else {
        setSelectedFrequency(Frequency.Custom);
      }
    }
  }, [props.defaultValue, props.frequencyType, monthlyDayData, weekDayData]);

  const getCronExpressionFromCronExpressionArray = (
    cronExpressionArray: string[]
  ): string => {
    let cronExpressionString = cronExpressionArray.join(" ").toString();

    return cronExpressionString;
  };

  // Loading all dropdown sources
  useEffect(() => {
    setFrequencyData(getFrequencyDropdownData());
    setTimeData(getTimeDropdownData());
    setWeekDayData(getWeeklyDaysDropdownData());
    setMonthlyDayData(getMonthlyDayDropdownData());
    setLagDayData(getLagDayDropdownData());
  }, []);

  useEffect(() => {
    // If Owner is Sender then we will display PredecessorIEA frequency option.
    if (ownerExchangeRole === ExchangeRoleEnum.Receiver) {
      setDisplayPredecessorIEA(false);
    } else if (ownerExchangeRole === ExchangeRoleEnum.Sender) {
      setDisplayPredecessorIEA(true);
    }
  }, [ownerExchangeRole, frequencyData]);

  const initiatingFrequencyData = useRef(true);
  useEffect(() => {
    if (displayPredecessorIEA !== undefined) {
      const shouldDisplayPredecessorIeaOption =
        ownerExchangeRole === ExchangeRoleEnum.Sender;
      setFrequencyData((prev) => {
        return prev.map((p) => {
          if (p.frequency === Frequency.PredecessorIEA) {
            p.visible = shouldDisplayPredecessorIeaOption;
          }
          return p;
        });
      });

      if (initiatingFrequencyData.current) {
        initiatingFrequencyData.current = false;
      } else {
        // While creating new iea, user can change exchange role, in that case, need to reset selection.
        // While editing active iea, we can not reset previously selected value. Also, user can not change exchagne role while editing active iea.
        setSelectedFrequency(undefined);
      }
    }
  }, [displayPredecessorIEA]);

  useEffect(() => {
    if (selectedFrequency) {
      if (selectedFrequency === Frequency.PredecessorIEA) {
        displayLoadingPanel();
        dispatch(loadActiveSimplifiedExchangeAgreementsAsync()).finally(
          hideLoadingPanel
        );
      }
    }
  }, [selectedFrequency]);

  const RenderFrequencyHour = useCallback(() => {
    const getSelectedItem = () => {
      var selectedItem = timeData.filter(
        (t) => t.time.hour === hours && t.time.minute === minutes
      );
      if (selectedItem && selectedItem.length) {
        return selectedItem[0].time;
      }
      return undefined;
    };

    return (
      <PromineoSelectBox
        placeholder="Select time"
        width={115}
        value={getSelectedItem()}
        items={timeData}
        valueExpr={"time"}
        displayExpr={"displayValue"}
        onValueChange={(time: Time) => {
          setMinutes(time.minute);
          setHours(time.hour);
        }}
      />
    );
  }, [selectedFrequency, timeData, hours, minutes]);

  const onPredecessorIeaSelection = (value: number) => {
    if (exchangeAgreements) {
      var selectedIEA = exchangeAgreements.filter((x) => x.id === value && x.id !== props.selectedExchangeAgreementId);
      if (selectedIEA.length === 1) {
        setSelectedPredecessor(selectedIEA[0]);
        handleCronExpressionChange("");
        props.onPredecessorAgreementIdChange?.(value);
      }
    }
  };

  const handleFrequencyValueChange = (value: Frequency) => {
    resetFrequencyValues();
    setSelectedFrequency(value);
    if (value === Frequency.PredecessorIEA) {
      props.onFrequencyTypeChange?.(FrequencyType.Predecessor);
    }
    else if (value === Frequency.Manual){
      props.onFrequencyTypeChange?.(FrequencyType.Manual);
    }
    else {
      props.onFrequencyTypeChange?.(FrequencyType.CronExpression);
    }
    handleCronExpressionChange("");
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex flex-wrap gap-x-5 gap-y-2">
          <PromineoSelectBox
            width={115}
            items={frequencyData}
            value={selectedFrequency}
            valueExpr={"frequency"}
            displayExpr={"displayValue"}
            onValueChange={handleFrequencyValueChange}
          />

          {selectedFrequency === Frequency.Custom && (
            <PromineoTextBox
              width={188}
              placeholder="Type CRON expression"
              defaultValue={props.defaultValue}
              onChange={({ event }: any) => {
                handleCronExpressionChange(event.currentTarget?.value);
              }}
            />
          )}

          {selectedFrequency === Frequency.PredecessorIEA && (
            <PredecessorIeaSelectionDropdown
              exchangeAgreements={exchangeAgreements.filter(x => x.id !== props.selectedExchangeAgreementId)}
              onValueChange={onPredecessorIeaSelection}
              defaultValue={props.predecessorAgreementId}
            />
          )}

          {selectedFrequency === Frequency.Weekly && (
            <PromineoSelectBox
              placeholder="Select day"
              width={115}
              defaultValue={weekDay}
              items={weekDayData}
              valueExpr={"weekDay"}
              displayExpr={"displayValue"}
              onValueChange={(value: WeekDay) => {
                setWeekDay(value);
              }}
            />
          )}

          {selectedFrequency === Frequency.Monthly && (
            <div className="flex space-x-5">
              <PromineoSelectBox
                placeholder="Select day"
                width={115}
                defaultValue={monthDays}
                items={monthlyDayData}
                valueExpr={"monthlyDay"}
                displayExpr={"displayValue"}
                onValueChange={(value: MonthlyDay) => {
                  setMonthDays(value);
                }}
              />

              <PromineoSelectBox
                placeholder="Select lag"
                width={115}
                defaultValue={
                  (props.lagInMinutes !== undefined) ? props.lagInMinutes / 60 / 24 : undefined
                }
                items={lagDayData}
                valueExpr={"lagDay"}
                displayExpr={"displayValue"}
                onValueChange={(value: number) => {
                  let lagMinutes = value * 24 * 60;
                  props.onLagMinutesChange?.(lagMinutes);
                }}
              />
            </div>
          )}

          {(selectedFrequency === Frequency.Daily ||
            selectedFrequency === Frequency.Weekly ||
            selectedFrequency === Frequency.Monthly) && <RenderFrequencyHour />}

          {selectedFrequency === Frequency.PredecessorIEA && (
            <div className="flex space-x-5">
              <div className="flex justify-center items-center">
                {!!selectedPredecessor ? (
                  <>
                    {`Daily at ${selectedPredecessor.executionCronDisplay}`}{" "}
                    {selectedPredecessor.frequencyType ===
                    FrequencyType.Predecessor
                      ? ` +${selectedPredecessor.lagInMinutes} minutes`
                      : ""}
                  </>
                ) : (
                  <span>{"Frequency preview"}</span>
                )}
              </div>

              <PromineoNumberBox
                format={`# minute(s)`}
                className="text-xs"
                value={lagInMinutes}
                showClearButton={true}
                placeholder="Select lag (in minutes)"
                showSpinButtons={true}
                max={30}
                min={1}
                step={5}
                onValueChange={(value: number) => {
                  setLagInMinutes(value);
                  props.onLagMinutesChange?.(value);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
