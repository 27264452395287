import PromineoViewGrid, {
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import NewExchangeAgreementListingResponse from "interfaces/response/NewExchangeAgreementListingResponse";
import { useNavigate } from "react-router-dom";
import { NEW_IEAS } from "shared/constants/RoutePathConstants";
import "components/common/grid/styles/PromineoUIGrid.css";
import { ReactElement, useCallback, useRef } from "react";
import InactiveIEAStatusCellComponent from "./InactiveIEAStatusCellComponent";
import IeaStartDateCellComponent from "./IeaStartDateCellComponent";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";

interface Props {
  ieas: NewExchangeAgreementListingResponse[];
  height: number;
  gridFilterWidget: ReactElement;
}

export default function NewIEAsGrid(props: Props) {
  const { ieas, height } = props;
  let navigate = useNavigate();

  const handleInactiveIEAClick = (
    iea: NewExchangeAgreementListingResponse
  ) => {
    navigate(`${NEW_IEAS}/${iea.id}`);
  };

  const handleRowClick = useCallback((data: any) => {
    handleInactiveIEAClick(data.data);
  }, []);

  const ActionRequiredCellRender = useCallback((rowData: any) => {
    const data = rowData.data as NewExchangeAgreementListingResponse;
    const isBold =
      data.actionRequired ===
      NewExchangeAgreementActionRequired.Complete ||
      data.actionRequired === NewExchangeAgreementActionRequired.SignOff
        ? true
        : false;
    const actionRequiredText = data.actionRequired === NewExchangeAgreementActionRequired.None ? "-" : data.actionRequiredText;
    return (
      <div className={`${isBold ? "font-bold" : ""}`}>
        {actionRequiredText}
      </div>
    );
  }, []);

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    items: [
      {
        onClick: (data: any) => {
          const inactiveIea: NewExchangeAgreementListingResponse =
            data.data;
          handleInactiveIEAClick(inactiveIea);
        },
        text: "View",
        visible: true,
      },
    ],
  });

  return (
    <>
      <PromineoViewGrid
        dataSource={ieas}
        height={height}
        className={`inactive-ieas-grid promineo-ui-grid`}
        onRowClick={handleRowClick}
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig.current}
        additionalWidget={props.gridFilterWidget}
        noDataText="Your ILAP exchange agreements (IEAs) will appear here once you’ve started creating them!"
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"Status"}
          cellComponent={InactiveIEAStatusCellComponent}
        />
        <Column
          caption={"Action"}
          dataField="actionRequiredText"
          alignment="left"
          cellRender={ActionRequiredCellRender}
        />
        <Column
          caption={"IEA Id"}
          dataField="id"
          alignment="left"
          width={100}
        />
        <Column caption={"IEA Title"} dataField="title" alignment="left" />
        <Column caption={"Role"} dataField="roleText" alignment="left" />
        <Column caption={"Sender"} dataField="sender" alignment="left" />
        <Column caption={"Receiver"} dataField="receiver" alignment="left" />
        <Column
          caption={"IEA start date"}
          dataField="startDate"
          alignment="left"
          cellComponent={IeaStartDateCellComponent}
          sortIndex={0}
          sortOrder={"asc"}
        />
      </PromineoViewGrid>
    </>
  );
}
