import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import HostFieldSelectionDropdown from "../../config-common/HostFieldSelectionDropdown";

interface Props {
  fieldMapping: FieldMappingResponse;
  onSelectionChange: (data: HostFieldResponse) => void;
  selectedCodeSet: number | null;
}

export default function HostFieldEditor(props: Props) {
  if (props.fieldMapping.hasDirectMapping) {
    return (
      <HostFieldSelectionDropdown
        width={"auto"}
        defaultValue={props.fieldMapping.mappedConnectorHostFieldId}
        planningObjectType={props.fieldMapping.planningObjectType}
        onHostFieldSelectionChange={props.onSelectionChange}
        selectedCodeSet={props.selectedCodeSet}
      />
    );
  }

  return <div className="col-text">Value transformation required</div>;
}
