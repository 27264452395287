import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import SapOrderFilterExpressionPickerDropdown from "./SapOrderFilterExpressionPickerDropdown";

interface Props {
  isEdit: boolean;
  orderFilterValue: string;
  height?: number;
  handleOrderFilterValueChange: (selectedFilter: string, parentName: string, valueChangedFromDropdown: boolean) => void;
}

export default function SapOrderFilterComponent(props: Props) {
  const { isEdit, handleOrderFilterValueChange, orderFilterValue } = props;

  return (
    <div className="space-y-2">
      {isEdit && (
        <div className="text-textGray font-semibold font-inter text-14px leading-17px flex justify-between">
          <div className="mt-1" >Order filter</div>
          <div>
            <SapOrderFilterExpressionPickerDropdown
              handleOrderFilterValueChange={handleOrderFilterValueChange}
            />
          </div>
        </div>
      )}

      {!isEdit && (
        <div className="text-textGray font-semibold font-inter text-14px leading-17px flex justify-between">
          <div className="mt-1" >Order filter</div>
        </div>
      )}

      <PromineoMultilineTextEdit
        readOnly={!isEdit}
        height={props.height ?? 170}
        placeholder={isEdit ? "Type or paste here..." : ""}
        className="whitespace-pre-line text-xs font-inter"
        value={orderFilterValue}
        onChange={(e: any) => {
          if (e.event?.target?.value !== undefined) {
            handleOrderFilterValueChange(e.event.target.value, "", false);
          }
        }}
      />
    </div>   
  );
}