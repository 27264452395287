import PromineoPopover from "components/common/controls/PromineoPopover";
import { Offset, Position } from "devextreme-react/popup";
import PromineoButton from "components/common/controls/buttons/PromineoButton";
import AvatarIcon from "components/icons/AvatarIcon";
import { signOutAsync } from "authentication/AuthenticationManager";
import MySelfResponse from "interfaces/response/MySelfResponse";

interface Props {
  target: string;
  visible: boolean;
  onHiding: () => void;
  userInformation: MySelfResponse;
}

export default function AccountPopover(props: Props) {
  const Content = () => {
    return (
      <div className="py-4 px-6">
        <div className="flex">
          <div className="mr-3 flex items-center border p-1.5 rounded-full">
            <AvatarIcon color="black" />
          </div>
          <div className="truncate">
            <div className="text-sm font-medium">
              {props.userInformation.fullName}
            </div>
            <div className="text-xs font-normal">
              {props.userInformation.email}
            </div>
          </div>
        </div>
        <div className="mt-4 ml-2 flex justify-center">
          <PromineoButton
            text="Sign Out"
            className="text-black"
            onClick={signOutAsync}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <PromineoPopover width={"auto"} height={"auto"} {...props}>
        <Position my={{ x: "right", y: "top" }}>
          <Offset x={100} y={10} />
        </Position>
        <Content />
      </PromineoPopover>
    </>
  );
}
