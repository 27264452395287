import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import { useAppDispatch, useAppSelector } from "store/hooks";
import TenantsGrid from "./TenantsGrid";
import { useEffect } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadAllTenantsAsync } from "store/actions/TenantActions";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function Tenants() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadAllTenantsAsync()).finally(hideLoadingPanel);
  }, []);

  const tenants = useAppSelector((store) => store.tenantData.tenants);

  const tenantsListHeaderDiv = "tenants-list-header";
  const excludedContainerIds: string[] = [tenantsListHeaderDiv];
  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  return (
    <div>
      <div className="flex mb-4" id={tenantsListHeaderDiv}>
        <PageHeaderTitle title={"Tenants"} />
      </div>
      <TenantsGrid tenants={tenants} height={gridHeight} />
    </div>
  );
}
