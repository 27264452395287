import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import ConfigDirectionDropdown from "./ConfigDirectionDropdown";

interface Props {
  onDirectionChange: (direction: ConfigDirection) => void;
  selectedConnectorId?: number;
  selectedDirection?: ConfigDirection;
  selectedDirectionText: string;
  readonly: boolean;
  className: string;
  isConfigPublishedPreviously?: boolean;
}

export default function ConfigDirectionHolder(props: Props) {
  return (
    <div className={props.className ? props.className : ""}>
      <div>Direction:</div>
      {!props.readonly && !props.isConfigPublishedPreviously ? (
        <ConfigDirectionDropdown
          onChange={props.onDirectionChange}
          selectedDirection={props.selectedDirection}
          selectedConnectorId={props.selectedConnectorId}
        />
      ) : (
        <div>{props.selectedDirectionText}</div>
      )}
    </div>
  );
}
