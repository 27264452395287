import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { updateFieldsContentControlForSystemAdminAsync } from "store/actions/SystemAdminActions";
import { useAppDispatch } from "store/hooks";
import SystemAdminContentControlDropdown from "./SystemAdminContentControlDropdown";
import SystemAdminFieldResponse from "interfaces/response/SystemAdminFieldResponse";

interface Props {
  field: SystemAdminFieldResponse;
  isDisabled?: boolean;
  disableText?: string;
}

export default function SystemAdminControlControlLabelComponent(props: Props) {
  const dispatch = useAppDispatch();
  const { field, isDisabled, disableText } = props;

  const handleSystemAdminContentControlValueChange = (value: number) => {
    displayLoadingPanel();
    dispatch(
      updateFieldsContentControlForSystemAdminAsync({
        fieldId: field.id,
        request: { contentControl: value },
      })
    ).finally(hideLoadingPanel);
  };

  return (
    <div title={isDisabled ? disableText : ""}>
      <SystemAdminContentControlDropdown
        isDisabled={isDisabled}
        contentControl={field.controlledOn}
        onValueChange={handleSystemAdminContentControlValueChange}
      />
    </div>
  );
}
