export default function FavoriteStrokeIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99967 10.5133L11.1197 12.9999L10.0263 8.31325L13.6663 5.15992L8.87301 4.74659L6.99967 0.333252L5.12634 4.74659L0.333008 5.15992L3.96634 8.31325L2.87967 12.9999L6.99967 10.5133Z"
        stroke="#DFD600"
        strokeWidth="0.5"
      />
    </svg>
  );
}
