import { StepFiveNewIeaProps } from "features/ieas/active-ieas/new-iea/StepFiveNewIEA";
import HostSystemConfigurationBase from "interfaces/host-system-config/HostSystemConfigurationBase";
import SapExportConfig from "interfaces/host-system-config/sap/SapExportConfig";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FunctionsParentName,
  OperatorsParentName,
} from "shared/constants/SapOrderFilter";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { SapExportFilterType } from "shared/enums/feature/sap-host-system/SapExportFilterType";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";

export interface StepFiveDataHookProps {
  hostSystemParameters?: SupportedHostSystemConfigurationType;
  direction: ConfigDirection;
  hostSystem: HostSystem;
  sapActivityFilterProps: {
    frequencyType: FrequencyType;
  };
}

interface HookResponseType {
  stepFiveProps: StepFiveNewIeaProps;
  hostParametersWithActivityFilterRequest?: SupportedHostSystemConfigurationType;
  isHostParametersWithActivityFilterRequestValid: boolean;
}

export default function useStepFiveNewIeaDataHook(
  props: StepFiveDataHookProps
): HookResponseType {
  const [isValidRequest, setIsValidRequest] = useState(true);
  const [exportParameters, setExportParameters] =
    useState<SupportedHostSystemConfigurationType>();

  useEffect(() => {
    if (props.hostSystemParameters) {
      setExportParameters(props.hostSystemParameters);
    }
  }, [props.hostSystemParameters, props.hostSystem]);

  const handleActivityFilterChange = (value: string) => {
    setExportParameters((prev: HostSystemConfigurationBase) => {
      var updatedParameter = prev
        ? ({ ...prev, activityFilter: value } as HostSystemConfigurationBase)
        : undefined;
      return updatedParameter;
    });
  };

  const handleSapOperationFilterChange = useCallback(
    (selectedFilter: string, valueChangedFromDropdown: boolean) => {
      const previousOperationFilter = (exportParameters as SapExportConfig)
        .operationFilter;
      var newFilter = "";

      if (!valueChangedFromDropdown) {
        newFilter = selectedFilter;
      } else {
        if (!previousOperationFilter) {
          newFilter = selectedFilter;
        } else {
          newFilter = `${previousOperationFilter} & ${selectedFilter}`;
        }
      }
      setExportParameters((prev) => {
        return { ...prev, operationFilter: newFilter } as SapExportConfig;
      });
    },
    [exportParameters]
  );

  const handleSapOrderFilterChange = useCallback(
    (
      selectedFilter: string,
      parentName: string,
      valueChangedFromDropdown: boolean
    ) => {
      const previousOrderFilter = (exportParameters as SapExportConfig)
        .orderFilter;
      var newFilterValue: string = "";

      if (!valueChangedFromDropdown) {
        newFilterValue = selectedFilter;
      } else {
        if (!previousOrderFilter) {
          newFilterValue = selectedFilter;
        } else {
          var filterJoiner =
            parentName === FunctionsParentName ||
            parentName === OperatorsParentName
              ? " "
              : " and ";
          newFilterValue = `${previousOrderFilter}${filterJoiner}${selectedFilter}`;
        }
      }
      setExportParameters((prev) => {
        return { ...prev, orderFilter: newFilterValue } as SapExportConfig;
      });
    },
    [exportParameters]
  );

  const handleSapPredecessorIeaChange = (isPredecessorIeaChange: boolean) => {
    if (props.direction === ConfigDirection.Sending) {
      var exportFilterTypeValue = isPredecessorIeaChange
        ? SapExportFilterType.PredecessorIEA
        : SapExportFilterType.OrderFilter;

      setExportParameters((prev) => {
        return {
          ...prev,
          exportFilterType: exportFilterTypeValue,
        } as SapExportConfig;
      });
    }
  };

  const response = useMemo<HookResponseType>(() => {
    return {
      stepFiveProps: {
        hostParameter: exportParameters,
        hostSystem: props.hostSystem,
        generalActivityFilter:
          props.hostSystem !== HostSystem.SAP && exportParameters
            ? {
                filterValue: exportParameters
                  ? (exportParameters as HostSystemConfigurationBase)
                      .activityFilter
                  : "",
                onCurrentFilterChange: handleActivityFilterChange,
              }
            : undefined,
        sapActivityFilter:
          props.hostSystem === HostSystem.SAP && exportParameters
            ? {
                frequencyType: props.sapActivityFilterProps.frequencyType,
                isEdit: true,
                isNew: true,
                isPredecessorIEASelected:
                  (exportParameters as SapExportConfig).exportFilterType ===
                  SapExportFilterType.PredecessorIEA,
                operationFilterValue: (exportParameters as SapExportConfig)
                  .operationFilter,
                orderFilterValue: (exportParameters as SapExportConfig)
                  .orderFilter,
                onOperationFilterValueChange: handleSapOperationFilterChange,
                onOrderFilterValueChange: handleSapOrderFilterChange,
                onPredecessorIEASelectionChange: handleSapPredecessorIeaChange,
              }
            : undefined,
      },
      isHostParametersWithActivityFilterRequestValid: isValidRequest,
      hostParametersWithActivityFilterRequest: exportParameters,
    };
  }, [
    props,
    isValidRequest,
    exportParameters,
    handleSapOperationFilterChange,
    handleSapOrderFilterChange,
  ]);

  return response;
}
