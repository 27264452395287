import CrossIcon from "components/icons/CrossIcon";

interface Props {
  onCancel: () => void;
  fieldMappingName: string;
}

export default function ViewValueMapsDialogHeader(props: Props) {
  return (
    <div>
      <div className="flex justify-between w-full">
        <div className="font-Poppins font-bold leading-4 text-10px">
          Value map - {props.fieldMappingName}
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            props.onCancel();
          }}
        >
          <CrossIcon />
        </div>
      </div>
    </div>
  );
}
