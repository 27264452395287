import { getValue, setValue } from "./StorageUtility";

const getStorageKeyForFavoriteItems = (gridName: string) =>
  `${gridName}-favorite-items`;

const getFavoriteItemFromStorage = (storageKey: string): any[] => {
  const favorites = getValue(storageKey);
  if (favorites) {
    return JSON.parse(favorites);
  }
  return [];
};

const setFavoriteItemsToStorage = (gridName: string, items: any[]) => {
  const storageKey = getStorageKeyForFavoriteItems(gridName);
  setValue(storageKey, JSON.stringify(items));
};

export const addToFavorite = (gridName: string, value: any) => {
  const existingFavorites = getFavoritItemsForGrid(gridName);
  setFavoriteItemsToStorage(gridName, [...existingFavorites, value]);
};

export const removeFromFavorite = (gridName: string, value: any) => {
  const existingFavorites = getFavoritItemsForGrid(gridName);
  const favoritesAfterRemove = existingFavorites.filter((x) => x !== value);
  setFavoriteItemsToStorage(gridName, favoritesAfterRemove);
};

export const getFavoritItemsForGrid = (gridName: string): any[] => {
  const storageKey = getStorageKeyForFavoriteItems(gridName);
  return getFavoriteItemFromStorage(storageKey);
};
