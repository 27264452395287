import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  changeFieldsContentControlForSystemAdminAsync,
  createFieldsForSystemAdminAsync,
  getSystemUsersAsync,
  loadIlapTermFieldsForSystemAdminAsync,
  loadSystemLevelFieldDetailsAsync,
  modifyIlapTermBySystemAdminAsync,
  modifyIlapTermWithValuesAsync,
} from "apis/SystemAdminApi";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import SystemFieldCreateRequest from "interfaces/request/FieldCreateRequest";
import SystemLevelContentControlChangeRequest from "interfaces/request/SystemLevelContentControlChangeRequest";
import FieldUpdateRequest from "interfaces/request/FieldUpdateRequest";

export const getIlapTermFieldsForTenantAdminAsync = createAsyncThunk(
  "system-admin/getFieldsForSystemAdmin",
  async () => {
    return await loadIlapTermFieldsForSystemAdminAsync();
  }
);

export const createFieldsForSystemAdmin = createAsyncThunk(
  "system-admin/createFieldsForSystemAdmin",
  async (systemFieldCreateRequest: SystemFieldCreateRequest) => {
    return await createFieldsForSystemAdminAsync(systemFieldCreateRequest);
  }
);

export const updateFieldsContentControlForSystemAdminAsync = createAsyncThunk(
  "system-admin/updateFieldsContentControlForSystemAdmin",
  async (data: { fieldId: number; request: SystemLevelContentControlChangeRequest }) => {
    return await changeFieldsContentControlForSystemAdminAsync(data.fieldId, data.request);
  }
);

export const getFieldForSystemAdminAsync = createAsyncThunk(
  "system-admin/getFieldForSystemAdminAsync",
  async (fieldId: number) => {
    return await loadSystemLevelFieldDetailsAsync(fieldId);
  }
);

export const updateIlapTermWithValuesAsync = createAsyncThunk(
  "system-admin/updateIlapTermWithValues",
  async (data: { systemFieldId: number; request: FieldManageRequest }) => {
    return await modifyIlapTermWithValuesAsync(data.systemFieldId, data.request);
  }
);

export const updateIlapTermBySystemAdminAsync = createAsyncThunk(
  "system-admin/updateIlapTermBySystemAdmin",
  async (data: { fieldId: number; request: FieldUpdateRequest }) => {
    return await modifyIlapTermBySystemAdminAsync(data.fieldId, data.request);
  }
);

export const getSystemUsersForTenantAdminAsync = createAsyncThunk(
  "system-admin/getSystemUsersForSystemAdmin",
  async () => {
    return await getSystemUsersAsync();
  }
);
