import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import NewExchangeAgreementListingResponse from "interfaces/response/NewExchangeAgreementListingResponse";
import { loadNewExchangeAgreementsAsync } from "store/actions/ExchangeAgreementActions";
import { NewExchangeAgreementStatus } from "shared/enums/feature/NewExchangeAgreementStatus";
import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import InactiveIEAGridSummary from "./InactiveIEAGridSummary";
import NewIEAsGrid from "./NewIEAsGrid";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import { InactiveIEASummaryFilterOption } from "shared/enums/feature/InactiveIEASummaryFilterOption";

export default function NewIEAs() {
  const headerTitleDivId: string = "inactive-ieas-header-title";
  const excludedContainerIds: string[] = [headerTitleDivId];

  const dispatch = useDispatch<AppDispatch>();
  const [inactiveExchangeAgreementList, setInactiveExchangeAgreementList] =
    useState<NewExchangeAgreementListingResponse[]>([]);
  const [filteredExchangeAgreementList, setFilteredExchangeAgreementList] =
    useState<NewExchangeAgreementListingResponse[]>([]);
  const [
    underConstructionExchangeAgreementList,
    setUnderConstructionExchangeAgreementList,
  ] = useState<NewExchangeAgreementListingResponse[]>([]);
  const [
    pendingStartExchangeAgreementList,
    setPendingStartExchangeAgreementList,
  ] = useState<NewExchangeAgreementListingResponse[]>([]);
  const [selectedFilter, setSelectedFilter] =
    useState<InactiveIEASummaryFilterOption>(
      InactiveIEASummaryFilterOption.Total
    );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadNewExchangeAgreementsAsync())
      .then(unwrapResult)
      .then(
        (inactiveIEAsResponse: NewExchangeAgreementListingResponse[]) => {
          setInactiveExchangeAgreementList(inactiveIEAsResponse);
        }
      )
      .finally(hideLoadingPanel);
  }, [dispatch]);

  useEffect(() => {
    let underConstructionExchangeAgreements: NewExchangeAgreementListingResponse[] =
      [];
    let pendingStartExchangeAgreements: NewExchangeAgreementListingResponse[] =
      [];

    for (
      let inactiveIEAIndex = 0;
      inactiveIEAIndex < inactiveExchangeAgreementList.length;
      inactiveIEAIndex++
    ) {
      let inactiveExchangeAgreement =
        inactiveExchangeAgreementList[inactiveIEAIndex];

      if (
        inactiveExchangeAgreement.status ===
        NewExchangeAgreementStatus.BeingCreated
      ) {
        underConstructionExchangeAgreements.push(inactiveExchangeAgreement);
      } else if (
        inactiveExchangeAgreement.status ===
        NewExchangeAgreementStatus.PendingExchangeAgreementStart
      ) {
        pendingStartExchangeAgreements.push(inactiveExchangeAgreement);
      }
    }

    setUnderConstructionExchangeAgreementList(
      underConstructionExchangeAgreements
    );
    setPendingStartExchangeAgreementList(pendingStartExchangeAgreements);

    // By default we want to display all inactive IEA's. For this reason, we are setting
    // the value for "FilteredExchangeAgreementList" as "inactiveExchangeAgreementList".
    setFilteredExchangeAgreementList(inactiveExchangeAgreementList);
  }, [inactiveExchangeAgreementList]);

  const ieasGridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 70,
  });

  const handleUnderConstructionIEASummaryButtonClick = () => {
    setSelectedFilter(InactiveIEASummaryFilterOption.UnderConstruction);
    setFilteredExchangeAgreementList(underConstructionExchangeAgreementList);
  };

  const handlePendingStartIEASummaryButtonClick = () => {
    setSelectedFilter(
      InactiveIEASummaryFilterOption.PendingExchangeAgreementStart
    );
    setFilteredExchangeAgreementList(pendingStartExchangeAgreementList);
  };

  const handleTotalIEASummaryButtonClick = () => {
    setSelectedFilter(InactiveIEASummaryFilterOption.Total);
    setFilteredExchangeAgreementList(inactiveExchangeAgreementList);
  };

  return (
    <>
      <div className="mb-4" id={headerTitleDivId}>
        <PageHeaderTitle title="New IEAs" />
      </div>
      <div>
        <NewIEAsGrid
          ieas={filteredExchangeAgreementList}
          height={ieasGridHeight}
          gridFilterWidget={
            <InactiveIEAGridSummary
              underConstructionIEAs={
                underConstructionExchangeAgreementList.length
              }
              pendingStartIEAs={pendingStartExchangeAgreementList.length}
              totalIEAs={inactiveExchangeAgreementList.length}
              selectedFilter={selectedFilter}
              onUnderConstructionIEASummaryButtonClick={
                handleUnderConstructionIEASummaryButtonClick
              }
              onPendingStartIEASummaryButtonClick={
                handlePendingStartIEASummaryButtonClick
              }
              onTotalIEASummaryButtonClick={handleTotalIEASummaryButtonClick}
            />
          }
        />
      </div>
    </>
  );
}
