import Circle from "components/common/Circle";
import InformationBar from "components/common/InformationBar";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import {
  PageOption,
  PageOptionItem,
} from "components/common/page-header/PageHeader";
import {
  ActivePageHeaderLabel,
  ExpiredPageHeaderLabel,
  UnderConstructionPageHeaderLabel,
} from "components/common/page-header/PageHeaderLabel";
import { Item } from "devextreme-react/tab-panel";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import ExchangeAgreementHeaderData from "interfaces/response/ExchangeAgreementHeaderData";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ACTIVE_IEA,
  ARCHIVE_IEAS,
  NEW_IEAS,
} from "shared/constants/RoutePathConstants";
import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { DirectionEnum } from "shared/enums/feature/DirectionEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import { getHostSystemConfigurationValue } from "shared/utilities/HostSystemConfigurationUtility";
import { isValidJsonString } from "shared/utilities/JsonUtility";
import { toastSuccess } from "shared/utilities/ToastUtility";
import {
  ModifyExchangeAgreementConfigUpdateAcceptanceStatusAsync,
  generateExchangeAgreementDataTransferEventAsync,
  loadExchangeAgreementDetailsAsync,
  pauseActiveExchangeAgreementAsync,
  resumeActiveExchangeAgreementAsync,
} from "store/actions/ExchangeAgreementActions";
import { resetSelectedExchangeAgreement } from "store/slices/ExchangeAgreementSlice";
import { AppDispatch, RootState } from "store/store";
import IEAHeader from "../IEAHeader";
import IEAInfo from "../active-ieas/IEAInfo";
import EventLogsGrid from "../active-ieas/event-log/EventLogsGrid";
import HostParametersOverride from "../common/HostParametersOverride";
import ConfigurationUpdatePreviewModal from "../configuration-update/ConfigurationUpdatePreviewModal";
import ExchangeAgreementConfigUpdateAcceptanceRequest from "interfaces/request/ExchangeAgreementConfigUpdateAcceptanceRequest";

interface Props {
  ieaStatus: "active" | "new" | "archive";
}

export default function IEAViewer(props: Props) {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const hostParameterGridHeight = 300;
  const [selectedActiveIEAId, setSelectedActiveIEAId] = useState<number>(0);
  const [headerData, setHeaderData] =
    useState<ExchangeAgreementHeaderData | null>(null);
  const tabTitleClassName = "font-poppins font-semibold text-10px leading-15px";
  const [headerMenuItems, setheaderMenuItems] = useState<PageOptionItem[]>([]);
  const [
    isConfigUpdatePreviewModalVisible,
    setIsConfigUpdatePreviewModalVisible,
  ] = useState(false);

  const loggedInUser = useSelector(
    (state: RootState) => state.userData.mySelfResponse
  );

  const selectedExchangeAgreement = useSelector(
    (state: RootState) => state.exchangeAgreementData.selectedExchangeAgreement
  );

  const [isCurrentUserSender, setIsCurrentUserSender] =
    useState<boolean>(false);

  const [senderHostSystem, setSenderHostSystem] = useState<
    HostSystem | undefined
  >(undefined);

  const [senderHostParameters, setSenderHostParameters] =
    useState<SupportedHostSystemConfigurationType>();
  const [receiverHostParameters, setReceiverHostParameters] =
    useState<SupportedHostSystemConfigurationType>();

  const [isCurrentUserOwner, setIsCurrentUserOwner] = useState<boolean>(false);
  const [isCurrentUserPartner, setIsCurrentUserPartner] =
    useState<boolean>(false);

  useEffect(() => {
    if (params.id && !isNaN(Number(params.id))) {
      setSelectedActiveIEAId(Number(params.id));
    }
  }, []);

  const handlePauseClick = (exchangeAgreementId: number) => {
    displayLoadingPanel();
    dispatch(pauseActiveExchangeAgreementAsync(exchangeAgreementId))
      .unwrap()
      .then(() => {
        toastSuccess("Exchange agreement paused.");
      })
      .finally(hideLoadingPanel);
  };

  const handleResumeClick = (exchangeAgreementId: number) => {
    displayLoadingPanel();
    dispatch(resumeActiveExchangeAgreementAsync(exchangeAgreementId))
      .unwrap()
      .then(() => {
        toastSuccess("Exchange agreement resumed.");
      })
      .finally(hideLoadingPanel);
  };

  const handleGenerateDataTransferEventClick = (
    exchangeAgreementId: number
  ) => {
    displayLoadingPanel();
    dispatch(
      generateExchangeAgreementDataTransferEventAsync(exchangeAgreementId)
    )
      .unwrap()
      .then(() => {
        toastSuccess("Data transfer event generated successfully");
      })
      .finally(hideLoadingPanel);
  };

  useEffect(() => {
    // based on if configs of the IEA are/is updated or not
    const menuItems: PageOptionItem[] = [];
    let menuId = 0;
    if (selectedExchangeAgreement) {
      menuItems.push({
        id: ++menuId,
        text: "Edit IEA",
        onSelectionChange: onEditActiveIeaClick,
      });

      if (isCurrentUserOwner && selectedExchangeAgreement.updateRequired) {
        menuItems.push({
          id: ++menuId,
          text: "View configuration updates",
          onSelectionChange: onViewConfigUpdateClick,
        });
      }

      if (props.ieaStatus === "active") {
        if (isCurrentUserSender) {
          const frequencyButtonText = selectedExchangeAgreement.isPaused
            ? "Resume transfers"
            : "Pause transfers";
          menuItems.push({
            id: ++menuId,
            text: frequencyButtonText,
            onSelectionChange: () => {
              if (selectedExchangeAgreement.isPaused) {
                handleResumeClick(selectedExchangeAgreement.id);
              } else {
                handlePauseClick(selectedExchangeAgreement.id);
              }
            },
          });

          menuItems.push({
            id: ++menuId,
            text: "Generate transfer event now",
            onSelectionChange: () => {
              handleGenerateDataTransferEventClick(
                selectedExchangeAgreement.id
              );
            },
          });
        }
      }

      setheaderMenuItems(menuItems);
    }
  }, [selectedExchangeAgreement, isCurrentUserSender, props.ieaStatus, isCurrentUserOwner]);

  useEffect(() => {
    if (selectedActiveIEAId !== 0) {
      displayLoadingPanel();
      dispatch(loadExchangeAgreementDetailsAsync(selectedActiveIEAId)).finally(
        hideLoadingPanel
      );
    }

    return () => {
      // Need to reset while unmounting because the same store state is used by InactiveIEA editors.
      dispatch(resetSelectedExchangeAgreement());
    };
  }, [dispatch, selectedActiveIEAId]);

  const onIEAUpdateCrossIconClick = () => {
    if (selectedExchangeAgreement) {
      let configUpdateAcceptanceStatusRequest: ExchangeAgreementConfigUpdateAcceptanceRequest =
        {
          isUpdatePerformedByOwner: false,
        };

      dispatch(
        ModifyExchangeAgreementConfigUpdateAcceptanceStatusAsync({
          exchangeAgreementId: selectedExchangeAgreement.id,
          updateRequest: configUpdateAcceptanceStatusRequest,
        })
      );
    }
  };

  useEffect(() => {
    if (loggedInUser && selectedExchangeAgreement) {
      let hasOwnerRole = selectedExchangeAgreement.ownerRepresentatives.some(
        (ownerRep) => ownerRep.id === loggedInUser.id
      );

      let hasPartnerRole =
        selectedExchangeAgreement.partnerRepresentatives.some(
          (partnerRep) => partnerRep.id === loggedInUser.id
        );
      var isSender =
        (hasOwnerRole &&
          selectedExchangeAgreement.direction ===
            DirectionEnum.OwnerToPartner) ||
        (hasPartnerRole &&
          selectedExchangeAgreement.direction === DirectionEnum.PartnerToOwner);

      var isReceiver =
        (hasOwnerRole &&
          selectedExchangeAgreement.direction ===
            DirectionEnum.PartnerToOwner) ||
        (hasPartnerRole &&
          selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner);

      setIsCurrentUserSender(isSender);
      setIsCurrentUserOwner(hasOwnerRole);
      setIsCurrentUserPartner(hasPartnerRole);
    } else {
      setIsCurrentUserOwner(false);
      setIsCurrentUserPartner(false);
    }
  }, [loggedInUser, selectedExchangeAgreement]);

  useEffect(() => {
    const setOwnerAndPartnerHostParameters = (senderHostSystem: number) => {
      if (selectedExchangeAgreement) {
        const receiverHostSystem =
          selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner
            ? selectedExchangeAgreement.partnerConnector?.hostSystem
            : selectedExchangeAgreement.ownerConnector?.hostSystem;

        var senderConfigSettings =
          selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner
            ? selectedExchangeAgreement.ownerConfigSettings
            : selectedExchangeAgreement.partnerConfigSettings;

        var receiverConfigSettings =
          selectedExchangeAgreement.direction === DirectionEnum.PartnerToOwner
            ? selectedExchangeAgreement.ownerConfigSettings
            : selectedExchangeAgreement.partnerConfigSettings;

        const senderHostParameters =
          senderHostSystem && senderConfigSettings
            ? getHostSystemConfigurationValue(
                senderHostSystem,
                ConfigDirection.Sending,
                senderConfigSettings
              ) ?? undefined
            : undefined;

        const receiverHostParameters =
          receiverHostSystem && receiverConfigSettings
            ? getHostSystemConfigurationValue(
                receiverHostSystem,
                ConfigDirection.Receiving,
                receiverConfigSettings
              ) ?? undefined
            : undefined;

        setSenderHostParameters(senderHostParameters);
        setReceiverHostParameters(receiverHostParameters);
      }
    };

    var hostSystem: HostSystem | undefined = undefined;
    if (selectedExchangeAgreement) {
      hostSystem =
        selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner
          ? selectedExchangeAgreement.ownerConnector.hostSystem
          : selectedExchangeAgreement.partnerConnector?.hostSystem;
      setSenderHostSystem(hostSystem);
      setOwnerAndPartnerHostParameters(hostSystem);
    }
  }, [selectedExchangeAgreement]);

  useEffect(() => {
    if (selectedExchangeAgreement) {
      var senderConfigSettings =
        selectedExchangeAgreement.direction === DirectionEnum.OwnerToPartner
          ? selectedExchangeAgreement.ownerConfigSettings
          : selectedExchangeAgreement.partnerConfigSettings;

      const ieaHeaderData: ExchangeAgreementHeaderData = {
        id: selectedExchangeAgreement.id,
        title: selectedExchangeAgreement.title,
        templateTitle: selectedExchangeAgreement.template.title,
        sender: selectedExchangeAgreement.sender,
        receiver: selectedExchangeAgreement.receiver,
        labels: selectedExchangeAgreement.labels,
        senderConfigSettings: isValidJsonString(senderConfigSettings)
          ? senderConfigSettings
          : "",
      };

      setHeaderData(ieaHeaderData);
    }
  }, [selectedExchangeAgreement, isCurrentUserSender]);

  const getEventLogTitle = () => {
    if (
      selectedExchangeAgreement &&
      selectedExchangeAgreement.eventLogs?.length > 0
    ) {
      let circleColor = PromineoColorVariant.IlapGreen;
      let hasFailedEventLogs = false;
      let hasOverDueEventLogs = false;

      for (
        let index = 0;
        index < selectedExchangeAgreement.eventLogs.length;
        index++
      ) {
        let selectedEventLog = selectedExchangeAgreement.eventLogs[index];

        if (
          selectedEventLog.uploadStatus === DataTransferStatus.Fail ||
          selectedEventLog.downloadStatus === DataTransferStatus.Fail
        ) {
          hasFailedEventLogs = true;
        } else if (
          selectedEventLog.uploadStatus === DataTransferStatus.Overdue ||
          selectedEventLog.downloadStatus === DataTransferStatus.Overdue
        ) {
          hasOverDueEventLogs = true;
        }
      }

      if (hasFailedEventLogs) {
        circleColor = PromineoColorVariant.Red;
      } else if (hasOverDueEventLogs) {
        circleColor = PromineoColorVariant.Orange;
      }

      return (
        <div className={`flex space-x-2 ${tabTitleClassName}`}>
          <div>Event log</div>
          <Circle color={circleColor} />
        </div>
      );
    }

    return <div className={tabTitleClassName}>Event log</div>;
  };

  const headerDivId: string = "active-iea-viewer-header";
  const excludedContainerIds: string[] = [headerDivId];

  const eventLogGridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 120,
  });

  const onViewConfigUpdateClick = () => {
    setIsConfigUpdatePreviewModalVisible(true);
  };

  const onEditActiveIeaClick = () => {
    if (props.ieaStatus === "active") {
      navigate(`${ACTIVE_IEA}/${params.id}/edit`);
    } else if (props.ieaStatus === "new") {
      navigate(`${NEW_IEAS}/${params.id}/edit`);
    } else if (props.ieaStatus === "archive") {
      navigate(`${ARCHIVE_IEAS}/${params.id}/edit`);
    }
  };

  const pageOptions = useMemo<PageOption>(() => {
    return {
      items: headerMenuItems,
      isVisible: true,
      dropdownPopupWidth:
        selectedExchangeAgreement?.updateRequired ||
        props.ieaStatus === "active"
          ? 220
          : 130,
    };
  }, [headerMenuItems, isCurrentUserSender]);

  if (!selectedExchangeAgreement) {
    return <></>;
  }

  return (
    <>
      <div id={headerDivId}>
        <IEAHeader
          senderHostSystem={senderHostSystem}
          isOwner={isCurrentUserOwner}
          isPartner={isCurrentUserPartner}
          headerData={headerData}
          ieaFrequencyType={
            selectedExchangeAgreement
              ? selectedExchangeAgreement.frequencyType
              : FrequencyType.CronExpression
          }
          isActivityFilterEditable={false}
          onAddNewLabel={() => {}}
          onDeleteLabel={() => {}}
          onSaveActivityFilterClick={() => {}}
          pageOptions={pageOptions}
          titleWidget={
            selectedExchangeAgreement.isActive ? (
              <ActivePageHeaderLabel />
            ) : selectedExchangeAgreement.isExpired ? (
              <ExpiredPageHeaderLabel />
            ) : (
              <UnderConstructionPageHeaderLabel />
            )
          } 
        />
        <div>
          {selectedExchangeAgreement?.updateRequired && isCurrentUserOwner && (
            <InformationBar
              text={"This IEA has configuration updates."}
              action={{
                text: "View updates",
                onClick: onViewConfigUpdateClick,
              }}
            />
          )}

          {selectedExchangeAgreement?.updateRequired && isCurrentUserPartner && !isCurrentUserOwner && (
            <InformationBar
              text={"This IEA have some pending updates, awaiting changes to be accepted by the owner."}
            />
          )}
        </div>

        <div>
          {isCurrentUserPartner && selectedExchangeAgreement?.isUpdatePerformedByOwner && (
            <InformationBar
              text={"This IEA has been updated by the owner."}
              crossButtonAction={{
                onClick: onIEAUpdateCrossIconClick
              }}
            />
          )}
        </div>

      </div>
      <PromineoTabPanel defaultSelectedIndex={location.state?.selectedTabIndex}>
        <Item
          tabRender={() => {
            return <div className={tabTitleClassName}>IEA info</div>;
          }}
        >
          {selectedExchangeAgreement && (
            <div className="p-4">
              <IEAInfo
                exchangeAgreement={selectedExchangeAgreement}
                isOwner={isCurrentUserOwner}
                isPartner={isCurrentUserPartner}
              />
            </div>
          )}
        </Item>
        <Item tabRender={getEventLogTitle}>
          {selectedExchangeAgreement && (
            <div className="p-4">
              <EventLogsGrid
                height={eventLogGridHeight}
                exchangeAgreementId={selectedExchangeAgreement.id}
                exchangeAgreementTitle={selectedExchangeAgreement.title}
                eventLogs={selectedExchangeAgreement.eventLogs}
              />
            </div>
          )}
        </Item>
        <Item
          tabRender={() => {
            return (
              <div className={tabTitleClassName}>Host system parameters</div>
            );
          }}
        >
          {selectedExchangeAgreement && (
            <div className="p-4">
              <HostParametersOverride
                exportConfig={senderHostParameters}
                importConfig={receiverHostParameters}
                senderConnectorId={
                  selectedExchangeAgreement.direction ===
                  DirectionEnum.OwnerToPartner
                    ? selectedExchangeAgreement.ownerConnector.id
                    : selectedExchangeAgreement.partnerConnector?.id
                }
                gridHeight={hostParameterGridHeight}
                isEditable={false}
                senderHostSystem={
                  selectedExchangeAgreement.direction ===
                  DirectionEnum.OwnerToPartner
                    ? selectedExchangeAgreement.ownerConnector?.hostSystem
                    : selectedExchangeAgreement.partnerConnector?.hostSystem
                }
                receiverHostSystem={
                  selectedExchangeAgreement.direction ===
                  DirectionEnum.OwnerToPartner
                    ? selectedExchangeAgreement.partnerConnector?.hostSystem
                    : selectedExchangeAgreement.ownerConnector?.hostSystem
                }
                senderHostSystemName={
                  selectedExchangeAgreement.direction ===
                  DirectionEnum.OwnerToPartner
                    ? selectedExchangeAgreement.ownerConnector?.hostSystemName
                    : selectedExchangeAgreement.partnerConnector?.hostSystemName
                }
                receiverHostSystemName={
                  selectedExchangeAgreement.direction ===
                  DirectionEnum.OwnerToPartner
                    ? selectedExchangeAgreement.partnerConnector?.hostSystemName
                    : selectedExchangeAgreement.ownerConnector?.hostSystemName
                }
                receiverCodeSet={
                  selectedExchangeAgreement.direction ===
                  DirectionEnum.PartnerToOwner
                    ? selectedExchangeAgreement.ownerSchedule?.userFieldSetId
                    : selectedExchangeAgreement.partnerSchedule?.userFieldSetId
                }
                senderCodeSet={
                  selectedExchangeAgreement.direction ===
                  DirectionEnum.OwnerToPartner
                    ? selectedExchangeAgreement.ownerSchedule?.userFieldSetId
                    : selectedExchangeAgreement.partnerSchedule?.userFieldSetId
                }
              />
            </div>
          )}
        </Item>
      </PromineoTabPanel>
      {isConfigUpdatePreviewModalVisible && selectedExchangeAgreement && (
        <ConfigurationUpdatePreviewModal
          onCancel={() => {
            setIsConfigUpdatePreviewModalVisible(false);
          }}
          exchangeAgreement={selectedExchangeAgreement}
        />
      )}
    </>
  );
}
