import LabelWithContent from "components/common/LabelWithContent";
import PromineoDatePicker from "components/common/controls/PromineoDatePicker";
import { IDateBoxOptions } from "devextreme-react/date-box";

export default function DateEditor(
  props: {
    title: string;
    onValueChange: (value: string) => void;
  } & IDateBoxOptions
) {
  const { title, onValueChange, ...rest } = props;
  return (
    <LabelWithContent
      label={title}
      addAsterisksymbol={true}
      content={
        <PromineoDatePicker
          placeholder="Select"
          onValueChanged={(data: any) => {
            onValueChange(data.value);
          }}
          {...rest}
        />
      }
    />
  );
}
