import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import { Popup } from "devextreme-react";

export interface TenantRequestDialogProps {
  title: string;
  contentText: string;
  confirmButtonText: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  hideCancelButton?: boolean;
}

export default function TenantRequestDialog(props: TenantRequestDialogProps) {
  return (
    <Popup
      visible={true}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={false}
      width={600}
      height={296}
      showTitle={false}
      shading={false}
    >
      <div className="px-8 pb-6 pt-8 flex flex-col justify-between h-full">
        <div className="flex flex-grow h-full justify-center items-center">
          <div className="flex flex-col">
            <div className="text-center font-medium font-poppins text-lg">
              {props.title}
            </div>
            <div className="text-sm mt-6 text-center font-inter">
              {props.contentText}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex justify-between">
            <div>
              {!props.hideCancelButton && (
                <PromineoButton
                  text={"Cancel"}
                  variant={PromineoButtonType.Secondary}
                  onClick={props.onCancel}
                />
              )}
            </div>
            <PromineoButton
              text={props.confirmButtonText}
              variant={PromineoButtonType.Primary}
              onClick={props.onConfirm}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
}
