import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PageHeader from "components/common/page-header/PageHeader";
import EditIcon from "components/icons/EditIcon";
import { useEffect, useState } from "react";
import {
  getTenantForTenantAdminAsync,
  updateAdminTenantAsync,
} from "store/actions/TenantAdminActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import TenantContactViewEdit from "./TenantContactViewEdit";
import TenantIdentityProviderViewer from "./TenantIdentityProviderViewer";
import TenantInfoViewEdit from "./TenantInfoViewEdit";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import TenantAdminSaveButton from "./TenantAdminSaveButton";
import TenantAdminCancelButton from "./TenantAdminCancelButton";
import TenantContactInformationBase from "interfaces/common/TenantContactInformationBase";
import OwnTenantResponse from "interfaces/response/OwnTenantResponse";
import OwnTenantWriteRequest from "interfaces/request/OwnTenantWriteRequest";
import { deepCopyObject } from "shared/utilities/CommonUtility";
import StorageViewOrEdit from "./StorageViewOrEdit";

export default function TenantViewEdit() {
  const dispatch = useAppDispatch();
  const maxNumberOfContact = 4;
  const tenantDetail = useAppSelector((store) => store.tenantAdminData.tenant);
  const [tenantDetailToViewOrModify, setTenantDetailToViewOrModify] =
    useState(tenantDetail);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(getTenantForTenantAdminAsync()).then(hideLoadingPanel);
  }, []);

  const sortByMainContact = (
    firstContactInformation: TenantContactInformationBase,
    secondContactInformation: TenantContactInformationBase
  ) => {
    if (
      firstContactInformation.isMainContact &&
      !secondContactInformation.isMainContact
    ) {
      return -1; // firstContactInformation comes before secondContactInformation
    } else if (
      !firstContactInformation.isMainContact &&
      secondContactInformation.isMainContact
    ) {
      return 1; // secondContactInformation comes before firstContactInformation
    } else {
      return 0; // no change in order
    }
  };

  const initializeTenantInfoForViewOrEdit = () => {
    if (tenantDetail && tenantDetail.contactInformation) {
      let contactInformation: TenantContactInformationBase[] = [];

      if (tenantDetail.contactInformation.length) {
        contactInformation = deepCopyObject(tenantDetail.contactInformation);
      }

      for (
        let index = contactInformation.length;
        index < maxNumberOfContact;
        index++
      ) {
        contactInformation.push({
          id: 0,
          name: "",
          email: "",
          phoneNumber: "",
          tenantId: tenantDetail.id,
          isMainContact: false,
        });
      }

      let sortedContactInformation = contactInformation.sort(sortByMainContact);

      if (!sortedContactInformation[0].isMainContact) {
        // After sorting if there is no primary contact information, then we are forcefully making the first contact
        // information as the primary contact.
        sortedContactInformation[0].isMainContact = true;
      }

      setTenantDetailToViewOrModify({
        ...tenantDetail,
        contactInformation: sortedContactInformation,
      });
    }
  };

  useEffect(() => {
    initializeTenantInfoForViewOrEdit();
  }, [tenantDetail]);

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleChange = (tenantInfo: OwnTenantResponse) => {
    setTenantDetailToViewOrModify(tenantInfo);
  };

  const getTenantValidationMessage = () => {
    let messages: string[] = [];
    if (!tenantDetailToViewOrModify?.name) {
      messages.push("Tenant name is required.");
    }

    if (!tenantDetailToViewOrModify?.companyRegistrationNumber) {
      messages.push("Company registration number is required.");
    }

    const contacts = tenantDetailToViewOrModify?.contactInformation;
    if (!contacts || !contacts.length) {
      messages.push("Main contact is required.");
    } else {
      if (!contacts[0].name || !contacts[0].phoneNumber || !contacts[0].email) {
        messages.push("All 3 fields are required for main contact.");
      }

      for (
        let ind = 1;
        ind < maxNumberOfContact && ind < contacts.length;
        ind++
      ) {
        let isInvalid = false;
        const contact = contacts[ind];

        if (contact.name && !(contact.email && contact.phoneNumber)) {
          isInvalid = true;
        } else if (contact.email && !(contact.name && contact.phoneNumber)) {
          isInvalid = true;
        } else if (contact.phoneNumber && !(contact.name && contact.email)) {
          isInvalid = true;
        }

        if (isInvalid) {
          messages.push(
            `All 3 fields are required for additional contact ${ind}`
          );
        }
      }
    }

    return messages;
  };

  const getTenantAdminUpdateRequest = (): OwnTenantWriteRequest => {
    let updateRequest: OwnTenantWriteRequest = deepCopyObject(
      tenantDetailToViewOrModify
    );
    let contactInformation: TenantContactInformationBase[] = [];
    // We have already validated the tenantAdminUpdateRquest.
    // Upto this point, we know that, there is atleast 1 contact information exists for the tenantAdminUpdateRquest.

    for (
      let index = 0;
      index < updateRequest.contactInformation.length;
      index++
    ) {
      let updatedContactInformation = updateRequest.contactInformation[index];

      if (
        updatedContactInformation.name &&
        updatedContactInformation.phoneNumber &&
        updatedContactInformation.email
      ) {
        contactInformation.push(updatedContactInformation);
      }
    }

    updateRequest.contactInformation = contactInformation;

    return updateRequest;
  };

  const handleSaveChanges = () => {
    const validationMessages = getTenantValidationMessage();

    if (!validationMessages.length) {
      const updateRequest = getTenantAdminUpdateRequest();

      displayLoadingPanel();
      dispatch(
        updateAdminTenantAsync({
          tenantId: updateRequest.id,
          tenantUpdateRequest: updateRequest,
        })
      )
        .unwrap()
        .then(() => {
          setIsEdit(false);
          toastSuccess("Tenant information updated successfully.");
        })
        .finally(hideLoadingPanel);
    } else {
      toastError(validationMessages.join("\n"));
    }
  };

  const handleCancelClick = () => {
    initializeTenantInfoForViewOrEdit();
    setIsEdit(false);
  };

  const Border = () => {
    return <div className="border-b border-lightGray"></div>;
  };

  if (!tenantDetailToViewOrModify) {
    return null;
  }

  return (
    <div>
      <div className="flex">
        <PageHeader
          title={tenantDetailToViewOrModify.name}
          isEdit={false}
          titleWidget={
            isEdit ? (
              <></>
            ) : (
              <div className="flex gap-2 cursor-pointer">
                <div onClick={handleEditClick} className="hover:cursor-pointer">
                  <EditIcon />
                </div>
              </div>
            )
          }
        />
      </div>
      <div className="flex justify-center">
        <div className="space-y-4 w-[912px]">
          <TenantInfoViewEdit
            tenantDetail={tenantDetailToViewOrModify}
            isEdit={isEdit}
            onChange={handleChange}
          />
          <Border />
          <TenantContactViewEdit
            tenantDetail={tenantDetailToViewOrModify}
            isEdit={isEdit}
            onChange={handleChange}
          />
          <Border />
          <TenantIdentityProviderViewer
            tenantDetail={tenantDetailToViewOrModify}
          />
          {/* <Border /> */}
          <StorageViewOrEdit
            isEdit={isEdit}
            onChange={handleChange}
            tenantDetail={tenantDetailToViewOrModify}
          />
        </div>
      </div>
      {isEdit ? (
        <div className="flex justify-between py-4 mt-16">
          <TenantAdminCancelButton onCancel={handleCancelClick} />
          <TenantAdminSaveButton onSave={handleSaveChanges} />
        </div>
      ) : null}
    </div>
  );
}
