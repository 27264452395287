import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTemplateAsync,
  getOwnTemplatesAsync,
  getReceiviedTemplatesAsync,
  getTemplateByIdAsync,
  updateTemplateAsync,
  getTemplateSharedDataListAsync,
  shareTemplateAsync,
  getSimplifiedTemplatesAsync,
  copyTemplateAsync,
  deleteTemplateByIdAsync,
} from "apis/TemplateApi";
import TemplateRequest from "interfaces/request/TemplateRequest";
import TemplateShareRequest from "interfaces/request/TemplateShareRequest";

export const loadTemplateByIdAsync = createAsyncThunk(
  "templates/loadTemplateById",
  async (templateId: number) => {
    return await getTemplateByIdAsync(templateId);
  }
);

export const modifyTemplateAsync = createAsyncThunk<
  any,
  { templateId: number; template: TemplateRequest }
>("templates/modifyTemplate", async (request) => {
  return await updateTemplateAsync(request.templateId, request.template);
});

export const addNewTemplateAsync = createAsyncThunk(
  "templates/addNewTemplate",
  async (template: TemplateRequest) => {
    return await createTemplateAsync(template);
  }
);

export const loadOwnTemplatesAsync = createAsyncThunk(
  "templates/loadOwnTemplates",
  async () => {
    return await getOwnTemplatesAsync();
  }
);

export const loadReceivedTemplatesAsync = createAsyncThunk(
  "templates/loadReceivedTemplates",
  async () => {
    return await getReceiviedTemplatesAsync();
  }
);

export const loadTemplateSharedDataListAsync = createAsyncThunk(
  "templates/getTemplateShareData",
  async (templateId: number) => {
    return await getTemplateSharedDataListAsync(templateId);
  }
);

export const shareTemplateWithTenantAsync = createAsyncThunk(
  "templates/shareTemplate",
  async (shareTemplate: TemplateShareRequest) => {
    return await shareTemplateAsync(shareTemplate);
  }
);

export const loadSimplifiedTemplatesAsync = createAsyncThunk(
  "templates/loadSimplifiedTemplates",
  async () => {
    return await getSimplifiedTemplatesAsync();
  }
);

export const copyTemplateByIdAsync = createAsyncThunk(
  "templates/copyTemplateById",
  async (templateId: number) => {
    return await copyTemplateAsync(templateId);
  }
);

export const removeTemplateByIdAsync = createAsyncThunk(
  "templates/removeTemplateById",
  async (templateId: number, { rejectWithValue }) => {
    try {
      return await deleteTemplateByIdAsync(templateId);
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);
