import NewIEAWizardInformation from "./NewIEAWizardInformation";
import IEACoreInfo, { IEACoreInfoProps } from "../IEACoreInfo";

export interface StepOneNewIeaProps {
  coreInfoProps: IEACoreInfoProps;
}

export default function StepOneNewIEA(props: StepOneNewIeaProps) {
  return (
    <div>
      <NewIEAWizardInformation
        text={`Give your IEA a descriptive title and define if you want to send or
            receive data, what data you want to exchange and with whom. 
            All fields are required (*). Click next step to continue.`}
      ></NewIEAWizardInformation>
      <div className="mt-3">
        <IEACoreInfo {...props.coreInfoProps} />
      </div>
    </div>
  );
}
