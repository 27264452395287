import { CheckBox, ICheckBoxOptions } from "devextreme-react/check-box";
import "./styles/PromineoCheckbox.css";

export default function PromineoCheckbox(props: ICheckBoxOptions) {
  const { text, className, ...rest } = props;

  const getClassNames = () => {
    return `promineo-checkbox ${className ?? ""}`;
  };

  return (
    <div className="flex items-center">
      <CheckBox
        className={getClassNames()}
        text={text}
        iconSize={"14px"}
        {...rest}
      />
    </div>
  );
}
