import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import { useState } from "react";
import FieldValueModal from "components/common/FieldValueModal";
import FieldMappingWithSummaryResponse from "interfaces/response/FieldMappingWithSummaryResponse";
import FieldDetailsWithSummaryResponse from "interfaces/response/FieldDetailsWithSummaryResponse";

interface Props {
  data: {
    data: FieldMappingWithSummaryResponse | FieldDetailsWithSummaryResponse;
  };
}

export default function FieldUpdateCellTemplate(props: Props) {
  const { updateSummary, allowBlank, name, values } = props.data.data;
  const [displayValueMapDialog, setDisplayValueMapDialog] = useState(false);
  const updateSummaryMessage = updateSummary ? updateSummary.summaryText : "";

  const showValueMapDialog = () => {
    setDisplayValueMapDialog(true);
  };

  const hideValueMapDialog = () => {
    setDisplayValueMapDialog(false);
  };

  return (
    <>
      <div className="flex jusitify-between items-center gap-x-2">
        <div className="w-full truncate" title={updateSummaryMessage}>
          {updateSummaryMessage}
        </div>
        {updateSummary?.isValueModified && (
          <div className="w-auto">
            <PromineoButton
              text="View"
              variant={PromineoButtonType.Secondary}
              onClick={showValueMapDialog}
            />
          </div>
        )}
      </div>
      {displayValueMapDialog && (
        <FieldValueModal
          fieldName={name}
          allowBlank={allowBlank}
          values={values}
          onClose={hideValueMapDialog}
        />
      )}
    </>
  );
}
