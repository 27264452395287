import LabelWithContent from "components/common/LabelWithContent";
import HostSystemConfigurationTextEditor from "features/setup/configs/host-system/HostSystemConfigurationTextEditor";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";

interface Props extends BaseHostSystemComponentProps {
  value: string;
}

export default function CalendarPrefixField(props: Props) {
  const { mode, fieldName, value, handleConfigurationValueChanged } =
    props;

  return (
    <LabelWithContent
      label="Calendar Prefix"
      content={
        <HostSystemConfigurationTextEditor
          mode={mode}
          fieldName={fieldName}
          textBoxValue={value}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      }
    />
  );
}
