import Circle from "components/common/Circle";
import InactiveExchangeAgreementListingResponse from "interfaces/response/NewExchangeAgreementListingResponse";
import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import { NewExchangeAgreementStatus } from "shared/enums/feature/NewExchangeAgreementStatus";

export default function InactiveIEAStatusCellComponent(e: {
  data: { data: InactiveExchangeAgreementListingResponse };
}) {
  return (
    <div>
      {e.data.data.status ===
        NewExchangeAgreementStatus.BeingCreated && (
        <div className="flex space-x-2">
          <div>
            <Circle color={PromineoColorVariant.Purple} />
          </div>
          <div>{e.data.data.statusText}</div>
        </div>
      )}
      {e.data.data.status ===
        NewExchangeAgreementStatus.PendingExchangeAgreementStart && (
        <div className="flex space-x-2">
          <div>
            <Circle color={PromineoColorVariant.LightOrange} />
          </div>
          <div>{e.data.data.statusText}</div>
        </div>
      )}
    </div>
  );
}
