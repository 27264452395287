import { useState } from "react";
import ScheduleCodeSetDialog from "../config-view/config-schedule/ScheduleCodeSetDialog";

interface Props {
  userFieldSetId?: number;
  connectorId?: number;
}

export default function ConfigCodeSetTemplate(props: Props) {
  const [isHostFieldDialogVisible, setIsHostFieldDialogVisible] =
    useState(false);

  const handleCodeSetClick = () => {
    setIsHostFieldDialogVisible(true);
  };

  const handleHostFieldPopupHiding = () => {
    setIsHostFieldDialogVisible(false);
  };

  return (
    <>
      <div className="underline cursor-pointer" onClick={handleCodeSetClick}>
        {props.userFieldSetId}
      </div>
      {isHostFieldDialogVisible && (
        <ScheduleCodeSetDialog
          userFieldSetId={props.userFieldSetId}
          connectorId={props.connectorId}
          onHiding={handleHostFieldPopupHiding}
        />
      )}
    </>
  );
}
