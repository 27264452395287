import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import SAPConnectorAuthenticationModeField from "./SAPConnectorAuthenticationModeField";
import { SapConnectorAuthenticationMode } from "shared/enums/feature/sap-host-system/SapConnectorAuthenticationMode";
import SAPAzureSamlOauthTokenAuthentication from "./SAPAzureSamlOauthTokenAuthentication";
import SAPBasicAuthentication from "./SAPBasicAuthentication";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";

interface Props {
  configuration: SAPConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
  executionComponent: ExecutionComponent;
}

export default function SAPAuthentication(props: Props) {
  return (
    <div>
      <ConnectorLabelWithContent
        mode={props.mode}
        addAsterisksymbol={true}
        label="Authentication type"
        content={
          <SAPConnectorAuthenticationModeField
            mode={props.mode}
            executionComponent={props.executionComponent}
            configuration={props.configuration}
            validateOnFocusOut={props.validateOnFocusOut}
            selectedAuthenticationMode={props.configuration.authenticationMode}
            onValueChange={props.handleAuthenticationModeValueChanged}
          />
        }
      />

      {props.configuration.authenticationMode ===
      SapConnectorAuthenticationMode.AzureSamlOauthToken ? (
        <SAPAzureSamlOauthTokenAuthentication
          configuration={props.configuration}
          mode={props.mode}
          handleAuthenticationModeValueChanged={
            props.handleAuthenticationModeValueChanged
          }
          handleConfigurationValueChanged={
            props.handleConfigurationValueChanged
          }
          validateOnFocusOut={props.validateOnFocusOut}
        />
      ) : null}

      {props.configuration.authenticationMode ===
      SapConnectorAuthenticationMode.Basic ? (
        <SAPBasicAuthentication
          configuration={props.configuration}
          handleConfigurationValueChanged={
            props.handleConfigurationValueChanged
          }
          mode={props.mode}
          validateOnFocusOut={props.validateOnFocusOut}
        />
      ) : null}
    </div>
  );
}
