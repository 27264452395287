import LabelWithContent from "components/common/LabelWithContent";
import IlapTermHeaderData from "interfaces/component-data/IlapTermHeaderData";
import SystemAdminContentControlDropdown from "../SystemAdminContentControlDropdown";
import LabelWithAsteriskSymbol from "components/common/LabelWithAsteriskSymbol";

interface Props {
  isEdit?: boolean;
  isNew?: boolean;
  headerData: IlapTermHeaderData;
  onChange: (value: number) => void;
}
export default function ContentControlLabel(props: Props) {
  const { isEdit, isNew, headerData, onChange } = props;
  return isEdit ? (
    <LabelWithContent
      label="Content control: "
      isInline={true}
      content={
        <SystemAdminContentControlDropdown
          width={110}
          contentControl={headerData?.contentControl}
          onValueChange={onChange}
          isDisabled={false}
        />
      }
    />
  ) : (
    <LabelWithAsteriskSymbol label={`Content control: ${headerData?.contentControlText}`} />
  );
}
