import ContentControlDropdown from "features/common/ContentControlDropdown";
import { FieldContentControlLevel } from "shared/enums/feature/FieldContentControlLevel";
import { useAppSelector } from "store/hooks";

interface Props {
  contentControl?: FieldContentControlLevel;
  isDisabled?: boolean;
  displayConfirmation?: boolean;
  confirmationMessage?: string;
  onValueChange?: (value: number) => void;
  width?: number;
}

export default function SystemAdminContentControlDropdown(props: Props) {
  // field control dropdown options are loaded in parent component.
  const contentControls = useAppSelector((store) => store.dropdownData.fieldContentControlLevels);

  return (
    <>
      <ContentControlDropdown
        width={props.width}
        isDisabled={props.isDisabled}
        contentControl={props.contentControl}
        datasource={contentControls}
        onValueChange={props.onValueChange}
        displayConfirmation={props.displayConfirmation}
        confirmationMessage={props.confirmationMessage}
      />
    </>
  );
}
