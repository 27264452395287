import { useState } from "react";
import FavoriteFillIcon from "components/icons/FavoriteFillIcon";
import FavoriteStrokeIcon from "components/icons/FavoriteStrokeIcon";

interface Props {
  value?: boolean;
  onValueChange?: (changedValue: boolean) => void;
}

export default function Favorite(props: Props) {
  const [isFavorite, setIsFavorite] = useState(props.value || false);

  const handleClick = () => {
    const changedValue = !isFavorite;
    props.onValueChange?.(changedValue);
    setIsFavorite(changedValue);
  };

  return (
    <div onClick={handleClick} className="cursor-pointer inline-block">
      {isFavorite === true ? <FavoriteFillIcon /> : <FavoriteStrokeIcon />}
    </div>
  );
}
