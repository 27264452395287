import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadAutonomousComponentServiceTokensAsync } from "store/actions/AutonomousComponentActions";
import ServiceTokenEditor from "./ServiceTokenEditor";
import ServiceTokenGrid from "./ServiceTokenGrid";
import { useParams } from "react-router-dom";
import { resetAutonomousComponentServiceTokens } from "store/slices/AutonomousComponentSlice";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import ServiceTokenStatusChanger from "./ServiceTokenStatusChanger";
import ServiceTokenDeleteConfirmationDialog from "./ServiceTokenDeleteConfirmationDialog";
import { ClientTypeEnum } from "shared/enums/ClientTypeEnum";
import { loadIlapAnalyticsServiceTokensAsync } from "store/actions/IlapAnalyticsActions";
import { resetIlapAnalyticsServiceTokens } from "store/slices/IlapAnalyticsSlice";

interface Props {
  clientType: ClientTypeEnum
}

export default function ServiceTokens(props: Props) {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [clientId, setClientId] = useState<
    undefined | number
  >();

  const [serviceTokenToChangeStatus, setServiceTokenToChangeStatus] = useState<
    undefined | ServiceTokenResponse
  >();
  const [serviceTokenToDelete, setServiceTokenToDelete] = useState<
    undefined | ServiceTokenResponse
  >();
  const [isTokenEditorVisible, setIsTokenEditorVisible] = useState(false);

  useEffect(() => {
    if (params.clientId) {
      let clientId = Number(params.clientId);
      if (!isNaN(clientId)) {
        setClientId(clientId);
        displayLoadingPanel();

        const promise = props.clientType === ClientTypeEnum.AutonomousComponent
          ? dispatch(loadAutonomousComponentServiceTokensAsync(clientId))
          : dispatch(loadIlapAnalyticsServiceTokensAsync(clientId))

        promise
        .finally(hideLoadingPanel);
      }
    }

    return () => {
      if (props.clientType === ClientTypeEnum.AutonomousComponent){
        dispatch(resetAutonomousComponentServiceTokens())
      }
      else{
        dispatch(resetIlapAnalyticsServiceTokens())
      }
    };
  }, []);

  const serviceTokenComponentHeader = "service-token-component-header";
  const excludedContainerIds = [serviceTokenComponentHeader];
  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  const serviceTokens = useAppSelector((store) => props.clientType === ClientTypeEnum.AutonomousComponent 
      ? store.autonomousComponentData.serviceTokens
      : store.ilapAnalyticsData.serviceTokens);

  const handleAddNewClick = useCallback(() => {
    setIsTokenEditorVisible(true);
  }, []);

  const handleDeleteClick = useCallback(
    (serviceToken: ServiceTokenResponse) => {
      setServiceTokenToDelete(serviceToken);
    },
    []
  );

  const handleActiveStatusChange = useCallback(
    (serviceToken: ServiceTokenResponse) => {
      setServiceTokenToChangeStatus(serviceToken);
    },
    []
  );

  const handleTokenEditorPopupClose = () => {
    setIsTokenEditorVisible(false);
  };

  const handleServiceTokenStatusChangeConfirmationDialogClose = () => {
    setServiceTokenToChangeStatus(undefined);
  };

  const handleDeleteServiceTokenConfirmationDialogClose = () => {
    setServiceTokenToDelete(undefined);
  };

  const getPageTitle = useMemo(() => {
    return props.clientType === ClientTypeEnum.AutonomousComponent
      ? "Service Tokens - Autonomous Component"
      : "Service Token - ILAP Analytics"
  }, [props.clientType])

  return (
    <div>
      <div className="flex mb-4" id={serviceTokenComponentHeader}>
        <PageHeaderTitle title={getPageTitle} />
      </div>
      <ServiceTokenGrid
        height={gridHeight}
        serviceTokens={serviceTokens}
        onAddNewClick={handleAddNewClick}
        onDeleteClick={handleDeleteClick}
        onActiveStatusChangeClick={handleActiveStatusChange}
      />
      {isTokenEditorVisible && clientId && (
        <ServiceTokenEditor
          onCancel={handleTokenEditorPopupClose}
          clientId={clientId}
          clientType={props.clientType}
        />
      )}

      <ServiceTokenStatusChanger
        onClose={handleServiceTokenStatusChangeConfirmationDialogClose}
        serviceToken={serviceTokenToChangeStatus}
        clientType={props.clientType}
      />
      <ServiceTokenDeleteConfirmationDialog
        serviceToken={serviceTokenToDelete}
        onClose={handleDeleteServiceTokenConfirmationDialogClose}
        clientType={props.clientType}
      />
    </div>
  );
}
