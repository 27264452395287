import { useEffect, useState } from "react";
import PromineoModal from "components/modal/PromineoModal";
import SharedTemplateDialogHeader from "./ShareTemplateDialogHeader";
import SharedWithTenantsViewGrid from "./ShareWithTenantsViewGrid";
import SharedTemplateDialogFooter from "./ShareTemplateDialogFooter";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import {
  loadTemplateSharedDataListAsync,
  shareTemplateWithTenantAsync,
} from "store/actions/TemplateActions";
import { loadSimplifiedTenantsAsync } from "store/actions/TenantActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import TemplateShareResponse from "interfaces/response/TemplateShareResponse";
import TenantSimplifiedResponse from "interfaces/response/TenantSimplifiedResponse";
import { toastSuccess, toastError } from "shared/utilities/ToastUtility";
import TemplateShareRequest from "interfaces/request/TemplateShareRequest";

interface Props {
  templateToShare: { templateId: number; templateTitle: string };
  onCancel: () => void;
}

export default function ShareTemplateWithTenantDialog(props: Props) {
  const dispatch = useAppDispatch();
  const { templateToShare, onCancel } = props;
  const [templateShareGridData, setTemplateShareGridData] = useState<TemplateShareResponse[]>([]);

  const [dropdownTenantsList, setDropdownTenantsList] = useState<TenantSimplifiedResponse[]>([]);

  const [tenantIdToShareWith, setTenantIdToShareWith] = useState<number>(0);

  const onShare = () => {
    if (tenantIdToShareWith) {
      const templateShareRequest: TemplateShareRequest = {
        templateId: templateToShare.templateId,
        tenantId: tenantIdToShareWith,
      };
      displayLoadingPanel();
      dispatch(shareTemplateWithTenantAsync(templateShareRequest))
        .unwrap()
        .then((shareResponse) => {
          toastSuccess(
            `Template ${templateToShare.templateTitle} is now shared with tenant ${shareResponse.tenantName}`
          );
        })
        .catch(() => {
          toastError("Failed to execute template sharing with tenant");
        })
        .finally(() => {
          hideLoadingPanel();
          onCancel();
        });
    }
  };

  const templateShareData = useAppSelector((state) => state.templateData.currentTemplateShareData);

  const simplifiedTenantsData = useAppSelector((state) => state.tenantData.simplifiedTenants);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadTemplateSharedDataListAsync(templateToShare.templateId))
      .unwrap()
      .catch(() => {
        toastError("Failed to retrieve partner tenant list");
      })
      .finally(hideLoadingPanel);

    displayLoadingPanel();
    dispatch(loadSimplifiedTenantsAsync(true))
      .catch(() => {
        toastError("Failed to retrieve tenant list");
      })
      .finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    setTemplateShareGridData(templateShareData);

    if (simplifiedTenantsData.length !== 0) {
      let nonPartnerTenantData: TenantSimplifiedResponse[] = simplifiedTenantsData.filter((tn) => {
        let found = false;
        templateShareData.forEach((val) => {
          found = found === false ? val.tenantId === tn.id && val.tenantName === tn.name : true;
        });
        return found === false;
      });
      setDropdownTenantsList(nonPartnerTenantData);
    }
  }, [templateShareData, simplifiedTenantsData]);

  return (
    <PromineoModal
      isVisible={true}
      displayDefaultActions={true}
      coloredBg={true}
      width={720}
      height={640}
      editorActionButtonProps={{
        footerComponent: (
          <SharedTemplateDialogFooter
            dataSource={dropdownTenantsList}
            tenantIdToShareWith={tenantIdToShareWith}
            setTenantIdToShareWith={setTenantIdToShareWith}
            onShare={onShare}
          />
        ),
      }}
    >
      <SharedTemplateDialogHeader onCancel={onCancel} />
      <SharedWithTenantsViewGrid
        sharedWithTenantsGridData={templateShareGridData}
        templateId={templateToShare.templateId}
      />
    </PromineoModal>
  );
}
