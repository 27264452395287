import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import TemplateRequest from "interfaces/request/TemplateRequest";
import DetailedTemplateResponse from "interfaces/response/DetailedTemplateResponse";
import { useState } from "react";
import { useNavigate } from "react-router";
import { TEMPLATES } from "shared/constants/RoutePathConstants";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { addNewTemplateAsync } from "store/actions/TemplateActions";
import { useAppDispatch } from "store/hooks";
import TemplateEditor from "./TemplateEditor";

export default function NewTemplate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [template] = useState<DetailedTemplateResponse>({
    fieldCount: 0,
    fields: [],
    id: 0,
    labels: [],
    ownConfigsCount: 0,
    ownerTenant: "",
    shareCount: 0,
    title: "",
    description: ""
  });

  const handleSaveChangesClick = (templateRequest: TemplateRequest) => {
    if (template && templateRequest) {
      displayLoadingPanel();
      dispatch(addNewTemplateAsync(templateRequest))
        .unwrap()
        .then((newTemplate: DetailedTemplateResponse) => {
          toastSuccess("Template created successfully.");
          navigate(`${TEMPLATES}/${newTemplate.id}`);
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  return template ? (
    <TemplateEditor
      copyOrNew={true}
      template={template}
      onSave={handleSaveChangesClick}
      onCancel={handleCancelClick}
    />
  ) : (
    <></>
  );
}
