import InfoIcon from "components/icons/InfoIcon";
import PromineoButton from "./controls/buttons/PromineoButton";
import CrossButton from "./controls/buttons/CrossButton";

interface Props {
  text: string;
  action?: {
    text: string;
    onClick: () => void;
  };
  crossButtonAction?: {
    onClick: () => void;
  };
}

export default function InformationBar(props: Props) {
  return (
    <div className="bg-blue50 rounded-lg h-[48px] flex items-center">
      <div className="flex justify-between px-4 w-full">
        <div className="flex gap-x-4 items-center">
          <InfoIcon />
          <span className="text-sm font-normal font-inter">{props.text}</span>
        </div>
        <div>
          <div>
            {!!props.action && (
              <PromineoButton
                text={props.action.text}
                onClick={props.action.onClick}
              />
            )}
          </div>
          <div>
            {!!props.crossButtonAction && (
              <CrossButton
                classNames={`delete-row-button w-18px`}
                onDeleteClick={props.crossButtonAction.onClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
