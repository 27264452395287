import { AccountInfo, Configuration } from "@azure/msal-browser";

const authority =
  process.env.REACT_APP_AUTH_AUTHORITY ||
  "https://ilapflowdev.b2clogin.com/ilapflowdev.onmicrosoft.com/b2c_1a_signup_signin_work/";
const clientId =
  process.env.REACT_APP_AUTH_CLIENTID || "c4489450-a61c-43bb-bb0e-221fada5be9c";
const scopes = (
  process.env.REACT_APP_AUTH_SCOPES ||
  "https://ilapflowdev.onmicrosoft.com/api-dev/user_impersonation,openid,offline_access,email"
).split(",");
const redirectUri =
  process.env.REACT_APP_AUTH_REDIRECTURI || "http://localhost:3200/";

const getKnownAuthorities = () => {
  const knownAuthorities: string[] = [];
  var authorityParts = authority.split("/");
  knownAuthorities.push(authorityParts[2]);

  return knownAuthorities;
};

export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: authority,
    knownAuthorities: getKnownAuthorities(), // Mark B2C tenant's domain as trusted.
    redirectUri: redirectUri, // Register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: redirectUri, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: scopes,
  prompt: 'login'
};

export const getTokenRequest = (account: AccountInfo | null) => {
  return {
    account: account ?? undefined,
    scopes: scopes,
  };
};
