import { SwitchButtonType } from "shared/enums/SwitchButtonType";

interface Props {
  buttonLeftText?: string;
  buttonRightText?: string;
  switchType: SwitchButtonType;
  onChange: () => void;
  checked?: boolean;
  defaultChecked?: boolean;
  isHidden?: boolean;
  disabled?:boolean;
}

export default function PromineoSwitchButton(props: Props) {
  const getCheckedColor = () => {
    let backgroundColor = "peer-checked:bg-ilapGreen";

    if (props.switchType === SwitchButtonType.Error) {
      backgroundColor = "peer-checked:bg-lightRed";
    } else if (props.switchType === SwitchButtonType.Warning) {
      backgroundColor = "peer-checked:bg-lightOrange";
    } else if (props.switchType === SwitchButtonType.Information) {
      backgroundColor = "peer-checked:bg-ilapLightBlue";
    }

    return backgroundColor;
  };

  return (
    <div>
      {!props.isHidden && (
        <div className="w-fit flex justify-center items-center">
          {props.buttonLeftText && (
            <span className="text-12px leading-22px font-medium text-gray-900 dark:text-gray-300 mr-3">
              {props.buttonLeftText}
            </span>
          )}
          <label className={`relative inline-flex items-center ${props.disabled ? "pointer-events-none" : "cursor-pointer"}`}>
            <input
              type="checkbox"
              onChange={props.onChange}
              checked={props.checked}
              defaultChecked={props.defaultChecked}
              value=""
              className="sr-only peer"
              disabled={props.disabled}
            />
            <div
              className={`w-22px h-3 bg-gray rounded-full peer-checked:after:translate-x-full after:border-white after:absolute after:top-[1px] after:left-[1px] after:bg-white after:rounded-full after:h-2.5 after:w-2.5 after:transition-all ${getCheckedColor()}`}
            ></div>
          </label>
          {props.buttonRightText && (
            <span className="text-12px leading-22px font-medium text-gray-900 dark:text-gray-300 ml-3">
              {props.buttonRightText}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
