import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";

export const GetBackgroundColor = (colorVariant: PromineoColorVariant) => {
  if (colorVariant === PromineoColorVariant.IlapGreen) {
    return "bg-ilapGreen";
  }
  if (colorVariant === PromineoColorVariant.LightGreen) {
    return "bg-lightGreen";
  }
  if (colorVariant === PromineoColorVariant.Orange) {
    return "bg-orange";
  }
  if (colorVariant === PromineoColorVariant.Red) {
    return "bg-red";
  }
  if (colorVariant === PromineoColorVariant.Purple) {
    return "bg-purple";
  }
  if (colorVariant === PromineoColorVariant.IlapBlue) {
    return "bg-ilapBlue";
  }
  if (colorVariant === PromineoColorVariant.LightRed) {
    return "bg-lightRed";
  }
  if (colorVariant === PromineoColorVariant.LightOrange) {
    return "bg-lightOrange";
  }
  if (colorVariant === PromineoColorVariant.LightGray) {
    return "bg-lightGray";
  }
  if (colorVariant === PromineoColorVariant.White) {
    return "bg-white";
  }
  if (colorVariant === PromineoColorVariant.IlapLightBlue) {
    return "bg-ilapLightBlue";
  }
}