import ConnectorLabelWithContent from "../../ConnectorLabelWithContent";
import AnalyticsConnectorAuthenticationModeField from "./AnalyticsConnectorAuthenticationModeField";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import AnalyticsAzureAdAuthentication from "./AnalyticsAzureAdAuthentication";
import { AnalyticsConnectorAuthenticationMode } from "shared/enums/feature/AnalyticsConnectorAuthenticationMode";
import AnalyticsServiceAccountTokenAuthentication from "./AnalyticsServiceAccountTokenAuthentication";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";

interface Props {
  configuration: AnalyticsConnectorConfiguration;
  mode: PromineoModalMode;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleAuthenticationModeValueChanged?: (value: number) => void;
  validateOnFocusOut?: (event: any) => void;
  executionComponent: ExecutionComponent;
}

export default function AnalyticsAuthentication(props: Props) {
  return (
    <div>
      <ConnectorLabelWithContent
        mode={props.mode}
        addAsterisksymbol={true}
        label="Authentication type"
        content={
          <AnalyticsConnectorAuthenticationModeField
            mode={props.mode}
            configuration={props.configuration}
            executionComponent={props.executionComponent}
            validateOnFocusOut={props.validateOnFocusOut}
            selectedAuthenticationMode={props.configuration.authenticationMode}
            onValueChange={props.handleAuthenticationModeValueChanged}
          />
        }
      />

      {props.configuration.authenticationMode ===
      AnalyticsConnectorAuthenticationMode.AzureAdAuthentication ? (
        <AnalyticsAzureAdAuthentication
          configuration={props.configuration}
          mode={props.mode}
          handleAuthenticationModeValueChanged={
            props.handleAuthenticationModeValueChanged
          }
          handleConfigurationValueChanged={
            props.handleConfigurationValueChanged
          }
          validateOnFocusOut={props.validateOnFocusOut}
        />
      ) : null}

      {props.configuration.authenticationMode ===
      AnalyticsConnectorAuthenticationMode.ServiceAccountToken ? (
        <AnalyticsServiceAccountTokenAuthentication
          configuration={props.configuration}
          onValueChange={props.handleConfigurationValueChanged}
          mode={props.mode}
          validateOnFocusOut={props.validateOnFocusOut}
        />
      ) : null}
    </div>
  );
}
