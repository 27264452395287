import NavItemOption from "interfaces/NavItemOption";

export const REDIRECT_URL_PROP = "redirectUrl"

export const checkIfIsActiveRoute = (
  locationPath: string,
  navRoute: string
) => {
  return locationPath === navRoute;
};

export const checkIfNavItemIsActive = (
  locationPath: string,
  navItem: NavItemOption
) => {
  if (navItem.isActive !== undefined) {
    return navItem.isActive;
  } else if (navItem.route) {
    return checkIfIsActiveRoute(locationPath, navItem.route);
  } else if (navItem.children) {
    const isActive = !!navItem.children.find(
      (childNav) =>
        childNav.route && checkIfIsActiveRoute(locationPath, childNav.route)
    );
    return isActive;
  }
  return false;
};
