import { Column, Scrolling } from "devextreme-react/data-grid";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import FieldValue from "interfaces/common/FieldValue";

interface Props {
  fieldValues: FieldValue[];
  height: number;
}

export default function BulkInsertFieldValuePreviewGrid(props: Props) {
  return (
    <div>
      <PromineoViewGrid dataSource={props.fieldValues} height={props.height}>
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column dataField={"valueCode"} caption="Value Code" />
        <Column dataField={"description"} caption="Description" />
      </PromineoViewGrid>
    </div>
  );
}
