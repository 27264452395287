import TemplateSelectorDropdown, {
  TemplateDropdownSourceType,
} from "features/common/TemplateSelectorDropdown";
import TemplateSimplifiedResponse from "interfaces/response/TemplateSimplifiedResponse";
import { useEffect, useState } from "react";

interface Props {
  templates: TemplateSimplifiedResponse[];
  onSelectionChange: (templateId: number, title: string) => void;
  selectedTemplateId?: number;
}

export default function IEATemplateSelectorDropdown(props: Props) {
  const [templateDataSource, setTemplateDataSource] =
    useState<TemplateDropdownSourceType>({
      own: [],
      received: [],
    });

  useEffect(() => {
    const ownTemplates: TemplateSimplifiedResponse[] = [];
    const receivedTemplates: TemplateSimplifiedResponse[] = [];

    props.templates.forEach((template) => {
      if (template.isReceived) {
        receivedTemplates.push(template);
      } else {
        ownTemplates.push(template);
      }
    });

    setTemplateDataSource({
      own: ownTemplates,
      received: receivedTemplates,
    });
  }, [props.templates]);

  return (
    <TemplateSelectorDropdown
      templateDataSource={templateDataSource}
      onSelectionChange={props.onSelectionChange}
      selectedTemplateId={props.selectedTemplateId}
      width={328}
    />
  );
}
