import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import ConnectorInformation from "./ConnectorInformation";
import ScheduleGrid from "./ScheduleGrid";
import HostFieldGrid from "./HostFieldGrid";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import { Item } from "devextreme-react/tab-panel";
import { useCallback, useEffect, useState } from "react";
import { CONNECTORS } from "shared/constants/RoutePathConstants";
import { useNavigate } from "react-router-dom";
import Breadcrumb, { BreadcrumbItem } from "components/common/breadcrumb/Breadcrumb";
import EditButton from "components/common/controls/buttons/EditButton";
import LabelHolder from "components/common/LabelHolder";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import PageHeader from "components/common/page-header/PageHeader";
import LabelResponse from "interfaces/response/LabelResponse";
import AnalyticsConnectorConfiguration from "interfaces/response/connector/AnalyticsConnectorConfiguration";
import SAPConnectorConfiguration from "interfaces/response/connector/SAPConnectorConfiguration";
import PrimaveraConnectorConfiguration from "interfaces/response/connector/PrimaveraConnectorConfiguration";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import MicrosoftProjectConnectorConfiguration from "interfaces/response/connector/MicrosoftProjectConnectorConfiguration";

interface Props {
  connector: ConnectorDetailsResponse;
  connectorConfiguration:
    | SafranApiConnectorConfigurationResponse
    | AnalyticsConnectorConfiguration
    | SAPConnectorConfiguration
    | PrimaveraConnectorConfiguration
    | MicrosoftProjectConnectorConfiguration;
  mode: PromineoModalMode;
  handleConnectorValueChanged?: (fieldName: string, value: any) => void;
  handleHostSystemValueChange?: (value: number) => void;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  handleSafranAuthenticationModeValueChanged?: (value: number) => void;
  handleAnalyticsAuthenticationModeValueChanged?: (value: number) => void;
  handleSAPAuthenticationModeValueChanged?: (value: number) => void;
  handlePrimaveraAuthenticationModeValueChanged?: (value: number) => void;
  handleTitleChanged?: (value: string) => void;
  onAddNewLabel?: (label: LabelResponse) => void;
  onDeleteLabel?: (label: LabelResponse) => void;
  divIdsToExcludeForGrid: string[];
}

export default function ConnectorViewerOrEditor(props: Props) {
  const {
    connector,
    connectorConfiguration,
    mode,
    handleConnectorValueChanged,
    handleHostSystemValueChange,
    handleConfigurationValueChanged,
    handleSafranAuthenticationModeValueChanged,
    handleAnalyticsAuthenticationModeValueChanged,
    handleSAPAuthenticationModeValueChanged,
    handlePrimaveraAuthenticationModeValueChanged,
    handleTitleChanged,
    onAddNewLabel,
    onDeleteLabel,
  } = props;

  const handleAddNewLabel = (label: LabelResponse) => {
    if (onAddNewLabel) {
      onAddNewLabel(label);
    }
  };

  const handleDeleteLabel = (label: LabelResponse) => {
    if (onDeleteLabel) {
      onDeleteLabel(label);
    }
  };

  const initialWidgets = [
    <LabelHolder
      labels={connector.labels ?? []}
      labelType={SupportedLabelEntity.Connector}
      allowAdd={mode === PromineoModalMode.Modify ? true : false}
      allowDelete={mode === PromineoModalMode.Modify ? true : false}
      onAddLabel={handleAddNewLabel}
      onDelete={handleDeleteLabel}
    />,
  ];

  const navigate = useNavigate();
  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadcrumbItem[]>([]);
  const [widgets, setWidgets] = useState(initialWidgets);

  useEffect(() => {
    const items: BreadcrumbItem[] = [
      {
        key: "connectors",
        text: "Connectors",
      },
      {
        key: "connector-edit",
        text: connector.name,
      },
    ];

    setBreadCrumbItems(items);
  }, [connector]);

  useEffect(() => {
    setWidgets(() => {
      return [...initialWidgets];
    });
  }, [connector]);

  const handleBreadCrumbSelectionChange = useCallback(() => {
    navigate(CONNECTORS, { replace: true });
  }, [navigate]);

  const handleEditButtonClick = () => {
    navigate(`${CONNECTORS}/${connector.id}/edit`);
  };

  const headerDivId: string = "connector-viewer-editor-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds: excludedContainerIds.concat(props.divIdsToExcludeForGrid),
    marginHeight: 165,
  });

  return (
    <>
      <div id={headerDivId}>
        <div className="mb-4">
          <Breadcrumb items={breadCrumbItems} onSelectionChange={handleBreadCrumbSelectionChange} />
        </div>

        <div className="flex">
          <PageHeader
            isEdit={mode === PromineoModalMode.Modify}
            hideBottomBorder={true}
            title={connector.name}
            onTitleChanged={handleTitleChanged}
            titleWidget={
              mode === PromineoModalMode.View ? (
                <EditButton classNames="w-9" onEditClick={handleEditButtonClick} />
              ) : (
                <></>
              )
            }
            headerWidgets={
              mode === PromineoModalMode.Modify
                ? widgets
                : mode === PromineoModalMode.View
                ? connector.labels?.length > 0
                  ? widgets
                  : undefined
                : undefined
            }
          />
        </div>
      </div>

      <PromineoTabPanel>
        <Item title={"Connector info"}>
          <div className="p-4">
            <ConnectorInformation
              connector={connector}
              connectorConfiguration={connectorConfiguration}
              mode={mode}
              handleConnectorValueChanged={handleConnectorValueChanged}
              handleHostSystemValueChange={handleHostSystemValueChange}
              handleConfigurationValueChanged={handleConfigurationValueChanged}
              handleSafranAuthenticationModeValueChanged={
                handleSafranAuthenticationModeValueChanged
              }
              handleAnalyticsAuthenticationModeValueChanged={
                handleAnalyticsAuthenticationModeValueChanged
              }
              handleSAPAuthenticationModeValueChanged={
                handleSAPAuthenticationModeValueChanged
              }
              handlePrimaveraAuthenticationModeValueChanged={
                handlePrimaveraAuthenticationModeValueChanged
              }
            />
          </div>
        </Item>
        <Item title={"Schedule list"}>
          <div className="p-4">
            <ScheduleGrid scheduleList={connector.hostSchedules} height={gridHeight} />
          </div>
        </Item>
        <Item title={"Hostfield list"}>
          <div className="p-4">
            <HostFieldGrid hostFieldList={connector.hostFields} height={gridHeight} />
          </div>
        </Item>
      </PromineoTabPanel>
    </>
  );
}
