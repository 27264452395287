import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";

interface Props {
  onValueChanged: (value: string) => void;
  value: string;
  height: number;
}

export default function BulkEditorInput(props: Props) {
  const { onValueChanged, value, height } = props;
  return (
    <PromineoMultilineTextEdit
      height={height}
      placeholder="Type or paste here..."
      className="whitespace-pre-line text-10px font-Inter"
      defaultValue={value}
      onValueChanged={(e: any) => onValueChanged(e.event?.target.value)}
    ></PromineoMultilineTextEdit>
  );
}
