import PromineoPopover from "components/common/controls/PromineoPopover";
import { Offset, Position } from "devextreme-react/popover";
import PopoverMenuItem from "./PopoverMenuItem";

interface Props {
  menuItems: PopoverMenuItem[];
  target: string;
  visible: boolean;
  onHiding: () => void;
  offsetX?: number;
  offsetY?: number;
  minWidth?: number;
}

export default function PromineoPopoverWithMenu(props: Props) {
  return (
    <div>
      <PromineoPopover
        width={"auto"}
        minWidth={props.minWidth ? props.minWidth : 109}
        height={"auto"}
        visible={props.visible && props.menuItems.length > 0}
        target={props.target}
        onHiding={props.onHiding}
      >
        <Position my={{ x: "right", y: "top" }}>
          <Offset
            x={props.offsetX ? props.offsetX : 45}
            y={props.offsetY ? props.offsetY : -5}
          />
        </Position>
        <div className="py-2">
          {props.menuItems.map((op) => {
            return (
              <div
                key={op.itemId}
                className={
                  "font-10px hover:bg-lightBlue space-y-3 py-2.5 px-4 text-xs cursor-pointer font-medium"
                }
                onClick={(e) => {
                  op.onClick();
                  props.onHiding();
                  e.stopPropagation();
                }}
              >
                {op.text}
              </div>
            );
          })}
        </div>
      </PromineoPopover>
    </div>
  );
}
