import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import FieldDetailValueResponse from "interfaces/response/FieldDetailValueResponse";
import { useRef } from "react";

interface Props {
  dataSource: FieldDetailValueResponse[];
  height: number;
}

export default function ViewFieldValueGrid(props: Props) {
  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  return (
    <PromineoViewGrid
      width={640}
      dataSource={props.dataSource}
      className="promineo-ui-grid"
      toolbarConfig={toolbarConfig.current}
      height={props.height}
      noDataText="Values will appear here once they are added!"
    >
      <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
      <Column caption={"Value Code"} dataField={"code"} />
      <Column caption={"Description"} dataField={"description"} />
    </PromineoViewGrid>
  );
}
