export default function DropdownIcon() {
  return (
    <svg 
      width="8" 
      height="6" 
      viewBox="0 0 8 6" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M4 5.24998L0 1.24998L0.933333 0.31665L4 3.38332L7.06667 0.31665L8 1.24998L4 5.24998Z" 
        fill="#727272"/>
    </svg>
  )
}