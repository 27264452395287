import { createSlice } from "@reduxjs/toolkit";
import {
  loadConnectorsForCurrentUserAsync,
  loadConnectorDetailsAsync,
  createConnector,
  updateConnector,
  loadSimplifiedConnectorsAsync,
  removeConnectorAsync,
  loadConnectorHostFieldAsync,
} from "store/actions/ConnectorActions";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";
import { toastSuccess } from "shared/utilities/ToastUtility";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import HostFieldResponse from "interfaces/response/HostFieldResponse";

export interface ConnectorState {
  connectors: ConnectorResponse[];
  selectedConnector: ConnectorDetailsResponse | null;
  simplifiedConnectors: ConnectorBaseResponse[];
  connectorHostFields: HostFieldResponse[];
}

const initialState: ConnectorState = {
  connectors: [],
  selectedConnector: null,
  simplifiedConnectors: [],
  connectorHostFields: [],
};

export const connectorSlice = createSlice({
  name: "connectors",
  initialState,
  reducers: {
    resetSelectedConnector: (state) => {
      state.selectedConnector = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadConnectorsForCurrentUserAsync.fulfilled,
      (state, action) => {
        state.connectors = action.payload;
      }
    );
    builder.addCase(loadConnectorDetailsAsync.fulfilled, (state, action) => {
      state.selectedConnector = action.payload;
    });
    builder.addCase(createConnector.fulfilled, (state, action) => {
      toastSuccess("Created Connector successfully.");
    });
    builder.addCase(updateConnector.fulfilled, (state, action) => {
      toastSuccess("Updated connector successfully.");
    });
    builder.addCase(loadSimplifiedConnectorsAsync.pending, (state, action) => {
      state.simplifiedConnectors = [];
    });
    builder.addCase(loadSimplifiedConnectorsAsync.fulfilled, (state, action) => {
        state.simplifiedConnectors = action.payload;
      }
    );
    builder.addCase(loadConnectorHostFieldAsync.fulfilled, (state, action) => {
      state.connectorHostFields = action.payload;
    });
    builder.addCase(removeConnectorAsync.fulfilled, (state, action) => {
      toastSuccess("Connector deleted successfully.");
    });
  },
});

export const { resetSelectedConnector } = connectorSlice.actions;

export default connectorSlice.reducer;
