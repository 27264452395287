export default function CheckIcon() {
  return (
    <svg 
      width="12" 
      height="10" 
      viewBox="0 0 12 10" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M3.99993 7.8L1.19993 5L0.266602 5.93334L3.99993 9.66667L11.9999 1.66667L11.0666 0.733337L3.99993 7.8Z" 
        fill="#727272"
      />
    </svg>
  );
}