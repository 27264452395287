interface Props {
  customClass?: string;
}
export default function BorderComponent(props: Props) {
  return (
    <div
      className={`border-b border-lightGray ${
        props.customClass ? props.customClass : ""
      }`}
    ></div>
  );
}
