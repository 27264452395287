import { useEffect, useState } from "react";

interface Props {
  toggleLeftText: string;
  toggleRightText: string;
  onToggleLeft: () => void;
  onToggleRight: () => void;
  value?: "left" | "right";
  width?: number;
}

const ToggleButton = (props: {
  text: string;
  onClick: () => void;
  isOn: boolean;
  isLeft: boolean;
}) => {
  const toggleOnClasses = `bg-lighterBlue text-ilapBlue border border-ilapLightBlue ${
    props.isLeft ? "rounded-l" : "rounded-r"
  }`;
  return (
    <div
      onClick={props.onClick}
      className={`w-full h-8 justify-center items-center flex text-center cursor-pointer py-1 px-6px text-xs font-normal font-poppins leading-[18px] ${
        props.isOn
          ? toggleOnClasses
          : `text-textGray bg-white border border-y-lightGray ${
              props.isLeft ? "rounded-l border-l-lightGray border-r-transparent" : "rounded-r border-r-lightGray border-l-transparent"
            } hover:text-ilapBlue`
      }`}
    >
      {props.text}
    </div>
  );
};

export default function PromineoToggleButtonLarge(props: Props) {
  const [toggleState, setToggleState] = useState(
    props.value === "right" ? true : false
  );

  useEffect(() => {
    setToggleState(props.value === "right" ? true : false);
  }, [props.value]);

  const handleToggleLeft = () => {
    setToggleState(false);
    props.onToggleLeft();
  };

  const handleToggleRight = () => {
    setToggleState(true);
    props.onToggleRight();
  };

  return (
    <div className="inline-block" style={{ width: props.width ?? "auto" }}> 
      <div className={`flex`}>
        <ToggleButton
          text={props.toggleLeftText}
          onClick={handleToggleLeft}
          isOn={toggleState === false}
          isLeft={true}
        />
        <ToggleButton
          text={props.toggleRightText}
          onClick={handleToggleRight}
          isOn={toggleState === true}
          isLeft={false}
        />
      </div>
    </div>
  );
}
