import { Button } from "devextreme-react";
import { IButtonOptions } from "devextreme-react/button";
import "./styles/PromineoButton.css";

export enum PromineoButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Ternary = "ternary",
  Success = "success",
  Danger = "danger",
  Basic = "basic",
  Borderless = "borderless",
  BorderlessWithBlueText = "borderlessWithBlueText",
  BorderlessDanger = "borderlessDanger",
}

interface Props {
  variant?: PromineoButtonType;
}

export default function PromineoButton(props: Props & IButtonOptions) {
  const { className, variant, ...rest } = props;

  let classNames = `promineo-button${className ? ` ${className}` : ""}`;

  if (!variant || variant === PromineoButtonType.Primary) {
    classNames = `${classNames} primary-button`;
  } else if (variant === PromineoButtonType.Secondary) {
    classNames = `${classNames} secondary-button`;
  } else if (variant === PromineoButtonType.Success) {
    classNames = `${classNames} success-button`;
  } else if (variant === PromineoButtonType.Danger) {
    classNames = `${classNames} danger-button`;
  } else if (variant === PromineoButtonType.Basic) {
    classNames = `${classNames} basic-button`;
  } else if (variant === PromineoButtonType.Borderless) {
    classNames = `${classNames} borderless-button`;
  } else if (variant === PromineoButtonType.BorderlessDanger) {
    classNames = `${classNames} borderless-danger-button`;
  } else if (variant === PromineoButtonType.Ternary) {
    classNames = `${classNames} ternary-button`;
  } else if (variant === PromineoButtonType.BorderlessWithBlueText) {
    classNames = `${classNames} promineo-button-blue-text`;
  }

  return (
    <>
      <Button height={32} {...rest} className={classNames} />
    </>
  );
}
