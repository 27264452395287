import { PromineoColorVariant } from "shared/enums/PromineoColorVariantEnum";
import { GetBackgroundColor } from "shared/utilities/BackgroundColorUtility";

interface Props {
  color: PromineoColorVariant;
  tooltipText?: string;
} 

export default function Circle(props: Props) {
  return (
    <div title={props.tooltipText} className={`h-3 w-3 rounded-full ${GetBackgroundColor(props.color)}`} ></div>
  );
}