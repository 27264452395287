interface Props {
  title: string;
  instructions?: string[];
}

export default function DialogHeader(props: Props) {
  const { title, instructions } = props;
  return (
    <div className="font-poppins leading-3 text-[12px] leading-15px text-textGray">
      <div className="font-bold mb-2">{title}</div>
      {instructions && instructions.length !== 0 && (
        <div className="mb-2">
          <ul className="list-disc ml-4">
            {instructions.map((instruction, ind) => {
              return <li key={ind}>{instruction}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
