import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";
import LabelWithContent from "components/common/LabelWithContent";
import StructuresToExportSelectionComponent from "features/setup/configs/host-system/safran/StructuresToExportSelectionComponent";

interface Props extends BaseHostSystemComponentProps {
  structuresToExportCandidates: HostFieldDropdownValueResponse[];
  selectedValues: string;
}

export default function OtherStructureField(props: Props) {
  const {
    mode,
    fieldName,
    structuresToExportCandidates,
    selectedValues,
    handleConfigurationValueChanged,
  } = props;

  return (
    <LabelWithContent
      label="Other Structures"
      content={
        <StructuresToExportSelectionComponent
          mode={mode}
          structuresToExportCandidates={structuresToExportCandidates}
          fieldName={fieldName}
          selectedValuesString={selectedValues}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      }
    />
  );
}
