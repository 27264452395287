interface Props {
  fillColor?: "white" | "black";
}
export default function LandingIlapLogo(props: Props) {
  return (
    <svg
      width="141"
      height="51"
      viewBox="0 0 141 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7363 50.1442L26.4116 41.564V17.1436L19.894 20.7904V39.5485L10.7363 34.4276V50.1442Z"
        fill="#53AE96"
      />
      <path
        d="M18.6561 37.4389V17.1436L0.505859 27.2912L18.6561 37.4389Z"
        fill="#53AE96"
      />
      <path
        d="M10.7349 17.4903V0L26.4102 8.74516L10.7349 17.4903Z"
        fill="#53AE96"
      />
      <path
        d="M42.9876 32.9672H34.6621V8.60352H42.9876V32.9672Z"
        fill="white"
      />
      <path
        d="M54.1004 8.58057V26.9274H71.4537L68.2551 32.9989H45.7539V8.58057H54.0983H54.1004Z"
        fill="white"
      />
      <path
        d="M107.188 33.0009H99.5125L97.3717 28.5365H81.0488L78.9081 33.0009H71.2324L83.402 8.58252H95.0164L107.186 33.0009H107.188ZM83.6775 23.0239H94.7409L90.43 14.0574H87.9885L83.6775 23.0239Z"
        fill="white"
      />
      <path
        d="M117.698 24.8602V32.9989H109.354V8.58057H127.573C135.859 8.58057 140.001 11.2277 140.001 16.5239V16.8517C140.001 22.19 135.859 24.8602 127.573 24.8602H117.698ZM117.698 19.379H127.428C130.006 19.379 131.295 18.5261 131.295 16.8181V16.7193C131.295 14.9903 130.006 14.1268 127.428 14.1268H117.698V19.379Z"
        fill="white"
      />
      <path
        d="M34.6621 47.0643V35.4536H37.8939C38.8898 35.4536 39.7705 35.677 40.5359 36.1232C41.3012 36.5699 41.8922 37.2052 42.3091 38.0291C42.7257 38.8534 42.9366 39.7996 42.942 40.868V41.6098C42.942 42.7049 42.7338 43.6646 42.3169 44.4884C41.9002 45.3128 41.3052 45.9454 40.5319 46.3866C39.7587 46.8279 38.8582 47.054 37.8309 47.0643H34.6621ZM36.1719 36.7052V45.8123H37.7602C38.9238 45.8123 39.8294 45.4454 40.477 44.7109C41.1243 43.9768 41.448 42.9309 41.448 41.5741V40.8955C41.448 39.5762 41.1426 38.5507 40.5319 37.8188C39.9213 37.0874 39.0549 36.7161 37.9333 36.7052H36.1719Z"
        fill="white"
      />
      <path
        d="M50.2483 47.0649C50.1644 46.8948 50.0961 46.5916 50.0438 46.1557C49.3676 46.8682 48.5603 47.2242 47.622 47.2242C46.7832 47.2242 46.0951 46.9837 45.5579 46.5027C45.0204 46.0217 44.752 45.4117 44.752 44.6726C44.752 43.7743 45.0887 43.0763 45.7623 42.579C46.4359 42.0823 47.3833 41.8337 48.6048 41.8337H50.0203V41.156C50.0203 40.6402 49.8681 40.2296 49.5642 39.9237C49.2601 39.6183 48.8119 39.4653 48.2195 39.4653C47.7005 39.4653 47.2654 39.598 46.9144 39.8637C46.5631 40.1299 46.3874 40.4512 46.3874 40.8288H44.925C44.925 40.3984 45.0756 39.9824 45.377 39.5808C45.6785 39.1793 46.0873 38.8621 46.6036 38.6279C47.1199 38.3942 47.6874 38.2769 48.3061 38.2769C49.2864 38.2769 50.0543 38.5255 50.61 39.0227C51.1657 39.5199 51.454 40.2044 51.4749 41.0761V45.0471C51.4749 45.8394 51.5745 46.4697 51.7738 46.9372V47.0649H50.2483ZM47.8342 45.9405C48.2905 45.9405 48.7229 45.8209 49.1318 45.5818C49.5407 45.3422 49.8367 45.0313 50.0203 44.6487V42.8782H48.8802C47.0977 42.8782 46.2066 43.4075 46.2066 44.4651C46.2066 44.9275 46.3587 45.2894 46.6626 45.5497C46.9667 45.8101 47.3573 45.9405 47.8342 45.9405Z"
        fill="white"
      />
      <path
        d="M55.7284 36.3467V38.4362H57.3167V39.5764H55.7284V44.9339C55.7284 45.28 55.7991 45.5394 55.9406 45.7127C56.0823 45.8855 56.3232 45.9721 56.664 45.9721C56.8317 45.9721 57.0624 45.9401 57.356 45.8765V47.0654C56.9734 47.171 56.6013 47.2238 56.2396 47.2238C55.5893 47.2238 55.0993 47.0248 54.7692 46.6259C54.4388 46.2271 54.2738 45.6608 54.2738 44.9272V39.5764H52.7246V38.4362H54.2738V36.3467H55.7284Z"
        fill="white"
      />
      <path
        d="M64.2678 47.0649C64.1839 46.8948 64.1157 46.5916 64.0634 46.1557C63.3871 46.8682 62.5799 47.2242 61.6416 47.2242C60.8027 47.2242 60.1146 46.9837 59.5774 46.5027C59.04 46.0217 58.7715 45.4117 58.7715 44.6726C58.7715 43.7743 59.1083 43.0763 59.7819 42.579C60.4554 42.0823 61.4029 41.8337 62.6243 41.8337H64.0398V41.156C64.0398 40.6402 63.8876 40.2296 63.5838 39.9237C63.2797 39.6183 62.8314 39.4653 62.2391 39.4653C61.7201 39.4653 61.285 39.598 60.9339 39.8637C60.5827 40.1299 60.4069 40.4512 60.4069 40.8288H58.9446C58.9446 40.3984 59.0952 39.9824 59.3966 39.5808C59.698 39.1793 60.1069 38.8621 60.6232 38.6279C61.1395 38.3942 61.707 38.2769 62.3256 38.2769C63.3059 38.2769 64.0738 38.5255 64.6295 39.0227C65.1852 39.5199 65.4735 40.2044 65.4944 41.0761V45.0471C65.4944 45.8394 65.5941 46.4697 65.7934 46.9372V47.0649H64.2678ZM61.8538 45.9405C62.31 45.9405 62.7425 45.8209 63.1513 45.5818C63.5602 45.3422 63.8563 45.0313 64.0398 44.6487V42.8782H62.8997C61.1172 42.8782 60.2261 43.4075 60.2261 44.4651C60.2261 44.9275 60.3783 45.2894 60.6821 45.5497C60.9862 45.8101 61.3768 45.9405 61.8538 45.9405Z"
        fill="white"
      />
      <path
        d="M75.2928 47.2242C74.1394 47.2242 73.2011 46.8402 72.4777 46.0722C71.7543 45.3038 71.3926 44.2765 71.3926 42.9901V42.7189C71.3926 41.863 71.5539 41.0987 71.8762 40.4259C72.1987 39.7536 72.6494 39.2275 73.2287 38.8472C73.808 38.4673 74.4357 38.2769 75.112 38.2769C76.218 38.2769 77.0778 38.6464 77.6911 39.3855C78.3044 40.1245 78.6111 41.1826 78.6111 42.5592V43.1733H72.8474C72.8683 44.0238 73.1132 44.711 73.5826 45.2348C74.0518 45.7587 74.6479 46.0204 75.3713 46.0204C75.8852 46.0204 76.3201 45.9139 76.6767 45.7014C77.0331 45.4888 77.3451 45.2068 77.6125 44.8558L78.501 45.5579C77.7881 46.6687 76.7187 47.2242 75.2928 47.2242ZM75.112 39.4892C74.5247 39.4892 74.032 39.7058 73.6338 40.139C73.2351 40.5726 72.9889 41.1799 72.8946 41.9614H77.1563V41.8495C77.1145 41.1 76.9152 40.5193 76.5588 40.1069C76.2022 39.695 75.7199 39.4892 75.112 39.4892Z"
        fill="white"
      />
      <path
        d="M83.1465 41.5864L85.0338 38.4365H86.7322L83.9487 42.7026L86.819 47.0648H85.1363L83.1701 43.8351L81.2043 47.0648H79.5137L82.3837 42.7026L79.6002 38.4365H81.2831L83.1465 41.5864Z"
        fill="white"
      />
      <path
        d="M91.5284 46.0204C92.0472 46.0204 92.5005 45.8606 92.8885 45.5416C93.2764 45.2226 93.4913 44.8242 93.5336 44.3455H94.9097C94.8834 44.84 94.7157 45.3106 94.4065 45.7569C94.0968 46.2035 93.684 46.56 93.1679 46.8257C92.6513 47.0915 92.105 47.2242 91.5284 47.2242C90.3699 47.2242 89.4485 46.8325 88.7642 46.0483C88.0804 45.2641 87.7383 44.1916 87.7383 42.8304V42.5831C87.7383 41.7434 87.8904 40.9962 88.1943 40.3424C88.4982 39.6887 88.9346 39.1806 89.5037 38.8192C90.0722 38.4578 90.7445 38.2769 91.5204 38.2769C92.4747 38.2769 93.2675 38.567 93.8993 39.1463C94.5306 39.7257 94.8674 40.4778 94.9097 41.4028H93.5336C93.4913 40.8446 93.2831 40.3862 92.9085 40.0275C92.5334 39.6684 92.0707 39.4892 91.5204 39.4892C90.7814 39.4892 90.2084 39.759 89.8022 40.2987C89.396 40.8383 89.1931 41.6184 89.1931 42.6391V42.9184C89.1931 43.9123 89.3947 44.678 89.7986 45.215C90.2022 45.7519 90.7788 46.0204 91.5284 46.0204Z"
        fill="white"
      />
      <path
        d="M97.9998 39.4809C98.6444 38.6782 99.4831 38.2766 100.516 38.2766C102.314 38.2766 103.221 39.3054 103.237 41.3628V47.0646H101.782V41.3547C101.777 40.733 101.636 40.2732 101.361 39.9754C101.086 39.6776 100.658 39.5287 100.076 39.5287C99.6041 39.5287 99.1899 39.6564 98.8331 39.9113C98.4767 40.1667 98.1991 40.5015 97.9998 40.9161V47.0646H96.5449V34.8159H97.9998V39.4809Z"
        fill="white"
      />
      <path
        d="M110.667 47.0649C110.583 46.8948 110.515 46.5916 110.462 46.1557C109.786 46.8682 108.978 47.2242 108.04 47.2242C107.201 47.2242 106.514 46.9837 105.976 46.5027C105.439 46.0217 105.17 45.4117 105.17 44.6726C105.17 43.7743 105.507 43.0763 106.181 42.579C106.854 42.0823 107.802 41.8337 109.023 41.8337H110.438V41.156C110.438 40.6402 110.286 40.2296 109.982 39.9237C109.678 39.6183 109.23 39.4653 108.638 39.4653C108.119 39.4653 107.684 39.598 107.333 39.8637C106.981 40.1299 106.806 40.4512 106.806 40.8288H105.343C105.343 40.3984 105.494 39.9824 105.795 39.5808C106.097 39.1793 106.506 38.8621 107.022 38.6279C107.538 38.3942 108.106 38.2769 108.724 38.2769C109.704 38.2769 110.473 38.5255 111.028 39.0227C111.584 39.5199 111.872 40.2044 111.893 41.0761V45.0471C111.893 45.8394 111.992 46.4697 112.192 46.9372V47.0649H110.667ZM108.253 45.9405C108.709 45.9405 109.141 45.8209 109.55 45.5818C109.959 45.3422 110.255 45.0313 110.438 44.6487V42.8782H109.298C107.516 42.8782 106.625 43.4075 106.625 44.4651C106.625 44.9275 106.777 45.2894 107.081 45.5497C107.385 45.8101 107.775 45.9405 108.253 45.9405Z"
        fill="white"
      />
      <path
        d="M115.552 38.4366L115.599 39.5208C116.249 38.6915 117.098 38.2769 118.147 38.2769C119.945 38.2769 120.852 39.3056 120.868 41.3631V47.0649H119.413V41.3549C119.407 40.7332 119.267 40.2734 118.992 39.9756C118.717 39.6778 118.288 39.5289 117.707 39.5289C117.235 39.5289 116.821 39.6566 116.464 39.9116C116.108 40.1669 115.83 40.5017 115.631 40.9164V47.0649H114.176V38.4366H115.552Z"
        fill="white"
      />
      <path
        d="M122.715 42.6788C122.715 41.3337 123.021 40.2639 123.635 39.4694C124.248 38.6744 125.06 38.2769 126.072 38.2769C127.11 38.2769 127.92 38.6491 128.502 39.3936L128.573 38.4366H129.902V46.8573C129.902 47.974 129.575 48.8539 128.923 49.4973C128.27 50.1403 127.393 50.462 126.292 50.462C125.679 50.462 125.079 50.3289 124.492 50.0631C123.905 49.7973 123.456 49.4332 123.147 48.9707L123.902 48.0855C124.526 48.867 125.289 49.2577 126.19 49.2577C126.898 49.2577 127.449 49.0556 127.845 48.6517C128.241 48.2479 128.439 47.679 128.439 46.9453V46.2035C127.857 46.8844 127.063 47.2242 126.057 47.2242C125.06 47.2242 124.255 46.8176 123.638 46.0041C123.023 45.191 122.715 44.0824 122.715 42.6788ZM124.177 42.8462C124.177 43.8194 124.374 44.5837 124.767 45.1392C125.16 45.6946 125.71 45.9725 126.418 45.9725C127.336 45.9725 128.009 45.5497 128.439 44.7042V40.7652C127.994 39.9409 127.325 39.5289 126.434 39.5289C125.726 39.5289 125.173 39.8082 124.775 40.3664C124.377 40.9245 124.177 41.7511 124.177 42.8462Z"
        fill="white"
      />
      <path
        d="M135.627 47.2242C134.473 47.2242 133.535 46.8402 132.812 46.0722C132.088 45.3038 131.727 44.2765 131.727 42.9901V42.7189C131.727 41.863 131.888 41.0987 132.21 40.4259C132.532 39.7536 132.983 39.2275 133.563 38.8472C134.141 38.4673 134.77 38.2769 135.446 38.2769C136.552 38.2769 137.412 38.6464 138.025 39.3855C138.638 40.1245 138.945 41.1826 138.945 42.5592V43.1733H133.181C133.202 44.0238 133.447 44.711 133.916 45.2348C134.385 45.7587 134.982 46.0204 135.705 46.0204C136.219 46.0204 136.654 45.9139 137.011 45.7014C137.367 45.4888 137.679 45.2068 137.946 44.8558L138.835 45.5579C138.122 46.6687 137.052 47.2242 135.627 47.2242ZM135.446 39.4892C134.859 39.4892 134.366 39.7058 133.968 40.139C133.569 40.5726 133.323 41.1799 133.228 41.9614H137.49V41.8495C137.448 41.1 137.249 40.5193 136.892 40.1069C136.536 39.695 136.054 39.4892 135.446 39.4892Z"
        fill="white"
      />
    </svg>
  );
}
