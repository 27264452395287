import { useNavigate } from "react-router-dom";
import PageHeader, {
  PageOption,
  PageOptionItem,
} from "components/common/page-header/PageHeader";
import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import LabelHolder from "components/common/LabelHolder";
import { CONFIGS } from "shared/constants/RoutePathConstants";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import { useEffect, useMemo, useState } from "react";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import ConnectorInfoDisplay from "../config-common/ConnectorInfoDisplay";
import { ConfigStatus } from "shared/enums/feature/ConfigStatus";
import { getLocalFormattedDateTimeFromUtcDateObject } from "shared/utilities/DateTimeUtility";
import { copyToClipboard } from "shared/utilities/CommonUtility";

interface Props {
  config: ConfigDetailResponse;
  onViewTemplateUpdateClick?: () => void;
}

export default function ConfigViewerHeader(props: Props) {
  let navigate = useNavigate();
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
    useState(false);

  const [headerMenuItems, setheaderMenuItems] = useState<PageOptionItem[]>([]);

  useEffect(() => {
    // based on if template of the config is updated or not
    const menuItems: PageOptionItem[] = [];
    menuItems.push({
      id: 1,
      text: "Edit config",
      onSelectionChange: onEditClick,
    });
    if (props.config.updateRequired && props.onViewTemplateUpdateClick) {
      menuItems.push({
        id: 2,
        text: "View template updates",
        onSelectionChange: props.onViewTemplateUpdateClick,
      });
    }
    menuItems.push({
      id: 3,
      text: "Copy config to clipboard",
      onSelectionChange: onCopyConfigToClipboard,
    });
    menuItems.push({
      id: 4,
      text: "Paste config from clipboard",
      onSelectionChange: onPasteConfigFromClipboard,
    });
    setheaderMenuItems(menuItems);
  }, [props.config.updateRequired]);

  const initialWidgets = [
    <div className="flex space-x-1">
      <div>Template: </div>
      <div className="max-w-[235px] truncate">{props.config.templateTitle}</div>
    </div>,
    <div className="flex space-x-2">
      <div>Connector:</div>{" "}
      <ConnectorInfoDisplay name={props.config.connectorName} />
    </div>,
    <>Direction: {props.config.directionText}</>,
    <div className="flex gap-x-2 items-center">
      {props.config.labels?.length !== 0 && <>Labels: </>}
      <LabelHolder
        labels={props.config.labels}
        labelType={SupportedLabelEntity.Config}
        allowAdd={false}
        allowDelete={false}
      />
    </div>,
    <># of schedules: {props.config.scheduleCount}</>,
    <>Created by: {props.config.createdBy}</>,
    <>Created on: {getLocalFormattedDateTimeFromUtcDateObject(props.config.createdOn)}</>,
  ];

  const pageTitle = `${props.config.id} \xa0 ${props.config.title}`;

  const navigateToEditConfig = () => {
    navigate(`${CONFIGS}/${props.config.id}/edit`);
  };

  const onEditClick = () => {
    if (props.config.status === ConfigStatus.Published) {
      setIsConfirmationDialogVisible(true);
    } else {
      navigateToEditConfig();
    }
  };

  const onCopyConfigToClipboard = async () => {
    // only copy the settings necessary during import
    const mappings = props.config.mappings.map(m => {

      const valueMaps = m.valueMaps.map(vm => {
        return {
          templateValue: vm.templateValue,
          value: vm.value,
          description: vm.description,
          fieldValueText: vm.fieldValueText,
          fieldValueDescription: vm.fieldValueDescription
        }
      });

      return {
        name: m.name,
        description: m.description,
        dataType: m.dataType,
        planningObjectType: m.planningObjectType,
        hasDirectMapping: m.hasDirectMapping,
        allowContentControl: m.allowContentControl,
        allowBlank: m.allowBlank,
        mappedConnectorHostFieldName: m.mappedConnectorHostFieldName,
        formula: m.formula,
        contentControlLevel: m.contentControlLevel,
        isConfigTemplateFieldMapping: m.isConfigTemplateFieldMapping,
        isCoreFieldMapping: m.isCoreFieldMapping,
        valueMaps: valueMaps
      }
    });

    const configSettings = {
      connectorHostSystem: props.config.connectorHostSystem,
      connectorName: props.config.connectorName,
      direction: props.config.direction,
      directionText: props.config.directionText,
      mappings: mappings,
      hostSystemParameters: props.config.hostSystemParameters
    };

    await copyToClipboard(JSON.stringify(configSettings));
  }

  const onPasteConfigFromClipboard = () => {
    navigate(`${CONFIGS}/${props.config.id}/edit`, { state: { getConfigFromClipboard: true } });
  }

  const breadCrumbsItems: BreadcrumbItem[] = [
    {
      key: "configs",
      text: "Configs",
    },
    {
      key: "config-name",
      text: `${props.config.title}`,
    },
  ];

  const handleConfirmClicked = () => {
    navigateToEditConfig();
  };

  const handleCancelConfirmationClicked = () => {
    setIsConfirmationDialogVisible(false);
  };

  const pageOptions = useMemo<PageOption>(() => {
    return {
      items: headerMenuItems,
      isVisible: true,
      dropdownPopupWidth: 210,
    };
  }, [headerMenuItems, props.config.updateRequired]);

  return (
    <>
      <div className="mb-1">
        <Breadcrumb
          items={breadCrumbsItems}
          onSelectionChange={(index, selectedItem) => {
            if (selectedItem.key === "configs") navigate(CONFIGS);
          }}
        />
      </div>
      <div className="flex">
        <PageHeader
          title={pageTitle}
          headerWidgets={initialWidgets}
          pageOptions={pageOptions}
          hideBottomBorder={true}
        />
      </div>

      {isConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleConfirmClicked}
          onCancel={handleCancelConfirmationClicked}
          content="Are you sure you want to edit this config?"
          subContent={`The config will be put under construction and no IEAs can be created with this config until the config is published again.`}
          cancelButtonText="Cancel"
          confirmButtonText="Edit config"
        />
      )}
    </>
  );
}
