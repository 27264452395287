import { ReactElement } from "react";

interface Props {
  widgets: ReactElement[];
}

export default function WidgetsWithSeparator(props: Props) {
  return (
    <div className="flex flex-wrap items-center text-[12px] leading-15px font-inter font-medium text-darkBlue">
      {props.widgets && props.widgets.length ? (
        props.widgets
          .map<React.ReactNode>((t, i) => <div key={`comp-${i}`}>{t}</div>)
          .reduce((prev, curr, ind) => [
            prev,
            <div className="mx-4 text-gray" key={`sep-${ind}`}>
              |
            </div>,
            curr,
          ])
      ) : (
        <></>
      )}
    </div>
  );
}
