import { Popup } from "devextreme-react/popup";
import DataTransferEventLogResponse from "interfaces/response/DataTransferEventLogResponse";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { useEffect, useState } from "react";
import DataTransferStatusCircle from "components/common/DataTransferStatusCircle";
import { EventLogSeverity } from "shared/enums/feature/EventLogSeverity";
import LogListView from "./LogListView";
import EventLogModalHeader from "./EventLogModalHeader";

interface Props {
  eventLogId: number;
  title: string;
  description: string;
  dataTransferStatus: DataTransferStatus;
  dataTransferStatusText: string;
  logDetailsResponse: DataTransferEventLogResponse[];
  onCancel: () => void;
}

export default function DetailsLogModal(props: Props) {
  const {
    eventLogId,
    title,
    description,
    dataTransferStatus,
    dataTransferStatusText,
    logDetailsResponse,
    onCancel,
  } = props;

  const [filteredDetailsLog, setFilteredDetailsLog] = useState<
    DataTransferEventLogResponse[]
  >([]);

  const [displayErrors, setDisplayErrors] = useState<boolean>(true);
  const [displayWarnings, setDisplayWarnings] = useState<boolean>(true);
  const [displayMessages, setDisplayMessages] = useState<boolean>(true);

  useEffect(() => {
    const logs = (logDetailsResponse || []).filter(
      (log) =>
        (log.logLevel === EventLogSeverity.Error && displayErrors) ||
        (log.logLevel === EventLogSeverity.Warning && displayWarnings) ||
        (log.logLevel === EventLogSeverity.Information && displayMessages)
    );

    setFilteredDetailsLog(logs);
  }, [logDetailsResponse, displayErrors, displayWarnings, displayMessages]);

  const handleDisplayErrorChanged = () => {
    setDisplayErrors((prev) => !prev);
  };

  const handleDisplayWarningChanged = () => {
    setDisplayWarnings((prev) => !prev);
  };

  const handleDisplayInformationMessageChanged = () => {
    setDisplayMessages((prev) => !prev);
  };

  const Footer = () => {
    return (
      <>
        <div className="flex space-x-2">
          <DataTransferStatusCircle status={dataTransferStatus} />
          <div className="font-inter font-medium text-10px leading-12px text-textGray">
            {dataTransferStatusText}
          </div>
        </div>
      </>
    );
  };

  return (
    <Popup
      wrapperAttr={{ class: "details-log-popup" }}
      visible={true}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={false}
      width={720}
      height={410}
      position={"center"}
      showTitle={false}
    >
      <div className="mx-6">
        <div className="mt-5">
          <EventLogModalHeader
            eventLogId={eventLogId}
            title={title}
            displayErrors={displayErrors}
            displayWarnings={displayWarnings}
            displayMessages={displayMessages}
            onDisplayMessagesChanged={handleDisplayInformationMessageChanged}
            onDisplayErrorChanged={handleDisplayErrorChanged}
            onDisplayWarningChanged={handleDisplayWarningChanged}
            onModalClose={onCancel}
          />
        </div>
        <div className="mt-12.5px">
          <div className="font-poppins font-medium text-10px leading-15px text-lightTextGray">
            {description}
          </div>
          <div className="mt-1">
            <LogListView logDetails={filteredDetailsLog} />
          </div>
        </div>
        <div className="mt-2.5">
          <Footer />
        </div>
      </div>
    </Popup>
  );
}
