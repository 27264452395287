import LabelWithContent from "components/common/LabelWithContent";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoDatepicker from "components/common/controls/PromineoDatePicker";
import PromineoModal from "components/modal/PromineoModal";
import PromineoModalHeader from "components/modal/PromineoModalHeader";
import ServiceTokenCreateRequest from "interfaces/request/autonomous-components/ServiceTokenCreateRequest";
import ServiceTokenResponse from "interfaces/response/autonomous-components/ServiceTokenResponse";
import { useState } from "react";
import { ClientTypeEnum } from "shared/enums/ClientTypeEnum";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { toastError } from "shared/utilities/ToastUtility";
import { addNewAutonomousComponentServiceTokenAsync } from "store/actions/AutonomousComponentActions";
import { addNewIlapAnalyticsServiceTokenAsync } from "store/actions/IlapAnalyticsActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  clientId: number;
  clientType: ClientTypeEnum;
  onTokenCreateSuccess: (tokenResponse: ServiceTokenResponse) => void;
  onCancel: () => void;
}

export default function ServiceTokenCreateDialog(props: Props) {
  const dispatch = useAppDispatch();
  const [serviceToken, setServiceToken] = useState<ServiceTokenCreateRequest>({
    expiryTime: "",
  });

  const handleChange = (fieldName: string, value: string) => {
    setServiceToken((prev) => {
      return { ...prev, [fieldName]: value };
    });
  };

  const handleCreateNew = () => {
    if (serviceToken.expiryTime) {
      displayLoadingPanel();

      const promise = props.clientType === ClientTypeEnum.AutonomousComponent 
        ? dispatch(
          addNewAutonomousComponentServiceTokenAsync({
            componentId: props.clientId,
            data: serviceToken,
          })
        )
        : dispatch(
          addNewIlapAnalyticsServiceTokenAsync({
            clientId: props.clientId,
            data: serviceToken,
          })
        );

      promise
        .unwrap()
        .then((response) => props.onTokenCreateSuccess(response))
        .finally(hideLoadingPanel);
    } else {
      displayNameValidationFailureToast();
    }
  };

  const displayNameValidationFailureToast = () => {
    toastError("Expiry is required.");
  };

  const handleExpiryDateChange = (evt: any) => {
    handleChange("expiryTime", evt.value);
  };

  return (
    <PromineoModal
      isVisible={true}
      width={400}
      height="200"
      editorActionButtonProps={{
        mode: PromineoModalMode.Create,
        cancelButtonCaption: "Cancel",
        createButtonCaption: "Create",
        doneButtonCaption: "Cancel",
        onCancel: props.onCancel,
        onCreate: handleCreateNew,
      }}
    >
      <PromineoModalHeader
        onCancel={props.onCancel}
        text={"Service Token"}
        showCloseIcon={false}
      />
      <div className="space-y-2 pb-4">
        <LabelWithContent
          label="Expiry"
          content={
            <PromineoDatepicker
              width={"100%"}
              type="datetime"
              onValueChanged={handleExpiryDateChange}
            />
          }
        />
      </div>
    </PromineoModal>
  );
}
