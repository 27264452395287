import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";

export default function DownloadWarningCountComponent(e: {
  data: { data: ActiveExchangeAgreementListingResponse };
}) {
  return e.data.data.downloadWarningCount ? (
    <>{e.data.data.downloadWarningCount}</>
  ) : (
    <>{"-"}</>
  );
}
