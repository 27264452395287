import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import CrossIcon from "components/icons/CrossIcon";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";

interface Props {
  eventLogId: number;
  title: string;
  displayErrors: boolean;
  displayWarnings: boolean;
  displayMessages: boolean;
  onDisplayErrorChanged: () => void;
  onDisplayWarningChanged: () => void;
  onDisplayMessagesChanged: () => void;
  onModalClose: () => void;
}

export default function EventLogModalHeader(props: Props) {
  const {
    eventLogId,
    title,
    displayErrors,
    displayWarnings,
    displayMessages,
    onDisplayErrorChanged,
    onDisplayWarningChanged,
    onDisplayMessagesChanged,
    onModalClose,
  } = props;

  const CrossButton = () => {
    return (
      <button onClick={onModalClose}>
        <div>
          <CrossIcon />
        </div>
      </button>
    );
  };

  return (
    <div className="flex justify-between">
      <div className="truncate h-4 w-80 text-textGray font-poppins font-bold text-10px leading-15px">
        <span>{eventLogId}</span>
        <span className="ml-2">{title}</span>
      </div>

      <div className="flex space-x-6">
        <PromineoSwitchButton
          buttonRightText="Errors"
          switchType={SwitchButtonType.Error}
          defaultChecked={displayErrors}
          onChange={onDisplayErrorChanged}
        />
        <PromineoSwitchButton
          buttonRightText="Warning"
          switchType={SwitchButtonType.Warning}
          defaultChecked={displayWarnings}
          onChange={onDisplayWarningChanged}
        />
        <PromineoSwitchButton
          buttonRightText="Messages"
          switchType={SwitchButtonType.Information}
          defaultChecked={displayMessages}
          onChange={onDisplayMessagesChanged}
        />
        <CrossButton />
      </div>
    </div>
  );
}
