import { useEffect, useState } from "react";

interface Props {
  toggleLeftText: string;
  toggleRightText: string;
  onToggleLeft: () => void;
  onToggleRight: () => void;
  onToggleLeftBg: string;
  onToggleRightBg: string;
  value?: "left" | "right";
}

const ToggleButton = (props: {
  text: string;
  onClick: () => void;
  isOn: boolean;
  toggleOnBg: string;
  toggleOffBg: string;
}) => {
  const toggleOnClasses = `${props.toggleOnBg} text-white`;
  return (
    <div
      onClick={props.onClick}
      className={`cursor-pointer py-1 px-6px text-10px rounded-lg font-medium font-inter ${
        props.isOn
          ? toggleOnClasses
          : `text-lightTextGray ${props.toggleOffBg} `
      }`}
    >
      {props.text}
    </div>
  );
};

export default function PromineoToggleButton(props: Props) {
  const [toggleState, setToggleState] = useState(
    props.value === "right" ? true : false
  );

  useEffect(() => {
    setToggleState(props.value === "right" ? true : false);
  }, [props.value]);

  const handleToggleLeft = () => {
    setToggleState(false);
    props.onToggleLeft();
  };

  const handleToggleRight = () => {
    setToggleState(true);
    props.onToggleRight();
  };

  return (
    <div className="rounded-lg bg-gray-100 inline-block">
      <div className={`flex bg-lightGray rounded-lg`}>
        <ToggleButton
          text={props.toggleLeftText}
          onClick={handleToggleLeft}
          isOn={toggleState === false}
          toggleOnBg={props.onToggleLeftBg}
          toggleOffBg={"bg-lightGray"}
        />
        <ToggleButton
          text={props.toggleRightText}
          onClick={handleToggleRight}
          isOn={toggleState === true}
          toggleOnBg={props.onToggleRightBg}
          toggleOffBg={"bg-lightGray"}
        />
      </div>
    </div>
  );
}
