import PageHeader from "components/common/page-header/PageHeader";
import EditIcon from "components/icons/EditIcon";
import LinkIcon from "components/icons/LinkIcon";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TENANT_ADMIN_FIELDS } from "shared/constants/RoutePathConstants";
import { ContentControl } from "shared/enums/feature/ContentControl";
import TenantAdminContentControlDropdown from "./TenantAdminContentControlDropdown";

interface Props {
  field: FieldDetailsResponse;
  contentControl?: ContentControl;
  isEdit?: boolean;
  onContentControlValueChange?: (value: ContentControl) => void;
  isNotAllowedToEdit?: boolean;
}

export default function ContentControlFieldHeader(props: Props) {
  const navigate = useNavigate();

  const handleEditClick = () => {
    if (props.field) {
      navigate(`${TENANT_ADMIN_FIELDS}/${props.field.id}/edit`);
    }
  };

  const ContentControlComponent = useCallback(() => {
    return props.isEdit && !props.isNotAllowedToEdit ? (
      <TenantAdminContentControlDropdown
        onValueChange={props.onContentControlValueChange}
        contentControl={props.contentControl}
        isDisabled={props.field.valueCount > 0}
        displayConfirmation={true}
      />
    ) : (
      <>{props.field.contentControlLevelText}</>
    );
  }, [
    props.contentControl,
    props.field,
    props.isEdit,
    props.isNotAllowedToEdit,
    props.onContentControlValueChange,
  ]);

  const initialWidgets = useMemo(() => {
    return [
      <>Planning object type: {props.field.planningObjectTypeText}</>,
      <>
        <div className="flex items-center gap-x-2">
          Content control: <ContentControlComponent />
        </div>
      </>,
      <>
        {props.field.allowBlank !== undefined &&
          props.field.allowBlank !== null && (
            <div>Allow blanks: {props.field.allowBlank ? "Yes" : "No"}</div>
          )}
      </>,
      <div className="flex items-center justify-center">
        <div className="mr-1">URL:</div>
        <LinkIcon />
      </div>,
    ];
  }, [props.field, ContentControlComponent]);

  const [widgets, setWidgets] = useState(initialWidgets);

  useEffect(() => {
    var newWidgets = [...initialWidgets].filter((el) => el.props.children);
    setWidgets(() => {
      return newWidgets;
    });
  }, [
    props.contentControl,
    props.field,
    props.isEdit,
    props.isNotAllowedToEdit,
    props.onContentControlValueChange,
    initialWidgets,
  ]);

  return (
    <PageHeader
      title={props.field.name}
      titleWidget={
        props.isEdit || props.isNotAllowedToEdit ? (
          <></>
        ) : (
          <div className="flex gap-2 cursor-pointer">
            <div onClick={handleEditClick}>
              <EditIcon />
            </div>
          </div>
        )
      }
      headerWidgets={widgets}
      displayDescriptionField={true}
      description={props.field.description}
    />
  );
}
