import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";

interface Props {
  onClick: (data: any) => void;
}

export default function ViewValueMapButtonCellTemplate(props: Props) {
  return (
    <PromineoButton
      variant={PromineoButtonType.Secondary}
      text="View"
      onClick={props.onClick}
      className="w-20"
    />
  );
}
