import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import {
  CheckIfPartnerResponsibleFieldsAreFilled,
  IsSignedByOwner,
  IsSignedByPartner,
} from "shared/utilities/ExchangeAgreementUtility";

interface Props {
  isPartnerSubmitButtonDisabled: boolean;
  isCurrentUserOwner: boolean;
  isCurrentUserPartner: boolean;
  selectedExchangeAgreement: ExchangeAgreementDetailedResponse;
  handleDeleteIEAClick: () => void;
  handleOwerIEASign: () => void;
  handleSubmitClick: () => void;
  handlePartnerIEASign: () => void;
  handleCancelClick: () => void;
  handleSaveActiveIEA: () => void;
  isSaveChangesButtonDisabled: boolean;
  isActiveIeaSaveButtonDisabled: boolean;
  isActiveIeaEdit?: boolean;
}

export default function InactiveIEAFooter(props: Props) {
  const {
    isPartnerSubmitButtonDisabled,
    isCurrentUserOwner,
    isCurrentUserPartner,
    selectedExchangeAgreement,
    handleDeleteIEAClick,
    handleOwerIEASign,
    handleSubmitClick,
    handlePartnerIEASign,
    handleSaveActiveIEA,
  } = props;

  return (
    <div className="flex justify-between mt-4">
      <div className="flex space-x-2">
        <PromineoButton
          variant={PromineoButtonType.Secondary}
          text="Cancel"
          onClick={props.handleCancelClick}
        />
        {isCurrentUserOwner && !props.isActiveIeaEdit && (
          <PromineoButton
            variant={PromineoButtonType.BorderlessDanger}
            text="Delete IEA"
            onClick={handleDeleteIEAClick}
          />
        )}
      </div>
      <div>
        {isCurrentUserOwner &&
          !isCurrentUserPartner &&
          selectedExchangeAgreement.actionRequiredIfInactive ===
          NewExchangeAgreementActionRequired.SignOff && (
            <PromineoButton
              variant={PromineoButtonType.Primary}
              text="Sign IEA on behalf of Owner"
              onClick={handleOwerIEASign}
            />
          )}

        {isCurrentUserOwner &&
          !isCurrentUserPartner &&
          selectedExchangeAgreement.actionRequiredIfInactive ===
          NewExchangeAgreementActionRequired.Complete && (
            <PromineoButton
              variant={PromineoButtonType.Primary}
              text="Submit"
              onClick={handleSubmitClick}
            />
          )}

        {props.isActiveIeaEdit && (
          <PromineoButton
            variant={PromineoButtonType.Primary}
            text="Save changes"
            onClick={handleSaveActiveIEA}
            disabled={props.isActiveIeaSaveButtonDisabled}
          />
        )}

        {isCurrentUserPartner &&
          !isCurrentUserOwner &&
          selectedExchangeAgreement.actionRequiredIfInactive ===
          NewExchangeAgreementActionRequired.SignOff && (
            <PromineoButton
              variant={PromineoButtonType.Primary}
              text="Sign IEA on behalf of partner"
              onClick={handlePartnerIEASign}
            />
          )}

        {isCurrentUserPartner &&
          !isCurrentUserOwner &&
          selectedExchangeAgreement.actionRequiredIfInactive ===
          NewExchangeAgreementActionRequired.Complete && (
            <PromineoButton
              variant={PromineoButtonType.Primary}
              text="Submit"
              onClick={handleSubmitClick}
              disabled={isPartnerSubmitButtonDisabled}
            />
          )}

        {isCurrentUserOwner && isCurrentUserPartner && (
          <div>
            {CheckIfPartnerResponsibleFieldsAreFilled(
              selectedExchangeAgreement
            ) ? (
              IsSignedByPartner(selectedExchangeAgreement) ? (
                IsSignedByOwner(selectedExchangeAgreement) ? (
                  <></>
                ) : (
                  <PromineoButton
                    variant={PromineoButtonType.Primary}
                    text="Sign IEA on behalf of Owner"
                    onClick={handleOwerIEASign}
                  />
                )
              ) : (
                <PromineoButton
                  variant={PromineoButtonType.Primary}
                  text="Sign IEA on behalf of partner"
                  onClick={handlePartnerIEASign}
                />
              )
            ) : (
              <PromineoButton
                variant={PromineoButtonType.Primary}
                text="Submit"
                onClick={handleSubmitClick}
                disabled={
                  props.isCurrentUserPartner
                    ? isPartnerSubmitButtonDisabled
                    : false
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
