import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";

interface Props {
  isContentControlChecked: boolean;
  isAllowBlanksChecked: boolean;
  onContentControlChange: () => void;
  onAllowBlanksChange: () => void;
  disableAllowContent?: boolean;
}

export default function EditFieldValueToggleButtons(props: Props) {
  return (
    <div className="flex gap-4">
      <PromineoSwitchButton
        switchType={SwitchButtonType.Default}
        onChange={props.onContentControlChange}
        checked={props.isContentControlChecked}
        buttonLeftText="Content control: "
        disabled={props.disableAllowContent}
      />
      <PromineoSwitchButton
        switchType={SwitchButtonType.Default}
        onChange={props.onAllowBlanksChange}
        checked={props.isAllowBlanksChecked}
        buttonLeftText="Allow blanks: "
        isHidden={!props.isContentControlChecked}
      />
    </div>
  );
}
