import LabelWithAsteriskSymbol from "components/common/LabelWithAsteriskSymbol";
import LabelWithContent from "components/common/LabelWithContent";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import LinkIcon from "components/icons/LinkIcon";
import IlapTermHeaderData from "interfaces/component-data/IlapTermHeaderData";

interface Props {
  isEdit?: boolean;
  isNew?: boolean;
  headerData: IlapTermHeaderData;
  onChange: (event: any) => void;
}
export default function UriLabel(props: Props) {
  const { isEdit, isNew, headerData, onChange } = props;
  return isEdit ? (
    <LabelWithContent
      label="URL: "
      isInline={true}
      content={
        <PromineoTextBox
          width={110}
          name="uri"
          placeholder="Type..."
          defaultValue={headerData?.uri}
          onChange={onChange}
        />
      }
    />
  ) : (
    <div className="flex items-center justify-center gap-2">
      <LabelWithAsteriskSymbol label={`URL:`} />
      <LinkIcon />
    </div>
  );
}
