import EyeIcon from "components/icons/EyeIcon";

interface Props {
  isDisabled?: boolean;
  onEditClick: () => void;
  classNames?: string;
}

export default function ViewButton(props: Props) {
  return (
    <div
      id="edit-btn"
      className={`rounded-lg cursor-pointer flex items-center justify-center bg-transparent hover:bg-backgroundGray ${
        props.isDisabled ? "pointer-events-none" : ""
      } ${props.classNames}`}
      onClick={props.onEditClick}
    >
      <EyeIcon />
    </div>
  );
}
