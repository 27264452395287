import TemplateRequest from "interfaces/request/TemplateRequest";
import DetailedTemplateResponse from "interfaces/response/DetailedTemplateResponse";
import OwnTemplateResponse from "interfaces/response/OwnTemplateResponse";
import ReceivedTemplateResponse from "interfaces/response/ReceivedTemplateResponse";
import TemplateShareResponse from "interfaces/response/TemplateShareResponse";
import TemplateShareRequest from "interfaces/request/TemplateShareRequest";
import { deleteDataAsync, getDataAsync, postDataAsync, putDataAsync } from "./API";
import TemplateDropdownResponse from "interfaces/response/TemplateDropdownResponse";
import ResponseBase from "interfaces/response/ResponseBase";

export const getTemplateByIdAsync = async (templateId: number) => {
  let apiUrl = `Templates/${templateId}`;
  let response = await getDataAsync<DetailedTemplateResponse>(apiUrl);

  return response;
};

export const updateTemplateAsync = async (
  templateId: number,
  updateRequest: TemplateRequest
) => {
  let apiUrl = `Templates/${templateId}`;
  let response = await putDataAsync<TemplateRequest, DetailedTemplateResponse>(
    apiUrl,
    updateRequest
  );

  return response;
};

export const createTemplateAsync = async (createRequest: TemplateRequest) => {
  let apiUrl = "Templates";
  let response = await postDataAsync<TemplateRequest, DetailedTemplateResponse>(
    apiUrl,
    createRequest
  );

  return response;
};

export const copyTemplateAsync = async (templateId: number) => {
  let apiUrl = `Templates/${templateId}/Copy`;
  let response = await postDataAsync<any, ResponseBase>(apiUrl, null);

  return response;
};

export const getOwnTemplatesAsync = async () => {
  let apiUrl = "Templates/Own";
  let response = await getDataAsync<OwnTemplateResponse[]>(apiUrl);

  return response;
};

export const getReceiviedTemplatesAsync = async () => {
  let apiUrl = "Templates/Received";
  let response = await getDataAsync<ReceivedTemplateResponse[]>(apiUrl);

  return response;
};

export const getTemplateSharedDataListAsync = async (templateId: number) => {
  let apiUrl = `Templates/${templateId}/Shared`;
  let response = await getDataAsync<TemplateShareResponse[]>(apiUrl);

  return response;
};

export const shareTemplateAsync = async (
  shareRequest: TemplateShareRequest
) => {
  let apiUrl = "Templates/Share";
  let response = await postDataAsync<
    TemplateShareRequest,
    TemplateShareResponse
  >(apiUrl, shareRequest);

  return response;
};

export const getSimplifiedTemplatesAsync = async () => {
  let apiUrl = "Templates/Simplified";
  let response = await getDataAsync<TemplateDropdownResponse>(apiUrl);
  
  return response;
};

export const deleteTemplateByIdAsync = async (templateId: number) => {
  let apiUrl = `Templates/${templateId}`;

  await deleteDataAsync(apiUrl, false);
  return templateId;
};
