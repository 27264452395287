import { StepOneNewIeaProps } from "features/ieas/active-ieas/new-iea/StepOneNewIEA";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import { useEffect, useMemo, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";

interface Props {
  configsForExchangeAgreement: ConfigForExchangeAgreementResponse[];
}

interface IeaCoreInfoRequest {
  title: string;
  exchangeRole: ConfigDirection;
  templateId: number;
  partnerTenantId: number;
  partnerRepresentativeId: number;
}

const defaultCoreInfoRequest: IeaCoreInfoRequest = {
  title: "",
  exchangeRole: ConfigDirection.Receiving,
  partnerRepresentativeId: 0,
  partnerTenantId: 0,
  templateId: 0,
};

export default function useStepOneNewIeaDataHook(props: Props): {
  stepOneProps: StepOneNewIeaProps;
  coreInfoRequest: IeaCoreInfoRequest;
  isCoreInfoRequestValid: boolean;
} {
  const [isValidRequest, setIsValidRequest] = useState(false);

  const [coreInfoRequest, setCoreInfoRequest] = useState<IeaCoreInfoRequest>(
    defaultCoreInfoRequest
  );

  useEffect(() => {
    let isValid = true;
    if (
      !coreInfoRequest.title ||
      !coreInfoRequest.templateId ||
      coreInfoRequest.exchangeRole === undefined ||
      coreInfoRequest.partnerRepresentativeId <= 0 ||
      coreInfoRequest.partnerTenantId <= 0
    ) {
      isValid = false;
    }
    setIsValidRequest(isValid);
  }, [coreInfoRequest]);

  const onTitleChange = (value: string) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, title: value };
    });
  };

  const onDirectionChange = (direction: ConfigDirection) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, exchangeRole: direction };
    });
  };

  const onTemplateIdChange = (value: number) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, templateId: value };
    });
  };

  const onPartnerTenantIdChange = (value: number) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, partnerTenantId: value };
    });
  };

  const onPartnerRepresentativeIdChange = (value: number) => {
    setCoreInfoRequest((prev) => {
      return { ...prev, partnerRepresentativeId: value };
    });
  };

  const response = useMemo(() => {
    return {
      stepOneProps: {
        coreInfoProps: {
          ownerConfiguration: props.configsForExchangeAgreement,
          direction: coreInfoRequest.exchangeRole,
          templateId: coreInfoRequest.templateId,
          onTitleChange,
          onDirectionChange,
          onTemplateIdChange,
          onPartnerTenantIdChange,
          onPartnerRepresentativeIdChange,
        },
      },
      coreInfoRequest: coreInfoRequest,
      isCoreInfoRequestValid: isValidRequest,
    };
  }, [coreInfoRequest, props.configsForExchangeAgreement, isValidRequest]);

  return response;
}
