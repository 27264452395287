import { ReactNode } from "react";
import IEAInfoTitle from "./IEAInfoTitle";

interface Props {
  title: string;
  bodyHeight: string;
  content: ReactNode;
  bodyIcon?: ReactNode;
}

export default function IEAContentBodyWithTitle(props: Props) {
  const { title, bodyHeight, content, bodyIcon } = props;

  return (
    <div className="flex justify-center">
      <div className="w-full space-y-2">
        <div className="flex">
          <IEAInfoTitle title={title} />
          {bodyIcon}
        </div>
        <div
          className={`${bodyHeight} bg-white rounded-lg border border-lightGray`}
        >
          <div className="m-4">{content}</div>
        </div>
      </div>
    </div>
  );
}
