import "./styles/PromineoDropdownButton.css";
import DropDownButton, {
  IDropDownButtonOptions,
} from "devextreme-react/drop-down-button";

export default function PromineoDropdownButton(props: IDropDownButtonOptions) {
  const { className, ...rest } = props;
  return (
    <>
      <DropDownButton
        {...rest}
        className={`promineo-dropdown-button ${className ?? ""}`}
      />
    </>
  );
}
